import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-hobbies-and-intrests',
  templateUrl: './hobbies-and-intrests.component.html',
  styleUrls: ['./hobbies-and-intrests.component.css']
})
export class HobbiesAndIntrestsComponent implements OnInit {
  hobbiesForm: FormGroup;
  currentUser: any;
  submitted: boolean;
  loading: boolean;
  alertService: any;
  postForm: any;
  errorMessage = '';
  userData: any = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getProfile();
    this.initForm();
  }
  initForm() {
    this.hobbiesForm = this.formBuilder.group({
      userId: this.currentUser.success.user.id,
      hobby1: [this.userData.hobby1],
      hobby2: [this.userData.hobby2],
      hobby3: [this.userData.hobby3],
      hobby4: [this.userData.hobby4],
    });
  }
  getProfile() {
    const data = {
      id: this.currentUser.success.user.id,
    };
    this.userService.getProfile(data).pipe(first()).subscribe(
      (userData) => {
        this.userData = userData[0];
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }

    );
  }

  setHobbies() {
    this.submitted = true;
    this.loading = true;
    if (this.hobbiesForm.invalid) {
      this.loading = false;
      return;
    }
    this.userService.updateProfile(this.hobbiesForm.value).pipe(first()).subscribe(
      data => {
        console.log(data);
        this.toastrService.success('Your Profile has been updated', 'Message!');
        this.loading = false;
        this.getProfile();

      },
      error => {
        this.toastrService.error('Something went wrong please Try Again!', 'Message!');
        this.loading = false;
        this.getProfile();
      }
    );
    this.hobbiesForm.reset();
  }

}
