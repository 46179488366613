import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { IffhsService } from 'src/app/data-services/iffhs.service';

@Component({
  selector: 'app-team-profile',
  templateUrl: './team-profile.component.html',
  styleUrls: ['./team-profile.component.css']
})
export class TeamProfileComponent implements OnInit {
  @ViewChild('newsSection') public newsSection: ElementRef;
  id: number;
  countryData = [];
  searchText: any;
  currentUser: any;
  status: boolean;
  isLoaderVisible = false;
  selectNews: any = {};
  news = [];
  slider: any = {};
  blogs = [];
  items = [];
  pageOfItems: Array<any>;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getNews();
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).subscribe((res: any) => {
      this.countryData = res;
      this.isLoaderVisible = false;
      // localStorage.setItem('countryData', JSON.stringify(this.countryData));
    });
  }
  public moveToStructure(): void {
    this.newsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getNews() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getNews(data).subscribe((res: any) => {
      this.news = res.news;
      this.blogs = res.blogs;
      this.items = this.news;
      this.isLoaderVisible = false;
    });
  }
  onClick(_new) {
    this.slider = _new;
  }
  openModal(modal: any, _new: any) {
    this.selectNews = _new;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

}
