import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.css']
})
export class VideoCallComponent implements OnInit {
  searchText = '';
  constructor() { }

  members = [
    {
      src: '../../../assets/img/committee/saleh.png',
      id: '22',
      name: 'Shaikh Saleh Salem',
      nationality: 'Saudi Arabia',
      position: 'President',
      flag: '../../../assets/svg-icons/1x1/sa.svg',
      year: '2014 - Now'
    },
    {
      src: '../../../assets/img/committee/Robert.JPG',
      id: '23',
      name: 'Robert Ley',
      nationality: 'France',
      position: 'former president',
      flag: '../../../assets/svg-icons/1x1/fr.svg',
      year: '2013 - 2014'
    },
    {
      src: '../../../assets/img/committee/saqr.png',
      id: '24',
      name: 'Mohmad bin Saqr',
      nationality: 'United Arab Emirates',
      position: 'former president',
      flag: '../../../assets/svg-icons/1x1/ae.svg',
      year: '2011 - 2013'
    },
    {
      src: '../../../assets/img/committee/Hector.JPG',
      id: '25',
      name: 'Hector Macias',
      position: 'Executive Committee',
      nationality: 'Argentina',
      flag: '../../../assets/svg-icons/1x1/ar.svg'
    },
    {
      src: '../../../assets/img/committee/Clovis.JPG',
      id: '26',
      name: 'Clovis Martins Da Silva',
      position: 'Executive Committee',
      nationality: 'Brazil',
      flag: '../../../assets/svg-icons/1x1/br.svg'
    },
    {
      src: '../../../assets/img/committee/Jorgen.JPG',
      id: '27',
      name: 'Jorgen Nielsen',
      position: 'Executive Committee',
      nationality: 'Denmark',
      flag: '../../../assets/svg-icons/1x1/dk.svg'
    },
    {
      src: '../../../assets/img/committee/Khaled.JPG',
      id: '28',
      name: 'Khaled Abul OYON',
      position: 'Executive Committee',
      nationality: 'Egypt',
      flag: '../../../assets/svg-icons/1x1/eg.svg'
    },
    // {
    //   src: '../../../assets/img/committee/Robert.JPG',
    //   name: 'Robert Ley',
    //   position: 'Executive Committee',
    //   nationality: 'France',
    //   flag: '../../../assets/svg-icons/1x1/fr.svg'
    // },
    {
      src: '../../../assets/img/committee/Igor.JPG',
      id: '29',
      name: 'Igor Goldes',
      position: 'Executive Committee',
      nationality: 'Russian Federation',
      flag: '../../../assets/svg-icons/1x1/ru.svg'
    },
    {
      src: '../../../assets/img/committee/Jose.JPG',
      id: '30',
      name: 'Jose Del Olmo',
      position: 'Executive Committee',
      nationality: ' Spain',
      flag: '../../../assets/svg-icons/1x1/es.svg'
    },
    {
      src: '../../../assets/img/committee/Jasem.JPG',
      id: '31',
      name: 'Jasem Al-Sayed',
      position: 'Executive Committee',
      nationality: 'United Arab Emirates ',
      flag: '../../../assets/svg-icons/1x1/ae.svg'
    },
    {
      src: '../../../assets/img/committee/Ashish.jpg',
      id: '32',
      name: 'Ashish Pendses',
      position: 'Executive Committee',
      nationality: 'India',
      flag: '../../../assets/svg-icons/1x1/in.svg'
    },
    {
      src: '../../../assets/img/committee/Ignatius.jpg',
      id: '33',
      name: 'Ignatius Fon Echekiye',
      position: 'Executive Committee',
      nationality: 'Cameroon ',
      flag: '../../../assets/svg-icons/1x1/cm.svg'
    },
    {
      src: '../../../assets/img/committee/Marcelo.jpg',
      id: '34',
      name: 'Marcelo Assaf',
      position: 'Executive Committee',
      nationality: 'Mexico',
      flag: '../../../assets/svg-icons/1x1/mx.svg'
    },
    {
      src: '../../../assets/img/committee/Fani.jpg',
      id: '35',
      name: 'Fani Stipkovic',
      position: 'Executive Committee',
      nationality: 'Croatia',
      flag: '../../../assets/svg-icons/1x1/hr.svg'
    },
    {
      src: '../../../assets/img/committee/Imed.jpg',
      id: '36',
      name: 'Imed Kilani',
      position: 'Executive Committee',
      nationality: 'Tunisia',
      flag: '../../../assets/svg-icons/1x1/tn.svg'
    },
  ];
  ngOnInit(): void {
  }

}
