import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-conf-timeline',
  templateUrl: './conf-timeline.component.html',
  styleUrls: ['./conf-timeline.component.css']
})
export class ConfTimelineComponent implements OnInit {
  @ViewChild('newsSection') public newsSection: ElementRef;
  id: number;
  confData: any = {};
  searchText: any;
  currentUser: any;
  status: boolean;
  isLoaderVisible = false;
  selectNews: any = {};
  news = [];
  slider: any = {};
  blogs = [];
  items = [];
  pageOfItems: Array<any>;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getNews();

  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  moveToStructure(): void {
    this.newsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getNews() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getNews(data).subscribe((res: any) => {
      this.news = res.news;
      this.blogs = res.blogs;
      this.items = this.news;
      this.isLoaderVisible = false;
    });

  }
  onClick(_new) {
    this.slider = _new;
  }
  openModal(modal: any, _new: any) {
    this.selectNews = _new;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }

}
