<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>

<!-- <div class="header-spacer header-spacer-small"></div> -->


<!-- Main Header Groups -->

<div class="main-header">
    <div class="content-bg-wrap bg-group"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Manage your Friend Groups</h1>
                    <p style="font-size: 1.5rem;">Welcome to your friends' groups. 
                        Do you want to know what your close friends have been up to? 
                        With the Groups page, you will be able to do that easily. Moreover, you will be able to organize your list, 
                        categorize your friends' list so that you only see the newsfeed of friends placed inside the group. All features are just a click away!
                        </p>
                </div>
            </div>
        </div>
    </div>

    <img class="img-bottom" src="../../../assets/img/group-bottom.png" alt="friends">
</div>

<!-- ... end Main Header Groups -->

<!-- Main Content Groups -->

<div class="container">
    <div class="row">
        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">

            <!-- Friend Item -->

            <div class="friend-item friend-groups create-group" data-mh="friend-groups-item">

                <a class="  full-block" data-toggle="modal" data-target="#create-friend-group-1"></a>
                <div class="content">

                    <a class="  btn btn-control bg-blue" data-toggle="modal" data-target="#create-friend-group-1">
                        <svg class="olymp-plus-icon">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-plus-icon"></use>
                        </svg>
                    </a>

                    <div class="author-content">
                        <a href="javascript:void(0);" class="h5 author-name">Add Group</a>
                        <!-- <div class="country">6 Friends in the Group</div> -->
                    </div>

                </div>

            </div>

            <!-- ... end Friend Item -->
        </div>

        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <!-- Friend Item -->

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                </use>
                            </svg>
                            <ul class="more-dropdown">
                                <li>
                                    <a href="javascript:void(0);">Edit Group</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Delete Group</a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Turn Off Notifications</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <img src="../../../assets/img/group-profile.png" alt="Olympus">
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name">My Family</a>
                                <div class="country">6 Friends in the Group</div>
                            </div>
                        </div>

                        <ul class="friends-harmonic">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic5.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic10.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic2.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/avatar30-sm.jpg" alt="author">
                                </a>
                            </li>
                        </ul>


                        <div class="control-block-button">
                            <a class="btn btn-control bg-blue" data-toggle="modal"
                                data-target="#create-friend-group-add-friends">
                                <svg class="olymp-happy-faces-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-faces-icon">
                                    </use>
                                </svg>
                            </a>

                            <a href="javascript:void(0);" class="btn btn-control btn-grey-lighter">
                                <svg class="olymp-settings-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>
                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>
        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <!-- Friend Item -->

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                </use>
                            </svg>
                            <ul class="more-dropdown">
                                <li>
                                    <a href="javascript:void(0);">Edit Group</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Delete Group</a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Turn Off Notifications</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <img src="../../../assets/img/group-profile.png" alt="photo">
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name">Daydreams Coworkers</a>
                                <div class="country">24 Friends in the Group</div>
                            </div>
                        </div>

                        <ul class="friends-harmonic">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic1.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic2.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic3.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic4.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic5.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic6.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic9.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="all-users bg-blue">+15</a>
                            </li>
                        </ul>

                        <div class="control-block-button">
                            <a class="  btn btn-control bg-blue" data-toggle="modal"
                                data-target="#create-friend-group-add-friends">
                                <svg class="olymp-happy-faces-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-faces-icon">
                                    </use>
                                </svg>
                            </a>

                            <a href="javascript:void(0);" class="btn btn-control btn-grey-lighter">
                                <svg class="olymp-settings-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                    </use>
                                </svg>
                            </a>
                        </div>

                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>
        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <!-- Friend Item -->

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                </use>
                            </svg>
                            <ul class="more-dropdown">
                                <li>
                                    <a href="javascript:void(0);">Edit Group</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Delete Group</a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Turn Off Notifications</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <img src="../../../assets/img/group-profile.png" alt="photo">
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name">Close Friends</a>
                                <div class="country">4 Friends in the Group</div>
                            </div>
                        </div>

                        <ul class="friends-harmonic">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic5.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic10.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                        </ul>


                        <div class="control-block-button">
                            <a class="  btn btn-control bg-blue" data-toggle="modal"
                                data-target="#create-friend-group-add-friends">
                                <svg class="olymp-happy-faces-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-faces-icon">
                                    </use>
                                </svg>
                            </a>

                            <a href="javascript:void(0);" class="btn btn-control btn-grey-lighter">
                                <svg class="olymp-settings-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>


                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>

        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <!-- Friend Item -->

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                </use>
                            </svg>
                            <ul class="more-dropdown">
                                <li>
                                    <a href="javascript:void(0);">Edit Group</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">Delete Group</a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Turn Off Notifications</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <img src="../../../assets/img/group-profile.png" alt="photo">
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name">Freelance Clients</a>
                                <div class="country">15 Friends in the Group</div>
                            </div>
                        </div>

                        <ul class="friends-harmonic">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic1.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic2.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic3.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic4.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic5.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic6.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic9.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);" class="all-users bg-blue">+6</a>
                            </li>
                        </ul>

                        <div class="control-block-button">
                            <a class="  btn btn-control bg-blue" data-toggle="modal"
                                data-target="#create-friend-group-add-friends">
                                <svg class="olymp-happy-faces-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-faces-icon">
                                    </use>
                                </svg>
                            </a>

                            <a href="javascript:void(0);" class="btn btn-control btn-grey-lighter">
                                <svg class="olymp-settings-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>
                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>
        <div class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <!-- Friend Item -->

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                </use>
                            </svg>
                            <ul class="more-dropdown">
                                <li>
                                    <a href="javascript:void(0);">Edit Group</a>
                                </li>
                                <li>
                                    <a data-toggle="modal" data-target="#delete-friend-group"> Delete Group</a>
                                </li>
                                <!-- <li>
                                    <a href="javascript:void(0);">Turn Off Notifications</a>
                                </li> -->
                            </ul>
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <img src="../../../assets/img/group-profile.png" alt="photo">
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name">Running Buddies</a>
                                <div class="country">7 Friends in the Group</div>
                            </div>
                        </div>

                        <ul class="friends-harmonic">
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic5.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic10.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic2.jpg" alt="friend">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/avatar30-sm.jpg" alt="author">
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0);">
                                    <img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
                                </a>
                            </li>
                        </ul>

                        <div class="control-block-button">
                            <a class="  btn btn-control bg-blue" data-toggle="modal"
                                data-target="#create-friend-group-add-friends">
                                <svg class="olymp-happy-faces-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-faces-icon">
                                    </use>
                                </svg>
                            </a>

                            <a href="javascript:void(0);" class="btn btn-control btn-grey-lighter">
                                <svg class="olymp-settings-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>
                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>

    </div>
</div>

<!-- ... end Main Content Groups -->



<!-- Window-popup Create Friends Group -->

<div class="modal fade" id="create-friend-group-1" tabindex="-1" role="dialog" aria-labelledby="create-friend-group-1"
    aria-hidden="true">
    <div class="modal-dialog window-popup create-friend-group create-friend-group-1" role="document">
        <div class="modal-content">
            <a routerLink="/groups" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-header">
                <h6 class="title">Create Friend Group</h6>
            </div>

            <div class="modal-body">
                <form [formGroup]="groupForm" (ngSubmit)="createGroup()">
                    <div class="form-group label-floating">
                        <label class="control-label">Group Name</label>
                        <input class="form-control" formControlName="groupName" placeholder="" value="" type="text"
                            [ngClass]="{ 'is-invalid': submitted && f.groupName.errors }">
                    </div>
                    <div *ngIf="submitted && f.groupName.errors" class="invalid-feedback">
                        <div *ngIf="f.groupName.errors.required">group name is required</div>
                    </div>

                    <div class="form-group with-button">
                        <input type="file" class="form-control" formControlName="groupName" placeholder="" value="">

                        <button class="bg-grey">
                            <svg class="olymp-computer-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                            </svg>
                        </button>

                    </div>

                    <div class="form-group label-floating is-select">
                        <svg class="olymp-happy-face-icon">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon"></use>
                        </svg>

                        <select class="selectpicker form-control style-2 show-tick" multiple data-max-options="2"
                            data-live-search="true">
                            <option title="Green Goo Rock" data-content='<div class="inline-items">
											<div class="author-thumb">
												<img src="../../../assets/img/avatar52-sm.jpg" alt="author">
											</div>
												<div class="h6 author-title">Green Goo Rock</div>

											</div>'>Green Goo Rock
                            </option>

                            <option title="Mathilda Brinker" data-content='<div class="inline-items">
												<div class="author-thumb">
													<img src="../../../assets/img/avatar74-sm.jpg" alt="author">
												</div>
												<div class="h6 author-title">Mathilda Brinker</div>
											</div>'>Mathilda Brinker
                            </option>

                            <option title="Marina Valentine" data-content='<div class="inline-items">
												<div class="author-thumb">
													<img src="../../../assets/img/avatar48-sm.jpg" alt="author">
												</div>
												<div class="h6 author-title">Marina Valentine</div>
											</div>'>Marina Valentine
                            </option>

                            <option title="Dave Marinara" data-content='<div class="inline-items">
												<div class="author-thumb">
													<img src="../../../assets/img/avatar75-sm.jpg" alt="author">
												</div>
												<div class="h6 author-title">Dave Marinara</div>
											</div>'>Dave Marinara
                            </option>

                            <option title="Rachel Howlett" data-content='<div class="inline-items">
												<div class="author-thumb">
													<img src="../../../assets/img/avatar76-sm.jpg" alt="author">
												</div>
												<div class="h6 author-title">Rachel Howlett</div>
											</div>'>Rachel Howlett
                            </option>

                        </select>
                    </div>
                    <button [disabled]="loading" class="btn btn-blue btn-lg full-width">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Create Group
                    </button>
                </form>
                <div *ngIf="errorFlag" class="alert alert-success" role="alert" style="color: white;">
                    {{message}}
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ... end Window-popup Create Friends Group -->


<!-- Window-popup Create Friends Group Add Friends -->

<div class="modal fade" id="create-friend-group-add-friends" tabindex="-1" role="dialog"
    aria-labelledby="create-friend-group-add-friends" aria-hidden="true">
    <div class="modal-dialog window-popup create-friend-group create-friend-group-add-friends" role="document">
        <div class="modal-content">
            <a class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">Add Friends to “Freelance Clients” Group</h6>
            </div>

            <div class="modal-body">
                <form class="form-group label-floating is-select">

                    <select class="selectpicker form-control style-2 show-tick" multiple data-max-options="2"
                        data-live-search="true">
                        <option title="Green Goo Rock" data-content='<div class="inline-items">
										<div class="author-thumb">
											<img src="../../../assets/img/avatar52-sm.jpg" alt="author">
										</div>
											<div class="h6 author-title">Green Goo Rock</div>

										</div>'>Green Goo Rock
                        </option>

                        <option title="Mathilda Brinker" data-content='<div class="inline-items">
											<div class="author-thumb">
												<img src="../../../assets/img/avatar74-sm.jpg" alt="author">
											</div>
											<div class="h6 author-title">Mathilda Brinker</div>
										</div>'>Mathilda Brinker
                        </option>

                        <option title="Marina Valentine" data-content='<div class="inline-items">
											<div class="author-thumb">
												<img src="../../../assets/img/avatar48-sm.jpg" alt="author">
											</div>
											<div class="h6 author-title">Marina Valentine</div>
										</div>'>Marina Valentine
                        </option>

                        <option title="Dave Marinara" data-content='<div class="inline-items">
											<div class="author-thumb">
												<img src="../../../assets/img/avatar75-sm.jpg" alt="author">
											</div>
											<div class="h6 author-title">Dave Marinara</div>
										</div>'>Dave Marinara
                        </option>

                        <option title="Rachel Howlett" data-content='<div class="inline-items">
											<div class="author-thumb">
												<img src="../../../assets/img/avatar76-sm.jpg" alt="author">
											</div>
											<div class="h6 author-title">Rachel Howlett</div>
										</div>'>Rachel Howlett
                        </option>

                    </select>
                </form>

                <a routerLink="/groups" class="btn btn-blue btn-lg full-width">Invite Friends</a>
            </div>
        </div>
    </div>
</div>

<!-- ... end Window-popup Create Friends Group Add Friends -->

<!-- Window-popup Delete Friends Group  -->

<div class="modal fade" id="delete-friend-group" tabindex="-1" role="dialog" aria-labelledby="delete-friend-group"
    aria-hidden="true">
    <div class="modal-dialog window-popup create-friend-group delete-friend-group" role="document">
        <div class="modal-content">
            <a class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">Delete “ Group Name ” Group</h6>
            </div>

            <div class="modal-body">
                <label>Are you Sure you want to delete Group</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary">No</button>
                <button type="button" class="btn btn-danger">Yes</button>
            </div>
        </div>
    </div>
</div>

<!-- ... end Window-popup Delete Friends Group  -->








<!-- Window-popup-CHAT for responsive min-width: 768px -->

<div class="ui-block popup-chat popup-chat-responsive" tabindex="-1" role="dialog"
    aria-labelledby="popup-chat-responsive" aria-hidden="true">

    <div class="modal-content">
        <div class="modal-header">
            <span class="icon-status online"></span>
            <h6 class="title">Chat</h6>
            <div class="more">
                <svg class="olymp-three-dots-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                </svg>
                <svg class="olymp-little-delete js-chat-open">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use>
                </svg>
            </div>
        </div>
        <div class="modal-body">
            <div class="mCustomScrollbar">
                <ul class="notification-list chat-message chat-message-field">
                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
                                gonna be handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/author-page.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Don’t worry Mathilda!</span>
                            <span class="chat-message-item">I already bought everything</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
                                gonna be handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>
                </ul>
            </div>

            <form class="need-validation">

                <div class="form-group">
                    <textarea class="form-control" placeholder="Press enter to post..."></textarea>
                    <div class="add-options-message">
                        <a href="javascript:void(0);" class="options-message">
                            <svg class="olymp-computer-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                            </svg>
                        </a>
                        <div class="options-message smile-block">

                            <svg class="olymp-happy-sticker-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon">
                                </use>
                            </svg>

                            <ul class="more-dropdown more-with-triangle triangle-bottom-right">
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat1.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat2.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat3.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat4.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat5.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat6.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat7.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat8.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat9.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat10.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat11.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat12.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat13.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat14.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat15.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat16.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat17.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat18.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat19.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat20.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat21.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat22.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat23.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat24.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat25.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat26.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat27.png" alt="icon">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>

<!-- ... end Window-popup-CHAT for responsive min-width: 768px -->