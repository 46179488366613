<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>


<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>


<div class="container">
    <div class="row">

        <!-- Main Content -->

        <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">

            <div class="ui-block">

                <!-- News Feed Form  -->

                <div class="news-feed-form">
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active inline-items" data-toggle="tab" href="#home-1" role="tab"
                                aria-expanded="true">

                                <svg class="olymp-status-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-status-icon">
                                    </use>
                                </svg>

                                <span>Post</span>
                            </a>
                        </li>

                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content">
                        <div class="tab-pane active" id="home-1" role="tabpanel" aria-expanded="true">
                            <form [formGroup]="postForm" (ngSubmit)="setPost()">
                                <!-- <div class="author-thumb">
                                    <img *ngIf="!currentUser.success.user.avatar" src="assets/img/user.jpg" width="36" height="36" alt="author">
                                    <img *ngIf="currentUser.success.user.avatar" alt="author" [src]="currentUser.success.user.avatar" class="avatar" width="36" height="36">
                                </div> -->
                                <div class="form-group with-icon label-floating is-empty">
                                    <!-- <label class="control-label">Share what you are thinking here...</label> -->
                                    <textarea class="form-control" formControlName="content"
                                        placeholder="Share what you are thinking here..."
                                        [ngClass]="{ 'is-invalid': submitted && f.content.errors }"></textarea>
                                </div>
                                <div class="add-options-message">
                                    <input style="display: none;" type="file" name="image" formControlName="image"
                                        #image (change)="onImageSelect($event)">
                                    <a href="javascript:void(0);" (click)="image.click()" class="options-message"
                                        data-toggle="tooltip" data-placement="top" data-original-title="Add File">
                                        <svg class="olymp-camera-icon">
                                            <use
                                                xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
                                            </use>

                                        </svg>
                                        <Label style="margin: 0px 25px;"> {{imageName}}</Label>
                                    </a>
                                    <input style="display: none;" type="file" name="video" formControlName="video"
                                        #video (change)="onVideoSelect($event)">
                                    <a href="javascript:void(0);" (click)="video.click()" class="options-message"
                                        data-toggle="tooltip" data-placement="top" data-original-title="Add Video">
                                        <i class="fas fa-video"></i>
                                        <Label style="margin: 0px 25px;"> {{videoName}}</Label>
                                    </a>
                                    <button class="btn btn-md-2 btn-primary"> Post </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <!-- ... end News Feed Form  -->
            </div>

            <div id="newsfeed-items-grid">



                <div *ngFor="let post of posts" class="ui-block">
                    <article class="hentry post">
                        <div class="post__author author vcard inline-items">
                            <a routerLink="/profile-details" [queryParams]="{'id': post.user_id}"><img
                                    *ngIf="!post.avatar" src="assets/img/author-main1.webp" alt="author"> </a>
                            <a routerLink="/profile-details" [queryParams]="{'id': post.user_id}"><img
                                    *ngIf="post.avatar" alt="author" [src]="post.avatar"
                                    onerror="this.onerror=null; this.src='assets/img/author-main1.webp';"
                                    class=" avatar img-responsive img-fluid" width="40" height="40"> </a>

                            <div class="author-date">
                                <a class="h6 post__author-name fn" routerLink="/profile-details"
                                    [queryParams]="{'id': post.user_id}">{{post.user.fullName | titlecase}}</a>
                                <div class="post__date">
                                    <time class="published" datetime="2004-07-24T18:18">
                                        {{post.created_at | date}}
                                    </time>
                                </div>
                            </div>

                            <div *ngIf="post.user_id === currentUser.success.user.id" class="more"><svg
                                    class="olymp-three-dots-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                    </use>
                                </svg>
                                <ul class="more-dropdown">
                                    <li>
                                        <a (click)="openEditModal(postEditModal,post)">Edit Post</a>
                                    </li>
                                    <li>
                                        <a (click)="openDeleteModal(postDeleteModal,post)">Delete Post</a>
                                    </li>
                                </ul>
                            </div>

                        </div>

                        <p>
                            {{post.content |titlecase}}
                        </p>
                        <div class="post-thumb">

                            <img *ngIf="post.image" [src]="post.image" alt="Image ">

                            <video *ngIf="post.video" id="my_video_1" class="video-js vjs-default-skin" width="100%"
                                height="auto" controls muted
                                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                                <source [src]="post.video" type='video/mp4' />
                                <source [src]="post.video" type="video/webm">
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <p *ngIf="post.document">Click Here! <a href="{{post.document}}">{{post.content}}</a>.</p>


                        </div>



                        <div class="post-additional-info inline-items">

                            <a (click)="likeUnlike(post.id)" class="post-add-icon inline-items">
                                <img src="assets/svg-icons/unLike.webp">
                                <span>{{post.likesCounter}}</span>
                            </a>

                            <!-- <ul class="friends-harmonic">
                                <li>
                                    <a  href="javascript:void(0);">
                                        <img src="assets/img/friend-harmonic7.jpg" alt="friend">
                                    </a>
                                </li>
                                <li>
                                    <a  href="javascript:void(0);">
                                        <img src="assets/img/friend-harmonic8.jpg" alt="friend">
                                    </a>
                                </li>
                                <li>
                                    <a  href="javascript:void(0);">
                                        <img src="assets/img/friend-harmonic9.jpg" alt="friend">
                                    </a>
                                </li>
                                <li>
                                    <a  href="javascript:void(0);">
                                        <img src="assets/img/friend-harmonic10.jpg" alt="friend">
                                    </a>
                                </li>
                                <li>
                                    <a  href="javascript:void(0);">
                                        <img src="assets/img/friend-harmonic11.jpg" alt="friend">
                                    </a>
                                </li>
                            </ul>

                            <div class="names-people-likes">
                                <a  href="javascript:void(0);">You</a>, <a  href="javascript:void(0);">Elaine</a> and
                                <br>22 more liked this
                            </div> -->


                            <div class="comments-shared">
                                <a (click)="toggle(post.id)" class="post-add-icon inline-items">
                                    <svg class="olymp-speech-balloon-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons.svg#olymp-speech-balloon-icon">
                                        </use>
                                    </svg>
                                    <span>{{post.commentsCounter}}</span>
                                </a>

                            </div>


                        </div>

                        <div class="control-block-button post-control-button">

                            <a (click)="likeUnlike(post.id)" class="btn btn-control">
                                <img src="assets/svg-icons/unLike.webp">
                            </a>

                            <a (click)="toggle(post.id)" class="btn btn-control">
                                <svg class="olymp-comments-post-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>

                    </article>

                    <!-- Comments -->

                    <ul *ngIf="show && show === post.id" class="comments-list">
                        <li *ngFor="let comment of comments" class="comment-item">
                            <div class="post__author author vcard inline-items">
                                <img *ngIf="!comment.avatar" src="assets/img/author-main1.webp" width="30"
                                    height="30" alt="author" class="mCS_img_loaded">
                                <img *ngIf="comment.avatar" alt="author" [src]="comment.avatar" class="mCS_img_loaded"
                                    width="30" height="30">

                                <div class="author-date">
                                    <a class="h6 post__author-name fn"> {{ comment.fullName | titlecase}}</a>
                                    <div class="post__date">
                                        <time class="published" datetime="2004-07-24T18:18">
                                            {{comment.created_at | date}}
                                        </time>
                                    </div>
                                </div>
                                <a (click)="openCommentDeleteModal(commentDeleteModal, comment , post.id)"
                                    class="more"><i *ngIf="currentUser.success.user.id === comment.user_id"
                                        class="fa fa-trash" aria-hidden="true"></i></a>

                            </div>

                            <p>{{ comment.commentsContent}}.</p>

                            <!-- <a href="javascript:void(0);" class="post-add-icon inline-items">
                                <svg class="olymp-heart-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-heart-icon">
                                    </use>
                                </svg>
                                <span>3</span>
                            </a> -->
                            <!-- <a  href="javascript:void(0);" class="reply">Reply</a> -->
                        </li>

                        <!-- Comment Form  -->

                        <form [formGroup]="commentForm" (ngSubmit)="doComment(post.id)"
                            class="comment-form inline-items">

                            <div class="post__author author vcard inline-items">
                                <img *ngIf="!currentUser.success.user.avatar"
                                    src="assets/img/author-main1.webp" alt="author">
                                <img *ngIf="currentUser.success.user.avatar" alt="author"
                                    [src]="currentUser.success.user.avatar" class="avatar" width="28" height="28">

                                <div class="form-group with-icon-right ">
                                    <textarea class="form-control" formControlName="content" placeholder=""></textarea>
                                    <!-- <div class="add-options-message">
                                        <a href="javascript:void(0);" class="options-message" data-toggle="modal"
                                            data-target="#update-header-photo">
                                            <svg class="olymp-camera-icon">
                                                <use
                                                    xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
                                                </use>
                                            </svg>
                                        </a>
                                    </div> -->
                                </div>
                            </div>

                            <button class="btn btn-md-2 btn-primary">Comment
                            </button>

                        </form>
                        <!-- ... end Comment Form  -->

                    </ul>

                    <!-- ... end Comments -->

                    <!-- <a  href="javascript:void(0);" class="more-comments">View more comments <span>+</span></a> -->



                </div>

            </div>

        </main>

        <!-- ... end Main Content -->


        <!-- Left Sidebar -->

        <aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
            <div class="ui-block">

                <!-- W-Weather -->

                <div class="widget w-wethear">
                    <a href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                        </svg></a>

                    <div class="wethear-now inline-items">
                        <div class="temperature-sensor">64°</div>
                        <div class="max-min-temperature">
                            <span>58°</span>
                            <span>76°</span>
                        </div>

                        <svg class="olymp-weather-partly-sunny-icon">
                            <use
                                xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                            </use>
                        </svg>
                    </div>

                    <div class="wethear-now-description">
                        <div class="climate">Partly Sunny</div>
                        <span>Real Feel: <span>67°</span></span>
                        <span>Chance of Rain: <span>49%</span></span>
                    </div>

                    <ul class="weekly-forecast">

                        <li>
                            <div class="day">sun</div>
                            <svg class="olymp-weather-sunny-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-sunny-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">60°</div>
                        </li>

                        <li>
                            <div class="day">mon</div>
                            <svg class="olymp-weather-partly-sunny-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                                </use>
                            </svg>
                            <div class="temperature-sensor-day">58°</div>
                        </li>

                        <li>
                            <div class="day">tue</div>
                            <svg class="olymp-weather-cloudy-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-cloudy-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">67°</div>
                        </li>

                        <li>
                            <div class="day">wed</div>
                            <svg class="olymp-weather-rain-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-rain-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">70°</div>
                        </li>

                        <li>
                            <div class="day">thu</div>
                            <svg class="olymp-weather-storm-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-storm-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">58°</div>
                        </li>

                        <li>
                            <div class="day">fri</div>
                            <svg class="olymp-weather-snow-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-snow-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">68°</div>
                        </li>

                        <li>
                            <div class="day">sat</div>

                            <svg class="olymp-weather-wind-icon-header">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-wind-icon-header">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">65°</div>
                        </li>

                    </ul>

                    <div class="date-and-place">
                        <h5 class="date">{{today | date: 'fullDate'}}</h5>
                        <h5 class="date">{{today | date: 'mediumTime'}}</h5>
                        <!-- <div class="place">{{currentUser.success.user.countryName}}</div> -->
                    </div>

                </div>

                <!-- W-Weather -->
            </div>

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Polling </h6>
                </div>
                <div class="ui-block-content">

                    <!-- W-Pool -->

                    <ul class="widget w-pool">
                        <li>
                            <p>Who is your Favorite Player? </p>
                        </li>
                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Lionel Messi
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="62" data-from="0"></span>
                                        <span class="units">62%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 62%"></span>
                                </div>

                                <div class="counter-friends">12 friends voted </div>


                            </div>
                        </li>

                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Cristiano Ronaldo
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="27" data-from="0"></span>
                                        <span class="units">27%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 27%"></span>
                                </div>
                                <div class="counter-friends">7 friends voted </div>


                            </div>
                        </li>

                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Neymar Jr.
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="11" data-from="0"></span>
                                        <span class="units">11%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 11%"></span>
                                </div>

                                <div class="counter-friends">2 people voted </div>


                            </div>
                        </li>
                    </ul>

                    <!-- .. end W-Pool -->
                    <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                        style="color: #3f4257;font-size: 25px;">Vote
                        Now!</a>
                </div>
            </div>
        </aside>

        <!-- ... end Left Sidebar -->


        <!-- Right Sidebar -->

        <aside class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="ui-block-content">
                    <a routerLink="/voting">
                        <img src="assets/img/voting.gif" alt="photo">
                    </a>
                    <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                        style="color: #3f4257;font-size: 25px;">Vote
                        Now!</a>
                </div>

            </div>


            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Friend Suggestions</h6>
                    <!-- <a href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg></a> -->
                </div>



                <!-- W-Action -->

                <ul class="widget w-friend-pages-added notification-list friend-requests">
                    <li *ngFor="let friend of suggestedFriends" class="inline-items">
                        <div class="author-thumb">
                            <img *ngIf="!friend.avatar" src="assets/img/author-main1.webp" alt="author"
                                style="width: 100%;">
                            <img *ngIf="friend.avatar" [src]="friend.avatar" alt="author"
                                style="height: 36px;width: 36px;">
                        </div>
                        <div class="notification-event">
                            <a routerLink="/profile-details" [queryParams]="{'id': friend.id}"
                                class="h6 notification-friend">{{ friend.fullName | titlecase }}</a>
                            <!-- <span class="chat-message-item">8 Friends in Common</span> -->
                        </div>
                        <!-- <span class="notification-icon">
							<a routerLink="/profile-details" [queryParams]="{'id': friend.id}" class="accept-request">
								<span class="icon-add without-text">
									<svg class="olymp-happy-face-icon">
										<use
											xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
										</use>
									</svg>
								</span> Add Friend
                        </a>
                        </span> -->
                    </li>



                </ul>

                <!-- ... end W-Action -->
            </div>

            <div class="ui-block">

                <div class="ui-block-title">
                    <h6 class="title"> Folllowers Suggestions</h6>
                    <!-- <a href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg></a> -->
                </div>


                <!-- W-Activity-Feed -->

                <ul class="widget w-friend-pages-added notification-list friend-requests">
                    <li *ngFor="let follower of suggestedFollowers" class="inline-items">
                        <div class="author-thumb">
                            <img *ngIf="!follower.avatar" src="assets/img/author-main1.webp" alt="author"
                                style="width: 100%;">
                            <img *ngIf="follower.avatar" [src]="follower.avatar" alt="author"
                                style="height: 36px;width: 36px;">
                        </div>
                        <div class="notification-event">
                            <a routerLink="/profile-details" [queryParams]="{'id': follower.id}"
                                class="h6 notification-friend">{{ follower.fullName | titlecase}}</a>
                            <!-- <span class="chat-message-item">8 Friends in Common</span> -->
                        </div>
                        <!-- <span class="notification-icon">
							<a routerLink="/profile-details" [queryParams]="{'id': follower.id}" class="accept-request">
								<span class="icon-add without-text">
									<svg class="olymp-happy-face-icon">
										<use
											xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
										</use>
									</svg>
								</span> follow
                        </a>
                        </span> -->
                    </li>



                </ul>

                <!-- .. end W-Activity-Feed -->
            </div>


            <div class="ui-block">

                <div class="widget w-action">

                    <img src="assets/img/logo.webp" alt="Football Families">
                    <div class="content">
                        <h4 class="title">Football Families</h4>
                        <span>THE BEST SOCIAL NETWORK THEME IS HERE!</span>
                        <!-- <a href="javascript:void(0);" class="btn btn-bg-secondary btn-md">Register Now!</a> -->
                    </div>
                </div>
            </div>

        </aside>

        <!-- ... end Right Sidebar -->

    </div>
</div>


<!-- Delete Modal -->
<div class="modal fade" bsModal #postDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Post</h5>
                <button type="button" class="close" (click)="postDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you Sure you want to delete Post</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="postDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deletePost(postDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" bsModal #commentDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Comment</h5>
                <button type="button" class="close" (click)="commentDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you Sure you want to delete Comment</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="commentDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteComment(commentDeleteModal,selectPostId)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->

<!-- Edit   Modal -->
<div class="modal fade" bsModal #postEditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Edit Post </h5>
                <button type="button" class="close" (click)="closeEditModal(postEditModal)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #postEditForm="ngForm">
                    <!-- <div class="form-group">
						<label for="projectName">
							<b>Post Content </b>
						</label>
						<input type="text" class="form-control" name="content" #content="ngModel"
							[(ngModel)]="selectPost.content" autocomplete="off" required>
					</div> -->
                    <div class="form-group">
                        <label style="padding-right: 290px;" for="projectDescription">
                            <b>Post Description</b>
                        </label>
                        <textarea class="form-control" name="content" #content="ngModel"
                            [(ngModel)]="selectPost.content" rows="2" required autocomplete="off"></textarea>
                    </div>
                    <div class="form-group">
                        <label for="projectEndDate">
                            <b>Attachment</b>
                        </label>
                        <img *ngIf="selectPost.attachment" [src]="selectPost.attachment" alt="Photo">
                        <input type="file" class="form-control" name="attachment" #attachment="ngModel"
                            [(ngModel)]="selectPost.attachment" (change)="onImageSelect($event)">

                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeEditModal(postEditModal)">Close</button>
                <button type="button" class="btn btn-primary" (click)="updatePost(postEditModal)"
                    [disabled]="!postEditForm.form.valid">Save changes</button>
            </div>
        </div>
    </div>
</div>
<!-- Edit   Modal -->