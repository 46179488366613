<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title">
                        <h1 class="text-center" style="font-weight: 500;">{{'associations.title' | translate}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="ui-block">
                <div class="friend-item">
                    <a routerLink="/fifa">
                        <div class="card">
                            <img src="assets/img/fifa-2.webp" class="img-responsive card-img"
                                style="width: 100%; height: auto;">
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="ui-block">
                <div class="friend-item">
                    <a routerLink="/non-fifa">
                        <div class="card">
                            <img src="assets/img/non-fifa-4.webp" class="img-responsive card-img"
                                style="width: 100%; height: auto;">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
