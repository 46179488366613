import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-team-coach',
  templateUrl: './team-coach.component.html',
  styleUrls: ['./team-coach.component.css']
})
export class TeamCoachComponent implements OnInit {

  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  status: boolean;
  coaches = [];
  selectFriend: any = {};
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getCoaches();
  }
  countryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
  }
  getCoaches() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCoaches(data).subscribe((res: any) => {
      this.coaches = res.coaches;
      this.isLoaderVisible = false;
    });
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getCoaches(data).subscribe((res: any) => {
      this.coaches = res.coaches;
      this.isLoaderVisible = false;
      // this.items = this.players;
      // for (let i = 0; i < this.players.length; i++) {
      //   var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
      //   this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      // }
    });
  }


}
