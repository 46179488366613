import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.css']
})
export class AssociationsComponent implements OnInit {

  language = '';
  direction;
  defaultLanguage;
  constructor(
    private messageService: MessageService) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

}
