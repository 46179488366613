import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';

@Component({
  selector: 'app-conf-history',
  templateUrl: './conf-history.component.html',
  styleUrls: ['./conf-history.component.css']
})
export class ConfHistoryComponent implements OnInit {
  id: number;
  confederationData: any = {};
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getConfederation();
  }
  getConfederation() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getConfederation(data).subscribe((res: any) => {
      this.confederationData = res.continent[0];
      this.isLoaderVisible = false;
    });
  }

}
