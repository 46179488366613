import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import {
  NgForm,
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.css'],
})
export class ProfileHeaderComponent implements OnInit {
  editProfileForm: FormGroup;
  currentUser: any;
  userData: any = {};
  loading: boolean;
  errorFlag: boolean;
  imageUrl: any = {};
  public imagePath;
  imgURL: any;
  visible: boolean = false;
  public webcamImage: WebcamImage = null;
  language = '';
  direction = '';
  defaultLanguage;
  /********************************Camera ******************** */
  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();

  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();
  /********************************************************************* */

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private messageService: MessageService
  ) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit() {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.editProfileForm = this.formBuilder.group({
      userId: this.currentUser.success.user.id,
      avatar: ['', Validators.required],
      // banner: ['', Validators.required],
    });
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    // tslint:disable-next-line: no-console
    console.info('received webcam image', webcamImage);
    this.pictureTaken.emit(webcamImage);
    console.log(webcamImage);
    this.webcamImage = webcamImage;
    this.editProfileForm.get('avatar').setValue(webcamImage.imageAsDataUrl);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(userData => this.userData = userData[0]);
  // }
  // initForm() {
  //   this.editProfileForm = this.formBuilder.group({
  //     id: this.currentUser.success.user.id,
  //     avatar: [this.userData.avatar],
  //     banner: [this.userData.banner],

  //   });
  // }
  onFileSelect(event) {
    this.visible = true;
    const avatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only Images are Supported', 'Message!');
      return;
    }
    this.editProfileForm.get('avatar').setValue(avatar);
  }
  preview(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) === null) {
      this.toastrService.error('Only Images are Supported', 'Message!');
      return;
    }
    const avatar = files[0];
    // console.log(avatar);

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };

    this.editProfileForm.get('avatar').setValue(avatar);
  }

  setPhotos() {
    this.loading = true;
    if (this.editProfileForm.invalid) {
      this.loading = false;
      return;
    }
    const fd = new FormData();
    fd.append('userId', this.editProfileForm.get('userId').value);
    fd.append('avatar', this.editProfileForm.get('avatar').value);
    // fd.append('banner', this.editProfileForm.get('banner').value);

    this.userService
      .updateProfile(fd)
      .pipe(first())
      .subscribe(
        (data) => {
          this.toastrService.success('Your Profile is updated', 'Message!');
          this.loading = false;
          localStorage.setItem('userData', JSON.stringify(this.userData));
        },
        (error) => {
          console.log(error);
          this.loading = false;
          // this.getProfile();
        }
      );
    this.editProfileForm = this.formBuilder.group({
      userId: this.currentUser.success.user.id,
      avatar: [null],
    });
  }
  // handleImage(webcamImage: WebcamImage) {
  //   this.webcamImage = webcamImage;
  // }
  // request(id: any) {
  //   const data = {
  //     userId: this.currentUser.success.user.id,
  //     follower_id: 2,
  //   };

  //   this.userService.request(data).pipe(first()).subscribe(
  //     succuss => {
  //       console.log(data);
  //       console.log('successfully request sent ');
  //       this.loading = false;
  //       this.errorFlag = false;
  //     },
  //     error => {
  //       console.log(error);
  //       this.loading = false;
  //       this.errorFlag = false;
  //     }
  //   );
  // }

  // follow(id: any) {
  //   const data = {
  //     userId: this.currentUser.success.user.id,
  //     follower_id: 2,
  //   };
  //   this.userService.follow(data).pipe(first()).subscribe(
  //     succuss => {
  //       console.log(data);
  //       console.log('successfully ');
  //       this.loading = false;
  //       this.errorFlag = false;
  //     },
  //     error => {
  //       console.log(error);
  //       this.loading = false;
  //       this.errorFlag = false;
  //     }
  //   );
  // }
}
