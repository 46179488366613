import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../data-services/authentication.service';
import { UserService } from '../../data-services/user.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../data-services/alert.service';
import { UserRootObject } from '../../_models/user';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { MessageService } from 'src/app/data-services/message.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // registerForm: FormGroup;
  loginForm: FormGroup;
  forgotForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  visible = false;
  isSubmitted = true;
  errorFlag = false;
  message: any = {};
  token: any;
  errorMessage = '';
  maxDate: Date;
  minDate: Date;
  // minDate = new Date(2000, 0, 1);
  // maxDate = new Date(2020, 0, 1);
  countries: any = [];
  phoneNumberFlag = false;
  dateOfBirthFlag = false;
  communicatorData: any;
  isActivatedVisible = true;
  isLoaderVisible = false;



  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private messageService: MessageService

  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/home']);
    }
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 32850);
    this.maxDate.setDate(this.maxDate.getDate() - 2920);
  }

  ngOnInit() {
    const languageDir = {
      lang: 'en',
      dir: 'ltr',
    };
    localStorage.setItem('language', JSON.stringify(languageDir));
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(50)])],
    });

    this.forgotForm = this.formBuilder.group({
      email: [''],
    });

  }


  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }
  login() {
    this.submitted = true;
    this.errorFlag = false;
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value).pipe(first()).subscribe((Data: UserRootObject) => {
      if (Data.success && Data.success.error !== 'Unauthorised') {
        if (Data.success.user.status) {
          // this.messageService.sendMessage(Data.success);
          localStorage.setItem('isIffhsLoggedIn', JSON.stringify(true));
          this.router.navigate(['/home']);
          this.isLoaderVisible = false;

        } else {
          this.errorFlag = true;
          this.message = 'Please Activate your account';
          this.visible = false;

          this.isLoaderVisible = false;
        }

      } else {
        this.errorFlag = true;
        this.message = 'Invalid username or password';
        this.visible = false;
        this.isLoaderVisible = false;
      }
    },
      (error) => {
        console.log('error');
        this.visible = false;
        this.errorFlag = false;
        this.isLoaderVisible = false;
      }
    );
  }

  get fP() { return this.forgotForm.controls; }
  forgotPassword() {
    if (this.forgotForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    if (this.forgotForm.valid === true) {

      this.userService.forgotPassword(this.forgotForm.value).pipe(first()).subscribe((Data: UserRootObject) => {
        if (Data) {
          this.errorMessage = 'Please check your email for Password!';
          this.isLoaderVisible = false;

        } else {
          this.errorMessage = 'Invalid email address';
          this.isLoaderVisible = false;
        }

      },
        (error) => {
          console.log(error);
          this.isLoaderVisible = false;
        }
      );
    } else {
      console.log('Validation Error');
    }
    this.forgotForm.reset();

  }

  resendEmail() {
    const data = {
      email: this.f.email.value,
    };
    this.userService.resendEmail(data).subscribe((Data: UserRootObject) => {
      if (Data) {
        this.isActivatedVisible = false;
        this.message = 'Please check your email  For Activation !';
      } else {
        this.message = 'Invalid email address';
      }
    });
  }

  // logout(id: number) {
  //   this.userService.delete(id)
  //     .pipe(first())
  //     .subscribe();
  // }
  getCountries() {
    this.userService.getCountries().subscribe((res: any) => {
      this.countries = res;
    });
  }
  getNumber(event) {
  }
  telInputObject(obj) {
    obj.setCountry('sa');
  }

  // onCountryChange(event) {
  //   console.log(event);
  //   this.registerForm.controls['IsdCode'].setValue(event.dialCode);
  //   this.registerForm.controls['IsoCode'].setValue(event.iso2);
  //   // console.log(this.registerForm.value);
  // }


}
