import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PostService } from 'src/app/data-services/post.service';


@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.component.html',
  styleUrls: ['./newsfeed.component.css']
})
export class NewsfeedComponent implements OnInit {
  postForm: FormGroup;
  commentForm: FormGroup;
  currentUser: any;
  suggestedFriends = [];
  suggestedFollowers = [];
  posts: any;
  submitted: boolean;
  errorFlag: boolean;
  visible: boolean;
  userData: any = {};
  selectPost: any = {};
  selectComment: any = {};
  selectPostId: number;
  comments: any;
  public show: number;
  public buttonName: any = 'Show';
  today = new Date();
  imageName = '';
  videoName = '';
  isLoaderVisible = false;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private postService: PostService,
  ) {
    window.scrollTo(0, 0);
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // this.getProfile();
    this.suggestsFriends();
    this.suggestsFollowers();
    this.loadAllPost();

    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: ['', Validators.required],
      image: [''],
      video: [''],
      type: [''],
    });

    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required],
      post_id: '',
    });

  }
  suggestsFriends() {
    this.isLoaderVisible = true;
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getSuggestFriends(data).pipe(first<any[]>()).subscribe(suggestedFriends => this.suggestedFriends = suggestedFriends);
    this.isLoaderVisible = false;
  }

  suggestsFollowers() {
    this.isLoaderVisible = true;
    const data = {
      userId: this.currentUser.success.user.id,
    };

    this.userService.getSuggestFollowers(data).pipe(first<any[]>())
      .subscribe(suggestedFollowers => this.suggestedFollowers = suggestedFollowers);
    this.isLoaderVisible = false;
  }

  /////////////////////////////////////// Posts Code
  loadAllPost() {
    this.isLoaderVisible = true;
    const data = {
      user_id: this.currentUser.success.user.id,
    };
    this.postService.getNewsFeed(data).pipe(first()).subscribe(posts => this.posts = posts);
    this.isLoaderVisible = false;
  }
  onImageSelect(event) {
    const image = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      alert('Only Image  are supported');
      return;
    }
    this.imageName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('image').setValue(image);

  }
  onVideoSelect(event) {
    const video = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/video\/*/) === null) {
      alert('Only video  are supported');
      return;
    }
    this.videoName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('video').setValue(video);

  }
  get f() { return this.postForm.controls; }
  setPost() {
    this.submitted = true;
    this.errorFlag = false;
    if (this.postForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('user_id', this.postForm.get('user_id').value);
    fd.append('content', this.postForm.get('content').value);
    fd.append('type', this.postForm.get('type').value);
    fd.append('image', this.postForm.get('image').value);
    fd.append('video', this.postForm.get('video').value);

    this.postService.post(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.visible = false;
        this.errorFlag = false;
        this.submitted = true;
        this.loadAllPost();
      },
      error => {
        this.isLoaderVisible = false;
        this.visible = false;
        this.errorFlag = false;

      }
    );
    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: [''],
      image: [''],
      type: [''],
    });
    // this.fileName = '';

  }
  deletePost(modal: any) {
    this.isLoaderVisible = true;
    const data = {
      postId: this.selectPost.id,

    };
    this.postService.deletePost(data).subscribe(
      posts => {
        this.isLoaderVisible = false;
        modal.hide();
        this.loadAllPost();
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;

      }
    );
  }
  openDeleteModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  openEditModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  updatePost(modal: any) {
    this.isLoaderVisible = true;
    const data = {
      id: this.selectPost.id,
      content: this.selectPost.content,
      attachment: this.selectPost.attachment,
    };
    this.postService.updatePost(data).subscribe(
      updatedPost => {
        this.isLoaderVisible = false;
        modal.hide();
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
      }
    );
  }

  closeEditModal(modal: any) {
    modal.hide();
    this.loadAllPost();
  }


  ///////////////////////////////////////// Likes Codes

  likeUnlike(id: number) {
    this.isLoaderVisible = true;
    const data = {
      post_id: id,
      user_id: this.currentUser.success.user.id,

    };
    this.postService.likeUnlike(data).pipe(first()).subscribe(
      succuss => {
        this.isLoaderVisible = false;
        this.errorFlag = false;
        this.loadAllPost();
      },
      error => {
        this.isLoaderVisible = false;
        this.errorFlag = false;
      }
    );
  }

  ///////////////////////////////////////// Comments Codes

  doComment(pId: number) {
    this.isLoaderVisible = true;

    if (this.commentForm.valid === true) {
      this.commentForm.value.post_id = pId;
      this.commentForm.value.commenter_id = this.currentUser.success.user.id;
      this.postService.comment(this.commentForm.value).pipe(first()).subscribe(
        succuss => {
          this.isLoaderVisible = false;
          this.loadAllPost();
          this.showComments(pId);
        },
        error => {
          console.log(error);
          this.isLoaderVisible = false;
        }
      );
    } else {
      this.isLoaderVisible = false;
    }
    this.commentForm.reset();

  }

  openCommentDeleteModal(modal: any, comment: any, postId: number) {
    this.selectComment = comment;
    this.selectPostId = postId;
    modal.show();
  }

  deleteComment(modal: any, postId: number) {
    this.isLoaderVisible = true;
    const data = {
      commentId: this.selectComment.id,
      post_id: this.selectPostId,
    };
    this.postService.deleteComment(data).subscribe(
      comments => {
        this.isLoaderVisible = false;
        modal.hide();
        this.showComments(postId);
      },
      error => {
        this.isLoaderVisible = false;
        console.log(error);
      }
    );
  }

  toggle(Id: number) {
    if (this.show !== Id) {
      this.show = Id;
    } else {
      this.show = 0;
    }

    this.showComments(Id);

    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }

  }

  showComments(Id: number) {
    this.isLoaderVisible = true;
    const data = {
      postId: Id,
    };
    this.postService.getAllComments(data).pipe(first()).subscribe(comments => this.comments = comments);
    this.isLoaderVisible = false;

  }


}
