<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>


<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Your Account Dashboard</h1>
                    <p style="font-size: 1.5rem;">You’ll find everything you need from modifying your profile information to browsing notifications and requests to chatting with friends to changing your password to managing your favorite page and endless other features, have fun!
                    </p>
                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->


<!-- Your Account Personal Information -->

<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Personal Information</h6>
                </div>
                <div class="ui-block-content">


                    <!-- Personal Information Form  -->

                    <form *ngIf="updateForm" [formGroup]="updateForm" (ngSubmit)="updateProfile()">
                        <div class="row">
                            <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label ">Your Email <span style="color: red;"> *
                                        </span></label>
                                    <input class="form-control" type="email" name="email" formControlName="email" placeholder="" readonly>
                                </div>
                            </div>
                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First & Middle Name <span style="color: red;"> *
                                        </span></label>
                                    <input class="form-control" placeholder="First & last Name" name="fullName" formControlName="fullName" pattern="[a-zA-Z ]*" readonly type="text">
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Your Gender </label>
                                    <input class="form-control" formControlName="gender" type="text" value="" readonly>
                                    <!-- <select class="form-control" formControlName="gender">
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select> -->
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Martial Status</label>
                                    <select class="form-control" formControlName="martialStatus">
                                        <option value="Married">Married</option>
                                        <option value="Single">Single</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Residence</label>
                                    <select name="residentCountry" id="residentCountry" class="form-control" formControlName="residentCountry">
                                        <option *ngFor="let country of countryList" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                </div>


                            </div>

                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name <span style="color: red;"> *
                                        </span></label>
                                    <input class="form-control" formControlName="lastName" placeholder="Last Name" type="text" value="" readonly>
                                </div>
                                <div class="form-group">
                                    <label class="control-label"> Date of Birth</label>
                                    <input class="form-control" formControlName="dob" type="text" value="{{currentUser.success.user.dob}}" readonly>
                                    <!-- <input class="form-control" placeholder="04/07/1996" bsDatepicker formControlName="dob" [minDate]="minDate" [maxDate]="maxDate" value="currentUser.success.user.dob | short" /> -->

                                    <!-- <span class="input-group-addon" style="top: 12px;">
                                        <svg class="olymp-month-calendar-icon icon">
                                            <use
                                                xlink:href="assets/svg-icons/sprites/icons.svg#olymp-month-calendar-icon">
                                            </use>
                                        </svg>
                                    </span> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Nationality</label>
                                    <input class="form-control" formControlName="countryName" type="text" value="" readonly>
                                    <!-- <select name="countryName" id="countryName" class="form-control" formControlName="countryName">
                                        <option *ngFor="let country of countryList" [value]="country.name">
                                            {{country.name}}</option>
                                    </select> -->
                                </div>

                                <div class="form-group">
                                    <label class="control-label" style="padding-left: 12px;">Mobile Number <span
                                            style="color: red;"> *
                                        </span></label>
                                    <input class="form-control" style="padding-right: 62px;padding-left: 100px;line-height: 2.0;" readonly value="" ng2TelInput (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)" formControlName="mobileNumber">
                                </div>


                            </div>


                            <!-- <div class="col col-lg-4 col-md-4 col-sm-12 col-12">
                                <div class="form-group label-floating is-select">
                                    <label class="control-label">Your State / Province</label>
                                    <select class="selectpicker form-control" formControlName="province">
										<option value="CA">California</option>
										<option value="TE">Texas</option>
									</select>
                                </div>
                            </div> -->
                            <!-- <div class="col col-lg-6 col-md-4 col-sm-12 col-12">
                                <div class="form-group label-floating is-select">
                                    <label class="control-label">Your City</label>
                                    <select class="selectpicker form-control" formControlName="city">
										<option [value]="currentUser.success.user.city">{{currentUser.success.user.city}}</option>
										<option value="SF">San Francisco</option>
										<option value="NY">New York</option>
									</select>
                                </div>
                            </div> -->
                            <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Introduction</label>
                                    <textarea class="form-control" placeholder="Write a little description about you" value="" formControlName="about"></textarea>
                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group with-icon label-floating">

                                    <input class="form-control" placeholder="Enter Your facebook Url" type="text" value="{{ currentUser.success.user.facebook}}" formControlName="facebook">
                                    <i class="fab fa-facebook-f c-facebook" aria-hidden="true"></i>
                                </div>
                                <div class="form-group with-icon label-floating">

                                    <input class="form-control" placeholder="Enter Your Twitter Url" type="text" value="{{ currentUser.success.user.twitter}}" formControlName="twitter">
                                    <i class="fab fa-twitter c-twitter" aria-hidden="true"></i>
                                </div>

                            </div>
                            <div *ngIf="errorMessage != ''" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div *ngIf="errorMessage != ''" class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>
                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <button (click)="reLoad()" class="btn btn-secondary btn-lg full-width">Restore </button>
                            </div>
                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">Save all Changes</button>
                            </div>

                        </div>
                    </form>

                    <!-- ... end Personal Information Form  -->
                </div>
            </div>
        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">

                <!-- Your Profile  -->

                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
                        <h6 class="title">Your PROFILE</h6>
                    </div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0 title">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Profile Settings
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use
                                                xlink:href="assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                            </use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">Personal Information</a>
                                    </li>

                                    <li>
                                        <a routerLink="/change-password">Change Password</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">Hobbies and Interests</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">Notifications</a>
                        <!-- <a routerLink="/notifications" class="items-round-little bg-blue"></a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" [queryParams]="{'id': currentUser.success.user.id}" class="h6 title">Chat /
                            Messages</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">Friend Requests</a>
                        <!-- <a href="friend-requests" class="items-round-little bg-blue">4</a> -->
                    </div>
                    <!-- <div class="ui-block-title ui-block-title-small">
						<h6 class="title">FAVOURITE PAGE</h6>
					</div>
					<div class="ui-block-title">
						<a href="javascript:void(0);" class="h6 title">Create Fav Page</a>
					</div>
					<div class="ui-block-title">
						<a href="javascript:void(0);" class="h6 title">Fav Page Settings</a>
					</div> -->
                </div>

                <!-- ... end Your Profile  -->

            </div>
        </div>
    </div>
</div>

<!-- ... end Your Account Personal Information -->