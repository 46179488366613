<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-profile-header></app-profile-header>



<div class="container" [dir]="direction">
    <div class="row">

        <!-- Main Content -->

        <div class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
            <div id="newsfeed-items-grid">
                <!-- /********************************** Post Form ******************************************************* -->

                <div class="ui-block">

                    <!-- News Feed Form  -->

                    <div class="news-feed-form">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active inline-items" data-toggle="tab" href="#home-1" role="tab"
                                    aria-expanded="true">

                                    <svg class="olymp-status-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-status-icon">
                                        </use>
                                    </svg>

                                    <span>{{'profile.post' | translate}}</span>
                                </a>
                            </li>
                            <!-- <li class="nav-item">
								<a class="nav-link inline-items" data-toggle="tab" href="#profile-1" role="tab"
									aria-expanded="false">
	
									<svg class="olymp-multimedia-icon">
										<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-multimedia-icon">
										</use>
									</svg>
	
									<span>Multimedia</span>
								</a>
							</li> -->

                            <!-- <li class="nav-item">
								<a class="nav-link inline-items" data-toggle="tab" href="#blog" role="tab"
									aria-expanded="false">
									<svg class="olymp-blog-icon">
										<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-blog-icon"></use>
									</svg>
	
									<span>Blog Post</span>
								</a>
							</li> -->
                        </ul>

                        <!-- Tab panes -->
                        <div class="tab-content">
                            <div class="tab-pane active" id="home-1" role="tabpanel" aria-expanded="true">
                                <form [formGroup]="postForm" (ngSubmit)="setPost()">
                                    <!-- <div class="author-thumb">
                                        <img *ngIf="!currentUser.success.user.avatar" src="assets/img/user.jpg" width="40" height="40" alt="author">
                                        <img *ngIf="currentUser.success.user.avatar" alt="author" [src]="userData[0].avatar" class="avatar" width="40" height="40">
                                    </div> -->
                                    <div class="form-group with-icon label-floating is-empty">

                                        <textarea class="form-control" formControlName="content"
                                            placeholder="{{'profile.share_here' | translate}}"></textarea>
                                    </div>
                                    <div class="add-options-message">
                                        <input style="display: none;" type="file" name="image" formControlName="image"
                                            #image (change)="onImageSelect($event)">
                                        <a href="javascript:void(0);" (click)="image.click()" class="options-message"
                                            data-toggle="tooltip" data-placement="top" data-original-title="Add File">
                                            <svg class="olymp-camera-icon">
                                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
                                                </use>

                                            </svg>
                                            <Label style="margin: 0px 25px;"> {{imageName}}</Label>
                                        </a>
                                        <input style="display: none;" type="file" name="video" formControlName="video"
                                            #video (change)="onVideoSelect($event)">
                                        <a href="javascript:void(0);" (click)="video.click()" class="options-message"
                                            data-toggle="tooltip" data-placement="top" data-original-title="Add Video">
                                            <i class="fas fa-video"></i>
                                            <Label style="margin: 0px 25px;"> {{videoName}}</Label>
                                        </a>


                                        <button [disabled]="loading" class="btn btn-md-2 btn-primary">
                                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                            {{'profile.post' | translate}}
                                        </button>


                                    </div>

                                </form>
                            </div>

                            <!-- <div class="tab-pane" id="profile-1" role="tabpanel" aria-expanded="true">
								<form>
									<div class="author-thumb">
										<img src="assets/img/author-page.jpg" alt="author">
									</div>
									<div class="form-group with-icon label-floating is-empty">
										<label class="control-label">Share what you are thinking here...</label>
										<textarea class="form-control" placeholder=""></textarea>
									</div>
									<div class="add-options-message">
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="ADD PHOTOS">
											<svg class="olymp-camera-icon" data-toggle="modal"
												data-target="#update-header-photo">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
												</use>
											</svg>
										</a>
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="TAG YOUR FRIENDS">
											<svg class="olymp-computer-icon">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-computer-icon">
												</use>
											</svg>
										</a>
	
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="ADD LOCATION">
											<svg class="olymp-small-pin-icon">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-small-pin-icon">
												</use>
											</svg>
										</a>
	
										<button class="btn btn-primary btn-md-2">Post </button>
	
	
									</div>
	
								</form>
							</div> -->

                            <!-- <div class="tab-pane" id="blog" role="tabpanel" aria-expanded="true">
								<form>
									<div class="author-thumb">
										<img src="assets/img/author-page.jpg" alt="author">
									</div>
									<div class="form-group with-icon label-floating is-empty">
										<label class="control-label">Share what you are thinking here...</label>
										<textarea class="form-control" placeholder=""></textarea>
									</div>
									<div class="add-options-message">
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="ADD PHOTOS">
											<svg class="olymp-camera-icon" data-toggle="modal"
												data-target="#update-header-photo">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
												</use>
											</svg>
										</a>
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="TAG YOUR FRIENDS">
											<svg class="olymp-computer-icon">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-computer-icon">
												</use>
											</svg>
										</a>
	
										<a href="javascript:void(0);" class="options-message" data-toggle="tooltip"
											data-placement="top" data-original-title="ADD LOCATION">
											<svg class="olymp-small-pin-icon">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-small-pin-icon">
												</use>
											</svg>
										</a>
	
										<button class="btn btn-primary btn-md-2">Post blog</button>
	
	
									</div>
	
								</form>
							</div> -->
                        </div>
                    </div>

                    <!-- ... end News Feed Form  -->
                </div>

                <!-- /*********************************************************************************************** -->

                <div *ngFor="let post of posts;trackBy: trackByFn" class="ui-block">

                    <article class="hentry post">

                        <div class="post__author author vcard inline-items">
                            <img *ngIf="!currentUser.success.user.avatar" src="assets/img/author-main1.webp"
                                class="img-responsive img-fluid" alt="author">
                            <img *ngIf="currentUser.success.user.avatar" alt="author" [src]="userData[0].avatar"
                                onerror="this.onerror=null; this.src='assets/img/author-main1.webp';"
                                class="img-responsive img-fluid avatar" width="132" height="132">

                            <div class="author-date">
                                <a class="h6 post__author-name fn"
                                    routerLink="/profile">{{ userData[0].fullName  | titlecase }}</a>
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{post.created_at | date:'medium'}}
                                    </time>
                                </div>
                            </div>

                            <div class="more">
                                <svg class="olymp-three-dots-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                    </use>
                                </svg>
                                <ul class="more-dropdown">
                                    <li>
                                        <a (click)="openEditModal(postEditModal,post)">{{'profile.edit_post' | translate}}</a>
                                    </li>
                                    <li>
                                        <a (click)="openDeleteModal(postDeleteModal,post)">{{'profile.delete_post' | translate}}</a>
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <p>
                            {{post.content}}
                        </p>
                        <div class="post-thumb">
                            <img *ngIf="post.image" [src]="post.image" class="img-responsive img-fluid" alt="Image ">

                            <video *ngIf="post.video" id="my_video_1" class="video-js vjs-default-skin" width="100%"
                                height="auto" controls muted
                                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                                <source [src]="post.video" type='video/mp4' />
                                <source [src]="post.video" type="video/webm">
                                {{'profile.embedded_video' | translate}}
                            </video>
                            <!-- <p *ngIf="post.document">Click Here! <a href="{{post.document}}">{{post.content}}</a>.</p> -->


                        </div>

                        <div class="post-additional-info inline-items">

                            <a (click)="likeUnlike(post.id)" id="post_id" class="post-add-icon inline-items">

                                <img src="assets/svg-icons/unLike.webp" height="34" width="34">

                                <span>{{post.likesCounter}}</span>
                            </a>
                            <!-- <a (click)="unLike(post.id)" id="post_id" class="post-add-icon inline-items">
								<span>DisLike</span>
								<svg class="olymp-heart-icon">
									<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-heart-icon">
									</use>
								</svg>
							</a> -->

                            <!-- <ul *ngFor="let like of likes" class="friends-harmonic"> -->
                            <!-- <ul class="friends-harmonic">
								<li>
									<a routerLink="/profile">
										<img src="assets/img/friend-harmonic7.jpg" alt="friend">
										<img *ngIf="like.avatar" alt="author" [src]="like.avatar" alt="friend">
									</a>
								</li>
								<li>
									<a routerLink="/profile">
										<img src="assets/img/friend-harmonic8.jpg" alt="friend">
									</a>
								</li>
								<li>
									<a routerLink="/profile">
										<img src="assets/img/friend-harmonic9.jpg" alt="friend">
									</a>
								</li>
								<li>
									<a routerLink="/profile">
										<img src="assets/img/friend-harmonic10.jpg" alt="friend">
									</a>
								</li>
								<li>
									<a routerLink="/profile">
										<img src="assets/img/friend-harmonic8.jpg" alt="friend">
									</a>
								</li>
							</ul>

							<div class="names-people-likes">
								<a routerLink="/profile">Jenny</a>, <a routerLink="/profile">Robert</a> and
								<br>6 more liked this
							</div> -->


                            <div class="comments-shared">
                                <a (click)="toggle(post.id)" class="post-add-icon inline-items">
                                    <svg class="olymp-speech-balloon-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-speech-balloon-icon">
                                        </use>
                                    </svg>
                                    <span>{{post.commentsCounter}}</span>
                                </a>

                                <!-- <a routerLink="/profile" class="post-add-icon inline-items">
									<svg class="olymp-share-icon">
										<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-share-icon">
										</use>
									</svg>
									<span>24</span>
								</a> -->
                            </div>


                        </div>

                        <div class="control-block-button post-control-button">

                            <!-- <a class="btn btn-control featured-post">
								<svg class="olymp-trophy-icon">
									<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-trophy-icon">
									</use>
								</svg>
							</a> -->

                            <a (click)="likeUnlike(post.id)" class="btn btn-control">
                                <img src="assets/svg-icons/unLike.webp">
                            </a>

                            <a (click)="toggle(post.id)" class="btn btn-control">
                                <svg class="olymp-comments-post-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon">
                                    </use>
                                </svg>
                            </a>

                            <!-- <a routerLink="/profile" class="btn btn-control">
								<svg class="olymp-share-icon">
									<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-share-icon">
									</use>
								</svg>
							</a> -->

                        </div>

                    </article>
                    <div *ngIf="show && show === post.id" [id]="post.id" class="modal-body">
                        <div class="mCustomScrollbar">
                            <ul class="notification-list chat-message chat-message-field">
                                <li *ngFor="let comment of comments;trackBy: trackByFn">

                                    <div class="author-thumb">
                                        <img *ngIf="!comment.avatar" src="assets/img/author-main1.webp" width="100%"
                                            height="100%" alt="author" class="mCS_img_loaded">
                                        <img *ngIf="comment.avatar" alt="author" [src]="comment.avatar"
                                            class="mCS_img_loaded" width="100%" height="100%">
                                    </div>
                                    <div class="notification-event">
                                        <a class="h6 post__author-name fn"> {{ comment.fullName | titlecase}} </a>
                                        <span class="notification-date"> <time class="entry-date updated"
                                                datetime="2004-07-24T18:18">{{comment.created_at | date:'medium'}}</time></span>
                                        <span class="chat-message-item"> {{ comment.commentsContent}}</span>

                                    </div>
                                    <div class="more">
                                        <svg class="olymp-three-dots-icon">
                                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                            </use>
                                        </svg>
                                        <ul class="more-dropdown">
                                            <!-- <li>
												<a (click)="openEditModal(postEditModal,post)">Edit Comment</a>
											</li>  -->
                                            <li>
                                                <a
                                                    (click)="openCommentDeleteModal(commentDeleteModal, comment , post.id)">{{'profile.delete' | translate}}
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </li>


                            </ul>
                        </div>

                        <form [formGroup]="commentForm" (ngSubmit)="doComment(post.id)" class="need-validation">

                            <div class="form-group">
                                <textarea class="form-control" placeholder="{{'profile.press_enter' | translate}}"
                                    formControlName="content">
								</textarea>
                            </div>
                            <button [disabled]="cloading" class="btn btn-md-1 btn-primary">
                                <!-- <span *ngIf="cloading" class="spinner-border spinner-border-sm mr-1"></span> -->
                                {{'profile.comment' | translate}}
                            </button>
                        </form>
                    </div>


                    <!-- .. end Post -->
                </div>
            </div>
            <!-- <div *ngIf="posts.length === 0"  class="ui-block-title">
				<h6 class="title" style="text-align: center;">{{ currentUser.success.user.fullName }} You have No Post's yet!
				</h6>
	
			</div> -->

            <!-- <a id="load-more-button" routerLink="/profile" class="btn btn-control btn-more"
				data-load-link="items-to-load.html" data-container="newsfeed-items-grid">
				<svg class="olymp-three-dots-icon">
					<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
				</svg>
			</a> -->
        </div>

        <!-- ... end Main Content -->


        <!-- Left Sidebar -->

        <div class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
            <div class="crumina-sticky-sidebar">
                <div class="sidebar__inner">
                    <div class="ui-block">
                        <div class="ui-block-title">
                            <h6 class="title">{{'profile.profile' | translate}}</h6>
                        </div>
                        <div class="ui-block-content">

                            <!-- W-Personal-Info -->

                            <ul class="widget w-personal-info item-block">
                                <li>
                                    <span class="title">{{'profile.about_me' | translate}}</span>
                                    <span class="text">{{'profile.hi' | translate}} {{userData[0].fullName  | titlecase}},<br>
                                        {{userData[0].about}}</span>
                                </li>
                                <li>
                                    <span class="title">{{'profile.hobbies' | translate}}</span>
                                    <span class="text">{{userData[0].hobby1}}</span>
                                    <span *ngIf="!currentUser.success.user.hobby1" class="text">{{'profile.no_data' | translate}}</span>
                                </li>
                                <li>
                                    <span class="title">{{'profile.fav_games' | translate}}</span>
                                    <span class="text">{{userData[0].hobby2}}</span>
                                    <span *ngIf="!currentUser.success.user.hobby2" class="text">{{'profile.no_data' | translate}}</span>
                                </li>
                                <li>
                                    <span class="title">{{'profile.fav_tv' | translate}}</span>
                                    <span class="text">{{userData[0].hobby3}}</span>
                                    <span *ngIf="!currentUser.success.user.hobby3" class="text">{{'profile.no_data' | translate}}</span>
                                </li>
                                <li>
                                    <span class="title">{{'profile.other_int' | translate}}</span>
                                    <span class="text">{{userData[0].hobby4}}</span>
                                    <span *ngIf="!currentUser.success.user.hobby4" class="text">{{'profile.no_data' | translate}}</span>
                                </li>
                            </ul>

                            <!-- .. end W-Personal-Info -->
                            <!-- W-Socials -->

                            <div class="widget w-socials">
                                <h6 class="title">{{'profile.other_social' | translate}}</h6>
                                <a href="https://{{userData[0].facebook}}" class="social-item bg-facebook">
                                    <i class="fab fa-facebook-f" aria-hidden="true"></i> {{'profile.facebook' | translate}}
                                </a>
                                <a href="https://{{userData[0].twitter}}" class="social-item bg-twitter">
                                    <i class="fab fa-twitter" aria-hidden="true"></i> {{'profile.twitter' | translate}}
                                </a>
                                <!-- <a routerLink="/profile" class="social-item bg-dribbble">
							<i class="fab fa-dribbble" aria-hidden="true"></i>
							Dribbble
						</a> -->
                            </div>


                            <!-- ... end W-Socials -->
                        </div>
                    </div>

                    <div class="ui-block">

                        <!-- W-Weather -->

                        <div class="widget w-wethear">
                            <a href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
                                    </use>
                                </svg></a>

                            <div class="wethear-now inline-items">
                                <div class="temperature-sensor">64°</div>
                                <div class="max-min-temperature">
                                    <span>58°</span>
                                    <span>76°</span>
                                </div>

                                <svg class="olymp-weather-partly-sunny-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                                    </use>
                                </svg>
                            </div>

                            <div class="wethear-now-description">
                                <div class="climate">{{'profile.part_sun' | translate}}</div>
                                <span>{{'profile.real_feel' | translate}} <span>67°</span></span>
                                <span>{{'profile.chance_rain' | translate}} <span>49%</span></span>
                            </div>

                            <ul class="weekly-forecast">

                                <li>
                                    <div class="day">{{'profile.sun' | translate}}</div>
                                    <svg class="olymp-weather-sunny-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-sunny-icon">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">60°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.mon' | translate}}</div>
                                    <svg class="olymp-weather-partly-sunny-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                                        </use>
                                    </svg>
                                    <div class="temperature-sensor-day">58°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.tue' | translate}}</div>
                                    <svg class="olymp-weather-cloudy-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-cloudy-icon">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">67°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.wed' | translate}}</div>
                                    <svg class="olymp-weather-rain-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-rain-icon">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">70°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.thu' | translate}}</div>
                                    <svg class="olymp-weather-storm-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-storm-icon">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">58°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.fri' | translate}}</div>
                                    <svg class="olymp-weather-snow-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-snow-icon">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">68°</div>
                                </li>

                                <li>
                                    <div class="day">{{'profile.sat' | translate}}</div>

                                    <svg class="olymp-weather-wind-icon-header">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-wind-icon-header">
                                        </use>
                                    </svg>

                                    <div class="temperature-sensor-day">65°</div>
                                </li>

                            </ul>

                            <div class="date-and-place">
                                <h5 class="date">{{today | date: 'fullDate'}}</h5>
                                <h5 class="date">{{today | date: 'mediumTime'}}</h5>
                                <!-- <div class="place">{{currentUser.success.user.countryName}}</div> -->
                            </div>

                        </div>

                        <!-- W-Weather -->
                    </div>


                </div>
            </div>

        </div>

        <!-- ... end Left Sidebar -->


        <!-- Right Sidebar -->

        <div class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
            <div class="crumina-sticky-sidebar">
                <div class="sidebar__inner">
                    <div class="ui-block">
                        <div class="ui-block-content">
                            <a routerLink="/voting">
                                <img src="assets/img/voting.gif" class="img-responsive img-fluid" width="100%"
                                    height="auto" alt="photo">
                            </a>
                            <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                                style="color: #3f4257;font-size: 25px;">{{'profile.vote' | translate}}</a>
                        </div>

                    </div>

                    <div *ngIf="friends.length > 0 " class="ui-block">
                        <div class="ui-block-title">
                            <h6 class="title">{{currentUser.success.user.fullName | titlecase}} {{'profile.friends' | translate}}</h6>
                        </div>
                        <div class="ui-block-content">
                            <ul class="widget w-faved-page js-zoom-gallery">
                                <li *ngFor="let friend of friends">
                                    <a routerLink="/profile-details" [queryParams]="{'id': friend.id}">
                                        <img *ngIf="!friend.avatar" src="assets/img/author-main1.webp" alt="author"
                                            style="width: 100%;">
                                        <img *ngIf="friend.avatar" [src]="friend.avatar" alt="author" img-responsive
                                            img-fluid style="height: auto;width: 100%;">
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div class="ui-block">
                        <div class="ui-block-title">
                            <h6 class="title">{{'profile.voting_q' | translate}}</h6>
                        </div>
                        <div class="ui-block-content">

                            <!-- W-Pool -->

                            <ul class="widget w-pool">
                                <li>
                                    <p>Who is your Favorite Player?</p>
                                </li>
                                <li>
                                    <div class="skills-item">
                                        <div class="skills-item-info">
                                            <span class="skills-item-title">
                                                <span class="radio">
                                                    <label>
                                                        <input type="radio" name="optionsRadios">
                                                        Lionel Messi
                                                    </label>
                                                </span>
                                            </span>
                                            <span class="skills-item-count">
                                                <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                                    data-to="62" data-from="0"></span>
                                                <span class="units">62%</span>
                                            </span>
                                        </div>
                                        <div class="skills-item-meter">
                                            <span class="skills-item-meter-active bg-primary" style="width: 62%"></span>
                                        </div>

                                        <div class="counter-friends">12 {{'profile.f_voted' | translate}}</div>


                                    </div>
                                </li>

                                <li>
                                    <div class="skills-item">
                                        <div class="skills-item-info">
                                            <span class="skills-item-title">
                                                <span class="radio">
                                                    <label>
                                                        <input type="radio" name="optionsRadios">
                                                        Cristiano Ronaldo
                                                    </label>
                                                </span>
                                            </span>
                                            <span class="skills-item-count">
                                                <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                                    data-to="27" data-from="0"></span>
                                                <span class="units">27%</span>
                                            </span>
                                        </div>
                                        <div class="skills-item-meter">
                                            <span class="skills-item-meter-active bg-primary" style="width: 27%"></span>
                                        </div>
                                        <div class="counter-friends">7 {{'profile.f_voted' | translate}}</div>


                                    </div>
                                </li>

                                <li>
                                    <div class="skills-item">
                                        <div class="skills-item-info">
                                            <span class="skills-item-title">
                                                <span class="radio">
                                                    <label>
                                                        <input type="radio" name="optionsRadios">
                                                        Neymar Jr.
                                                    </label>
                                                </span>
                                            </span>
                                            <span class="skills-item-count">
                                                <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                                    data-to="11" data-from="0"></span>
                                                <span class="units">11%</span>
                                            </span>
                                        </div>
                                        <div class="skills-item-meter">
                                            <span class="skills-item-meter-active bg-primary" style="width: 11%"></span>
                                        </div>

                                        <div class="counter-friends">2 {{'profile.p_voted' | translate}}</div>


                                    </div>
                                </li>
                            </ul>

                            <!-- .. end W-Pool -->
                            <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                                style="color: #3f4257;font-size: 25px;">{{'profile.vote' | translate}}</a>
                        </div>
                    </div>
                    <div class="ui-block">

                        <!-- W-Calendar -->

                        <div class="w-calendar">
                            <div class="calendar">
                                <header>
                                    <h6 class="month">{{'profile.may' | translate}}</h6>
                                </header>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>{{'profile.mon' | translate}}</td>
                                            <td>{{'profile.tue' | translate}}</td>
                                            <td>{{'profile.wed' | translate}}</td>
                                            <td>{{'profile.thu' | translate}}</td>
                                            <td>{{'profile.fri' | translate}}</td>
                                            <td>{{'profile.sat' | translate}}</td>
                                            <td>{{'profile.sun' | translate}}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td data-month="12" data-day="1">1</td>
                                            <td data-month="12" data-day="2">
                                                2
                                            </td>
                                            <td data-month="12" data-day="3">3</td>
                                            <td data-month="12" data-day="4">4</td>
                                            <td data-month="12" data-day="5">5</td>
                                            <td data-month="12" data-day="6">6</td>
                                            <td data-month="12" data-day="7">7</td>
                                        </tr>
                                        <tr>
                                            <td data-month="12" data-day="8">8</td>
                                            <td data-month="12" data-day="9">9</td>
                                            <td data-month="12" data-day="10">10</td>
                                            <td data-month="12" data-day="11">11</td>
                                            <td data-month="12" data-day="12">12</td>
                                            <td data-month="12" data-day="13">13</td>
                                            <td data-month="12" data-day="14">14</td>
                                        </tr>
                                        <tr>
                                            <td data-month="12" data-day="15">15</td>
                                            <td data-month="12" data-day="16">16</td>
                                            <td data-month="12" data-day="17">17</td>
                                            <td data-month="12" data-day="18">18</td>
                                            <td data-month="12" data-day="19">19</td>
                                            <td data-month="12" data-day="20">20</td>
                                            <td data-month="12" data-day="21">21</td>
                                        </tr>
                                        <tr>
                                            <td data-month="12" data-day="22">22</td>
                                            <td data-month="12" data-day="23">23</td>
                                            <td data-month="12" data-day="24">24</td>
                                            <td data-month="12" data-day="25">25</td>
                                            <td data-month="12" data-day="26">26</td>
                                            <td data-month="12" data-day="27">27</td>
                                            <td data-month="12" data-day="28">28</td>
                                        </tr>
                                        <tr>
                                            <td data-month="12" data-day="29">29</td>
                                            <td data-month="12" data-day="30">30</td>
                                            <td data-month="12" data-day="31">31</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ... end Right Sidebar -->

    </div>
</div>
<!-- [config]="{backdrop: 'static'}" -->
<!-- Delete Modal -->
<div class="modal fade" bsModal #postDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" [dir]="direction">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'profile.delete_post' | translate}}</h5>
                <button type="button" class="close" (click)="postDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>{{'profile.sure_delete' | translate}}</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="postDeleteModal.hide()">{{'profile.no' | translate}}</button>
                <button type="button" class="btn btn-danger" (click)="deletePost(postDeleteModal)">{{'profile.yes' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->

<!-- Delete Modal comment -->
<div class="modal fade" bsModal #commentDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" [dir]="direction">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'profile.delete_comment' | translate}}</h5>
                <button type="button" class="close" (click)="commentDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>{{'profile.sre_comment' | translate}}</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="commentDeleteModal.hide()">{{'profile.no' | translate}}</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteComment(commentDeleteModal,selectPostId)">{{'profile.yes' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal Comemnt -->

<!-- Edit Post  Modal -->

<div class="modal fade" bsModal #postEditModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" [dir]="direction">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'profile.edit_post' | translate}}</h5>
                <button type="button" class="close" (click)="closeEditModal(postEditModal)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #postEditForm="ngForm">
                    <!-- <div class="form-group">
						<label for="projectName">
							<b>Post Content </b>
						</label>
						<input type="text" class="form-control" name="content" #content="ngModel"
							[(ngModel)]="selectPost.content" autocomplete="off" required>
					</div> -->
                    <div class="form-group">
                        <label style="padding-right: 290px;" for="projectDescription">
                            <b>{{'profile.descrip_post' | translate}}</b>
                        </label>
                        <textarea class="form-control" name="content" #content="ngModel"
                            [(ngModel)]="selectPost.content" rows="2" required autocomplete="off"></textarea>
                    </div>
                    <div *ngIf="selectPost.image" class="form-group">
                        <label for="image">
                            <b>{{'profile.image' | translate}}</b>
                        </label>
                        <img [src]="selectPost.image" alt="Photo">
                        <input type="file" class="form-control" name="image" #image="ngModel"
                            [(ngModel)]="selectPost.image" (change)="onImageSelect($event)">

                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeEditModal(postEditModal)">{{'profile.close' | translate}}</button>
                <button type="button" class="btn btn-primary" (click)="updatePost(postEditModal)"
                    [disabled]="!postEditForm.form.valid">{{'profile.save_change' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<!-- Edit Post  Modal -->

<!-- Window-popup Update Header Photo -->

<div class="modal fade" id="update-header-photo" tabindex="-1" role="dialog" aria-labelledby="update-header-photo"
    aria-hidden="true" [dir]="direction">
    <div class="modal-dialog window-popup update-header-photo" role="document">
        <div class="modal-content">
            <a routerLink="/profile" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">{{'profile.cover_update' | translate}}</h6>
            </div>

            <!-- <div class="modal-body">
                <a class="upload-photo-item">
                    <svg class="olymp-computer-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                    </svg>

                    <h6>Profile Photo</h6>
                    <span>Browse from device.</span>
                    <form [formGroup]="editProfileForm" (ngSubmit)="setPhotos()">
                        <input type="file" name="avatar" formcontrolName="avatar" #avatar (change)="onFileSelectAvatar($event)">
                        <button [disabled]="loading" class="btn btn-md-2 btn-primary m-3">
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
							Save
						</button>
                    </form>
                </a>

                <a class="upload-photo-item">
                    <svg class="olymp-computer-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                    </svg>

                    <h6>Cover Photo</h6>
                    <span>Browse from device.</span>
                    <form [formGroup]="editProfileForm" (ngSubmit)="setPhotos()">
                        <input type="file" class="form-control" formcontrolName="banner" name="avatar">
                        <button [disabled]="loading" class="btn btn-md-2 btn-primary m-3">
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
							Save
						</button>
                    </form>
                </a>
            </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="post-photo" tabindex="-1" role="dialog" aria-labelledby="update-header-photo"
    aria-hidden="true" [dir]="direction">
    <div class="modal-dialog window-popup update-header-photo" role="document">
        <div class="modal-content">
            <a routerLink="/profile" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">{{'profile.post_photo' | translate}}</h6>
            </div>

            <div class="modal-body">
                <a class="upload-photo-item">
                    <svg class="olymp-computer-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                    </svg>

                    <h6>{{'profile.up_photo' | translate}}</h6>
                    <span>{{'profile.browse_comp' | translate}}</span>
                    <form>
                        <input type="file" class="form-control" formcontrolName="avatar" name="avatar">
                        <!-- <button [disabled]="loading" class="btn btn-md-2 btn-primary">
							<span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
							Save
						</button> -->
                    </form>
                </a>

                <a routerLink="/profile" class="upload-photo-item" data-toggle="modal"
                    data-target="#choose-from-my-photo">

                    <svg class="olymp-photos-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-photos-icon"></use>
                    </svg>

                    <h6>{{'profile.choose_photo' | translate}}</h6>
                    <span>{{'profile.choose_up' | translate}}</span>
                </a>
            </div>
        </div>
    </div>
</div>