import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AuthenticationService } from '../../../data-services/authentication.service';
import { User } from './../../../_models/user';
import { first } from 'rxjs/operators';
import { UserService } from '../../../data-services/user.service';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/data-services/message.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  direction = '';

  searchForm: FormGroup;
  currentUser: any;
  userData: any = {};
  searchData: any = {};
  loading = false;
  submitted = false;
  data: any;
  public show = false;
  public buttonName: any = 'Show';
  sidebar: any;
  notification: any;
  messages: any[] = [];
  subscription: Subscription;
  isIffhsLoggedIn: boolean;
  switchLanguage;
  defaultLanguage;
  browserLang;
  lang;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private location: Location,
    private messageService: MessageService,
    public translate: TranslateService
  ) {
    this.userData = JSON.parse(localStorage.getItem('userData'));
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    console.log('DEfault language ' + this.defaultLanguage.lang);
    this.direction = this.defaultLanguage.dir;
    this.isIffhsLoggedIn = JSON.parse(localStorage.getItem('isIffhsLoggedIn'));
    this.messageService.selectedLanguage.subscribe(res => {
      this.switchLanguage = res.lang;
      this.direction = res.dir;
      this.lang = res.lang;
    });


    translate.addLangs(['en', 'de', 'ar', 'fr']);
    translate.setDefaultLang(this.defaultLanguage.lang);
    translate.use(this.defaultLanguage.lang);
    this.browserLang = translate.getDefaultLang();
    this.languageChanged();
    this.messageService.selectedLanguage.next(this.browserLang);
  }


  selectedLanguage(language, direction) {

    if (language === 'ar') {
      this.direction = direction;
    }
    const languageDir = {
      lang: language,
      dir: direction,
    };
    localStorage.setItem('language', JSON.stringify(languageDir));
    this.translate.use(language);
    this.switchLanguage = language;
    this.messageService.selectedLanguage.next(languageDir);
  }
  languageChanged() {
    this.translate.use(this.browserLang.match(/de|en|ar|ur|fr/) ? this.browserLang : 'en');
    // console.log(this.lang);
    // this.translate.use(lang);
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.direction = this.defaultLanguage.dir;
    this.subscription = this.messageService.onMessage().subscribe(message => {
      if (message) {
        this.messages.push(message);
      } else {
        this.messages = [];
      }
    });

    this.searchForm = this.formBuilder.group({
      query: ['', Validators.required],
    });
    

  }
  getNotification() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getNotification(data).pipe(first()).subscribe(notification => this.notification = notification);
  }
  toggleSideBar(event) {
    var mobileWidthApp = $('body').outerWidth();
    if (mobileWidthApp <= 560) {
      $(event.target).closest('body').find('.popup-chat-responsive').removeClass('open-chat');
    }

    $(event.target).toggleClass('active');
    $(event.target).closest(this.sidebar).toggleClass('open');
    return false;
  }

  ngAfterViewInit(): void {
    this.sidebar = $('.fixed-sidebar');
  }

  backClicked() {
    this.location.back();
  }
  forwardClicked() {
    this.location.forward();
  }
  get f() {
    return this.searchForm.controls;
  }
  search() {
    this.submitted = true;
    if (this.searchForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService.search(this.searchForm.value).pipe(first<any>()).subscribe(
      searchData => {
        this.data = searchData;
        this.loading = false;
        this.router.navigate(['/search']);
      },
      error => {
        this.loading = false;
      }
    );
  }
  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);

  }


}
