<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-friend-profile-header></app-friend-profile-header>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <div class="h6 title">{{ friendData.fullName | titlecase }}’s Videos</div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="photos.length === 0" class="ui-block-title">
        <h6 class="title" style="text-align: center;"> No Photos Found!
        </h6>

    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <!-- Tab panes -->
            <div class="tab-content">
                <div class="tab-pane active" id="album-page" role="tabpanel">
                    <div class="photo-album-wrapper">
                        <div *ngFor="let photo of photos" class="photo-album-item-wrap col-4-width">
                            <div class="photo-album-item" data-mh="album-item">
                                <div class="photo-item">
                                    <img src="{{photo.image}}" alt="photo" width="250" height="250">
                                    <div class="overlay overlay-dark"></div>
                                    <a href="#" class="post-add-icon">
                                        <svg class="olymp-heart-icon">
                                            <use
                                                xlink:href="../../../../../assets/svg-icons/sprites/icons.svg#olymp-heart-icon">
                                            </use>
                                        </svg>
                                        <span>{{photo.likesCounter}}</span>
                                    </a>

                                    <a href="#" data-toggle="modal" data-target="#open-photo-popup-v1" class="  full-block"></a>
                                </div>
                                <div class="content">
                                    <a href="#" class="title h5">{{photo.content}}</a>
                                    <!-- <span class="sub-title"> Likes: {{photo.likesCounter}} <br> Comments: {{photo.commentsCounter}}</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>