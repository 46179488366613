<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/club-cover.webp" alt="cover" width="1232" height="410">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="https://api.football-family.com/img/clubs/{{countryData.name}}/{{clubData.id}}/avatar.png" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: 100%;" />
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{clubData.fullName}}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive" style="width: 100%; height: 100%;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/club-profile" [queryParams]="{'id': clubData.id}" routerLinkActive="active">Timeline</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-photos" [queryParams]="{'id': clubData.id}" routerLinkActive="active">Media</a>
                                    </li>

                                    <li>
                                        <a routerLink="/club-followers" [queryParams]="{'id': clubData.id}" routerLinkActive="active">Followers</a>
                                    </li>
                                    <li *ngIf="currentUser.success.user.user_type_id == '12' && currentUser.success.user.fullName == clubData.fullName || currentUser.success.user.user_type_id == '1'">
                                        <a routerLink="/club-dashboard" [queryParams]="{'id': clubData.id}" routerLinkActive="active">
                                            <img src="../../../assets/../../../assets/img/dashboard.webp" alt="cover" width="40" height="40">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="control-block-button">
                            <!-- <a (click)="request(clubData.id)" class="btn btn-control bg-blue follow">
								  <img *ngIf="clubData.gender ==='male'"  src="../../../assets/../../../assets/svg-icons/add-user-male.png" alt="video icon" style="width: 50px;">
								  <img *ngIf="clubData.gender !=='male'" src="../../../assets/../../../assets/svg-icons/add-user-male.png" alt="video icon" style="width: 50px;">
                            </a> -->
                            <!-- <a class="btn btn-control bg-primary follow">
                                <svg class="olymp-star-icon">
                                    <use xlink:href="../../../assets/../../../assets/svg-icons/sprites/icons.svg#olymp-star-icon"></use>
                                </svg>

                                <span > Follow</span>
                              
                            </a> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>