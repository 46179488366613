import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { DatePipe } from '@angular/common';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-national-team-dashboard-player',
  templateUrl: './national-team-dashboard-player.component.html',
  styleUrls: ['./national-team-dashboard-player.component.css']
})
export class NationalTeamDashboardPlayerComponent implements OnInit {

  playerForm: FormGroup;
  id: number;
  teamClass: string;
  category: string;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';
  maxDate: Date;
  minDate: Date;
  imageError: string = '';
  positions = [
    {
      id: '1',
      name: 'Goal Keeper',
    },
    {
      id: '2',
      name: 'Defender',
    },
    {
      id: '3',
      name: 'Center back',
    },
    {
      id: '4',
      name: 'Right back',
    },
    {
      id: '5',
      name: 'Left back',
    },
    {
      id: '6',
      name: 'Center Defensive midfielder',
    },
    {
      id: '7',
      name: 'Center midfielder',
    },
    {
      id: '8',
      name: 'Central attacking midfielder',
    },
    {
      id: '9',
      name: 'Right midfielder',
    },
    {
      id: '10',
      name: 'Left midfielder',
    },
    {
      id: '11',
      name: 'Right wing',
    },
    {
      id: '12',
      name: 'Left wing',
    },
    {
      id: '13',
      name: 'Center forward',
    },
    {
      id: '14',
      name: 'Left forward',
    },
    {
      id: '15',
      name: 'Right forward',
    },

  ];

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public datePipe: DatePipe,
    private toastrService: ToastrService,

  ) {
    this.minDate = new Date();
    this.maxDate = new Date();

    this.minDate.setDate(this.minDate.getDate() - 52850);
    this.maxDate.setDate(this.maxDate.getDate() - 2920);
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.teamClass = params['name'];
      this.category = params['category'];
    });
    this.countryDetails();
    this.playerForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      playerPosition: ['', Validators.required],
      playerDob: ['', Validators.required],
      playerAvatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
    this.countryId = this.countryData.id;
    this.countryName = this.countryData.name;
  }
  onFileSelect(event) {
    const playerAvatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.imageError = 'Only images are supported';
      return;
    }
    this.playerForm.get('playerAvatar').setValue(playerAvatar);

  }
  get f() { return this.playerForm.controls; }
  addPlayer() {
    this.submitted = true;
    if (this.playerForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const dob = this.datePipe.transform(this.f.playerDob.value, 'yyyy-MM-dd');
    const fd = new FormData();
    fd.append('countryId', this.playerForm.get('countryId').value);
    fd.append('firstName', this.playerForm.get('firstName').value);
    fd.append('lastName', this.playerForm.get('lastName').value);
    fd.append('playerPosition', this.playerForm.get('playerPosition').value);
    fd.append('playerDob', dob);
    fd.append('teamClass', this.teamClass);
    fd.append('teamClass', this.category);
    fd.append('playerAvatar', this.playerForm.get('playerAvatar').value);
    fd.append('createdBy', this.playerForm.get('createdBy').value);
    console.log(dob);
    this.iffhsService.addPlayer(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Player added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.resetForm();
    this.imageError = '';
  }
  resetForm() {
    this.playerForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: [''],
      lastName: [''],
      playerPosition: [''],
      playerDob: [''],
      playerAvatar: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }


}
