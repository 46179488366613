<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>{{'change-password.dashboard' | translate}}</h1>
                    <p style="font-size: 1.5rem;">{{'change-password.parag' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->



<!-- Your Account Personal Information -->

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'change-password.title' | translate}}</h6>
                </div>
                <div class="ui-block-content">


                    <!-- Change Password Form -->

                    <form [formGroup]="changeForm" (ngSubmit)="changePassword()">
                        <div class="row">

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'change-password.current' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input class="form-control" type="password" formControlName="currentPass" value="" [ngClass]="{ 'is-invalid': submitted && f.currentPass.errors }">
                                    <div *ngIf="submitted && f.currentPass.errors" class="invalid-feedback">
                                        <div *ngIf="f.currentPass.errors.required">{{'change-password.pass_req' | translate}}</div>
                                    </div>
                                    <small class="form-text text-muted error" *ngIf="!changeForm.controls['password'].valid && !isSubmitted">
                                        {{'change-password.hint' | translate}}
                                    </small>
                                </div>
                            </div>

                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label" style="color: #000; font-size: 1rem; text-align: center;">{{'change-password.your_pass' | translate}}
                                        <span style="color: red;"> * </span></label>
                                    <input class="form-control" name="password" placeholder="{{'change-password.enter_pass' | translate}}" formControlName="password" type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required && submitted">{{'change-password.pass_req' | translate}}
                                        </div>
                                    </div>
                                    <small class="form-text text-muted error">
                                        {{'change-password.hint' | translate}}
                                    </small>

                                </div>
                            </div>
                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label" style="color: #000; font-size: 1rem; text-align: center;">{{'change-password.conf_pass' | translate}}
                                        <span style="color: red;"> * </span></label>
                                    <input class="form-control" name="password" placeholder="{{'change-password.conf_pass' | translate}}" formControlName="confirmPassword" type="password" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">{{'change-password.pass_req' | translate}}  {{'change-password.hint' | translate}}</div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">{{'change-password.pass_match' | translate}}</div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="errorMessage != ''" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div *ngIf="errorMessage != ''" class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>


                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button [disabled]="loading" class="btn btn-primary btn-lg full-width">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    {{'change-password.change_pass' | translate}}
                                </button>
                            </div>

                        </div>
                    </form>

                    <!-- ... end Change Password Form -->
                </div>
            </div>
        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">

                <!-- Your Profile  -->

                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
                        <h6 class="title">Your PROFILE</h6>
                    </div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        {{'change-password.prof_sett' | translate}}
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use
                                                xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                            </use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">{{'change-password.per_info' | translate}}</a>
                                    </li>
                                    <!-- <li>
										<a  href="javascript:void(0);">Account Settings</a>
									</li> -->
                                    <li>
                                        <a routerLink="/change-password">{{'change-password.title' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">{{'change-password.hobby_int' | translate}}</a>
                                    </li>
                                    <!-- <li>
										<a  href="javascript:void(0);">Education and Employement</a>
									</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">{{'change-password.notif' | translate}}</a>
                        <!-- <a  href="javascript:void(0);" class="items-round-little bg-primary">8</a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" class="h6 title">{{'change-password.chat_mes' | translate}}</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">{{'change-password.friend_req' | translate}}</a>
                        <!-- <a href="#" class="items-round-little bg-blue">4</a> -->
                    </div>

                </div>

                <!-- ... end Your Profile  -->

            </div>
        </div>
    </div>
</div>

<!-- ... end Your Account Personal Information -->