<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Friends Requests</h1>
                    <p style="font-size: 1.5rem;"> Welcome to your Friends Requests page! You will be able to monitor all your friend requests.</p>

                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->

<!-- Your Account Personal Information -->

<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Friend Requests</h6>

                </div>

                <div *ngIf="friends.length === 0" class="ui-block-title">
                    <h6 class="display-message"> No Requests Found! </h6>

                </div>



                <!-- Notification List Frien Requests -->

                <ul class="notification-list friend-requests">
                    <li *ngFor="let friend of friends">
                        <div class="author-thumb">
                            <a routerLink="/profile-details" [queryParams]="{'id': friend.id}"><img *ngIf="!friend.avatar" src="../../../assets/img/author-main1.webp" width="42" height="42" alt="author"> </a>
                            <a routerLink="/profile-details" [queryParams]="{'id': friend.id}"><img *ngIf="friend.avatar" alt="author" [src]="friend.avatar" class="avatar" width="42" height="42"> </a>
                        </div>
                        <div class="notification-event">
                            <a routerLink="/profile-details" [queryParams]="{'id': friend.id,'role': 'User'}" class="h6 notification-friend">{{ friend.user.fullName | titlecase}}</a>
                            <!-- <span class="chat-message-item">Mutual Friend: Sarah Hetfield</span> -->
                        </div>
                        <span class="notification-icon">
                            <a (click)="acceptRequest(friend.friend_id)" class="accept-request">
                                Accept
                            </a>

                            <a (click)="declineRequest(friend.friend_id)" class="accept-request request-del">
                                <!-- <span class="icon-minus">
													<svg class="olymp-happy-face-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon"></use></svg>
												</span> --> Decline
                        </a>

                        </span>


                    </li>

                    <!-- <li class="accepted">
						<div class="author-thumb">
							<img src="../../../assets/img/avatar17-sm.jpg" alt="author">
						</div>
						<div class="notification-event">
							You and <a href="javascript:void(0);" class="h6 notification-friend">Mary Jane Stark</a> just became
							friends. Write on <a href="javascript:void(0);" class="notification-link">his wall</a>.
						</div>
						<span class="notification-icon">
							<svg class="olymp-happy-face-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
								</use>
							</svg>
						</span>

						<div class="more">
							<svg class="olymp-three-dots-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
								</use>
							</svg>
							<svg class="olymp-little-delete">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use>
							</svg>
						</div>
					</li> -->



                </ul>

                <!-- ... end Notification List Friend Requests -->
            </div>

        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">

                <!-- Your Profile  -->

                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
                        <h6 class="title">Your PROFILE</h6>
                    </div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Profile Settings
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use
                                                xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                            </use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">Personal Information</a>
                                    </li>

                                    <li>
                                        <a routerLink="/change-password">Change Password</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">Hobbies and Interests</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">Notifications</a>
                        <!-- <a routerLink="/notifications" class="items-round-little bg-primary">8</a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" class="h6 title">Chat / Messages</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">Friend Requests</a>
                        <!-- <a routerLink="/friend-requests" class="items-round-little bg-blue">4</a> -->
                    </div>
                    <!-- <div class="ui-block-title ui-block-title-small">
						<h6 class="title">FAVOURITE PAGE</h6>
					</div>
					<div class="ui-block-title">
						<a href="javascript:void(0);" class="h6 title">Create Fav Page</a>
					</div>
					<div class="ui-block-title">
						<a href="javascript:void(0);" class="h6 title">Fav Page Settings</a>
					</div> -->
                </div>

                <!-- ... end Your Profile  -->

            </div>
        </div>
    </div>
</div>

<!-- ... end Your Account Personal Information -->