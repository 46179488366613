import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-legend-dashboard',
  templateUrl: './legend-dashboard.component.html',
  styleUrls: ['./legend-dashboard.component.css']
})
export class LegendDashboardComponent implements OnInit {

  legendForm: FormGroup;
  id: number;
  countryData: any = {};
  clubData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';
  countries: any = {};

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);

    this.getAllCountries();
    this.legendForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      position: ['', Validators.required],
      countryName: [''],
      gender: ['', Validators.required],
      phase: ['', Validators.required],
      avatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }

  getAllCountries() {
    this.userService.getCountries().subscribe((res: any) => {
      this.countries = res;
    });
  }
  onFileSelect(event) {
    const avatar = event.target.files[0];
    this.legendForm.get('avatar').setValue(avatar);

  }
  get f() { return this.legendForm.controls; }
  addLegend() {
    this.submitted = true;
    if (this.legendForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('firstName', this.legendForm.get('firstName').value);
    fd.append('lastName', this.legendForm.get('lastName').value);
    fd.append('position', this.legendForm.get('position').value);
    fd.append('countryName', this.legendForm.get('countryName').value);
    fd.append('createdBy', this.legendForm.get('createdBy').value);
    fd.append('gender', this.legendForm.get('gender').value);
    fd.append('phase', this.legendForm.get('createdBy').value);
    fd.append('avatar', this.legendForm.get('avatar').value);
    this.iffhsService.addLegend(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Legend Added!', 'Message!');

        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Again!', 'Message!');

      }
    );
    this.legendForm = this.formBuilder.group({
      firstName: [null],
      lastName: [null],
      countryName: [null],
      position: [null],
      avatar: [null],
      createdBy: [this.currentUser.success.user.id],
    });

  }

}
