import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule, routingComponent } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FileUploadModule } from 'ng2-file-upload';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
// import { DropdownModule } from 'primeng/dropdown';
// import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
// import { GoogleMapsModule } from '@angular/google-maps';
// import { CalendarModule, DateAdapter } from 'angular-calendar';
// import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { JwPaginationModule } from 'jw-angular-pagination';
import {WebcamModule} from 'ngx-webcam';
import {APP_BASE_HREF} from '@angular/common';
import { LangTranslateModule } from './lang-translate.module';
import {BidiModule} from '@angular/cdk/bidi';




@NgModule({
  declarations: [
    AppComponent,
    routingComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BrowserAnimationsModule,
    GalleryModule,
    LightboxModule,
    Ng2TelInputModule,
    Ng2SearchPipeModule,
    FileUploadModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    AutocompleteLibModule,
    ToastrModule.forRoot(),
    JwPaginationModule,
    WebcamModule,
    LangTranslateModule,
    BidiModule
    // NgxGalleryModule,
    // DropdownModule,
    // PasswordStrengthMeterModule
    // GoogleMapsModule
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // }),

  ],
  providers: [
    DatePipe,
    {provide: APP_BASE_HREF, useValue: '/'},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
