<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<app-presidents></app-presidents>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>


<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <ul class="cat-list-bg-style align-center sorting-menu">
                    <li class="cat-list__item" data-filter="*">
                        <a routerLink="/executive-committee" class=""> <strong>
                            {{'iffhs-members.executive_committee' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item " data-filter=".politics"><a routerLink="/honorable-committee" class=""><strong> {{'iffhs-members.honorable_committee' | translate}} </strong></a></li>
                    <li class="cat-list__item active" data-filter=".natural"><a routerLink="/iffhs-members" class=""><strong>
                        {{'iffhs-members.iffhs_member' | translate}} </strong></a></li>
                    <li class="cat-list__item" data-filter=".worlds">
                        <a routerLink="/president-consultant" class="">
                            <strong> {{'iffhs-members.president_consultant' | translate}} </strong></a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals"><a routerLink="/president-assistants" class=""><strong> {{'iffhs-members.president_assistant' | translate}}</strong></a></li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/gala-award" class="">
                            <strong> {{'iffhs-members.iffhs_awards' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/ranking" class="">
                            <strong> {{'iffhs-members.ranking' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/history" class="">
                            <strong> {{'iffhs-members.history' | translate}} </strong> </a>
                    </li>
                </ul>
                <ul class="cat-list-bg-style align-center sorting-menu">
                    <div (click)="getContinent(1)" class="author-thumb">

                        <a> <img src="../../../assets/img/caf.webp" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 1}">
                                <a href="javascript:void(0);" class="">{{'iffhs-members.caf' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(3)"> <img src="../../../assets/img/afc.webp" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 3}"><a href="javascript:void(0);">{{'iffhs-members.afc' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(4)"> <img src="../../../assets/img/uefa.webp" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 4}"><a href="javascript:void(0);" class="">{{'iffhs-members.uefa' | translate}}</a></li>
                        </a>
                    </div>

                    <div class="author-thumb">
                        <a (click)="getContinent(5)"> <img src="../../../assets/img/ofc.webp" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 5}"><a href="javascript:void(0);" class="">{{'iffhs-members.ofc' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(7)"> <img src="../../../assets/img/conmebol.webp" style="padding: 15px; margin: 0 auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 7}"><a href="javascript:void(0);" class="">{{'iffhs-members.conmebol' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(6)"> <img src="../../../assets/img/concacaf.webp" style="padding: 15px; margin: 0 auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 6}"><a href="javascript:void(0);" class="">{{'iffhs-members.concacaf' | translate}}</a></li>
                        </a>
                    </div>

                </ul>

                <div *ngIf="iffhsCountries.id === countries.id" class="container">
                    
                    <div *ngFor="let iffhsCountry of iffhsCountries" class="row">
                        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 align-center mb-5">
                            <div class="country-name">
                                <h2 style="font-weight: 500;">
                                    <img src="../../../assets/svg-icons/1x1/{{iffhsCountry.code | lowercase}}.svg" alt="flag" style="width: 35px; height: 25px; margin-right: 12px;"> {{ iffhsCountry.name}}
                                </h2>

                            </div>
                        </div>
                        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <table class="table table-bordered" style="border: 2px solid;">
                                <tr>
                                    <th class="text-center" colspan="3">{{'iffhs-members.iffhs_member' | translate | uppercase }}</th>

                                </tr>
                                <tr>
                                    <td class="text-center">
                                        <div class="author-thumb">
                                            <img src="../../../assets/img/author-main1.webp" alt="author" width="50" height="50">
                                        </div>
                                    </td>
                                    <td class="text-center">{{'iffhs-members.name' | translate}}</td>

                                    <td class="text-center">{{ iffhsCountry.fname | titlecase}} {{ iffhsCountry.lname | titlecase}}</td>
                                </tr>
                            </table>
                            <BR>

                        </div>

                        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <table class="table table-bordered" style="border: 2px solid;">
                                <tr>
                                    <th class="text-center" colspan="3">{{'iffhs-members.iffhs_member' | translate | uppercase }}</th>

                                </tr>
                                <tr>
                                    <td class="text-center">
                                        <div class="author-thumb">
                                            <img src="../../../assets/img/author-main1.webp" alt="author" width="50" height="50">
                                        </div>
                                    </td>
                                    <td class="text-center" style="padding-top: 20px; font-weight: 500;font-size: 14px;">{{'iffhs-members.name' | translate}}</td>
                                    <td class="text-center">{{ 'iffhs-members.coming_soon' | translate | uppercase}}</td>

                                </tr>
                            </table>
                            <BR>
                        </div>


                    </div>
                </div>
                <div *ngIf="!countries.fname" class="container">
                    <div *ngFor="let country of countries" class="row">
                        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 align-center mb-5">
                            <div class="country-name">
                                <h2 style="font-weight: 500;">
                                    <img src="../../../assets/svg-icons/1x1/{{country.code | lowercase}}.svg" alt="flag" style="width: 35px; height: 25px; margin-right: 12px;"> {{ country.name}}
                                </h2>

                            </div>
                        </div>
                        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <table class="table table-bordered" style="border: 2px solid;">
                                <tr>
                                    <th class="text-center" colspan="3">{{'iffhs-members.iffhs_member' | translate | uppercase }}</th>

                                </tr>
                                <tr>
                                    <td class="text-center">
                                        <div class="author-thumb">
                                            <img src="../../../assets/img/author-main1.webp" alt="author" width="50" height="50">
                                        </div>
                                    </td>
                                    <td class="text-center">{{'iffhs-members.name' | translate}}</td>

                                    <td class="text-center">{{ 'iffhs-members.coming_soon' | translate | uppercase}}</td>
                                </tr>
                            </table>
                            <BR>

                        </div>

                        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <table class="table table-bordered" style="border: 2px solid;">
                                <tr>
                                    <th class="text-center" colspan="3">{{'iffhs-members.iffhs_member' | translate | uppercase }}</th>

                                </tr>
                                <tr>
                                    <td class="text-center">
                                        <div class="author-thumb">
                                            <img src="../../../assets/img/author-main1.webp" alt="author" width="50" height="50">
                                        </div>
                                    </td>
                                    <td class="text-center" style="padding-top: 20px; font-weight: 500;font-size: 14px;">{{'iffhs-members.name' | translate}}</td>
                                    <td class="text-center">{{ 'iffhs-members.coming_soon' | translate | uppercase}}</td>

                                </tr>
                            </table>
                            <BR>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>
</div>