import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IffhsService {
  url = 'https://api.football-family.com/api/v1/';

  constructor(private http: HttpClient) { }
  // Getter and Setter of Countries 
  // createMeeting() {
  //   return this.http.post(this.url + 'iffhsCountries ', null);
  // }
  getCountries() {
    return this.http.post(this.url + 'iffhsCountries ', null);
  }
  getAllCountries() {
    return this.http.post(this.url + 'listCountries ', null);
  }
  getContinentIffhs(data: any) {
    return this.http.post(this.url + 'getContinentIffhs ', data);
  }

  getCountriesFans() {
    return this.http.post(this.url + 'getCountriesFans ', null);
  }
  getCountryDetails(id: any) {
    return this.http.post(this.url + 'getCountry ', id);
  }
  getNationalTeam(data: any) {
    return this.http.post(this.url + 'getNationalTeam ', data);
  }

  getContinent(data: any) {
    return this.http.post(this.url + 'getContinent', data);
  }
  getGeneralContinent(data: any) {
    return this.http.post(this.url + 'getGeneralContinents', data);
  }

  getFederation(data: any) {
    return this.http.post(this.url + 'getFollowerCountries', data);
  }
  getState(data: any) {
    return this.http.post(this.url + 'getFollowerStates', data);
  }
  getCities(data: any) {
    return this.http.post(this.url + 'getFollowerCities', data);
  }

  getAllFifaContries() {
    return this.http.post(this.url + 'fifa', null);
  }

  getfifaContinent(data: any) {
    return this.http.post(this.url + 'fifa', data);
  }

  getNonFifaContinent(data: any) {
    return this.http.post(this.url + 'nonfifa', data);
  }

  getAllNonFifaContries() {
    return this.http.post(this.url + 'nonfifa', null);
  }
  getFans(data: any) {
    return this.http.post(this.url + 'getFans', data);
  }
  //   Dashboard of team Set and Get methods
  addNews(data: any) {
    return this.http.post(this.url + 'addNews', data);
  }
  addClub(data: any) {
    return this.http.post(this.url + 'addClub', data);
  }
  getNews(data: any) {
    return this.http.post(this.url + 'getNews', data);
  }
  addMedia(data: any) {
    return this.http.post(this.url + 'addMedia', data);
  }
  getMedia(data: any) {
    return this.http.post(this.url + 'getMedia', data);
  }
  addFootballMedia(data: any) {
    return this.http.post(this.url + 'addFootballMedia', data);
  }
  getFootballMedia(data: any) {
    return this.http.post(this.url + 'getFootballMedia', data);
  }
  addMatch(data: any) {
    return this.http.post(this.url + 'addMatch', data);
  }
  getMatches(data: any) {
    return this.http.post(this.url + 'getMatches', data);
  }
  addPlayer(data: any) {
    return this.http.post(this.url + 'addPlayer', data);
  }
  getPlayers(data: any) {
    return this.http.post(this.url + 'getPlayers', data);
  }
  addLeague(data: any) {
    return this.http.post(this.url + 'addLeague', data);
  }
  getLeagues(data: any) {
    return this.http.post(this.url + 'getLeagues', data);
  }
  getClubsByLeague(data: any) {
    return this.http.post(this.url + 'getClubsByLeague', data);
  }
  addReferee(data: any) {
    return this.http.post(this.url + 'addReferee', data);
  }
  getReferees(data: any) {
    return this.http.post(this.url + 'getReferees', data);
  }
  addCoach(data: any) {
    return this.http.post(this.url + 'addCoach', data);
  }
  getCoaches(data: any) {
    return this.http.post(this.url + 'getCoach', data);
  }
  addLegend(data: any) {
    return this.http.post(this.url + 'addLegend', data);
  }
  getLegends(data: any) {
    return this.http.post(this.url + 'getLegends', data);
  }
  getLegendProfile(id: any) {
    return this.http.post(this.url + 'showLegendProfile', id);
  }
  //   Dashboard of Clubs Set and Get methods
  getLocalClubs(id: any) {
    return this.http.post(this.url + 'getLocalClubs', id);
  }
  getClubs(data: any) {
    return this.http.post(this.url + 'getClubs', data);
  }
  getClubProfile(id: any) {
    return this.http.post(this.url + 'getClubProfile', id);
  }
  // *********************** Confederation
  getConfederation(id: any) {
    return this.http.post(this.url + 'getConfederation', id);
  }
  follow(data: any) {
    return this.http.post(this.url + 'follow', data);
  }
  // *********************** Voting
  getVotingQuestions(data: any) {
    return this.http.post(this.url + 'getVotingQuestions', data);
  }
  getAllQuestions(data: any) {
    return this.http.post(this.url + 'getAllQuestions', data);
  }
  castVoting(data: any) {
    return this.http.post(this.url + 'castVote', data);
  }
  // *********************** Award

  getAllAwards() {
    return this.http.post(this.url + 'getAllAwards', null);
  }
  getAwardById(data: any) {
    return this.http.post(this.url + 'getAwardById', data);
  }
  getWinnerById(data: any) {
    return this.http.post(this.url + 'getWinnerById', data);
  }
  getAwardDetails(data: any) {
    return this.http.post(this.url + 'getAwardDetails', data);
  }
  getAwards(data: any) {
    return this.http.post(this.url + 'getAwards', data);
  }
  deleteAward(data: any) {
    return this.http.post(this.url + 'deleteAward', data);
  }
  deleteWinner(data: any) {
    return this.http.post(this.url + 'deleteWinner', data);
  }

  getAllBanners() {
    return this.http.post(this.url + 'getAwardBanners', null);
  }
  getAllAwardWinners() {
    return this.http.post(this.url + 'getAllAwardWinners', null);
  }
  editWinner(data: any) {
    return this.http.post(this.url + 'editWinner', data);
  }
  getAwardBannerById(data: any) {
    return this.http.post(this.url + 'getAwardBannerById', data);
  }

  deleteBanner(data: any) {
    return this.http.post(this.url + 'deleteBanner', data);
  }
  getBannerAwards(data: any) {
    return this.http.post(this.url + 'getBannerAwards', data);
  }
  addAwardBanner(data: any) {
    return this.http.post(this.url + 'addAwardBanners', data);
  }
  editAwardBanner(data: any) {
    return this.http.post(this.url + 'editAwardBanner', data);
  }
  editAward(data: any) {
    return this.http.post(this.url + 'editAward', data);
  }
  addAward(data: any) {
    return this.http.post(this.url + 'addAward', data);
  }
  addAwardWinner(data: any) {
    return this.http.post(this.url + 'addAwardWinner', data);
  }
  /****************************** Voting Question********************** */
  addVotingQuestions(data: any) {
    return this.http.post(this.url + 'addVotingQuestions', data);
  }
  getQuestionById(data: any) {
    return this.http.post(this.url + 'getQuestionById', data);
  }
  deleteQuestion(data: any) {
    return this.http.post(this.url + 'deleteQuestion', data);
  }
  editQuestion(data: any) {
    return this.http.post(this.url + 'editQuestion', data);
  }
  /****************************** Raking ********************** */
  addRanking(data: any) {
    return this.http.post(this.url + 'addRanking', data);
  }
  getAllRanking() {
    return this.http.post(this.url + 'getAllRanking', null);
  }
  deleteRank(data: any) {
    return this.http.post(this.url + 'deleteRank', data);
  }
  getRankById(data: any) {
    return this.http.post(this.url + 'getRankById', data);
  }
  editRank(data: any) {
    return this.http.post(this.url + 'editRank', data);
  }
  getLeaguesByCountry(data: any) {
    return this.http.post(this.url + 'getLeaguesByCountry', data);
  }
}
