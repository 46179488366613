import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-team-player',
  templateUrl: './team-player.component.html',
  styleUrls: ['./team-player.component.css']
})
export class TeamPlayerComponent implements OnInit {

  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  status: boolean;
  players = [];
  selectFriend: any = {};
  isLoaderVisible = false;
  age = [];
  items = [];
  pageOfItems: Array<any>;
  categories = [
    {
      id: '1',
      name: 'All Category'
    },
    {
      id: '2',
      name: 'By Clubs'
    },
    {
      id: '3',
      name: 'By Position'
    },
    {
      id: '4',
      name: 'By Ranking'
    },
    {
      id: '5',
      name: 'By Age'
    },
    {
      id: '6',
      name: 'By Nationality'
    },
  ];
  leagues = [
    {
      id: '1',
      name: 'First League'
    },
    {
      id: '2',
      name: '2nd League'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getPlayers();
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  getPlayers() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getPlayers(data).subscribe((res: any) => {
      this.players = res.players;
      this.items = this.players;
      this.isLoaderVisible = false;
      for (let i = 0; i < this.players.length; i++) {
        var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }

  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getPlayers(data).subscribe((res: any) => {
      this.players = res.players;
      this.isLoaderVisible = false;
      this.items = this.players;
      for (let i = 0; i < this.players.length; i++) {
        var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
    });
  }

}
