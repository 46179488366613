import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { GroupService } from 'src/app/data-services/group.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {
  currentUser: any;
  groupForm: FormGroup;
  submitted: boolean;
  errorFlag: boolean;
  loading: boolean;
  visible: boolean;
  groups: any;
  message = '';

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private groupService: GroupService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);

    this.groupForm = this.formBuilder.group({
      user_id: '',
      groupName: ['', Validators.required],
      groupAvatar: [''],
      postFlag: true,
    });

    this.loadAllGroups();
  }

  get f() { return this.groupForm.controls; }
  createGroup() {
    this.submitted = true;
    this.errorFlag = false;
    this.loading = true;

    if (this.groupForm.valid === true) {

      this.groupService.createGroup(this.groupForm.value).pipe(first()).subscribe(
        data => {
          alert('succusfully Created');
          this.errorFlag = true;
          this.message = 'succusfully created';
          this.visible = false;
          this.loading = false;
        },
        error => {
          alert('Created failed');
          this.visible = false;
          this.loading = false;
          this.errorFlag = false;

        }
      );
    } else {
      console.log('Validation error');
      this.errorFlag = true;
      this.message = 'Validation error';
      this.visible = false;
      this.loading = false;

    }
    this.groupForm.reset();
  }

  loadAllGroups() {
    const data = {
      user_id: this.currentUser.success.user.id,
    };
    this.groupService.getAllGroups(data).pipe(first()).subscribe(groups => this.groups = groups);
  }

}
