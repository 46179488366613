import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-national-team-header',
  templateUrl: './national-team-header.component.html',
  styleUrls: ['./national-team-header.component.css']
})
export class NationalTeamHeaderComponent implements OnInit {
  id: number;
  teamClass: string;
  category: string;
  countryData: any = {};
  currentUser: any;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) {

  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.teamClass = params['name'];
      this.category = params['category'];
    });
    // this.countryData = JSON.parse(localStorage.getItem('countryData'));
    this.getCountryDetails();
  }
  getCountryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    // localStorage.setItem('countryData', JSON.stringify(this.countryData));
  }
}
