import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-club-dashboard-leagues',
  templateUrl: './club-dashboard-leagues.component.html',
  styleUrls: ['./club-dashboard-leagues.component.css']
})
export class ClubDashboardLeaguesComponent implements OnInit {

  leagueForm: FormGroup;
  id: number;
  clubData: any = {};
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';
  imageError: string = '';

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getClubProfile();
    this.leagueForm = this.formBuilder.group({
      clubId: [this.id],
      leagueName: ['', Validators.required],
      leagueDescription: ['', Validators.required],
      leagueLogo: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  getClubProfile() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getClubProfile(data).subscribe((res: any) => {
      this.clubData = res.club;
      this.countryData = res.country;
      this.isLoaderVisible = false;
    });
  }
  onFileSelect(event) {
    const leagueLogo = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.leagueForm.get('leagueLogo').setValue(leagueLogo);

  }
  get f() { return this.leagueForm.controls; }
  addLeague() {
    this.submitted = true;
    if (this.leagueForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('clubId', this.leagueForm.get('clubId').value);
    fd.append('leagueName', this.leagueForm.get('leagueName').value);
    fd.append('leagueDescription', this.leagueForm.get('leagueDescription').value);
    fd.append('createdBy', this.leagueForm.get('createdBy').value);
    fd.append('leagueLogo', this.leagueForm.get('leagueLogo').value);
    this.iffhsService.addLeague(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('League Added!', 'Message!');

        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Again Later!', 'Message!');

      }
    );
    this.leagueForm = this.formBuilder.group({
      clubId: [this.id],
      leagueName: [''],
      leagueDescription: [''],
      leagueLogo: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }



}
