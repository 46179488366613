<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="assets/img/loader.webp">
</div>
<div class="main-header">
    <div class="content-bg-wrap bg-badges"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Voting !</h1>
                    <p style="font-size: 1.5rem;">Welcome to the Voting page! Here you’ll find a list of questions that will let us know you better. The more questions you answer, the more chances of winning. Start voting now!</p>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <img src="assets/img/voting/voting-award-message.webp" style="height: auto; width: 100%;" class="img-responsive img-fluid" alt="banner">
                    <div *ngIf="currentUser.success.user.id == 1 || currentUser.success.user.id == 5 || currentUser.success.user.id == 18 || currentUser.success.user.user_type_id == '1'">
                        <a routerLink="/add-voting-questions">
                            <img src="assets/img/dashboard.webp" class="img-responsive img-fluid center" alt="Dashboard" style="width: 6rem;"></a>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>




<div *ngIf="annualVisible" class="container">

    <div class="row">

        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">

            <div (click)="onClick('annualQuestion')">
                <img src="assets/img/annualQuestion.webp" class="img-responsive card-img" style="width: 100%; height: auto;">
            </div>


        </div>
        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div (click)="onClick('eventQuestion')">
                <img src="assets/img/eventQuestion.webp" class="img-responsive card-img" style="width: 100%; height: auto;">
            </div>
        </div>
    </div>


</div>

<section *ngIf="!annualVisible" id="tabs" class="project-tab">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <nav>
                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Stage 1</a>
                        <a class="nav-item nav-link " id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Stage 2</a>
                        <a class="nav-item nav-link " id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Stage 3</a>
                    </div>
                </nav>
                <div *ngIf="questions.length === 0" class="ui-block-title">
                    <h6 class="display-message">No Question Found!
                    </h6>
                </div>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <form [formGroup]="votingForm" (ngSubmit)="castVote()" class="content">
                            <div class="ui-block-content">

                                <div *ngFor="let question of questions;let i=index" class="form-group">
                                    <label class="control-label custom-lable">{{i+1}}. {{question.question}} </label>
                                    <ng-autocomplete [data]="players" placeHolder="Please Select your Answer" [searchKeyword]="keyword" formControlName="answer" (selected)='selectEvent($event, i, question)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplate"
                                        [notFoundTemplate]="notFoundTemplate">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-player>
                                        <a style="font-size: 1.5rem;font-weight: bold;" [innerHTML]="player.fullName"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                        <div style="font-size: 1.5rem;font-weight: bold;" [innerHTML]="notFound"></div>
                                    </ng-template>
                                </div>

                            </div>
                            <div class="alert alert-info text-center note" role="alert">
                                Note: All Questions are mandatory. Please answer all the Question
                            </div>
                            <button [disabled]="votingForm.invalid" class="btn btn-primary full-width">
                                Submit
                            </button>
                        </form>

                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <br><br><br>
                        <h1 class="text-center" style="font-weight: bold;"> Phase 2 Question coming soon </h1>
                    </div>
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <br><br><br>
                        <h1 class="text-center" style="font-weight: bold;"> Phase 3 Question coming soon </h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>