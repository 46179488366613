import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.css']
})
export class RankingComponent implements OnInit {
  awards = [];
  years = [];
  searchText: string;
  id: number;
  name: string;
  currentUser: any;
  countries = [];
  title: string;
  isLoaderVisible = false;
  fans = [];

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }
  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // this.route.queryParams.subscribe(params => {
    //   this.id = params['id'];
    //   this.name = params['name'];
    // });

    this.fans = Array(10).fill(0).map((x, i) => ({ id: (i + 1), name: `Coming Soon` }));
    this.years = Array(6).fill(0).map((x, i) => ({ id: (i + 2020) }));

    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }


  // getRanking() {
  //   const data = {
  //     awardId: this.id,
  //   };
  //   this.iffhsService.getAwardDetails(data).subscribe((res: any) => {
  //     this.awards = res.awards;
  //     this.title = res.title.name;
  //     this.countries = res.countries;
  //     this.isLoaderVisible = false;
  //     console.log(this.awards);
  //   });
  // }


}
