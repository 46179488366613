import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-dashboard-add-media',
  templateUrl: './dashboard-add-media.component.html',
  styleUrls: ['./dashboard-add-media.component.css']
})
export class DashboardAddMediaComponent implements OnInit, AfterViewInit {
  sidebar: any;
  mediaForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage = '';
  media = [
    {
      id: '1',
      name: 'News Paper'
    },
    {
      id: '2',
      name: 'Magazine'
    },
    {
      id: '3',
      name: 'Radio'
    },
    {
      id: '4',
      name: 'News Paper'
    },
    {
      id: '5',
      name: 'Electronic Magazine'
    },
    {
      id: '6',
      name: 'Electronic NewsPaper'
    },
    {
      id: '7',
      name: 'Social Media'
    },
    {
      id: '8',
      name: 'TV'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.mediaForm = this.formBuilder.group({
      countryId: [this.id],
      mediaType: ['', Validators.required],
      mediaTitle: ['', Validators.required],
      mediaContent: ['', Validators.required],
      mediaAttachment: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],

    });
  }
  toggleSideBar(event) {
    $(".profile-settings-open").on('click', function () {
      $('.profile-settings-responsive').toggleClass('open');
      return false;
    });
  }
  ngAfterViewInit(): void {
    this.sidebar = $('.js-profile-settings-open');
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  onFileSelect(event) {
    const mediaAttachment = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.mediaForm.get('mediaAttachment').setValue(mediaAttachment);
  }
  get f() { return this.mediaForm.controls; }
  postMedia() {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('countryId', this.mediaForm.get('countryId').value);
    fd.append('mediaType', this.mediaForm.get('mediaType').value);
    fd.append('mediaTitle', this.mediaForm.get('mediaTitle').value);
    fd.append('mediaContent', this.mediaForm.get('mediaContent').value);
    fd.append('createdBy', this.mediaForm.get('createdBy').value);
    fd.append('mediaAttachment', this.mediaForm.get('mediaAttachment').value);


    this.iffhsService.addFootballMedia(fd).pipe(first()).subscribe(
      data => {
        this.toastrService.success('Football Media Added!', 'Message!');
        this.isLoaderVisible = false;
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
        this.isLoaderVisible = false;
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.mediaForm = this.formBuilder.group({
      countryId: [this.id],
      mediaType: [''],
      mediaTitle: [''],
      mediaContent: [''],
      mediaAttachment: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
}
