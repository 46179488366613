<!-- Fixed Sidebar Right -->

<div class="fixed-sidebar right" >
    <!-- *ngIf="language !='ar'" -->
    <div class="fixed-sidebar-right sidebar--small" id="sidebar-right">

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="chat-users">
                <li *ngFor="let friend of friends " class="inline-items js-chat-open ">
                    <div class="author-thumb " (click)="openChatModal(chatModal,friend) ">
                        <img *ngIf="!friend.avatar " alt="author " class="avatar " src="../../../assets/img/user.jpg "
                            width="42 " height="40 ">
                        <img *ngIf="friend.avatar " alt="author " class="avatar " [src]="friend.avatar " width="42 "
                            height="42 ">
                        <span class="icon-status online "></span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="search-friend inline-items ">
            <a (click)="toggleSideBar($event)" class="js-sidebar-open ">
                <div class="olymp-menu-icon ">
                    <img src="../../../assets/svg-icons/menu-icon.png" alt="Menu">
                </div>
            </a>
        </div>

        <!-- <a href="# " class="olympus-chat inline-items js-chat-open ">
            <svg class="olymp-chat---messages-icon ">
                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon "></use>
            </svg>
        </a> -->

    </div>

    <div class="fixed-sidebar-right sidebar--large " id="sidebar-right-1 ">

        <div class="mCustomScrollbar " data-mcs-theme="dark ">

            <div class="ui-block-title ui-block-title-small ">
                <a href="javascript:void(0)" class="title "> Friends</a>
                <a routerLink="/profile-setting">Settings</a>
            </div>

            <ul class="chat-users ">
                <li *ngFor="let friend of friends | filter:searchText" class="inline-items js-chat-open ">

                    <div (click)="openChatModal(chatModal,friend) " class="author-thumb ">
                        <img *ngIf="!friend.avatar " alt="author " class="avatar " src="../../../assets/img/user.jpg "
                            width="42 " height="40 ">
                        <img *ngIf="friend.avatar " alt="author " class="avatar " [src]="friend.avatar " width="42 "
                            height="42 ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a routerLink="/profile-details" [queryParams]="{'id': friend.id, 'role': 'User'}"
                            class="h6 author-name ">{{friend.user.fullName}}</a>
                        <span class="status ">ONLINE</span>
                    </div>

                    <!-- <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div> -->

                </li>
                <!-- <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar62-sm.jpg " class="avatar ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Mathilda Brinker</a>
                        <span class="status ">AT WORK!</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>

                </li> -->

            </ul>


            <!-- <div class="ui-block-title ui-block-title-small ">
                <a href="# " class="title ">MY FAMILY</a>
                <a href="# ">Settings</a>
            </div> 

            <ul class="chat-users ">
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar64-sm.jpg " class="avatar ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Sarah Hetfield</a>
                        <span class="status ">ONLINE</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>
                </li>
            </ul>


            <div class="ui-block-title ui-block-title-small ">
                <a href="# " class="title ">UNCATEGORIZED</a>
                <a href="# ">Settings</a>
            </div>

            <ul class="chat-users ">
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar71-sm.jpg " class="avatar ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Bruce Peterson</a>
                        <span class="status ">ONLINE</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>


                </li>
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar72-sm.jpg " class="avatar ">
                        <span class="icon-status away "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Chris Greyson</a>
                        <span class="status ">AWAY</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>

                </li>
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar63-sm.jpg " class="avatar ">
                        <span class="icon-status status-invisible "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Nicholas Grisom</a>
                        <span class="status ">INVISIBLE</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>
                </li>
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar72-sm.jpg " class="avatar ">
                        <span class="icon-status away "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Chris Greyson</a>
                        <span class="status ">AWAY</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>
                </li>
                <li class="inline-items js-chat-open ">

                    <div class="author-thumb ">
                        <img alt="author " src="../../../assets/img/avatar71-sm.jpg " class="avatar ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a href="# " class="h6 author-name ">Bruce Peterson</a>
                        <span class="status ">ONLINE</span>
                    </div>

                    <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div>
                </li>
            </ul> -->

        </div>

        <div class="search-friend inline-items ">
            <form class="form-group ">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                    [ngModelOptions]="{standalone: true}" placeholder="Search Friends..." value=" ">
            </form>

            <a routerLink="/profile-setting" class="settings ">
                <svg class="olymp-settings-icon ">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon "></use>
                </svg>
            </a>

            <a (click)="toggleSideBar($event)" class="js-sidebar-open ">
                <div class="olymp-close-icon ">
                    <img src="../../../assets/svg-icons/close-icon.webp" alt="close">
                </div>
            </a>
        </div>

        <!-- <a href="# " class="olympus-chat inline-items js-chat-open ">
            <h6 class="olympus-chat-title ">OLYMPUS CHAT</h6>
            <svg class="olymp-chat---messages-icon ">
                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon "></use>
            </svg>
        </a> -->

    </div>
</div>
<!-- <div class="fixed-sidebar right ar-fixed" *ngIf="language =='ar'" >
    <div class="fixed-sidebar-right sidebar--small" id="sidebar-right">

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="chat-users">
                <li *ngFor="let friend of friends " class="inline-items js-chat-open ">
                    <div class="author-thumb " (click)="openChatModal(chatModal,friend) ">
                        <img *ngIf="!friend.avatar " alt="author " class="avatar " src="../../../assets/img/user.jpg "
                            width="42 " height="40 ">
                        <img *ngIf="friend.avatar " alt="author " class="avatar " [src]="friend.avatar " width="42 "
                            height="42 ">
                        <span class="icon-status online "></span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="search-friend inline-items ">
            <a (click)="toggleSideBar($event)" class="js-sidebar-open ">
                <div class="olymp-menu-icon ">
                    <img src="../../../assets/svg-icons/menu-icon.png" alt="Menu">
                </div>
            </a>
        </div>

    </div>

    <div class="fixed-sidebar-right sidebar--large " id="sidebar-right-1 ">

        <div class="mCustomScrollbar " data-mcs-theme="dark ">

            <div class="ui-block-title ui-block-title-small ">
                <a href="javascript:void(0)" class="title "> Friends</a>
                <a routerLink="/profile-setting">Settings</a>
            </div>

            <ul class="chat-users ">
                <li *ngFor="let friend of friends | filter:searchText" class="inline-items js-chat-open ">

                    <div (click)="openChatModal(chatModal,friend) " class="author-thumb ">
                        <img *ngIf="!friend.avatar " alt="author " class="avatar " src="../../../assets/img/user.jpg "
                            width="42 " height="40 ">
                        <img *ngIf="friend.avatar " alt="author " class="avatar " [src]="friend.avatar " width="42 "
                            height="42 ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a routerLink="/profile-details" [queryParams]="{'id': friend.id, 'role': 'User'}"
                            class="h6 author-name ">{{friend.user.fullName}}</a>
                        <span class="status ">ONLINE</span>
                    </div>


                </li>
                
            </ul>


           

        </div>

        <div class="search-friend inline-items ">
            <form class="form-group ">
                <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
                    [ngModelOptions]="{standalone: true}" placeholder="Search Friends..." value=" ">
            </form>

            <a routerLink="/profile-setting" class="settings ">
                <svg class="olymp-settings-icon ">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon "></use>
                </svg>
            </a>

            <a (click)="toggleSideBar($event)" class="js-sidebar-open ">
                <div class="olymp-close-icon ">
                    <img src="../../../assets/svg-icons/close-icon.webp" alt="close">
                </div>
            </a>
        </div>

    </div>
</div> -->
<!-- ... end Fixed Sidebar Right -->


<!-- Fixed Sidebar Right-Responsive -->

<div class="fixed-sidebar right fixed-sidebar-responsive " id="sidebar-right-responsive ">

    <div class="fixed-sidebar-right sidebar--small ">
        <a (click)="toggleSideBar($event)" class="js-sidebar-open ">
            <svg class="olymp-menu-icon ">
                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-menu-icon "></use>
            </svg>
            <svg class="olymp-close-icon ">
                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon "></use>
            </svg>
        </a>
    </div>

    <div class="fixed-sidebar-right sidebar--large ">
        <div class="mCustomScrollbar " data-mcs-theme="dark ">

            <div class="ui-block-title ui-block-title-small ">
                <a routerLink="/friends" class="title "> Friends</a>
                <a routerLink="/profile-setting">Settings</a>
            </div>

            <ul class="chat-users ">
                <li *ngFor="let friend of friends | filter:searchText" class="inline-items js-chat-open ">

                    <div (click)="openChatModal(chatModal,friend) " class="author-thumb ">
                        <img *ngIf="!friend.avatar " alt="author " class="avatar " src="../../../assets/img/user.jpg "
                            width="42 " height="40 ">
                        <img *ngIf="friend.avatar " alt="author " class="avatar " [src]="friend.avatar " width="42 "
                            height="42 ">
                        <span class="icon-status online "></span>
                    </div>

                    <div class="author-status ">
                        <a routerLink="/profile-details" [queryParams]="{'id': friend.id}"
                            class="h6 author-name ">{{friend.user.fullName}}</a>
                        <span class="status ">ONLINE</span>
                    </div>

                    <!-- <div class="more "><svg class="olymp-three-dots-icon ">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon "></use>
                        </svg>

                        <ul class="more-icons ">
                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="START CONVERSATION " class="olymp-comments-post-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="ADD TO CONVERSATION " class="olymp-add-to-conversation-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-add-to-conversation-icon ">
                                    </use>
                                </svg>
                            </li>

                            <li>
                                <svg data-toggle="tooltip " data-placement="top " data-original-title="BLOCK FROM CHAT " class="olymp-block-from-chat-icon ">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-block-from-chat-icon ">
                                    </use>
                                </svg>
                            </li>
                        </ul>

                    </div> -->

                </li>
            </ul>
        </div>

        <div class="search-friend inline-items ">
            <form class="form-group ">
                <input class="form-control " placeholder="Search Friends... " value=" " type="text ">
            </form>

            <a routerLink="/profile-setting" class="settings ">
                <svg class="olymp-settings-icon ">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-settings-icon"></use>
                </svg>
            </a>

            <a href="#" class="js-sidebar-open ">
                <svg class="olymp-close-icon ">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
        </div>

        <!-- <a href="# " class="olympus-chat inline-items js-chat-open ">
            <h6 class="olympus-chat-title ">OLYMPUS CHAT</h6>
            <svg class="olymp-chat---messages-icon ">
                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon "></use>
            </svg>
        </a> -->
    </div>

</div>

<!-- ... end Fixed Sidebar Right-Responsive -->

<!-- Popup Chat -->
<div class="modal fade" bsModal #chatModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog window-popup restore-password-popup" role="document">

        <div class="ui-block popup-chat">
            <div class="ui-block-title">
                <div class="author-thumb">
                    <span class="icon-status online"></span>
                    <img *ngIf="!selectFriend.avatar" alt="author" class="avatar" src="../../../assets/img/user.jpg"
                        width="36" height="36">
                    <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar" width="36"
                        height="36">
                </div>
                <h6 class="title"> <a routerLink="/profile-details"
                        [queryParams]="{'id': selectFriend.id}">{{ selectFriend.fullName }} </a></h6>
                <button type="button" class="close" (click)="closeChatModal(chatModal)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <ul class="notification-list chat-message chat-message-field">

                    <div>
                        <!-- left -->
                        <li *ngFor="let senderMsg of senderMessages">
                            <div class="author-thumb">
                                <img *ngIf="!selectFriend.avatar" alt="author" class="avatar"
                                    src="../../../assets/img/user.jpg" width="36" height="36">
                                <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar"
                                    width="36" height="36">
                            </div>
                            <div class="notification-event">
                                <span class="chat-message-item">{{senderMsg.message}}</span>
                                <span class="notification-date"><time class="entry-date updated"
                                        datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                            </div>
                        </li>
                        <!-- Right -->
                        <li *ngFor="let receiverMsg of receiverMessages">
                            <div class="author-thumb">
                                <img *ngIf="!currentUser.success.user.avatar" alt="author" class="avatar"
                                    src="../../../assets/img/user.jpg" width="36" height="36">
                                <img *ngIf="currentUser.success.user.avatar" alt="author" class="avatar"
                                    [src]="currentUser.success.user.avatar" width="36" height="36">
                            </div>
                            <div class="notification-event">
                                <span class="chat-message-item">{{receiverMsg.message}}</span>
                                <span class="notification-date"><time class="entry-date updated"
                                        datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                            </div>
                        </li>
                    </div>

                </ul>
            </div>

            <form [formGroup]="sendMessageForm" (ngSubmit)="sendMessage(selectFriend.id)">

                <div class="form-group label-floating is-empty">
                    <textarea class="form-control" placeholder="Write message..." formControlName="message"></textarea>
                    <div class="add-options-message">
                        <button type="submit" class="options-message"> send </button>
                    </div>
                </div>

            </form>


        </div>

    </div>
</div>

<!-- ... end Popup Chat -->