import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-team-national-teams',
  templateUrl: './team-national-teams.component.html',
  styleUrls: ['./team-national-teams.component.css']
})
export class TeamNationalTeamsComponent implements OnInit {
  modalRef: BsModalRef;
  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  clubs: [];
  searchText: string;
  isLoaderVisible = false;
  selectTeam: any;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) { }
  teams = [
    {
      id: '1',
      name: 'First Team',
      src: 'assets/img/team-class/first-team.webp',
    },
    {
      id: '2',
      name: 'Olympic Team',
      src: 'assets/img/team-class/olympic-team.webp',
    },
    // {
    //   id: '3',
    //   name: 'Youth Team',
    //   src: 'assets/img/team-class/youth-team.webp',
    // },
    // {
    //   id: '4',
    //   name: 'Under 20',
    //   src: 'assets/img/team-class/u-20.webp',
    // },
    // {
    //   id: '9',
    //   name: 'Academic Team',
    //   src: 'assets/img/team-class/beach-soccor.webp',
    // },
    {
      id: '5',
      name: 'Youth Under 19',
      src: 'assets/img/team-class/u-19.webp',
    },
    {
      id: '6',
      name: 'Youth Under 17',
      src: 'assets/img/team-class/u-16.webp',
    },
    // {
    //   id: '7',
    //   name: 'Special Needs Team',
    //   src: 'assets/img/team-class/special-need.webp',
    // },
    // {
    //   id: '8',
    //   name: 'Beach Soccer',
    //   src: 'assets/img/team-class/beach-soccor.webp',
    // },
    
  ];
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  openModal(template: any, team) {
    this.selectTeam = team;
    this.modalRef = this.modalService.show(
      template,
      {
        class: 'modal-lg modal-dialog-centered',
        ignoreBackdropClick: true,
        keyboard: false
      });

  }




}
