<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<app-presidents></app-presidents>


<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <ul class="cat-list-bg-style align-center sorting-menu">
                    <li class="cat-list__item" data-filter="*">
                        <a routerLink="/executive-committee" class=""> <strong>
                            {{'president-assistants.executive_committee' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item " data-filter=".politics"><a routerLink="/honorable-committee" class="">
                        <strong> {{'president-assistants.honorable_committee' | translate}} </strong></a></li>
                    <li class="cat-list__item" data-filter=".natural"><a routerLink="/iffhs-members" class=""><strong>
                        {{'president-assistants.iffhs_member' | translate}} </strong></a></li>
                    <li class="cat-list__item" data-filter=".worlds">
                        <a routerLink="/president-consultant" class="">
                            <strong> {{'president-assistants.president_consultant' | translate}} </strong></a>
                    </li>
                    <li class="cat-list__item active" data-filter=".animals"><a routerLink="/president-assistants" class="">
                        <strong> {{'president-assistants.president_assistant' | translate}}</strong></a></li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/gala-award" class="">
                            <strong> {{'president-assistants.iffhs_awards' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/ranking" class="">
                            <strong> {{'president-assistants.ranking' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/history" class="">
                            <strong> {{'president-assistants.history' | translate}} </strong> </a>
                    </li>
                </ul>

                <div style="text-align: center;">

                    <h2 style="color: #3f4257;"> {{'president-assistants.coming_soon' | translate}}</h2>



                </div>

            </div>
        </div>
        <!-- <app-iffhs-news></app-iffhs-news> -->
    </div>
</div>