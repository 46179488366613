<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="../../../assets/../../../assets/img/covers/{{confederationData.id}}.webp" class="img-responsive img-fluid" alt="cover" onerror="this.onerror=null;this.src='../../../assets/img/federations-cover.jpg';" style="width: 100%;height: auto;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="../../../assets/img/{{confederationData.football_name | lowercase}}.webp" class="img-responsive img-fluid" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 100%; height: 120px;" />
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{confederationData.football_name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="../../../assets/img/maps/{{confederationData.id}}.webp" class="img-responsive img-fluid" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 100%; height: 120px;" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/conf-timeline" [queryParams]="{'id': confederationData.id}" routerLinkActive="active">Timeline</a>
                                    </li>

                                    <li>
                                        <a routerLink="/conf-photos" [queryParams]="{'id': confederationData.id}" routerLinkActive="active">Media</a>
                                    </li>
                                    <li>
                                        <a routerLink="/conf-history" [queryParams]="{'id': confederationData.id}" routerLinkActive="active">History</a>
                                    </li>

                                    <li>
                                        <a routerLink="/conf-followers" [queryParams]="{'id': confederationData.id}" routerLinkActive="active">Followers</a>
                                    </li>
                                    <li *ngIf="currentUser.success.user.user_type_id == '6' && currentUser.success.user.fullName == confederationData.football_name || currentUser.success.user.user_type_id == '1'">
                                        <a routerLink="/conf-dashboard" [queryParams]="{'id': confederationData.id}" routerLinkActive="active">
                                            <img src="../../../assets/../../../assets/img/dashboard.webp" alt="dashboard" width="40" height="40">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>