import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first, map } from 'rxjs/operators';
import { PostService } from 'src/app/data-services/post.service';
import { from } from 'rxjs';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Gallery, GalleryItem, ImageItem, ThumbnailsPosition, ImageSize } from '@ngx-gallery/core';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhotosComponent implements OnInit {
  albumForm: FormGroup;
  currentUser: any;
  userData: any = {};
  photos = [];
  items: GalleryItem[];

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private postService: PostService,
    private formBuilder: FormBuilder,
    public gallery: Gallery
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getUserPhotos();

    // console.log(data);
    // this.items = data.map(item =>
    //   new ImageItem({ src: item.srcUrl, thumb: item.previewUrl })
    // );


  }
  basicLightboxExample() {
    this.gallery.ref().load(this.items);
  }

  getUserPhotos() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.postService.getUserPhotos(data).subscribe((res: any) => {
      this.photos = res;
      for (let i = 0; i < this.photos.length; i++) {
        this.photos[i] = this.photos[i].image;
        this.items = this.photos.map(item =>
          new ImageItem({ src: item, thumb: item })
        );
        console.log(this.items[i].data.thumb);
      }
      this.basicLightboxExample();

    });
  }

}
// const data = [
//   {
//     srcUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg',
//     previewUrl: 'https://preview.ibb.co/jrsA6R/img12.jpg'
//   },
//   {
//     srcUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg',
//     previewUrl: 'https://preview.ibb.co/kPE1D6/clouds.jpg'
//   },
//   {
//     srcUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg',
//     previewUrl: 'https://preview.ibb.co/mwsA6R/img7.jpg'
//   },
//   {
//     srcUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg',
//     previewUrl: 'https://preview.ibb.co/kZGsLm/img8.jpg'
//   }
// ];
