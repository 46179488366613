import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-team-players',
  templateUrl: './team-players.component.html',
  styleUrls: ['./team-players.component.css']
})
export class TeamPlayersComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  clubs: [];
  players = [];
  searchText: string;
  isLoaderVisible = false;
  age = [];
  items = [];
  leagues = [];
  pageOfItems: Array<any>;

  language = '';
  direction;
  defaultLanguage;

  categories = [
    {
      id: '1',
      name: 'All Category'
    },
    {
      id: '2',
      name: 'By Clubs'
    },
    {
      id: '3',
      name: 'By Position'
    },
    {
      id: '4',
      name: 'By Ranking'
    },
    {
      id: '5',
      name: 'By Age'
    },
    {
      id: '6',
      name: 'By Nationality'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      }); 
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getLeagues();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  getLeagues() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getLeagues(data).subscribe((res: any) => {
      this.leagues = res.leagues;
      this.isLoaderVisible = false;
    });
  }
  getPlayers() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      teamClass: 'first team',
    };
    this.iffhsService.getNationalTeam(data).subscribe((res: any) => {
      this.players = res.players;
      this.items = this.players;
      this.isLoaderVisible = false;
      for (let i = 0; i < this.players.length; i++) {
        let timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getCoaches(data).subscribe((res: any) => {
      this.players = res.players;
      this.isLoaderVisible = false;
      this.items = this.players;
      for (let i = 0; i < this.players.length; i++) {
        var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
    });
  }


}
