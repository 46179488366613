<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-club-header></app-club-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Matches</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="matchForm" (ngSubmit)="addMatch()">
                        <div class="row">
                            <div (click)="onInternational()" class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    International
                                </button>
                            </div>
                            <div (click)="getLocalClubs(countryData.id)" class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Local Match
                                </button>
                            </div>
                            <div *ngIf="showCountries" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Country <span style="color: red;">
                                            *</span></label>
                                    <select name="countryName" id="countryName"
                                        class="nice-select filter-input mar-top-0 form-control" tabindex="0">
                                        <option class="list option selected focus" *ngFor="let country of countryList"
                                            [value]="country.id" (click)="getLocalClubs(country.id)">{{country.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="showForm" class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Host Team Name <span style="color: red;">
                                            *</span></label>
                                    <input disabled type="text" value="{{clubData.fullName}}" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Visitor Team Name <span style="color: red;">
                                            *</span></label>
                                    <select name="team1" id="visitingTeam" formControlName="visitingTeam"
                                        class="nice-select filter-input mar-top-0 form-control" tabindex="0"
                                        [ngClass]="{ 'is-invalid': submitted && f.visitingTeam.errors }">
                                        <option class="list option selected focus" *ngFor="let club of clubs"
                                            [value]="club.fullName">{{club.fullName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.visitingTeam.errors" class="invalid-feedback">
                                        <div *ngIf="f.visitingTeam.errors.required">Visitor Team name is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showForm" class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Host Team Score <span style="color: red;">
                                            *</span></label>
                                    <input type="text" min="0" max="30" OnlyNumber="true"
                                        (keypress)="numberOnly($event)" formControlName="hostScore" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.hostScore.errors }" />
                                    <div *ngIf="submitted && f.hostScore.errors" class="invalid-feedback">
                                        <div *ngIf="f.hostScore.errors.required">Score is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Visitor Team Score <span style="color: red;">
                                            *</span></label>
                                    <input type="text" min="0" max="30" (keypress)="numberOnly($event)"
                                        formControlName="visitorScore" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.visitorScore.errors }" />
                                    <div *ngIf="submitted && f.visitorScore.errors" class="invalid-feedback">
                                        <div *ngIf="f.visitorScore.errors.required">Score is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showForm" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <textarea class="form-control" name="matchSummary" formControlName="matchSummary"
                                        placeholder="Match Summary"
                                        [ngClass]="{ 'is-invalid': submitted && f.matchSummary.errors }"></textarea>
                                    <div *ngIf="submitted && f.matchSummary.errors" class="invalid-feedback">
                                        <div *ngIf="f.matchSummary.errors.required">Summary is required</div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showForm" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">
                <div class="your-profile">
                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Dashboard Settings
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use xlink:href="#olymp-dropdown-arrow-icon"></use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li class="active ">
                                        <a routerLink="/club-dashboard" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-media" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add Photos/Videos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-matches" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Matches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-leagues" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add Leagues</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-player" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Player</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-coach" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Coach</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>