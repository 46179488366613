import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { PostService } from 'src/app/data-services/post.service';

@Component({
  selector: 'app-f-videos',
  templateUrl: './f-videos.component.html',
  styleUrls: ['./f-videos.component.css']
})
export class FVideosComponent implements OnInit {
  currentUser: any;
  videos = [];
  id: any;
  friendData: any = {};
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private postService: PostService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(
      params => this.id = params.id
    );
    this.friendDetails();
    this.getUserVideos();
  }
  friendDetails() {
    const data = {
      id: this.id,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );

  }
  getUserVideos() {
    const data = {
      userId: this.id,
    };
    this.postService.getUserVideos(data).pipe(first<any>()).subscribe(videos => this.videos = videos);
    console.log(this.videos);
  }


}
