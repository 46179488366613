<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<app-profile-header></app-profile-header>

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-8 order-xl-2 col-lg-8 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'about.hobby_int' | translate}}</h6>
                    <a routerLink="/hobbies-and-intrests" class="more"><svg class="olymp-three-dots-icon">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                        </svg></a>
                </div>
                <div class="ui-block-content">
                    <div class="row">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">


                            <!-- W-Personal-Info -->

                            <ul class="widget w-personal-info item-block">
                                <li>
                                    <span class="title">{{'about.hobbies' | translate}}</span>
                                    <span *ngIf="currentUser.success.user.hobby1"
                                        class="text">{{ userData[0].hobby1 | titlecase }}.</span>
                                    <span *ngIf="!currentUser.success.user.hobby1" class="text">{{'about.fill_fields' | translate}}</span>
                                </li>
                                <li>
                                    <span class="title">{{'about.fav_games' | translate}}</span>
                                    <span *ngIf="currentUser.success.user.hobby2"
                                        class="text">{{ userData[0].hobby2 | titlecase }}.</span>
                                    <span *ngIf="!currentUser.success.user.hobby2" class="text">{{'about.fill_fields' | translate}}</span>
                                </li>

                            </ul>

                            <!-- ... end W-Personal-Info -->
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">


                            <!-- W-Personal-Info -->

                            <ul class="widget w-personal-info item-block">
                                <li>
                                    <span class="title">{{'about.fav_tv' | translate}}</span>
                                    <span *ngIf="currentUser.success.user.hobby3"
                                        class="text">{{ userData[0].hobby3 | titlecase }}. </span>
                                    <span *ngIf="!currentUser.success.user.hobby3" class="text">{{'about.fill_fields' | translate}}</span>

                                </li>
                                <li>
                                    <span class="title">{{'about.other_int' | translate}}</span><br>
                                    <span *ngIf="currentUser.success.user.hobby4"
                                        class="text">{{ userData[0].hobby4 | titlecase }}.</span><br>
                                    <span *ngIf="!currentUser.success.user.hobby4" class="text"> {{'about.fill_fields' | translate}}</span>
                                </li>
                            </ul>

                            <!-- ... end W-Personal-Info -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col col-xl-4 order-xl-1 col-lg-4 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'about.personal' | translate}}</h6>
                    <a routerLink="/profile-setting" class="more"><svg class="olymp-three-dots-icon">
                            <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                        </svg></a>
                </div>
                <div class="ui-block-content">


                    <!-- W-Personal-Info -->

                    <ul class="widget w-personal-info">
                        <li>
                            <span class="title">{{'about.about_me' | translate}}</span>
                            <span class="text">Hi, I’m {{userData[0].fullName  | titlecase}},<br>
                                {{userData[0].about}}.</span>
                        </li>
                        <li>
                            <span class="title">{{'about.birthday' | translate}}</span>
                            <span class="text"> {{ userData[0].dob | date  }} </span>
                        </li>
                        <li>
                            <span class="title">{{'about.country' | translate}}</span>
                            <span class="text"> {{ userData[0].countryName | titlecase}} </span>
                        </li>
                        <li>
                            <span class="title">{{'about.lives' | translate}}</span>
                            <span class="text">{{ userData[0].countryName | titlecase }}</span>
                        </li>

                        <li>
                            <span class="title">{{'about.joined' | translate}}</span>
                            <span class="text">{{ userData[0].created_at | date }}</span>
                        </li>
                        <li>
                            <span class="title">{{'about.gender' | translate}}</span>
                            <span class="text">{{ userData[0].gender  | titlecase}}</span>
                        </li>
                        <li>
                            <span class="title">{{'about.marital' | translate}}</span>
                            <span *ngIf="currentUser.success.user.martialStatus"
                                class="text">{{userData[0].martialStatus}}</span>
                            <span *ngIf="!currentUser.success.user.martialStatus" class="text">{{'about.no_info' | translate}}</span>
                        </li>
                        <li>
                            <span class="title">{{'about.email' | translate}}</span>
                            <a href="javascript:void(0);" class="text">{{ userData[0].email | titlecase }}</a>
                        </li>

                        <li>
                            <span class="title">{{'about.mobile' | translate}}</span>
                            <span class="text"> {{ userData[0].mobileNumber }} </span>
                        </li>

                    </ul>

                    <!-- ... end W-Personal-Info -->
                    <!-- W-Socials -->

                    <div class="widget w-socials">
                        <h6 *ngIf="currentUser.success.user.facebook || currentUser.success.user.twitter "
                            class="title">{{'about.other_social' | translate}}</h6>
                        <a *ngIf="currentUser.success.user.facebook" href="https://{{userData[0].facebook}}"
                            class="social-item bg-facebook">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i> {{'about.facebook' | translate}}
                        </a>
                        <a *ngIf="currentUser.success.user.twitter" href="https://{{userData[0].twitter}}"
                            class="social-item bg-twitter">
                            <i class="fab fa-twitter" aria-hidden="true"></i> {{'about.twitter' | translate}}
                        </a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>