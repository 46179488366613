import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { UserService } from 'src/app/data-services/user.service';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  currentUser: any;
  userData: any = {};

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      });
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.userData = JSON.parse(localStorage.getItem('userData'));
    
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
    // this.getProfile();
  }

  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(
  //     userData => this.userData = userData[0]
  //   );

  // }

}
