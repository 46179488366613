import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { SlicePipe } from '@angular/common';

@Component({
  selector: 'app-add-award-banner',
  templateUrl: './add-award-banner.component.html',
  styleUrls: ['./add-award-banner.component.css']
})
export class AddAwardBannerComponent implements OnInit {

  awardBannerForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  banners = [];
  names: String;
  domElement: string = 'view';
  selectedBanner: any = {};
  items = [];
  pageOfItems: Array<any>;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getAllBanners();
    this.awardBannerForm = this.formBuilder.group({
      bannerId: ['', Validators.required],
      bannerName: ['', Validators.required],
      numberOfAwards: [''],
      bannerPhoto: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],

    });
  }
  getAllBanners() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllBanners().subscribe((res: any) => {
      this.banners = res.banners;
      this.items = this.banners;
      this.isLoaderVisible = false;

    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  onClick(add: string) {
    if (add === 'add') {
      this.domElement = add;
    } else if (add === 'view') {
      this.domElement = add;
      this.getAllBanners();
    }
  }
  onEdit(add: string, banner: any) {
    if (add === 'edit') {
      this.domElement = add;
      this.selectedBanner = banner;
      this.awardBannerForm = this.formBuilder.group({
        bannerId: [this.selectedBanner.id, Validators.required],
        bannerName: [this.selectedBanner.name, Validators.required],
        numberOfAwards: [this.selectedBanner.awards.length],
        bannerPhoto: ['', Validators.required],
        createdBy: [this.currentUser.success.user.id],

      });
    } else if (add === 'view') {
      this.domElement = add;
      this.getAllBanners();
    }

  }
  openDeleteModal(modal: any, banner: any) {
    this.selectedBanner = banner;
    modal.show();
  }
  deleteBanner(modal: any) {
    this.isLoaderVisible = true;
    const data = {
      bannerId: this.selectedBanner.id,
    };
    this.iffhsService.deleteBanner(data).subscribe(
      res => {
        modal.hide();
        this.getAllBanners();
        this.isLoaderVisible = false;
        this.toastrService.success('Banner Deleted', 'Message!');

      },
      error => {
        console.log(error);
      }
    );
  }

  onImageSelect(event) {
    const bannerPhoto = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.awardBannerForm.get('bannerPhoto').setValue(bannerPhoto);

  }

  get f() { return this.awardBannerForm.controls; }
  addAwardBanner() {
    this.submitted = true;
    if (this.awardBannerForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('bannerId', this.awardBannerForm.get('bannerId').value);
    fd.append('bannerName', this.awardBannerForm.get('bannerName').value);
    fd.append('numberOfAwards', this.awardBannerForm.get('numberOfAwards').value);
    fd.append('createdBy', this.awardBannerForm.get('createdBy').value);
    fd.append('bannerPhoto', this.awardBannerForm.get('bannerPhoto').value);

    this.iffhsService.addAwardBanner(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Award Banner Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.awardBannerForm = this.formBuilder.group({
      countryId: [this.id],
      bannerId: [''],
      bannerName: [''],
      numberOfAwards: [''],
      bannerPhoto: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
}
