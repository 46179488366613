<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/cover.webp" class="avatar img-responsive img-fluid" alt="nature">
                        <div class="top-header-author">
                            <div *ngIf="friendData[0].user_type_id === 14" class="author-thumb">
                                <img alt="author"
                                    src="https://api.football-family.com/img/legends/avatars/{{friendData[0].id}}/avatar.png"
                                    class="avatar img-responsive img-fluid"
                                    onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                    style="width: 100%; height: 100%;">
                            </div>

                            <div *ngIf="friendData[0].user_type_id !== 14" class="author-thumb">
                                <img *ngIf="!friendData[0].avatar" src="assets/img/author-main1.webp"
                                    class="avatar img-responsive img-fluid" width="100%" height="100%" alt="author">
                                <img *ngIf="friendData[0].avatar" alt="author" [src]="friendData[0].avatar"
                                    class="avatar img-responsive img-fluid"
                                    onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                    style="width: 100%; height: 100%;">
                            </div>
                            <div class="author-content">
                                <p class="h3 author-name">{{friendData[0].fullName | titlecase}}</p>
                                <div class="country"> <strong> {{ friendData[0].role | titlecase}} </strong></div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/profile-details"
                                            [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Timeline</a>
                                    </li>
                                    <li>
                                        <a routerLink="/friend-about"
                                            [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">About</a>
                                    </li>
                                    <li>
                                        <a routerLink="/f-photo" [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Photos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/f-videos" [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Videos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/f-friends" [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Friends</a>
                                    </li>
                                    <li>
                                        <a routerLink="/f-followers"
                                            [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Followers</a>
                                    </li>
                                    <li>
                                        <a routerLink="/f-following"
                                            [queryParams]="{'id': friendData[0].id,'role': role}"
                                            routerLinkActive="active">Following</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="control-block-button">

                            <a *ngIf="friendData[0].friendStatus" (click)="request(friendData[0].id)"
                                class="btn btn-control bg-grey-light follow">
                                <span *ngIf="friendData[0].friendStatus ==='true'"> <img
                                        src="../../../../assets/img/unfriend.webp" class="img-responsive img-fluid"
                                        alt="follow" style="width: 44px;
                                    margin: 5px 10px; "></span>
                                <span *ngIf="friendData[0].friendStatus ==='false'"> <img
                                        src="../../../../assets/img/friend.webp" class="img-responsive img-fluid"
                                        alt="follow" style="width: 44px;
                                    margin: 5px 10px; "></span>
                            </a>

                            <a *ngIf="friendData[0].followStatus" (click)="follow(friendData[0].id)"
                                class="btn btn-control bg-grey-light follow">

                                <span *ngIf="friendData[0].followStatus ==='false'"> <img
                                        src="../../../../assets/img/unfollow.webp" class="img-responsive img-fluid"
                                        alt="follow" style="width: 44px;
                                        margin: 5px 10px; "></span>
                                <span *ngIf="friendData[0].followStatus ==='true'"> <img
                                        src="../../../../assets/img/follow.webp" class="img-responsive img-fluid"
                                        alt="follow" style="width: 44px;
                                        margin: 5px 10px; "></span>
                            </a>

                            <!-- <a (click)="openChatModal(chatModal,friendData[0])" class="btn btn-control bg-grey-light follow">
                                <svg class="olymp-chat---messages-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon">
                                    </use>
                                </svg>
                            </a> -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Popup Chat -->
<div class="modal fade" bsModal #chatModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog window-popup restore-password-popup" role="document">

        <div class="ui-block popup-chat">
            <div class="ui-block-title">
                <div class="author-thumb">
                    <span class="icon-status online"></span>
                    <img *ngIf="!selectFriend.avatar" alt="author" class="avatar" src="../../../assets/img/user.jpg"
                        style="width: 36px; height: 36px;">
                    <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar"
                        style="width: 36px; height: 36px;">
                </div>
                <h6 class="title">{{ selectFriend.fullName }}</h6>
                <button type="button" class="close" (click)="closeChatModal(chatModal)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <ul class="notification-list chat-message chat-message-field">
                    <li>
                        <div class="author-thumb">
                            <img *ngIf="!selectFriend.avatar" alt="author" class="avatar"
                                src="../../../assets/img/user.jpg" style="width: 36px; height: 36px;">
                            <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar"
                                style="width: 36px; height: 36px;">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow!
                                I’m
                                gonna be
                                handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img *ngIf="!currentUser.success.user.avatar" alt="author" class="avatar"
                                src="../../../assets/img/user.jpg" style="width: 36px; height: 36px;">
                            <img *ngIf="currentUser.success.user.avatar" alt="author" class="avatar"
                                [src]="currentUser.success.user.avatar" style="width: 36px; height: 36px;">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Don’t worry Mathilda!</span>
                            <span class="chat-message-item">I already bought everything</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
                        </div>
                    </li>
                </ul>
            </div>

            <form>

                <div class="form-group label-floating is-empty">
                    <textarea class="form-control" placeholder="Press enter to reply..."></textarea>
                    <div class="add-options-message">
                        <a href="javascript:void(0);" class="options-message">
                            <svg class="olymp-computer-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                            </svg>
                        </a>
                        <!-- <div class="options-message smile-block">

							<svg class="olymp-happy-sticker-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon">
								</use>
							</svg>

							<ul class="more-dropdown more-with-triangle triangle-bottom-right">
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat1.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat2.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat3.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat4.png" alt="icon">
									</a>
								</li>


							</ul>
						</div> -->
                    </div>
                </div>

            </form>


        </div>

    </div>
</div>

<!-- ... end Popup Chat -->