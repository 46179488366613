import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-president-consultant',
  templateUrl: './president-consultant.component.html',
  styleUrls: ['./president-consultant.component.css']
})
export class PresidentConsultantComponent implements OnInit {
  
  language = '';
  direction;
  defaultLanguage;

  imageSrc = {
    src: '../../../assets/img/committee/IbrahimAlNajjar.webp',
    id: '13473',
    name: 'Ibrahim Al Najjar',
    position: 'President Consultant',
    nationality: 'Palestine',
    flag: '../../../assets/svg-icons/1x1/ps.svg'
  };
  images = [
    {
      src: '../../../assets/img/committee/IbrahimAlNajjar.webp',
      id: '13473',
      name: 'Ibrahim Al Najjar',
      position: 'President Consultant',
      nationality: 'Palestine',
      flag: '../../../assets/svg-icons/1x1/ps.svg'
    },
    {
      src: '../../../assets/img/committee/MohamedSouwaid.webp',
      id: '13472',
      name: 'Mohamed Souwaid',
      position: 'President Consultant',
      nationality: 'Saudi Arabia ',
      flag: '../../../assets/svg-icons/1x1/sa.svg'
    },
  ];
  constructor(
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      });  
  }

  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

  onClick(imageNameObject) {
    this.imageSrc = imageNameObject;
  }


}
