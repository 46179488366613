<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/covers/{{countryData.continent_id}}.webp" alt="cover" class="img-responsive img-fluid" onerror="this.onerror=null;this.src='assets/img/club-cover.webp';" style="width: 100%;height: 100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive img-fluid" style="width: 100%; height: 100%;" onerror="this.onerror=null;this.src='assets/img/clubs.webp';">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png" class="img-responsive float-left" alt="flag" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: 100%;">
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLinkActive="active">Fans ({{ fans.length}})</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="fans.length === 0" class="ui-block-title">
                <h6 class="display-message">No Data Found!
                </h6>
            </div>
        </div>
    </div>
</div>
<div *ngIf="fans.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Fans </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let fan of pageOfItems | filter:searchText" class="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': fan.id,'role': 'Fan'}">
                                    <img *ngIf="!fan.avatar" src="assets/img/author-main1.webp" alt="author" style="width: 100%;">
                                    <img *ngIf="fan.avatar" [src]="fan.avatar" alt="author" onerror="this.onerror=null; this.src='assets/img/author-main1.webp';" class="img-responsive img-fluid" style="width: 100%; height: auto;">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': fan.id,'role': 'Fan'}" class="h5 author-name">
                                    {{fan.fullName}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <div>
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </ul>
    </nav>
</div>