import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conf-dashboard',
  templateUrl: './conf-dashboard.component.html',
  styleUrls: ['./conf-dashboard.component.css']
})
export class ConfDashboardComponent implements OnInit {

  newsForm: FormGroup;
  id: number;
  countryData: any = {};
  confederationData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';
  imageError = '';
  visible = true;
  onChangeValue = '';

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getConfederation();
    this.newsForm = this.formBuilder.group({
      confederationId: [this.id],
      newsType: ['', Validators.required],
      newsTitle: ['', Validators.required],
      newsContent: ['', Validators.required],
      newsAttachment: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  getConfederation() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getConfederation(data).subscribe((res: any) => {
      this.confederationData = res.continent[0];
      this.isLoaderVisible = false;
    });
  }
  onChange(e) {
    this.onChangeValue = e.target.value || 0;
    if (this.onChangeValue === 'video') {
      this.visible = false;
    } else if (this.onChangeValue === 'photo') {
      this.visible = true;
    }
  }


  onFileSelect(event) {
    const newsAttachment = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.newsForm.get('newsAttachment').setValue(newsAttachment);

  }

  get f() { return this.newsForm.controls; }
  postNews() {
    this.submitted = true;

    if (this.newsForm.invalid) {

      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('confederationId', this.newsForm.get('confederationId').value);
    fd.append('newsType', this.newsForm.get('newsType').value);
    fd.append('newsTitle', this.newsForm.get('newsTitle').value);
    fd.append('newsContent', this.newsForm.get('newsContent').value);
    fd.append('createdBy', this.newsForm.get('createdBy').value);
    fd.append('newsAttachment', this.newsForm.get('newsAttachment').value);
    this.iffhsService.addNews(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success(this.onChangeValue + ' Added!', 'Message!');

        this.submitted = true;
      },
      error => {
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Again!', 'Message!');

      }
    );
    this.resetForm();
    this.imageError = '';
  }
  resetForm() {
    this.newsForm = this.formBuilder.group({
      confederationId: [this.id],
      newsType: [null],
      newsTitle: [null],
      newsContent: [null],
      newsAttachment: [null],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
