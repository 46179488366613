<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-club-header></app-club-header>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title">
                        Followers:
                    </div>
                    <div class="h6 label-title text-right">0 {{placeName}}
                        <a href="javascript:void(0);">
                            <img src="../../../../assets/img/unfollow.webp" alt="follow" style="width: 44px;
							margin: -10px 10px; "></a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex1200">
                <div class="ui-block-title">

                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control">
                                <option class="list option selected focus"> Select Continent</option>
                                <option class="list option selected focus" *ngFor="let continent of continents"
                                    [value]="continent.id" (click)="getCountries(continent.id, continent.name)">
                                    {{continent.name}}
                                </option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isCountryDisabled">
                                <option class="list option selected focus" *ngFor="let country of countries"
                                    [value]="country.id" (click)="getState(country.id,country.name )">
                                    {{country.name}}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isStateDisabled">
                                <option class="list option selected focus" *ngFor="let state of states"
                                    [value]="state.id" (click)="getCities(state.id, state.name)">{{state.name}}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isCityDisabled">
                                <option class="list option selected focus" *ngFor="let city of cities" [value]="city.id"
                                    (click)="getCity(city.id,city.name )">{{city.name}}</option>
                            </select>
                        </fieldset>
                    </div>

                    <!-- <form class="w-search">
						<div class="form-group with-button">
							<input class="form-control" type="text" placeholder="Search Blog Posts......">
							<button>
								<svg class="olymp-magnifying-glass-icon"><use xlink:href="svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use></svg>
							</button>
						</div>
					</form> -->
                </div>
            </div>
        </div>
    </div>
</div>


<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="ui-block">

                    <!-- Post -->

                    <article class="hentry blog-post">

                        <div class="post-thumb">
                            <iframe *ngIf="url === ''" width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=asia&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY" allowfullscreen=""></iframe>

                            <iframe *ngIf="url !== ''" width="100%" height="450" frameborder="0" style="border:0" [src]='sanitizer.bypassSecurityTrustResourceUrl(url)' allowfullscreen="">
                            </iframe>



                            <!-- <iframe  width="100%" height="450" frameborder="0"
								style="border:0"
								src="https://www.google.com/maps/embed/v1/place?q=makkah&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY"
								allowfullscreen></iframe> -->
                            <!-- <iframe
								src="https://www.google.com/maps/search/?api=1&query=Saudi+Arabia"
								width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen=""
								aria-hidden="false" tabindex="0"></iframe> -->
                        </div>

                        <!-- <div class="post-content">
							<a href="#" class="post-category bg-blue-light">THE COMMUNITY</a>
							<a href="#" class="h4 post-title">Here’s the Featured Urban photo of August! </a>
							<p>Here’s a photo from last month’s photoshoot. We got really awesome shots for the new catalog.</p>
					
							<div class="author-date">
								by
								<a class="h6 post__author-name fn" href="#">Maddy Simmons</a>
								<div class="post__date">
									<time class="published" datetime="2017-03-24T18:18">
										- 7 hours ago
									</time>
								</div>
							</div>
					
							<div class="post-additional-info inline-items">
								<div class="friends-harmonic-wrap">
									<ul class="friends-harmonic">
										<li>
											<a href="#">
												<img src="../../../../../assets/img/icon-chat27.png" alt="icon">
											</a>
										</li>
										<li>
											<a href="#">
												<img src="../../../../../assets/img/icon-chat2.png" alt="icon">
											</a>
										</li>
									</ul>
									<div class="names-people-likes">
										26
									</div>
								</div>
					
								<div class="comments-shared">
									<a href="#" class="post-add-icon inline-items">
										<svg class="olymp-speech-balloon-icon">
											<use xlink:href="../../../../../assets/svg-icons/sprites/icons.svg#olymp-speech-balloon-icon"></use>
										</svg>
										<span>0</span>
									</a>
								</div>
					
							</div>
						</div> -->

                    </article>

                    <!-- ... end Post -->
                </div>
            </div>



        </div>


        <!-- Pagination -->

        <!-- <nav aria-label="Page navigation">
			<ul class="pagination justify-content-center">
				<li class="page-item disabled">
					<a class="page-link" href="#" tabindex="-1">Previous</a>
				</li>
				<li class="page-item"><a class="page-link" href="#">1<div class="ripple-container"><div class="ripple ripple-on ripple-out" style="left: -10.3833px; top: -16.8333px; background-color: rgb(255, 255, 255); transform: scale(16.7857);"></div></div></a></li>
				<li class="page-item"><a class="page-link" href="#">2</a></li>
				<li class="page-item"><a class="page-link" href="#">3</a></li>
				<li class="page-item"><a class="page-link" href="#">...</a></li>
				<li class="page-item"><a class="page-link" href="#">12</a></li>
				<li class="page-item">
					<a class="page-link" href="#">Next</a>
				</li>
			</ul>
		</nav> -->

        <!-- ... end Pagination -->
    </div>
</section>