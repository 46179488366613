import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/data-services/user.service';
import countries from '../../../../../../assets/json/countries.json';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-club-dashboard-matches',
  templateUrl: './club-dashboard-matches.component.html',
  styleUrls: ['./club-dashboard-matches.component.css']
})
export class ClubDashboardMatchesComponent implements OnInit {

  matchForm: FormGroup;
  id: number;
  countryData: any = {};
  clubData: any = {};
  countries: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  clubs: any = [];
  errorMessage = '';
  showForm = false;
  showCountries = false;
  selectedId: number;
  countryList: { name: string, id: string }[] = countries;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getClubProfile();
    this.matchForm = this.formBuilder.group({
      hostTeam: [this.id],
      visitingTeam: ['', Validators.required],
      hostScore: ['', Validators.required],
      visitorScore: ['', Validators.required],
      matchSummary: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  getClubProfile() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getClubProfile(data).subscribe((res: any) => {
      this.clubData = res.club;
      this.countryData = res.country;
      this.isLoaderVisible = false;
    });
  }

  get f() { return this.matchForm.controls; }
  addMatch() {
    this.submitted = true;
    if (this.matchForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('hostTeam', this.matchForm.get('hostTeam').value);
    fd.append('visitingTeam', this.matchForm.get('visitingTeam').value);
    fd.append('hostScore', this.matchForm.get('hostScore').value);
    fd.append('visitorScore', this.matchForm.get('visitorScore').value);
    fd.append('matchSummary', this.matchForm.get('matchSummary').value);
    fd.append('createdBy', this.matchForm.get('createdBy').value);

    this.iffhsService.addMatch(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Football Match Added!', 'Message!');

        this.submitted = true;
      },
      error => {

        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.matchForm = this.formBuilder.group({
      hostTeam: [this.id],
      visitingTeam: [''],
      hostScore: [''],
      visitorScore: [''],
      matchSummary: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  onInternational() {
    this.showCountries = true;
    // this.getAllCountries();
  }
  onLocal() {

    this.getLocalClubs(this.selectedId);
  }
  getLocalClubs(id: any) {
    this.selectedId = id;
    this.showForm = true;
    this.showCountries = false;
    console.log(id);
    const data = {
      countryId: id,
    };
    this.iffhsService.getLocalClubs(data).subscribe((res: any) => {
      this.clubs = res;
    });
  }
  getAllCountries() {
    this.userService.getCountries().subscribe((res: any) => {
      this.countries = res;
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
