<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/covers/{{countryData.continent_id}}.webp" alt="cover" onerror="this.onerror=null;this.src='assets/img/club-cover.webp';" style="width: 100%;height:  100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive" style="width: 100%; height:  100%;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png" class="img-responsive float-left" alt="flag" style="width: 100%; height:  100%;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLinkActive="active">{{'team-referees.referees' | translate}} ( {{referees.length}} )</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> {{'team-referees.referees' | translate}} </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">{{'team-referees.fifa' | translate}}</option>
                                <option value="NON FIFA">{{'team-referees.non_fifa' | translate}}</option>
                            </select>
                        </fieldset>
                    </div>

                    <!-- <div class="w-select">
                        <fieldset class="form-group">
                            <select class="form-control">
                                <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}
                                </option>

                            </select>
                        </fieldset>
                    </div> -->
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" 
                            placeholder="{{'team-referees.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>           
        </div>
        <div *ngIf="referees.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message">{{'team-referees.no_data' | translate}} </h6>
        </div>
    </div>

</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div *ngFor="let referee of referees  | filter:searchText" class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': referee.id,'role': 'Referee'}">
                                    <img src="{{referee.refereeAvatar}}" alt="Avatar" style="width: 120px; height: 120px;" onerror="this.onerror=null;this.src='assets/img/author-main1.webp';" style="width: 120px; height: 120px;">
                                </a>
                            </div>

                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': referee.id,'role': 'Referee'}" class="h5 author-name"> {{referee.refereeName | titlecase}} </a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>