<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<!-- Top Header-Profile -->


<!-- ... end Top Header-Profile -->

<div class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block responsive-flex">
				<div class="ui-block-title">
					<div class="h6 title"> Community Members </div>
					<form class="w-search">
						<div class="form-group with-button">
							<input class="form-control" type="text" placeholder="Search ...">
							<button>
								<svg class="olymp-magnifying-glass-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use></svg>
							</button>
						</div>
                    </form>
                </div>
			</div>
		</div>
	</div>
</div>

<!-- Main Content Birthday -->

<div class="container">
	<div class="row">
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/france.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">France</a>
						<div class="birthday-date"> 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/brazil.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Brazil</a>
						<div class="birthday-date">988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/argentina.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Argentina</a>
						<div class="birthday-date">5280</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/saudi.png" alt="Saudi Arabia">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Saudi Arabia</a>
						<div class="birthday-date">485</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/france.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">France</a>
						<div class="birthday-date"> 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/brazil.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Brazil</a>
						<div class="birthday-date">988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/argentina.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Argentina</a>
						<div class="birthday-date">5280</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/saudi.png" alt="Saudi Arabia">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Saudi Arabia</a>
						<div class="birthday-date">485</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/france.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">France</a>
						<div class="birthday-date"> 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/brazil.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Brazil</a>
						<div class="birthday-date">988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/argentina.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Argentina</a>
						<div class="birthday-date">5280</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/saudi.png" alt="Saudi Arabia">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Saudi Arabia</a>
						<div class="birthday-date">485</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/france.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">France</a>
						<div class="birthday-date"> 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/brazil.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Brazil</a>
						<div class="birthday-date">988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/argentina.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Argentina</a>
						<div class="birthday-date">5280</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/saudi.png" alt="Saudi Arabia">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Saudi Arabia</a>
						<div class="birthday-date">485</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/france.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">France</a>
						<div class="birthday-date"> 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/brazil.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Brazil</a>
						<div class="birthday-date">988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/argentina.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Argentina</a>
						<div class="birthday-date">5280</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/saudi.png" alt="Saudi Arabia">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Saudi Arabia</a>
						<div class="birthday-date">485</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<!-- <a routerLink="/legends-list" class="btn btn-sm bg-blue">Read more</a> -->
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>


	</div>
</div>

<!-- ... end Main Content Birthday -->
