import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-award-fans-details',
  templateUrl: './award-fans-details.component.html',
  styleUrls: ['./award-fans-details.component.css']
})
export class AwardFansDetailsComponent implements OnInit {
  awards = [];
  years = [];
  searchText: string;
  id: number;
  name: string;
  currentUser: any;
  countries = [];
  title: string;
  isLoaderVisible = false;
  fans = [];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.name = params['name'];
    });
    this.getAwardDetails();
    this.fans = Array(10).fill(0).map((x, i) => ({ id: (i + 1), name: `Coming Soon` }));
    this.years = Array(6).fill(0).map((x, i) => ({ id: (i + 2020) }));
  }


  getAwardDetails() {
    this.isLoaderVisible = true;
    const data = {
      awardId: this.id,
    };
    this.iffhsService.getAwardDetails(data).subscribe((res: any) => {
      this.awards = res.awards;
      this.title = res.title.name;
      this.countries = res.countries;
      this.isLoaderVisible = false;
    });
  }


}
