<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div *ngIf="domElement==='view'" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">IFHHS Award Banners</h6>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <button (click)="onClick('add')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-plus" aria-hidden="true"></i>
                        Add</button>
                </div>
                <div class="ui-block-content">


                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="id-width">ID</th>
                                <th>Name</th>
                                <th class="id-width">Awards</th>
                                <!-- <th>Photos</th> -->
                                <th class="id-width">Edit</th>
                                <th class="id-width">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let banner of pageOfItems | filter:searchText">
                                <td>{{banner.id}}</td>
                                <td>{{banner.name | slice:0:110}}</td>
                                <td>{{banner.awards.length}}</td>
                                <!-- <td>
                                    <img src="https://api.football-family.com/img/awards/banners/{{banner.id}}.png" width="50px" class="avatar" alt="photo">
                                </td> -->
                                <td routerLink="/edit-award-banner" [queryParams]="{'id': banner.id}"><i class="fas fa-edit fa-2x"></i></td>
                                <td (click)="openDeleteModal(bannerDeleteModal,banner)"><i class="fas fa-trash fa-2x"></i></td>
                            </tr>
                        </tbody>
                    </table>

                   
                    <div *ngIf="banners.length === 0" class="ui-block-title">
                        <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
                    </div>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <div>
                                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>

        </div>

    </div>

</div>
<div *ngIf="domElement==='add' || domElement==='edit'" class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add IFFHS Award Banner </h6>
                    <button (click)="onClick('view')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>

                        View </button>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="awardBannerForm" (ngSubmit)="addAwardBanner()">
                        <div class="row">

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div *ngIf="domElement==='edit'" class="form-group">
                                    <label class="control-label">Enter Banner ID<span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="bannerId" disabled placeholder="52" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bannerId.errors }" />
                                </div>
                                <div *ngIf="domElement==='add'" class="form-group">
                                    <label class="control-label">Enter Banner ID<span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="bannerId" placeholder="52" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bannerId.errors }" />
                                    <div *ngIf="submitted && f.bannerId.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerId.errors.required">ID is required *</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Banner Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="bannerName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.bannerName.errors }" />
                                    <div *ngIf="submitted && f.bannerName.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerName.errors.required">Name is required *</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Number of Awards <span style="color: red;"> *
                                        </span> </label>
                                    <input type="text" formControlName="numberOfAwards" placeholder="23" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.numberOfAwards.errors }" />

                                    <div *ngIf="submitted && f.numberOfAwards.errors" class="invalid-feedback">
                                        <div *ngIf="f.numberOfAwards.errors.required">Award Numbers is required *</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Photo ( Select only Image ) <span style="color: red;">
                                            *
                                        </span></label>
                                    <input type="file" formControlName="bannerPhoto" accept="image/*" class="form-control" (change)="onImageSelect($event)" [ngClass]="{ 'is-invalid': submitted && f.bannerPhoto.errors }" />
                                    <div *ngIf=" submitted && f.bannerPhoto.errors " class="invalid-feedback ">
                                        <div *ngIf="f.bannerPhoto.errors.required ">Photo is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button  class="btn btn-primary btn-lg full-width">                                    
                                    Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" bsModal #bannerDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Banner (Category)</h5>
                <button type="button" class="close" (click)="bannerDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you sure you want to delete {{selectedBanner.name }}.?</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="bannerDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteBanner(bannerDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->