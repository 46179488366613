<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/voting/ranking-dashboard-banner.webp" class="img-responsive img-fluid"
                            alt="cover" style="width: 100%;height: 100%;">
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a href="javascript:void(0);" routerLinkActive="active"> {{'add-ranking.ranking' | translate}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="domElement==='view'" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'add-ranking.iffhs_ranking' | translate}}</h6>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{'add-ranking.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <button (click)="onClick('add')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-plus" aria-hidden="true"></i>
                            {{'add-ranking.add' | translate}}</button>
                </div>
                <div class="ui-block-content">


                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th>{{'add-ranking.id' | translate}}</th>
                                <th style="width: 30%;">{{'add-ranking.name' | translate}}</th>
                                <th>{{'add-ranking.photo' | translate}}</th>
                                <th>{{'add-ranking.award' | translate}}</th>
                                <th>{{'add-ranking.year' | translate}}</th>
                                <th>{{'add-ranking.edit' | translate}}</th>
                                <th>{{'add-ranking.delete' | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let rank of ranking | filter:searchText">
                                <td>{{rank.id}}</td>
                                <td>{{rank.firstName }} {{rank.lastName }}</td>
                                <td>
                                    <img src="{{rank.avatar}}" width="50px" class="avatar" alt="photo">
                                </td>
                                <td>{{rank.award}}</td>
                                <td>{{rank.yearOfRanking}}</td>
                                <td routerLink="/edit-ranking" [queryParams]="{'id': rank.id}"><i
                                        class="fas fa-edit fa-2x"></i></td>
                                <td (click)="openDeleteModal(rankDeleteModal,rank)"><i class="fas fa-trash fa-2x"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="ranking.length === 0" class="ui-block-title">
                        <h6 *ngIf="!isLoaderVisible" class="display-message"> {{'add-ranking.no_data' | translate}} </h6>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <div>
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </ul>
    </nav> -->
</div>
<div *ngIf="domElement==='add'" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'add-ranking.add' | translate}}</h6>
                    <button (click)="onClick('view')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>
                            {{'add-ranking.view' | translate}}</button>
                </div>
                <div class="ui-block-content">
                    <form [formGroup]="addRankingForm" (ngSubmit)="addRanking()" class="content">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.select_category' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="category"
                                        [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                                        <option value="Annual" selected>{{'add-ranking.annual' | translate}} </option>
                                        <option value="Event">{{'add-ranking.event' | translate}} </option>

                                    </select>
                                    <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                                        <div *ngIf="f.category.errors.required">{{'add-ranking.req_category' | translate}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.select_award' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="award"
                                        [ngClass]="{ 'is-invalid': submitted && f.award.errors }">
                                        <option *ngFor="let award of awards" value="{{award.id}}" selected>
                                            {{award.name | slice:0:30}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.award.errors" class="invalid-feedback">
                                        <div *ngIf="f.award.errors.required">{{'add-ranking.req_award' | translate}}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.fname' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="firstName" placeholder="Jhon"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">{{'add-ranking.req_fname' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.lname' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="lastName" placeholder="Snow"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">{{'add-ranking.req_lname' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.select_year' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="yearOfRanking" placeholder="2018"
                                        [ngClass]="{ 'is-invalid': submitted && f.yearOfRanking.errors }">
                                        <option *ngFor="let year of years" value="{{year.name}}" selected>
                                            {{year.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.yearOfRanking.errors" class="invalid-feedback">
                                        <div *ngIf="f.yearOfRanking.errors.required">{{'add-ranking.req_year' | translate}}</div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'add-ranking.select_rank' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="rank"
                                        [ngClass]="{ 'is-invalid': submitted && f.rank.errors }">
                                        <option *ngFor="let rank of ranking" value="{{rank.id}}" selected>
                                            {{rank.id}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.rank.errors" class="invalid-feedback">
                                        <div *ngIf="f.rank.errors.required">{{'add-ranking.req_rank' | translate}}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label"> {{'add-ranking.avatar' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="file" formControlName="avatar" accept="image/*" class="form-control"
                                        (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.avatar.errors }" />
                                    <div *ngIf=" submitted && f.avatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.avatar.errors.required "> {{'add-ranking.req_avatar' | translate}}</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    {{'add-ranking.save' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" bsModal #rankDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{'add-ranking.delete_ranking' | translate}}</h5>
                <button type="button" class="close" (click)="rankDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>{{'add-ranking.sure_delete' | translate}} {{selectedRank.name }}.?</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="rankDeleteModal.hide()">{{'add-ranking.no' | translate}}</button>
                <button type="button" class="btn btn-danger" (click)="deleteRank(rankDeleteModal)">{{'add-ranking.yes' | translate}}</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->