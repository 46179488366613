<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> National Teams </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>           
        </div>
        <div *ngIf="teams.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message">No Data Found ! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let team of teams | filter:searchText" class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div (click)="openModal(template , team)" class="author-thumb">
                                <img src="{{team.src}}" style="width: 120px; height: 120px;" alt="photo">
                            </div>
                            <div class="author-content">
                                <p (click)="openModal(template , team)" class="h5 author-name"> {{team.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title pull-left "> {{ selectTeam.name }} of {{countryData.name}}</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Men'}"
                                            (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Men'}"
                                            class="h5 author-name" (click)="modalRef.hide()"> Men
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Women'}"
                                            (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Women'}"
                                            class="h5 author-name" (click)="modalRef.hide()"> Women
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>