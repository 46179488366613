import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.css']
})
export class WeatherComponent implements OnInit {
  weatherSearchForm: FormGroup;
  today = new Date();
  loading = false;
  submitted = false;
  data: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.weatherSearchForm = this.formBuilder.group({
      location: ['', Validators.required],
    });
  }
  get f() { return this.weatherSearchForm.controls; }
  getWeather() {
    this.submitted = true;
    this.loading = true;
    if (this.weatherSearchForm.invalid) {
      this.loading = false;
      return;
    }
    this.userService.getWeather(this.weatherSearchForm.value).subscribe(data =>
      this.data = data
    );
    console.log(this.data);
  }


}
