import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {
  countries: [];
  searchText: string;
  selectedId: number;
  selectedTop: number;
  activePro = true;
  isLoaderVisible = false;
  legends = false;
  url = 'club';
  visible = false;
  afterSelect = true;
  language = '';
  direction = '';
  defaultLanguage;

  constructor(
    private iffhsService: IffhsService,
    private messageService: MessageService
  ) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;

    this.getAllCountries();
  }

  getAllCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllCountries().subscribe((res: any) => {
      this.countries = res;
      this.isLoaderVisible = false;
    });
  }
  goTo(id: number) {
    this.activePro = false;
    if (id === 1) {
      this.selectedTop = 1;
      this.visible = true;
      this.legends = false;
      this.afterSelect = false;
      this.url = 'club';
    } else if (id === 2) {
      this.selectedTop = 2;
      this.visible = true;
      this.legends = false;
      this.url = 'players';
      this.afterSelect = false;
    } else if (id === 3) {
      this.selectedTop = 3;
      this.visible = true;
      this.legends = false;
      this.url = 'referees';
      this.afterSelect = false;
    } else if (id === 4) {
      this.selectedTop = 4;
      this.visible = true;
      this.legends = false;
      this.url = 'team';
      this.afterSelect = false;
    } else if (id === 5) {
      this.selectedTop = 5;
      this.visible = true;
      this.legends = false;
      this.url = 'club';
      this.afterSelect = false;
    } else if (id === 6) {
      this.selectedTop = 6;
      // this.visible = true;
      this.legends = true;
      this.url = 'legends';
      this.afterSelect = false;
    } else if (id === 7) {
      this.selectedTop = 7;
      this.visible = true;
      this.url = 'coaches';
      this.afterSelect = false;
      this.legends = false;
    }


  }
  getContinent(id: number) {
    this.isLoaderVisible = true;
    this.activePro = false;
    const data = {
      continentId: id,
    };
    this.iffhsService.getGeneralContinent(data).subscribe((res: any) => {
      if (res[0].continent_id === 1) {
        this.selectedId = 1;
        this.countries = res;
        this.isLoaderVisible = false;
      } else if (res[0].continent_id === 3) {
        this.selectedId = 3;
        this.countries = res;
        this.isLoaderVisible = false;

      } else if (res[0].continent_id === 4) {
        this.selectedId = 4;
        this.countries = res;
        this.isLoaderVisible = false;

      } else if (res[0].continent_id === 5) {
        this.selectedId = 5;
        this.countries = res;
        this.isLoaderVisible = false;
      } else if (res[0].continent_id === 6) {
        this.selectedId = 6;
        this.countries = res;
        this.isLoaderVisible = false;
      } else if (res[0].continent_id === 7) {
        this.selectedId = 7;
        this.countries = res;
        this.isLoaderVisible = false;
      }

    });

  }

}
