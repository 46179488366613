import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-club-profile',
  templateUrl: './club-profile.component.html',
  styleUrls: ['./club-profile.component.css']
})
export class ClubProfileComponent implements OnInit {
  @ViewChild('newsSection') public newsSection: ElementRef;
  modalRef: BsModalRef;
  id: number;
  countryData: any = {};
  clubData: any = {};
  matches = [];
  leagues = [];
  players = [];
  coaches = [];
  searchText: any;
  currentUser: any;
  status: boolean;
  isLoaderVisible = false;
  selectNews: any = {};
  news = [];
  slider: any = {};
  blogs = [];
  items = [];
  age = [];
  pageOfItems: Array<any>;
  selectTeam: any;
  categories = [
    {
      id: '1',
      name: 'All Category'
    },
    {
      id: '2',
      name: 'By Clubs'
    },
    {
      id: '3',
      name: 'By Position'
    },
    {
      id: '4',
      name: 'By Ranking'
    },
    {
      id: '5',
      name: 'By Age'
    },
    {
      id: '6',
      name: 'By Nationality'
    },
  ];
  suggestedTeam = [
    {
      id: '1',
      playerName: 'Player 1'
    },
    {
      id: '2',
      playerName: 'Player 2'
    },
    {
      id: '3',
      playerName: 'Player 3'
    },
  ];
  teams = [
    // {
    //   id: '1',
    //   name: 'First Team',
    //   src: 'assets/img/team-class/first-team.webp',
    // },
    // {
    //   id: '2',
    //   name: 'Olympic Team',
    //   src: 'assets/img/team-class/olympic-team.webp',
    // },
    // {
    //   id: '3',
    //   name: 'Youth Team',
    //   src: 'assets/img/team-class/youth-team.webp',
    // },
    // {
    //   id: '4',
    //   name: 'Under 20',
    //   src: 'assets/img/team-class/u-20.webp',
    // },
    {
      id: '9',
      name: 'Academic Team',
      src: 'assets/img/team-class/academic team.webp',
    },
    // {
    //   id: '5',
    //   name: 'Youth Under 19',
    //   src: 'assets/img/team-class/u-19.webp',
    // },
    // {
    //   id: '6',
    //   name: 'Youth Under 17',
    //   src: 'assets/img/team-class/u-16.webp',
    // },
    // {
    //   id: '7',
    //   name: 'Special Needs Team',
    //   src: 'assets/img/team-class/special-need.webp',
    // },
    // {
    //   id: '8',
    //   name: 'Beach Soccer',
    //   src: 'assets/img/team-class/beach-soccor.webp',
    // },

  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getClubProfile();
    this.getNews();
    this.getMatches();
    this.getLeagues();
    this.getPlayers();
    this.getCoaches();
  }
  getClubProfile() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getClubProfile(data).subscribe((res: any) => {
      this.clubData = res.club;
      this.countryData = res.country;
      this.isLoaderVisible = false;
    });
  }
  onChangePage(pageOfItems: Array<any>) {

    this.pageOfItems = pageOfItems;
  }
  moveToStructure(): void {
    this.newsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getNews() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getNews(data).subscribe((res: any) => {
      this.news = res.news;
      this.blogs = res.blogs;
      this.items = this.news;
      this.isLoaderVisible = false;
    });
  }
  onClick(_new) {
    this.slider = _new;
  }
  openModal(modal: any, _new: any) {
    this.selectNews = _new;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }
  getMatches() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getMatches(data).subscribe((res: any) => {
      this.matches = res.matches;
      this.isLoaderVisible = false;
    });
  }
  getLeagues() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getLeagues(data).subscribe((res: any) => {
      this.leagues = res.leagues;
      this.isLoaderVisible = false;
    });
  }
  getPlayers() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getPlayers(data).subscribe((res: any) => {
      this.players = res.players;
      this.isLoaderVisible = false;
    });
  }
  onChangeFilter(e) {
    const data = {
      clubId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getPlayers(data).subscribe((res: any) => {
      this.players = res.players;
      this.isLoaderVisible = false;
      this.items = this.players;
      // tslint:disable-next-line: prefer-for-of
      // for (let i = 0; i < this.players.length; i++) {
      //   var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
      //   this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      // }
    });
  }
  getCoaches() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getCoaches(data).subscribe((res: any) => {
      this.coaches = res.coaches;
      this.isLoaderVisible = false;
    });
  }

  openTeamModal(template: any, team) {
    this.selectTeam = team;
    this.modalRef = this.modalService.show(
      template,
      {
        class: 'modal-lg modal-dialog-centered',
        ignoreBackdropClick: true,
        keyboard: false
      });

  }




}
