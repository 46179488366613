import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/data-services/user.service';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-iffhs-members',
  templateUrl: './iffhs-members.component.html',
  styleUrls: ['./iffhs-members.component.css']
})
export class IffhsMembersComponent implements OnInit {
  selectedId: number = 0;
  countries: any = [];
  iffhsCountries: any = [];
  allCountries: any = [];
  activePro = true;
  isLoaderVisible = false;
  language = '';
  direction;
  defaultLanguage;

  constructor(
    private userService: UserService,
    private iffhsService: IffhsService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      });
  }

  ngOnInit(): void {
    this.getAllCountries();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

  getAllCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllCountries().subscribe((res: any) => {
      this.countries = res;
      this.isLoaderVisible = false;
      // console.log(this.countries);
    });
  }

  getCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getCountries().subscribe((res: any) => {
      this.countries = res;
      this.isLoaderVisible = false;
      console.log(res);
    });
  }
  getContinent(id: number) {
    console.log(id);
    this.activePro = false;
    this.isLoaderVisible = true;
    const data = {
      continentId: id,
    };

    this.iffhsService.getContinentIffhs(data).subscribe((res: any) => {
      if (res.allCountries[0].continent_id === 1) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 1;
        this.isLoaderVisible = false;
        console.log(this.iffhsCountries);
        console.log(this.countries);
      } else if (res.allCountries[0].continent_id === 3) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 3;
        this.isLoaderVisible = false;
        console.log(this.iffhsCountries);
        console.log(this.countries);
      } else if (res.allCountries[0].continent_id === 4) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 4;
        this.isLoaderVisible = false;
        console.log(res);


      } else if (res.allCountries[0].continent_id === 5) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 5;
        this.isLoaderVisible = false;
        console.log(res);

      } else if (res.allCountries[0].continent_id === 6) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 6;
        this.isLoaderVisible = false;
        console.log(res);

      } else if (res.allCountries[0].continent_id === 7) {
        this.iffhsCountries = res.iffhsCountries;
        this.countries = res.allCountries;
        this.selectedId = 7;
        this.isLoaderVisible = false;
        console.log(res);
      }

    });

  }




}
