import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/data-services/user.service';
import countries from '../../../../../../assets/json/countries.json';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-matches',
  templateUrl: './dashboard-matches.component.html',
  styleUrls: ['./dashboard-matches.component.css']
})
export class DashboardMatchesComponent implements OnInit {

  matchForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countries: any = [];
  errorMessage: string = '';
  country: any;
  keyword = 'name';
  countryList: { name: string, id: string }[] = countries;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.matchForm = this.formBuilder.group({
      countryId: [this.id],
      hostTeam: [this.id],
      visitingTeam: ['', Validators.required],
      hostScore: ['', Validators.required],
      visitingScore: ['', Validators.required],
      matchSummary: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }

  selectEvent(item) {
    this.country = item.name;
  }
  get f() { return this.matchForm.controls; }
  addMatch() {
    this.submitted = true;
    if (this.matchForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('countryId', this.matchForm.get('countryId').value);
    fd.append('hostTeam', this.matchForm.get('hostTeam').value);
    fd.append('visitingTeam', this.matchForm.get('visitingTeam').value);
    fd.append('hostScore', this.matchForm.get('hostScore').value);
    fd.append('visitingScore', this.matchForm.get('visitingScore').value);
    fd.append('matchSummary', this.matchForm.get('matchSummary').value);
    fd.append('createdBy', this.matchForm.get('createdBy').value);

    this.iffhsService.addMatch(fd).pipe(first()).subscribe(
      data => {
        this.toastrService.success('Football Match Added!', 'Message!');
        this.submitted = true;
        this.isLoaderVisible = false;

      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.resetForm();
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  resetForm() {
    this.matchForm = this.formBuilder.group({
      countryId: [this.id],
      hostTeam: [this.id],
      visitingTeam: [''],
      hostScore: [''],
      visitingScore: [''],
      matchSummary: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
}
