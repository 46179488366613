import { Component, OnInit } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-presidents',
  templateUrl: './presidents.component.html',
  styleUrls: ['./presidents.component.css']
})
export class PresidentsComponent implements OnInit {
  language = '';
  direction = '';
  defaultLanguage;
  constructor(
    private messageService: MessageService) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }
  members = [
    {
      src: '../../../assets/img/presidents/alfredo.webp',
      id: '13479',
      name: 'late Dr.alfredo poge',
      nationality: 'Germany',
      position: 'former president',
      flag: '../../../assets/svg-icons/1x1/gr.svg',
      year: '1984 - 2011'
    },
    {
      src: '../../../assets/img/presidents/saqr.webp',
      id: '13480',
      name: 'Mohmad bin Saqr',
      nationality: 'United Arab Emirates',
      position: 'former president',
      flag: '../../../assets/svg-icons/1x1/ae.svg',
      year: '2011 - 2013'
    },
    {
      src: '../../../assets/img/presidents/robert.webp',
      id: '13481',
      name: 'Robert Ley',
      nationality: 'France',
      position: 'former president',
      flag: '../../../assets/svg-icons/1x1/fr.svg',
      year: '2013 - 2014'
    },
    {
      src: '../../../assets/img/presidents/saleh.webp',
      id: '13478',
      name: 'Shaikh Saleh Salem',
      nationality: 'Saudi Arabia',
      position: 'President',
      flag: '../../../assets/svg-icons/1x1/sa.svg',
      year: '2014 - Now'
    },



  ];
  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

}
