import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-award',
  templateUrl: './edit-award.component.html',
  styleUrls: ['./edit-award.component.css']
})
export class EditAwardComponent implements OnInit {

  awardEditForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  award: any = {};
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
  ) {
    this.route.queryParams.subscribe(params => this.id = params.id);

  }

  async ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getAwardById();

  }

  getAwardById() {
    this.isLoaderVisible = true;
    const data = {
      awardId: this.id,
    };
    this.iffhsService.getAwardById(data).subscribe((res: any) => {
      this.award = res;
      this.isLoaderVisible = false;
    },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }
    );
  }
  initForm() {
    this.awardEditForm = this.formBuilder.group({
      bannerId: [this.award.type_id],
      awardId: [this.id],
      awardName: [this.award.name, Validators.required],
      awardPhoto: [''],
      createdBy: [this.currentUser.success.user.id],

    });
  }

  onImageSelect(event) {
    const awardPhoto = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.awardEditForm.get('awardPhoto').setValue(awardPhoto);

  }
  goToDashboard() {
    this.router.navigate(['/add-award']);
  }

  get f() { return this.awardEditForm.controls; }
  editAward() {
    this.submitted = true;

    if (this.awardEditForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('bannerId', this.awardEditForm.get('bannerId').value);
    fd.append('awardId', this.awardEditForm.get('awardId').value);
    fd.append('awardName', this.awardEditForm.get('awardName').value);
    fd.append('createdBy', this.awardEditForm.get('createdBy').value);
    fd.append('awardPhoto', this.awardEditForm.get('awardPhoto').value);

    this.iffhsService.editAward(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Award Updated!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.resetForm();
  }
  resetForm() {
    this.getAwardById();
    this.awardEditForm = this.formBuilder.group({
      bannerId: [this.award.type_id],
      awardId: [this.id],
      awardName: [this.award.name],
      awardPhoto: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
