<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<app-conf-header></app-conf-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Photo / Videos</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="mediaForm" (ngSubmit)="postMedia()">
                        <div class="row">
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Type <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" name="mediaType" formControlName="mediaType"
                                        (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.mediaType.errors }">
                                        <option value="Photo" selected>Photo</option>
                                        <option value="Video">Video</option>
                                    </select>
                                    <div *ngIf="submitted && f.mediaType.errors" class="invalid-feedback">
                                        <div *ngIf="f.mediaType.errors.required">Type is required *</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Title <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" name="mediaTitle" formControlName="mediaTitle"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.mediaTitle.errors }" />
                                    <div *ngIf="submitted && f.mediaTitle.errors" class="invalid-feedback">
                                        <div *ngIf="f.mediaTitle.errors.required">Title is required *</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="control-label">Content </label>
                                    <textarea class="form-control" name="mediaContent" formControlName="mediaContent"
                                        placeholder="Content..."></textarea>
                                    <!-- <div *ngIf="submitted && f.mediaContent.errors" class="invalid-feedback">
                                        <div *ngIf="f.mediaContent.errors.required">Content is required *</div>
                                    </div> -->
                                </div>
                                <div *ngIf="onChangeValue === 'Photo'" class="form-group">
                                    <label class="control-label">Image ( Only Image )<span style="color: red;"> *
                                        </span></label>
                                    <input type="file" name="mediaAttachment" formControlName="mediaAttachment"
                                        class="form-control" (change)="onImageSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.mediaAttachment.errors }" />
                                    <div *ngIf=" submitted && f.mediaAttachment.errors " class="invalid-feedback ">
                                        <div *ngIf="f.mediaAttachment.errors.required ">Image is required *</div>
                                    </div>

                                </div>
                                <div *ngIf="onChangeValue === 'Video'" class="form-group">
                                    <label class="control-label">Video ( Only Video )<span style="color: red;"> *
                                        </span></label>
                                    <input type="file" name="mediaAttachment" formControlName="mediaAttachment"
                                        class="form-control" (change)="onVideoSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.mediaAttachment.errors }" />
                                    <div *ngIf=" submitted && f.mediaAttachment.errors " class="invalid-feedback ">
                                        <div *ngIf="f.mediaAttachment.errors.required ">Video is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">
                <div class="your-profile">
                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne"
                                        aria-expanded="true" aria-controls="collapseOne">
                                        Dashboard Settings
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use xlink:href="#olymp-dropdown-arrow-icon"></use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>
                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li class="active ">
                                        <a routerLink="/conf-dashboard" [queryParams]="{ 'id': confederationData.id}"
                                            routerLinkActive="active "> Add News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/conf-dashboard-photos"
                                            [queryParams]="{ 'id': confederationData.id}" routerLinkActive="active ">
                                            Add Photos/Videos
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>