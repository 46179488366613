<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-club-header></app-club-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Coach</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="coachForm" (ngSubmit)="addCoach()">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First Name <span style="color: red;"> *</span></label>
                                    <input type="text" formControlName="firstName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name <span style="color: red;"> *</span></label>
                                    <input type="text" formControlName="lastName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Avatar <span style="color: red;"> *</span></label>
                                    <input type="file" formControlName="coachAvatar" accept="image/*"
                                        class="form-control" (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.coachAvatar.errors }" />
                                    <div *ngIf=" submitted && f.coachAvatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.coachAvatar.errors.required ">Avatar is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none ">
            <div class="ui-block ">
                <div class="your-profile ">
                    <div id="accordion " role="tablist " aria-multiselectable="true ">
                        <div class="card ">
                            <div id="collapseOne " class="collapse show " role="tabpanel "
                                aria-labelledby="headingOne ">
                                <ul class="your-profile-menu ">
                                    <li class="active ">
                                        <a routerLink="/club-dashboard" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-media" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add Photos/Videos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-matches" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Matches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-leagues" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active "> Add Leagues</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-player" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Player</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-coach" [queryParams]="{ 'id': clubData.id} "
                                            routerLinkActive="active ">Add Coach</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>