<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container mt-2">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 label-title text-center"> {{title }}</div> -->
                    <div class="h6 label-title text-center"> Fans Award in {{ name | titlecase}}</div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
            <div *ngIf="awards.length === 0" class="ui-block-title">
                <h6 class="display-message"> Coming Soon End of Season 2020 </h6>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let year of years" class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">

                    <div class="h6 label-title text-center"> Coming soon {{year.id}} </div>

                </div>

            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>S.NO#</th>
                        <th>Name</th>
                        <th style="width: 33%;">Date of Birth</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fan of fans| filter:searchText; let i=index">
                        <td>{{i+1}}</td>
                        <td>
                            <a href="javascript:void(0)"><img src="assets/img/user.jpg" width="50px" class="avatar" alt="Avatar"> {{fan.name}} {{year.id}}
                            </a>
                        </td>
                        <td>24 March 199{{i}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>