<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/voting/ranking-dashboard-banner.webp"
                            class="img-responsive img-fluid" alt="cover" style="width: 100%;height: 100%;">
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a href="javascript:void(0);" routerLinkActive="active"> {{'edit-ranking.ranking' | translate}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{'edit-ranking.add' | translate}}</h6>
                    <button (click)="goToDashboard()" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>
                            {{'edit-ranking.view' | translate}}</button>
                </div>
                <div class="ui-block-content">
                    <form [formGroup]="editRankingForm" *ngIf="editRankingForm" (ngSubmit)="editRanking()"
                        class="content">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.select_category' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="category"
                                        [ngClass]="{ 'is-invalid': submitted && f.category.errors }">
                                        <option value="Annual" selected>{{'edit-ranking.annual' | translate}} </option>
                                        <option value="Event">{{'edit-ranking.event' | translate}} </option>
                                    </select>
                                    <div *ngIf="submitted && f.category.errors" class="invalid-feedback">
                                        <div *ngIf="f.category.errors.required">{{'edit-ranking.req_category' | translate}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.select_award' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="award"
                                        [ngClass]="{ 'is-invalid': submitted && f.award.errors }">
                                        <option *ngFor="let award of awards" value="{{award.id}}" selected>
                                            {{award.name  | slice:0:30}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.award.errors" class="invalid-feedback">
                                        <div *ngIf="f.award.errors.required">{{'edit-ranking.req_award' | translate}}
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.fname' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="firstName" placeholder="Jhon"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">{{'edit-ranking.req_fname' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.lname' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="lastName" placeholder="Snow"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">{{'edit-ranking.req_lname' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.select_year' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="yearOfRanking" placeholder="2018"
                                        [ngClass]="{ 'is-invalid': submitted && f.yearOfRanking.errors }">
                                        <option *ngFor="let year of years" value="{{year.name}}" selected>
                                            {{year.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.yearOfRanking.errors" class="invalid-feedback">
                                        <div *ngIf="f.yearOfRanking.errors.required">{{'edit-ranking.req_year' | translate}}</div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">{{'edit-ranking.select_rank' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="rank"
                                        [ngClass]="{ 'is-invalid': submitted && f.rank.errors }">
                                        <option *ngFor="let rank of positions" value="{{rank.position}}" selected>
                                            {{rank.position}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.rank.errors" class="invalid-feedback">
                                        <div *ngIf="f.rank.errors.required">{{'edit-ranking.req_rank' | translate}}
                                        </div>
                                    </div>

                                </div>

                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <img src="{{rank.avatar}}" height="120" alt="photo" width="120"><br>

                                <div class="form-group">
                                    <label class="control-label"> {{'edit-ranking.avatar' | translate}} <span style="color: red;"> *
                                        </span></label>
                                    <input type="file" formControlName="avatar" accept="image/*" class="form-control"
                                        (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.avatar.errors }" />
                                    <div *ngIf=" submitted && f.avatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.avatar.errors.required "> {{'edit-ranking.req_avatar' | translate}}</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    {{'edit-ranking.update' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>