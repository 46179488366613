import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-f-followers',
  templateUrl: './f-followers.component.html',
  styleUrls: ['./f-followers.component.css']
})
export class FFollowersComponent implements OnInit {

  currentUser: any;
  userData: any = {};
  followers = [];
  selectFollower: any = {};
  id: any;
  friendData: any = {};
  searchText: string;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(
      params => this.id = params.id
    );
    this.friendDetails();
    this.getMyFollowers();
  }
  friendDetails() {
    const data = {
      id: this.id,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );

  }
  getMyFollowers() {
    const data = {
      userId: this.id,
    };
    this.userService.getFollowers(data).pipe(first<any[]>()).subscribe(followers => this.followers = followers);
  }


  openFriendDeleteModal(modal: any, follower: any) {
    this.selectFollower = follower;
    modal.show();
  }

  deleteFriend(modal: any) {
    const data = {
      userId: this.currentUser.success.user.id,
      friendId: this.selectFollower.id,
      status: 'declined',
    };
    this.userService.confirmFriend(data).subscribe(
      deleted => {
        modal.hide();
        alert('deleted');
        this.getMyFollowers();
      },
      error => {
        console.log(error);
        alert('failed');
      }
    );
  }

  openChatModal(modal: any, friend: any) {
    this.selectFollower = friend;
    modal.show();
  }

  closeChatModal(modal: any) {
    modal.hide();
  }


}
