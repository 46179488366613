import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.css']
})
export class FollowersComponent implements OnInit {
  currentUser: any;
  followers = [];
  searchText: string;
  selectFollower: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getFollowers();
  }

  getFollowers() {
    const data = {
      user_id: this.currentUser.success.user.id,
    };
    this.userService.getFollowers(data).subscribe((res: any) => {
      this.followers = res;
      console.log(this.followers);
    });
  }

  openFollowerDeleteModal(modal: any, follower: any) {
    this.selectFollower = follower;
    modal.show();
  }

  deleteFollower(modal: any) {
    const data = {
      user_id: this.currentUser.success.user.id,
      follower_id: this.selectFollower.id,
    };
    this.userService.follow(data).pipe(first()).subscribe(
      succuss => {
        if (succuss) {
          modal.hide();
          this.toastrService.success('Deleted', 'Message!');
        }
      },
      error => {
        this.toastrService.success('Something Went Wrong Try Again.', 'Message!');
      }
    );

  }


}
