<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">

                    <img src="../../../assets/img/rooms/fc-banner.webp" class="img-responsive img-fluid" alt="banner">

                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="roomNameLabel !== ''" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title">
                        <h1 class="text-center custom"> {{roomNameLabel | titlecase}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section *ngIf="visible" class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="iffhsMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/iffhs-room.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>
            <div *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="executiveMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/executive-committee.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>
            <div *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="GeneralMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/General-Meeting.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>
            <div *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="superStarMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/Legend-Players.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>
            <div *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="legendsMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/Superstar-Players.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>
            <div *ngIf="currentUser.success.user.role === 'president'" class="col col-xl-4 col-lg-4 col-md-3 col-sm-6 col-12">
                <div (click)="fansMeeting()" class="ui-block">
                    <article class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="../../../assets/img/rooms/fans.webp" class="img-responsive img-fluid" width="100%" height="100%" alt="Photo">
                        </div>
                    </article>
                </div>
            </div>

        </div>
    </div>

</section>

<div *ngIf="visible" class="container">
    <div *ngIf="currentUser.success.user.role === 'president'" class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-2 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-content">
                    <form class="content">
                        <div class="row">
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label"> Create New Meeting <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" #roomName placeholder="Enter the meeting Name" class="form-control" />

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button (click)=createRoom(roomName) class="btn btn-primary btn-lg full-width">
                                    Create Meeting!
                                </button>
                            </div>

                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>
</div>