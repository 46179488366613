<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h5 label-title"> Timeline </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="news.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>
<div *ngIf="news.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="label-title">News </h6>
                </div>
                <ul class="widget w-friend-pages-added notification-list friend-requests">
                    <li *ngFor="let _new of news | slice:0:4" (click)="onClick(_new)" class="inline-items card">
                        <div (click)="onClick(_new)" class="notification-event">
                            <a class="h6 news-list">{{_new.newsTitle | titlecase}}</a>
                        </div>
                        <span class="notification-icon" data-toggle="tooltip" data-placement="top"
                            data-original-title="read more">
                            <a class="read" href="javascript:void(0);" (click)="openModal(newsModal,_new)">
                                <span class="read"> Read more</span>
                            </a>
                        </span>
                    </li>
                    <li *ngIf="news.length > 4" class="inline-items card">
                        <button (click)="moveToStructure()" class="btn btn-primary read btn-block">All News</button>
                    </li>
                </ul>

            </div>
        </div>
        <div class="col col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
            <div id="carouselExampleIndicators" class="carousel" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let _new of news" class="carousel-item active">
                        <img *ngIf="!slider.newsAttachment" (click)="openModal(newsModal,_new)"
                            class="d-block w-100 img-responsive img-fluid" src="{{_new.newsAttachment}}" alt="News"
                            onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';"
                            style="width: 100%; height: 400px;">
                        <img *ngIf="slider.newsAttachment" (click)="openModal(newsModal,slider)"
                            class="d-block w-100 img-responsive img-fluid" src="{{slider.newsAttachment}}" alt="News"
                            onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';"
                            style="width: 100%; height: 400px;">

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container mt-2">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Blogs </div>

                </div>
            </div>
            <div *ngIf="blogs.length === 0" class="ui-block-title">
                <h6 class="display-message"> No Data found! </h6>
            </div>
        </div>

    </div>
</div>
<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let blog of blogs | filter:searchText" class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div style="background-color: transparent;" class="ui-block">
                    <article (click)="openModal(newsModal,blog)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{blog.newsAttachment}}" alt="Photo" class="img-responsive img-fluid"
                                onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';"
                                style="width: 100%; height: 260px;">
                        </div>
                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{blog.newsTitle | titlecase}} </a>
                            <div class="author-date">
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{blog.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</section>
<div #newsSection id="newsSection" *ngIf="news.length > 4" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> News </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section *ngIf="news.length > 4" class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of pageOfItems |filter:searchText"
                class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div style="background-color: transparent;" class="ui-block">
                    <article (click)="openModal(newsModal,item)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{item.newsAttachment}}" class="img-responsive img-fluid" width="100%"
                                height="260" alt="Photo"
                                onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';"
                                style="width: 100%; height: 260px;">
                        </div>
                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{item.newsTitle | titlecase}} </a>
                            <div class="author-date">
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{item.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <div>
                    <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                </div>
            </ul>
        </nav>
    </div>
</section>

<div class="modal fade" bsModal #newsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="private-event"
    aria-hidden="true">
    <div class="modal-dialog window-popup event-private-public private-event" role="document">
        <div class="modal-content">
            <a (click)="closeModal(newsModal)" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-body">
                <article class="hentry post has-post-thumbnail thumb-full-width private-event">

                    <div class="private-event-head inline-items">
                        <div class="author-date">
                            <a class="h3 event-title"
                                href="javascript:void(0);">{{selectNews.newsTitle | titlecase}}</a>
                            <div class="event__date">
                                <time class="published" datetime="2017-03-24T18:18">
                                    {{selectNews.created_at | date:'mediumDate'}}
                                </time>
                            </div>
                        </div>
                    </div>
                    <div class="post-thumb">
                        <img src="{{selectNews.newsAttachment}}" alt="photo" style="height: auto; width: 100%;"
                            onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';">
                    </div>
                    <div class="row">
                        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="post__author author vcard inline-items">
                                <h3 class="head"> Description:</h3>
                            </div>
                            <p>
                                {{selectNews.newsContent | titlecase}}
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>