import { Component, OnInit } from "@angular/core";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { IffhsService } from "src/app/data-services/iffhs.service";
import { AuthenticationService } from "src/app/data-services/authentication.service";
import { first } from "rxjs/operators";

@Component({
  selector: "app-national-team-followers",
  templateUrl: "./national-team-followers.component.html",
  styleUrls: ["./national-team-followers.component.css"],
})
export class NationalTeamFollowersComponent implements OnInit {
  id: number;
  countryData: any = {};
  currentUser: any;
  searchText: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  followers: [];
  countries: [];
  states: [];
  cities: [];
  mapLocation = "";
  address: any;
  placeName: string = 'World Wide';

  url = "";
  urlSafe: SafeResourceUrl;
  isCountryDisabled = true;
  isStateDisabled = true;
  isCitiesDisabled = true;
  isCityDisabled = true;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    public sanitizer: DomSanitizer
  ) { }
  continents = [
    {
      id: "1",
      name: "CAF",
      src: "../../../assets/img/caf.webp",
      continent: "Africa",
    },
    {
      id: "3",
      name: "AFC",
      src: "../../../assets/img/afc.webp",
      continent: "Asia",
    },
    {
      id: "4",
      name: "UEFA",
      src: "../../../assets/img/uefa.webp",
      continent: "Europe",
    },
    {
      id: "5",
      name: "OFC",
      src: "../../../assets/img/ofc.webp",
      continent: "Oceania ",
    },
    {
      id: "6",
      name: "CONCACAF",
      src: "../../../assets/img/concacaf.webp",
      continent: "North America",
    },
    {
      id: "7",
      name: "CONMEBOL",
      src: "../../../assets/img/conmebol.webp",
      continent: "South America",
    },
  ];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.route.queryParams.subscribe((params) => (this.id = params.id));
    this.countryDetails();
    console.log(this.continents);
  }
  sanitizeUrl() {
    const url =
      "https://www.google.com/maps/embed/v1/place?q=makkah&key=my_key";
    console.log(url);
    this.sanitizer.bypassSecurityTrustUrl(url);
  }
  countryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService
      .getCountryDetails(data)
      .pipe(first<any>())
      .subscribe((countryData) => (this.countryData = countryData[0]));
    console.log(this.countryData);
  }
  getCountries(id: number, name: string) {
    this.placeName = name;
    const data = {
      continentId: id,
    };
    this.iffhsService.getFederation(data).subscribe((res: any) => {
      console.log(res[0].continent_id);
      if (res[0].continent_id === 1) {
        this.countries = res;
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=Africa&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        console.log(res);
      } else if (res[0].continent_id === 3) {
        this.countries = res;
        this.mapLocation = "Asia";
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=Asia&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        // console.log(this.mapLocation);
      } else if (res[0].continent_id === 4) {
        this.countries = res;
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=Europe&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        console.log(res);
      } else if (res[0].continent_id === 5) {
        this.countries = res;
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=Oceania&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        console.log(res);
      } else if (res[0].continent_id === 6) {
        this.countries = res;
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=North America&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        console.log(res);
      } else if (res[0].continent_id === 7) {
        this.countries = res;
        this.mapLocation = "South America";
        this.url =
          "https://www.google.com/maps/embed/v1/place?q=South America&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
        this.isCountryDisabled = false;

        console.log(res);
      }
    });
  }
  getState(id: number, name: string) {
    this.placeName = name;
    const data = {
      countryId: id,
      countryName: name,
    };
    this.url =
      "https://www.google.com/maps/embed/v1/place?q=" +
      name +
      "&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
    this.isStateDisabled = false;
    this.iffhsService
      .getState(data)
      .pipe(first<any>())
      .subscribe((states) => (this.states = states));
    // console.log(this.states);

  }
  getCities(id: number, name: string) {
    this.placeName = name;
    const data = {
      stateId: id,
    };
    this.url =
      "https://www.google.com/maps/embed/v1/place?q=" +
      name +
      "&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
    this.isCityDisabled = false;
    this.iffhsService
      .getCities(data)
      .pipe(first<any>())
      .subscribe((cities) => (this.cities = cities));
    // console.log(this.cities);

  }
  getCity(id: number, name: string) {
    this.placeName = name;
    // const data = {
    //   stateId: id,

    // };
    this.url =
      "https://www.google.com/maps/embed/v1/place?q=" +
      name +
      "&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY";
    this.isCityDisabled = false;
    // this.iffhsService.getCities(data).pipe(first<any>()).subscribe(cities => this.cities = cities);
    // console.log(this.cities);
  }
  doFollow() {
    console.log("tested");
  }
}
