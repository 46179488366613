import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-friends-birthday',
  templateUrl: './friends-birthday.component.html',
  styleUrls: ['./friends-birthday.component.css']
})
export class FriendsBirthdayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
