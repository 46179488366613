import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-coach',
  templateUrl: './dashboard-coach.component.html',
  styleUrls: ['./dashboard-coach.component.css']
})
export class DashboardCoachComponent implements OnInit {

  coachForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.coachForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      coachAvatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.countryId = this.countryData.id;
    this.countryName = this.countryData.name;
    this.isLoaderVisible = false;
  }
  onFileSelect(event) {
    const coachAvatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.coachForm.get('coachAvatar').setValue(coachAvatar);

  }
  get f() { return this.coachForm.controls; }
  addCoach() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.coachForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('countryId', this.coachForm.get('countryId').value);
    fd.append('firstName', this.coachForm.get('firstName').value);
    fd.append('lastName', this.coachForm.get('lastName').value);
    fd.append('createdBy', this.coachForm.get('createdBy').value);
    fd.append('coachAvatar', this.coachForm.get('coachAvatar').value);


    this.iffhsService.addCoach(fd).pipe(first()).subscribe(
      data => {
        this.toastrService.success('Coach Added!', 'Message!');
        this.isLoaderVisible = false;
        this.submitted = true;
      },
      error => {
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
        this.isLoaderVisible = false;
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.coachForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: [''],
      lastName: [''],
      coachAvatar: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }
}
