import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  currentUser: any;
  messageFlag = false;
  notifications = [];
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getNotifications();
  }

  getNotifications() {
    this.messageFlag = false;
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getNotifications(data).pipe(first<any[]>()).subscribe(notifications => this.notifications = notifications);
  }

}
