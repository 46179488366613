import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';

@Component({
  selector: 'app-award-fans',
  templateUrl: './award-fans.component.html',
  styleUrls: ['./award-fans.component.css']
})
export class AwardFansComponent implements OnInit {
  searchText: string;
  countries = [];
  isLoaderVisible = false;
  visible = true;
  title: string;
  constructor(
    private iffhsService: IffhsService,
  ) { }

  ngOnInit(): void {
  }
  getFifaCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllFifaContries().subscribe((res: any) => {
      this.countries = res.countries;
      this.isLoaderVisible = false;
    });
  }
  getNonFifaCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllNonFifaContries().subscribe((res: any) => {
      this.countries = res;
      this.isLoaderVisible = false;
      console.log(this.countries);
    });
  }
  onClick(value: string) {
    this.title = value;
    this.visible = false;
    if (value === 'fifa') {
      this.getFifaCountries();
    } else if (value === 'non-fifa') {
      this.getNonFifaCountries();
    }


  }

}
