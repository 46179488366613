<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">
                <div class="h6 title">
                    <h1 class="text-center" style="font-weight: 500;">{{'General Assembly' | uppercase}} </h1>
                    <h2 class="text-center"> <img src="../../../assets/img/fifa-text.png" width="120" height="120"
                            alt="Fifa"> </h2>
                </div>
                <ul class="cat-list-bg-style align-center sorting-menu">
                    <div (click)="getContinent(1)" class="author-thumb">

                        <a> <img src="../../../assets/img/caf.webp" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 1}">
                                <a href="javascript:void(0);" class="">CAF</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(3)"> <img src="../../../assets/img/afc.webp" style="padding: 15px;"
                                alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 3}"><a
                                    href="javascript:void(0);">AFC</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(4)"> <img src="../../../assets/img/uefa.webp" style="padding: 15px;"
                                alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 4}"><a
                                    href="javascript:void(0);" class="">UEFA</a></li>
                        </a>
                    </div>

                    <div class="author-thumb">
                        <a (click)="getContinent(5)"> <img src="../../../assets/img/ofc.webp" style="padding: 15px;"
                                alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 5}"><a
                                    href="javascript:void(0);" class="">OFC</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(7)"> <img src="../../../assets/img/conmebol.webp"
                                style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 7}"><a
                                    href="javascript:void(0);" class="">CONMEBOL</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(6)"> <img src="../../../assets/img/concacaf.webp"
                                style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 6}"><a
                                    href="javascript:void(0);" class="">CONCACAF</a></li>
                        </a>
                    </div>

                </ul>
            </div>

        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 title">General Assembly </div> -->
                    <div class="h6 title"> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="countries.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="title customStyle text-center"> No Data found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let country of countries  | filter:searchText"
            class="col col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="birthday-item inline-items">
                    <div class="author-thumb">
                        <a routerLink="/ga-clubs" [queryParams]="{'id': country.id}"> <img
                                src="../../../assets/svg-icons/1x1/{{country.code | lowercase}}.svg" alt="flag"
                                style="width: 55px; height: 55px;"> </a>
                    </div>
                    <div class="birthday-author-name">
                        <a routerLink="/ga-clubs" [queryParams]="{'id': country.id}"
                            class="h6 author-name">{{ country.name | titlecase}}</a>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>