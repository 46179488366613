<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <!-- <img src="assets/img/media-cover.webp" alt="nature"> -->
                        <img src="assets/img/covers/{{countryData.continent_id}}.webp" class="img-responsive img-fluid" alt="cover" onerror="this.onerror=null;this.src='assets/img/club-cover.webp';" style="width: 100%;height: 100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive img-fluid" style="width: 100%; height: 100%;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png" class="img-responsive img-fluid float-left" alt="flag" style="width: 100%; height: 100%;">
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLinkActive="active">{{'media-details.title' | translate}}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> {{'media-details.media' | translate}} </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" 
                            placeholder="{{'media-details.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="media.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> {{'media-details.no_data' | translate}} </h6>

        </div>
    </div>
</div>

<div class="container" [dir]="direction">
    <div class="row justify-content-md-center">
        <div *ngFor="let medium of media  | filter:searchText" class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a href="javascript:void(0);"><img src="{{medium.mediaAttachment}}" class="img-responsive img-fluid" style="width: 100%; height: 100%;" alt="photo"> </a>
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name"> {{medium.mediaTitle | titlecase}} </a>
                                <div class="country">{{medium.mediaType | titlecase}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>