import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user';
import { AuthenticationService } from './authentication.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = 'https://api.football-family.com/api/v1/';
  currentUser: any;
  userData: any = [];


  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  getAll() {
    return this.http.get<User[]>('S{config.apiUrl}/users');
  }
  getCountries() {
    return this.http.post(this.url + 'listCountries', null);
  }
  getWeather(data: any) {
    return this.http.post('http://api.openweathermap.org/data/2.5/forecast?id=524901&APPID=a5be57364d239a973665b9386d377593', data);
  }
  getProfile(data: any) {
    return this.http.post(this.url + 'showProfile', data);
  }
  getNotification(data: any) {
    return this.http.post(this.url + 'getNotification', data);
  }
  sendMessage(data: any) {
    return this.http.post(this.url + 'sendMessage', data);
  }
  receivedMessage(data: any) {
    return this.http.post(this.url + 'getMessages', data);
  }
  // getUserData(data: number) {
  //   this.http.post(this.url + 'showProfile', data).subscribe(
  //     userData => this.userData = userData[0],
  //   );
  //   console.log(this.userData);

  // }
  registerWithCommunicator(data: any) {
    return this.http.post('http://h-meetings.com/sign-up', data);
  }

  search(data: any) {
    return this.http.post(this.url + 'search', data);
  }

  register(user: User) {
    return this.http.post(this.url + 'restRegister', user);
  }

  updateProfile(user: any) {
    return this.http.post(this.url + 'editProfile', user);
  }

  validatePassword(data: any) {
    return this.http.post(this.url + 'checkPass', data);
  }

  changePassword(data: any) {
    return this.http.post(this.url + 'changePass', data);
  }

  forgotPassword(data: any) {
    return this.http.post(this.url + 'resetPassEmail', data);
  }
  resendEmail(data: any) {
    return this.http.post(this.url + 'resendActivation', data);
  }

  follow(data: any) {
    return this.http.post(this.url + 'follow', data);
  }

  request(data: any) {
    return this.http.post(this.url + 'addFriend', data);
  }


  getFollowers(data: any) {
    return this.http.post(this.url + 'followers', data);
  }
  getFollowing(data: any) {
    return this.http.post(this.url + 'following', data);
  }

  getFriends(data: any) {
    return this.http.post(this.url + 'myFriends', data);
  }
  getSuggestFriends(data: any) {
    return this.http.post(this.url + 'suggestedFriends', data);
  }

  getSuggestFollowers(data: any) {
    return this.http.post(this.url + 'suggestedFollowers', data);
  }
  getFriendsRequests(data: any) {
    return this.http.post(this.url + 'listFriends', data);
  }
  getNotifications(data: any) {
    return this.http.post(this.url + 'getNotifications', data);
  }

  confirmFriend(data: any) {
    return this.http.post(this.url + 'confirmFriend', data);
  }

  deleteFollower(data: any) {
    return this.http.post(this.url + 'deleteFollower', data);
  }

  setHobbies(data: any) {
    return this.http.post(this.url + 'hobbies', data);
  }

  delete(id: number) {
    return this.http.delete('${config.apiUrl}/users/${id}');
  }
}
