<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="content-bg-wrap landing-page ">
</div>


<!-- Header Standard Landing  -->

<div class="header--standard header--standard-landing" id="header--standard">
    <div class="container">
        <div class="header--standard-wrap">
            <a href="javascript:void(0);" class="logo">
                <div class="img-wrap mt-5">
                    <img class="img-fluid" src="../../../assets/../../../assets/img/logo.webp" alt="Football Families">
                </div>
                <div class="title-block">
                    <h6 class="logo-title" style="font-size: 1.6rem;"> Football Families </h6>
                </div>
            </a>


        </div>
    </div>
</div>

<!-- ... end Header Standard Landing  -->
<div class="header-spacer--standard"></div>

<div class="container">
    <div class="row display-flex">
        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="landing-content">
                <h2>Welcome to Football Families World</h2>
                <p style="font-size: 1.125rem;font-weight: 500;">
                    Come along with us to the biggest Football Platform in the World!
                </p>
                <a data-toggle="tab" routerLink="/register" role="tab"
                    class="btn btn-md btn-border register c-white">Register
                    Now!</a>
            </div>
        </div>

        <div class="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12">
            <!-- Login-Registration Form  -->

            <div class="registration-login-form">
                <div *ngIf="errorFlag" class="alert alert-danger" role="alert">
                    {{message}}

                    <div *ngIf="message === 'Please Activate your account'" (click)="resendEmail()" class="activate"
                        role="alert">
                        <span *ngIf="isActivatedVisible"> {{'Click here For Activation'}} </span>
                    </div>
                </div>


                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item ">
                        <a class="nav-link" data-toggle="tab" routerLink="/login" role="tab">

                            <img src="../../../../assets/svg-icons/login.webp" alt="Register" style="width: 22px;">
                        </a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link active" data-toggle="tab" routerLink="/register" role="tab">
                            <img src="../../../../assets/svg-icons/register.webp" alt="Login" style="width: 22px;">
                        </a>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content">

                    <div class="tab-pane active" id="profile" role="tabpanel" data-mh="log-tab">
                        <div class="title h6" style="font-size: 20px;">Login to your Account</div>
                        <form [formGroup]="loginForm" (ngSubmit)="login()" class="content">
                            <div class="row">
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Your Email
                                            <span style="color: red;"> * </span></label>
                                        <input type="text" formControlName="email" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">email is required</div>
                                        </div>

                                    </div>
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Your Password
                                            <span style="color: red;"> * </span></label>
                                        <input type="password" formControlName="password" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required</div>
                                        </div>
                                    </div>

                                    <div class="remember">
                                        <!-- <div class="checkbox">
                                            <label>
                                                <input name="optionsCheckboxes" type="checkbox">
                                                Remember Me
                                            </label>
                                        </div> -->

                                        <a routerLink="/login" class="forgot" data-toggle="modal"
                                            data-target="#restore-password">Forgot my Password</a>
                                    </div>

                                    <button style="font-size: 18px;" class="btn btn-primary full-width">
                                        Login
                                    </button>

                                    <div class="or customStyle"></div>

                                    <!-- <a data-toggle="tab" href="#home" role="tab" class="btn btn-lg bg-facebook full-width btn-icon-left"><i
                                            class="fab fa-facebook-f" aria-hidden="true"></i>Login with Facebook</a>

                                    <a data-toggle="tab" href="#home" role="tab" class="btn btn-lg bg-twitter full-width btn-icon-left"><i class="fab fa-twitter"
                                            aria-hidden="true"></i>Login with Twitter</a>
                                    <a data-toggle="tab" href="#home" role="tab" class="btn btn-lg bg-green full-width btn-icon-left">
                                        <i class="fab fa-weixin" aria-hidden="true"></i>Login with We chat</a>
                                    <a data-toggle="tab" href="#home" role="tab" class="btn btn-lg bg-google full-width btn-icon-left"><i
                                            class="fab fa-google-plus-g"></i>
                                        Login with Google Plus</a> -->

                                    <p class="customStyle">Don’t you have an account? <a routerLink="/register">Register
                                            Now!</a> it’s really simple and you can start enjoying all the benefits!</p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- ... end Login-Registration Form  -->
        </div>
    </div>
</div>

<!-- Window-popup Restore Password -->

<div class="modal fade" id="restore-password" tabindex="-1" role="dialog" aria-labelledby="restore-password"
    aria-hidden="true">
    <div class="modal-dialog window-popup restore-password-popup" role="document">
        <div class="modal-content">
            <a routerLink="/login" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">Restore your Password</h6>
            </div>

            <div class="modal-body">
                <form [formGroup]="forgotForm" (ngSubmit)="forgotPassword()">
                    <p>Enter your email and click the send code button. You’ll receive a code in your email. Please use
                        that code below to change the old password for a new one.
                    </p>
                    <div class="form-group label-floating">
                        <label class="control-label" style="font-size: 1rem;">Your Email <span style="color: red;"> *
                            </span></label>
                        <input class="form-control" placeholder="" formControlName="email" type="email" value=""
                            [ngClass]="{ 'is-invalid': submitted && fP.email.errors }">
                        <!-- <div *ngIf="submitted && fP.email.errors" class="invalid-feedback">
                            <div *ngIf="fP.email.errors.required">email is required</div>
                        </div> -->
                    </div>
                    <div *ngIf="errorMessage != ''" class="alert alert-danger" role="alert">
                        {{errorMessage}}
                    </div>
                    <button class="btn btn-purple btn-lg full-width">
                        Send me the Code
                    </button>
                    <!-- <div class="form-group label-floating">
                        <label class="control-label">Enter the Code</label>
                        <input class="form-control" placeholder="" type="text" value="">
                    </div>
                    <div class="form-group label-floating">
                        <label class="control-label">Your New Password</label>
                        <input class="form-control" placeholder="" type="password" value="olympus">
                    </div>
                    <button class="btn btn-primary btn-lg full-width">Change your Password!</button> -->
                </form>

            </div>
        </div>
    </div>
</div>