import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-fifa',
  templateUrl: './fifa.component.html',
  styleUrls: ['./fifa.component.css']
})
export class FifaComponent implements OnInit {

  countries = [];
  subContinents = [];
  searchText: string;
  selectedId: number;
  activePro = true;
  isLoaderVisible = false;
  afterSelect = true;

  language = '';
  direction;
  defaultLanguage;


  constructor(
    private iffhsService: IffhsService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      });
     }

  ngOnInit(): void {
    this.getAllCountries();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

  getAllCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllFifaContries().pipe(first<any[]>()).subscribe((res: any) => {
      this.countries = res.countries;
      this.isLoaderVisible = false;
    });

  }



  getContinent(id: number) {
    this.isLoaderVisible = true;
    this.activePro = false;
    const data = {
      continentId: id,
    };
    this.iffhsService.getfifaContinent(data).pipe(first<any>()).subscribe((res: any) => {
      this.countries = res.countries;
      this.selectedId = id;
      this.afterSelect = false;
      this.isLoaderVisible = false;
    });

  }

}
