import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-add-award',
  templateUrl: './add-award.component.html',
  styleUrls: ['./add-award.component.css']
})
export class AddAwardComponent implements OnInit {

  addAwardForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  banners = [];
  awards = [];
  names: String;
  domElement: string = 'view';
  selectedAward: any = {};
  items = [];
  pageOfItems: Array<any>;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getAllAwards();

    this.addAwardForm = this.formBuilder.group({
      bannerId: ['', Validators.required],
      awardName: ['', Validators.required],
      awardPhoto: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });

  }
  getAllAwards() {
    this.isLoaderVisible = true;

    this.iffhsService.getAllAwards().subscribe((res: any) => {
      this.awards = res.awards;
      this.items = this.awards;
      this.isLoaderVisible = false;

    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }


  onImageSelect(event) {
    const awardPhoto = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.addAwardForm.get('awardPhoto').setValue(awardPhoto);

  }

  onclick(add: string) {
    if (add === 'add') {
      this.domElement = add;
      this.getAllBanners();
    } else if (add === 'view') {
      this.domElement = add;
      this.getAllAwards();
    }
  }
  openDeleteModal(modal: any, award: any) {
    this.selectedAward = award;
    modal.show();
  }
  deleteAward(modal: any) {
    this.isLoaderVisible = true;
    const data = {
      awardId: this.selectedAward.id,
    };
    this.iffhsService.deleteAward(data).subscribe(
      res => {
        modal.hide();
        this.getAllAwards();
        this.toastrService.success('Award Deleted', 'Message!');
        this.isLoaderVisible = false;
      },
      error => {
        console.log(error);
      }
    );
  }
  onChange(e) {
    this.onChangeValue = e.target.value || 0;

    if (this.onChangeValue === 'Video') {
      this.visible = false;
    } else if (this.onChangeValue === 'Photo') {
      this.visible = true;
    }
  }
  getAllBanners() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllBanners().subscribe((res: any) => {
      this.banners = res.banners;
      this.isLoaderVisible = false;

    });
  }
  get f() { return this.addAwardForm.controls; }
  addAward() {
    this.submitted = true;
    if (this.addAwardForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('bannerId', this.addAwardForm.get('bannerId').value);
    fd.append('awardName', this.addAwardForm.get('awardName').value);
    fd.append('awardPhoto', this.addAwardForm.get('awardPhoto').value);
    fd.append('createdBy', this.addAwardForm.get('createdBy').value);

    this.iffhsService.addAward(fd).pipe(first()).subscribe(
      data => {
        this.getAllAwards();
        this.toastrService.success('Award Added!', 'Message!');
        this.isLoaderVisible = false;
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
        this.isLoaderVisible = false;
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.addAwardForm = this.formBuilder.group({
      bannerId: [''],
      awardName: [''],
      awardPhoto: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
