import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/auth.guard';
// commons
import { HeaderComponent } from './screens/commons/header/header.component';
import { LeftSideComponent } from './screens/commons/left-side/left-side.component';
import { RightSideComponent } from './screens/commons/right-side/right-side.component';
// Pages
import { ProfileComponent } from './screens/profile/profile.component';
import { AboutComponent } from './screens/profile/about/about.component';
import { FriendsComponent } from './screens/profile/friends/friends.component';
import { PhotosComponent } from './screens/profile/photos/photos.component';
import { VideosComponent } from './screens/profile/videos/videos.component';
import { LoginComponent } from './screens/login/login.component';
import { ProfileSettingComponent } from './screens/profile/profile-setting/profile-setting.component';
import { NewsfeedComponent } from './screens/profile/newsfeed/newsfeed.component';
import { GroupsComponent } from './screens/groups/groups.component';
import { WeatherComponent } from './screens/weather/weather.component';
import { CalenderComponent } from './screens/calender/calender.component';
import { CommunityComponent } from './screens/community/community.component';
import { FriendsBirthdayComponent } from './screens/friends-birthday/friends-birthday.component';
import { StatisticsComponent } from './screens/statistics/statistics.component';
import { FriendRequestsComponent } from './screens/profile/friend-requests/friend-requests.component';
import { EventsComponent } from './screens/events/events.component';
import { HomeComponent } from './screens/home/home.component';
import { LegendsComponent } from './screens/legends/legends.component';
import { ContactUsComponent } from './screens/contact-us/contact-us.component';
import { AssociationsComponent } from './screens/iffhs/associations/associations/associations.component';
import { GeneralAssemblyComponent } from './screens/iffhs/general-assembly/general-assembly.component';
import { MediaComponent } from './screens/iffhs/media/media.component';
import { FansComponent } from './screens/iffhs/fans/fans.component';
import { LandingComponent } from './screens/landing/landing.component';
import { ExecutiveCommitteeComponent } from './screens/iffhs/executive-committee/executive-committee.component';
import { HonorableCommitteeComponent } from './screens/iffhs/honorable-committee/honorable-committee.component';
import { PresidentConsultantComponent } from './screens/iffhs/president-consultant/president-consultant.component';
import { MembersComponent } from './screens/members/members.component';
import { ChangePasswordComponent } from './screens/profile/change-password/change-password.component';
import { HobbiesAndIntrestsComponent } from './screens/profile/hobbies-and-intrests/hobbies-and-intrests.component';
import { NotificationsComponent } from './screens/profile/notifications/notifications.component';
import { ChatMessageComponent } from './screens/profile/chat-message/chat-message.component';
import { TeamsComponent } from './screens/iffhs/teams/teams.component';
import { VotingComponent } from './screens/voting/voting.component';
import { ErrorPageComponent } from './screens/error-page/error-page.component';
import { ProfileDetailsComponent } from './screens/profile-details/profile-details.component';
import { ProfileHeaderComponent } from './screens/commons/profile-header/profile-header.component';
import { FriendProfileHeaderComponent } from './screens/commons/friend-profile-header/friend-profile-header.component';
import { FaqsComponent } from './screens/faqs/faqs.component';
import { FriendAboutComponent } from './screens/profile-details/friend-about/friend-about.component';
import { FollowersComponent } from './screens/profile/followers/followers.component';
import { PresidentAssistantsComponent } from './screens/iffhs/president-assistants/president-assistants.component';
import { IffhsNewsComponent } from './screens/commons/iffhs-news/iffhs-news.component';
import { IffhsMembersComponent } from './screens/iffhs/iffhs-members/iffhs-members.component';
import { TermsConditionsComponent } from './screens/terms-conditions/terms-conditions.component';
import { VideoCallComponent } from './screens/video-call/video-call.component';
import { CallingMembersComponent } from './screens/calling-members/calling-members.component';
import { PresidentsComponent } from './screens/commons/presidents/presidents.component';
import { TimelineComponent } from './screens/commons/timeline/timeline.component';
import { FVideosComponent } from './screens/profile-details/f-videos/f-videos.component';
import { FPhotosComponent } from './screens/profile-details/f-photos/f-photos.component';
import { FFriendsComponent } from './screens/profile-details/f-friends/f-friends.component';
import { FFollowersComponent } from './screens/profile-details/f-followers/f-followers.component';
import { SearchComponent } from './screens/search/search.component';
import { FifaComponent } from './screens/iffhs/associations/fifa/fifa.component';
import { NonFifaComponent } from './screens/iffhs/associations/non-fifa/non-fifa.component';
import { TeamProfileComponent } from './screens/iffhs/team/team-profile/team-profile.component';
import { TeamHeaderComponent } from './screens/iffhs/team/team-header/team-header.component';
import { TeamPhotosComponent } from './screens/iffhs/team/team-photos/team-photos.component';
import { TeamLeaguesComponent } from './screens/iffhs/team/team-leagues/team-leagues.component';
import { TeamMatchesComponent } from './screens/iffhs/team/team-matches/team-matches.component';
import { TeamNationalTeamsComponent } from './screens/iffhs/team/team-national-teams/team-national-teams.component';
import { TeamFollowersComponent } from './screens/iffhs/team/team-followers/team-followers.component';
import { TeamClubsComponent } from './screens/iffhs/team/team-clubs/team-clubs.component';
import { ClubProfileComponent } from './screens/iffhs/clubs/club-profile/club-profile.component';
import { ClubHeaderComponent } from './screens/iffhs/clubs/club-header/club-header.component';
import { ClubFollowersComponent } from './screens/iffhs/clubs/club-followers/club-followers.component';
import { ClubPhotosComponent } from './screens/iffhs/clubs/club-photos/club-photos.component';
import { GaClubsComponent } from './screens/iffhs/general-assembly/ga-clubs/ga-clubs.component';
import { FansDetailsComponent } from './screens/iffhs/fans/fans-details/fans-details.component';
import { MediaDetailsComponent } from './screens/iffhs/media/media-details/media-details.component';
import { ChatBoxComponent } from './screens/commons/chat-box/chat-box.component';
import { TeamDetailsComponent } from './screens/iffhs/teams/team-details/team-details.component';
import { TeamCoachesComponent } from './screens/iffhs/teams/team-coaches/team-coaches.component';
import { TeamLegendsComponent } from './screens/iffhs/teams/team-legends/team-legends.component';
import { TeamPlayersComponent } from './screens/iffhs/teams/team-players/team-players.component';
import { TeamRefereesComponent } from './screens/iffhs/teams/team-referees/team-referees.component';
import { TeamClubComponent } from './screens/iffhs/teams/team-club/team-club.component';
import { TeamTeamComponent } from './screens/iffhs/teams/team-team/team-team.component';
import { TeamDashboardComponent } from './screens/iffhs/team/team-dashboard/team-dashboard.component';
import { DashboardMediaComponent } from './screens/iffhs/team/team-dashboard/dashboard-media/dashboard-media.component';
import { DashboardMatchesComponent } from './screens/iffhs/team/team-dashboard/dashboard-matches/dashboard-matches.component';
import { ClubDashboardComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard.component';
import { ClubDashboardMediaComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard-media/club-dashboard-media.component';
import { ClubDashboardMatchesComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard-matches/club-dashboard-matches.component';
import { DashboardLeaguesComponent } from './screens/iffhs/team/team-dashboard/dashboard-leagues/dashboard-leagues.component';
import { DashboardPlayerComponent } from './screens/iffhs/team/team-dashboard/dashboard-player/dashboard-player.component';
import { ClubDashboardLeaguesComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard-leagues/club-dashboard-leagues.component';
import { ClubDashboardPlayerComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard-player/club-dashboard-player.component';
import { TeamPlayerComponent } from './screens/iffhs/team/team-player/team-player.component';
import { DashboardRefereeComponent } from './screens/iffhs/team/team-dashboard/dashboard-referee/dashboard-referee.component';
import { DashboardCoachComponent } from './screens/iffhs/team/team-dashboard/dashboard-coach/dashboard-coach.component';
import { DashboardAddMediaComponent } from './screens/iffhs/team/team-dashboard/dashboard-add-media/dashboard-add-media.component';
import { ClubDashboardCoachComponent } from './screens/iffhs/clubs/club-dashboard/club-dashboard-coach/club-dashboard-coach.component';
import { TeamCoachComponent } from './screens/iffhs/team/team-coach/team-coach.component';
import { ConfHeaderComponent } from './screens/iffhs/associations/conf-header/conf-header.component';
import { ApplyComponent } from './screens/iffhs/clubs/apply/apply.component';
import { LegendDashboardComponent } from './screens/legends/legend-dashboard/legend-dashboard.component';
import { ConfDashboardComponent } from './screens/iffhs/associations/conf-dashboard/conf-dashboard.component';
import { ConfDashboardPhotosComponent } from './screens/iffhs/associations/conf-dashboard/conf-dashboard-photos/conf-dashboard-photos.component';
import { ConfVideosComponent } from './screens/iffhs/associations/conf-videos/conf-videos.component';
import { ConfPhotosComponent } from './screens/iffhs/associations/conf-photos/conf-photos.component';
import { ConfFollowersComponent } from './screens/iffhs/associations/conf-followers/conf-followers.component';
import { LegendProfileComponent } from './screens/legends/legend-profile/legend-profile.component';
import { RegisterComponent } from './screens/register/register.component';
import { NationalTeamComponent } from './screens/iffhs/national team/national-team/national-team.component';
import { NationalTeamDashboardComponent } from './screens/iffhs/national team/national-team-dashboard/national-team-dashboard.component';
import { NationalTeamPhotoComponent } from './screens/iffhs/national team/national-team-photo/national-team-photo.component';
import { NationalTeamFollowersComponent } from './screens/iffhs/national team/national-team-followers/national-team-followers.component';
import { NationalTeamHeaderComponent } from './screens/iffhs/national team/national-team-header/national-team-header.component';
import { NationalTeamDashboardPlayerComponent } from './screens/iffhs/national team/national-team-dashboard/national-team-dashboard-player/national-team-dashboard-player.component';
import { NationalTeamDashboardMediaComponent } from './screens/iffhs/national team/national-team-dashboard/national-team-dashboard-media/national-team-dashboard-media.component';
import { NationalTeamDashboardCoachComponent } from './screens/iffhs/national team/national-team-dashboard/national-team-dashboard-coach/national-team-dashboard-coach.component';
import { ConfTimelineComponent } from './screens/iffhs/associations/conf-timeline/conf-timeline.component';
import { GalaAwardComponent } from './screens/iffhs/gala-award/gala-award.component';
import { RankingComponent } from './screens/iffhs/ranking/ranking.component';
import { HistoryComponent } from './screens/iffhs/history/history.component';
import { ConfHistoryComponent } from './screens/iffhs/associations/conf-history/conf-history.component';
import { AwardDetailsComponent } from './screens/iffhs/gala-award/award-details/award-details.component';
import { AwardFansComponent } from './screens/iffhs/gala-award/award-fans/award-fans.component';
import { AwardFansDetailsComponent } from './screens/iffhs/gala-award/award-fans-details/award-fans-details.component';
import { AwardDashboardComponent } from './screens/iffhs/gala-award/award-dashboard/award-dashboard.component';
import { AddAwardBannerComponent } from './screens/iffhs/gala-award/award-dashboard/add-award-banner/add-award-banner.component';
import { AddAwardComponent } from './screens/iffhs/gala-award/award-dashboard/add-award/add-award.component';
import { AddAwardWinnerComponent } from './screens/iffhs/gala-award/award-dashboard/add-award-winner/add-award-winner.component';
import { AddVotingQuestionComponent } from './screens/voting/voting-dashbaord/add-voting-question/add-voting-question.component';
import { AddRankingComponent } from './screens/iffhs/ranking/ranking-dashboard/add-ranking/add-ranking.component';
import { EditAwardBannerComponent } from './screens/iffhs/gala-award/award-dashboard/add-award-banner/edit-award-banner/edit-award-banner.component';
import { EditAwardComponent } from './screens/iffhs/gala-award/award-dashboard/add-award/edit-award/edit-award.component';
import { EditAwardWinnerComponent } from './screens/iffhs/gala-award/award-dashboard/add-award-winner/edit-award-winner/edit-award-winner.component';
import { EditRankingComponent } from './screens/iffhs/ranking/ranking-dashboard/edit-ranking/edit-ranking.component';
import { EditVotingQuestionComponent } from './screens/voting/voting-dashbaord/edit-voting-question/edit-voting-question.component';
import { DashboardAddClubComponent } from './screens/iffhs/team/team-dashboard/dashboard-add-club/dashboard-add-club.component';
import { LeagueClubsComponent } from './screens/iffhs/team/league-clubs/league-clubs.component';
import { FollowingComponent } from './screens/profile/following/following.component';
import { FFollowingComponent } from './screens/profile-details/f-following/f-following.component';



const routes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'about',
    component: AboutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'friends',
    component: FriendsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'photos',
    component: PhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'videos',
    component: VideosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-setting',
    component: ProfileSettingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'newsfeed',
    component: NewsfeedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'groups',
    component: GroupsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'weather',
    component: WeatherComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'calender',
    component: CalenderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'community',
    component: CommunityComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'friends-birthday',
    component: FriendsBirthdayComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'statistics',
    component: StatisticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'friend-requests',
    component: FriendRequestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'events',
    component: EventsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'legends',
    component: LegendsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'legend-dashboard',
    component: LegendDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'associations',
    component: AssociationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'general-assembly',
    component: GeneralAssemblyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'media',
    component: MediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fans',
    component: FansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'landing',
    component: LandingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'executive-committee',
    component: ExecutiveCommitteeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'honorable-committee',
    component: HonorableCommitteeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'president-consultant',
    component: PresidentConsultantComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'members',
    component: MembersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'hobbies-and-intrests',
    component: HobbiesAndIntrestsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chat-message',
    component: ChatMessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'teams',
    component: TeamsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'voting',
    component: VotingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile-details',
    component: ProfileDetailsComponent

  },
  {
    path: 'profile-header',
    component: ProfileHeaderComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'friend-profile-header',
    component: FriendProfileHeaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'faqs',
    component: FaqsComponent,

  },
  {
    path: 'friend-about',
    component: FriendAboutComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'f-photo',
    component: FPhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'f-friends',
    component: FFriendsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'iffhs-news',
    component: IffhsNewsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'president-assistants',
    component: PresidentAssistantsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent,
  },
  {
    path: 'followers',
    component: FollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'following',
    component: FollowingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'iffhs-members',
    component: IffhsMembersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'video-call',
    component: VideoCallComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'calling-members',
    component: CallingMembersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'presidents',
    component: PresidentsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'timeline',
    component: TimelineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'f-followers',
    component: FFollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'f-following',
    component: FFollowingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'f-videos',
    component: FVideosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fifa',
    component: FifaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'non-fifa',
    component: NonFifaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-profile',
    component: TeamProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-header',
    component: TeamHeaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-photos',
    component: TeamPhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-leagues',
    component: TeamLeaguesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-matches',
    component: TeamMatchesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-national-teams',
    component: TeamNationalTeamsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-followers',
    component: TeamFollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-clubs',
    component: TeamClubsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-followers',
    component: ClubFollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-header',
    component: ClubHeaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-photos',
    component: ClubPhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-profile',
    component: ClubProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ga-clubs',
    component: GaClubsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'fans-details',
    component: FansDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'media-details',
    component: MediaDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chat-box',
    component: ChatBoxComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-details',
    component: TeamDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-clubs',
    component: TeamClubsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-coaches',
    component: TeamCoachesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-legends',
    component: TeamLegendsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'legend-profile',
    component: LegendProfileComponent
  },
  {
    path: 'team-national-teams',
    component: TeamNationalTeamsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-players',
    component: TeamPlayersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-referees',
    component: TeamRefereesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'league-clubs',
    component: LeagueClubsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-club',
    component: TeamClubComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-team',
    component: TeamTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-dashboard',
    component: TeamDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-player',
    component: TeamPlayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'team-coach',
    component: TeamCoachComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-media',
    component: DashboardMediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-matches',
    component: DashboardMatchesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-leagues',
    component: DashboardLeaguesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-player',
    component: DashboardPlayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-referee',
    component: DashboardRefereeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-coach',
    component: DashboardCoachComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-add-club',
    component: DashboardAddClubComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard-add-media',
    component: DashboardAddMediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard',
    component: ClubDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard-media',
    component: ClubDashboardMediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard-matches',
    component: ClubDashboardMatchesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard-leagues',
    component: ClubDashboardLeaguesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard-player',
    component: ClubDashboardPlayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'club-dashboard-coach',
    component: ClubDashboardCoachComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'apply',
    component: ApplyComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-header',
    component: ConfHeaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-timeline',
    component: ConfTimelineComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-dashboard',
    component: ConfDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-dashboard-photos',
    component: ConfDashboardPhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-videos',
    component: ConfVideosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-photos',
    component: ConfPhotosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-followers',
    component: ConfFollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'conf-history',
    component: ConfHistoryComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team',
    component: NationalTeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-dashboard',
    component: NationalTeamDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-photo',
    component: NationalTeamPhotoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-followers',
    component: NationalTeamFollowersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-header',
    component: NationalTeamHeaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-dashboard-player',
    component: NationalTeamDashboardPlayerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-dashboard-media',
    component: NationalTeamDashboardMediaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'national-team-dashboard-coach',
    component: NationalTeamDashboardCoachComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'gala-award',
    component: GalaAwardComponent
  },
  {
    path: 'award-details',
    component: AwardDetailsComponent
  },
  {
    path: 'award-fans',
    component: AwardFansComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-fans-details',
    component: AwardFansDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'award-dashboard',
    component: AwardDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-award-banner',
    component: AddAwardBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-award',
    component: AddAwardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-award',
    component: EditAwardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-award-winner',
    component: AddAwardWinnerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-award-winner',
    component: EditAwardWinnerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-award-banner',
    component: EditAwardBannerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-voting-questions',
    component: AddVotingQuestionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-voting-question',
    component: EditVotingQuestionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ranking',
    component: RankingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-ranking',
    component: AddRankingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-ranking',
    component: EditRankingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'history',
    component: HistoryComponent,
    canActivate: [AuthGuard]
  },

  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponent = [
  // common
  TeamPhotosComponent,
  HeaderComponent,
  LeftSideComponent,
  RightSideComponent,
  ProfileHeaderComponent,
  FriendProfileHeaderComponent,
  PresidentsComponent,
  TimelineComponent,
  // pages
  FFollowingComponent,
  FollowingComponent,
  LeagueClubsComponent,
  DashboardAddClubComponent,
  EditVotingQuestionComponent,
  EditRankingComponent,
  EditAwardWinnerComponent,
  EditAwardComponent,
  EditAwardBannerComponent,
  AddRankingComponent,
  AddVotingQuestionComponent,
  AddAwardWinnerComponent,
  AddAwardComponent,
  AddAwardBannerComponent,
  AwardDashboardComponent,
  AwardFansDetailsComponent,
  AwardFansComponent,
  AwardDetailsComponent,
  ConfHistoryComponent,
  GalaAwardComponent,
  RankingComponent,
  HistoryComponent,
  ConfTimelineComponent,
  NationalTeamDashboardCoachComponent,
  NationalTeamDashboardMediaComponent,
  NationalTeamDashboardPlayerComponent,
  NationalTeamHeaderComponent,
  NationalTeamPhotoComponent,
  NationalTeamFollowersComponent,
  NationalTeamComponent,
  NationalTeamDashboardComponent,
  RegisterComponent,
  LegendProfileComponent,
  ConfFollowersComponent,
  ConfVideosComponent,
  ConfPhotosComponent,
  ConfDashboardPhotosComponent,
  ConfDashboardComponent,
  LegendDashboardComponent,
  ApplyComponent,
  ConfHeaderComponent,
  TeamCoachComponent,
  ClubDashboardCoachComponent,
  DashboardAddMediaComponent,
  DashboardCoachComponent,
  DashboardRefereeComponent,
  TeamPlayerComponent,
  ClubDashboardPlayerComponent,
  ClubDashboardLeaguesComponent,
  DashboardPlayerComponent,
  DashboardLeaguesComponent,
  ClubDashboardComponent,
  ClubDashboardMediaComponent,
  ClubDashboardMatchesComponent,
  DashboardMatchesComponent,
  DashboardMediaComponent,
  TeamDashboardComponent,
  TeamTeamComponent,
  TeamClubComponent,
  TeamClubsComponent,
  TeamCoachesComponent,
  TeamLegendsComponent,
  TeamNationalTeamsComponent,
  TeamPlayersComponent,
  TeamRefereesComponent,
  TeamDetailsComponent,
  ChatBoxComponent,
  MediaDetailsComponent,
  FansDetailsComponent,
  GaClubsComponent,
  ClubFollowersComponent,
  ClubHeaderComponent,
  ClubPhotosComponent,
  ClubProfileComponent,
  TeamClubsComponent,
  TeamFollowersComponent,
  TeamNationalTeamsComponent,
  TeamMatchesComponent,
  TeamLeaguesComponent,
  TeamHeaderComponent,
  ProfileComponent,
  AboutComponent,
  FriendsComponent,
  PhotosComponent,
  VideosComponent,
  LoginComponent,
  ProfileSettingComponent,
  NewsfeedComponent,
  GroupsComponent,
  WeatherComponent,
  CalenderComponent,
  CommunityComponent,
  FriendsBirthdayComponent,
  StatisticsComponent,
  FriendRequestsComponent,
  EventsComponent,
  HomeComponent,
  LegendsComponent,
  ContactUsComponent,
  AssociationsComponent,
  GeneralAssemblyComponent,
  MediaComponent,
  FansComponent,
  LandingComponent,
  ExecutiveCommitteeComponent,
  HonorableCommitteeComponent,
  PresidentConsultantComponent,
  MembersComponent,
  ChangePasswordComponent,
  HobbiesAndIntrestsComponent,
  NotificationsComponent,
  ChatMessageComponent,
  TeamsComponent,
  VotingComponent,
  ProfileDetailsComponent,
  ErrorPageComponent,
  FaqsComponent,
  FriendAboutComponent,
  FollowersComponent,
  PresidentAssistantsComponent,
  IffhsNewsComponent,
  IffhsMembersComponent,
  TermsConditionsComponent,
  VideoCallComponent,
  CallingMembersComponent,
  FVideosComponent,
  FPhotosComponent,
  FPhotosComponent,
  FFriendsComponent,
  FFollowersComponent,
  SearchComponent,
  FifaComponent,
  NonFifaComponent,
  TeamProfileComponent
];
