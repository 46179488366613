<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>

<div class="main-header">
    <div class="content-bg-wrap bg-birthday"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Meet the legends of Football history</h1>
                    <p>Welcome to your friends birthdays page! Here you’ll find all your friends birthdays so you’ll
                        never mis one again! In addition, the dates are automatically saved to your calendar to let you
                        know, but you also have a “Create Event”
                        button in case you wanna organize a party or event on that especific date.</p>
                </div>
            </div>
        </div>
    </div>

    <img class="img-bottom" src="assets/img/legends-banner.png" alt="Legends">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title" style="display: inline-block;font-size: 20px;margin-top: 10px;">
                        <a routerLink="/legends"> Legends </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Legend</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="legendForm" (ngSubmit)="addLegend()">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First Name *</label>
                                    <input type="text" formControlName="firstName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name *</label>
                                    <input type="text" formControlName="lastName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required *</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label">Select Country *</label>
                                    <select name="countryName" id="countryName"
                                        class="nice-select filter-input mar-top-0 form-control" tabindex="0">
                                        <option class="list option selected focus" *ngFor="let country of countries"
                                            [value]="country.id" selected>{{country.name}}
                                        </option>
                                    </select>

                                </div>
                                <div class="form-group">
                                    <label class="control-label">Select Gender *</label>
                                    <select class="form-control" formControlName="gender"
                                        [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                        <option value="male" selected>Male</option>
                                        <option value="female">Female</option>

                                    </select>
                                    <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                        <div *ngIf="f.gender.errors.required">Gender is required *</div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="control-label">Select Phase *</label>
                                    <select class="form-control" formControlName="phase"
                                        [ngClass]="{ 'is-invalid': submitted && f.phase.errors }">
                                        <option value="phase1" selected>Phase 1</option>
                                        <option value="phase2">Phase 2</option>

                                    </select>
                                    <div *ngIf="submitted && f.phase.errors" class="invalid-feedback">
                                        <div *ngIf="f.phase.errors.required">Phase is required *</div>
                                    </div>

                                </div>


                                <div class="form-group">
                                    <label class="control-label">Select Position *</label>
                                    <select class="form-control" formControlName="position"
                                        [ngClass]="{ 'is-invalid': submitted && f.position.errors }">
                                        <option value="goalKeeper" selected>Goal Keeper</option>
                                        <option value="defender">Defender</option>
                                        <option value="striker">Striker</option>
                                    </select>
                                    <div *ngIf="submitted && f.position.errors" class="invalid-feedback">
                                        <div *ngIf="f.position.errors.required">Position is required *</div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="control-label">Avatar *</label>
                                    <input type="file" formControlName="avatar" class="form-control"
                                        (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.avatar.errors }" />
                                    <div *ngIf=" submitted && f.avatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.avatar.errors.required ">Avatar is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width"> Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none ">
            <div class="ui-block ">
                <div class="your-profile ">
                    <div class="ui-block-title ui-block-title-small ">
                        <h6 class="title ">Your DASHBOARD</h6>
                    </div>

                    <div id="accordion " role="tablist " aria-multiselectable="true ">
                        <div class="card ">
                            <!-- <div class="card-header " role="tab " id="headingOne ">
                                <h6 class="mb-0 ">
                                    <a data-toggle="collapse " data-parent="#accordion " href="#collapseOne " aria-expanded="true " aria-controls="collapseOne ">
                                        Dashboard Settings
                                        <svg class="olymp-dropdown-arrow-icon ">
                                            <use xlink:href="#olymp-dropdown-arrow-icon "></use>
                                        </svg>
                                    </a>
                                </h6>
                            </div> -->

                            <!-- <div id="collapseOne " class="collapse show " role="tabpanel " aria-labelledby="headingOne ">
                                <ul class="your-profile-menu ">
                                    <li class="active ">
                                        <a routerLink="/club-dashboard" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active "> Add News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-media" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active "> Add Photos/Videos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-matches" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active ">Add Matches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-leagues" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active "> Add Leagues</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-player" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active ">Add Player</a>
                                    </li>
                                    <li>
                                        <a routerLink="/club-dashboard-coach" [queryParams]="{ 'id': clubData.id} " routerLinkActive="active ">Add Coach</a>
                                    </li>

                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>