import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { PostService } from 'src/app/data-services/post.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {
  currentUser: any;
  videos = [];
  constructor(
    private authenticationService: AuthenticationService,
    private postService: PostService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getUserVideos();
  }
  getUserVideos() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.postService.getUserVideos(data).pipe(first<any>()).subscribe(videos => this.videos = videos);
  }

}
