<!-- Header-BP -->

<header class="header" id="site-header" *ngIf="lang =='ar'" [dir]="direction">

    <div class="page-title ar" style="float: right;">
        <a routerLink="/home">
            <img src="assets/img/logo.webp" width="150" alt="logo" style="margin-top: -33px;">
        </a>

    </div>


    <div class="header-content-wrapper">
        <a (click)="forwardClicked()" style="float: right;">
            <img src="assets/svg-icons/forward-icon.png" alt="Home" style="width: 40px; margin-top: 16px;">
        </a>
        <a (click)="backClicked()" style="float: right;">
            <img src="assets/svg-icons/back-icon.png" alt="Home" style="width: 40px; margin-top: 16px;">
        </a>
        
        <form [formGroup]="searchForm" (ngSubmit)="search()"
            class="search-bar w-search notification-list friend-requests" style="float: right;">
            <div class="form-group with-button">
                <input class="form-control js-user-search" placeholder="{{'header.search' | translate}}" type="text"
                    formControlName="query" [ngClass]="{ 'is-invalid': submitted && f.query.errors }">
                <button routerLink="/search" [queryParams]="{'query': f.query.value}">
                    <svg class="olymp-magnifying-glass-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use>
                    </svg>
                </button>
            </div>
        </form>

        <div class="control-block" style="float: left; margin-left: 143px;">
            <div class="control-icon more has-items">
                <a routerLink="/home">
                    <img src="assets/svg-icons/home-icon.png" alt="Home" style="width: 30px;">
                </a>
            </div>

            <div class="control-icon more has-items">
                <img src="assets/img/translation.png" alt="Language" style="width: 30px;">

                <div class="more-dropdown more-with-triangle triangle-top-center">

                    <div class="mCustomScrollbar" data-mcs-theme="dark">
                        <ul class="notification-list friend-requests">
                            <li (click)="selectedLanguage('en','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/en.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">English</a>
                                </div>
                            </li>

                            <li (click)="selectedLanguage('de','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/de.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">German</a>
                                </div>
                            </li>
                            <li (click)="selectedLanguage('ar','rtl')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/sa.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">Arabic</a>
                                </div>
                            </li>
                            <li (click)="selectedLanguage('fr','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/fr.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">French</a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>


            <div class="control-icon more has-items">
                <img src="assets/svg-icons/notification.png" alt="Home" style="width: 30px;">

            </div>

            <div class="author-page author vcard inline-items more">
                <div class="author-thumb">
                    <a routerLink="/profile">
                        <img *ngIf="!currentUser.success.user.avatar" alt="author" src="assets/img/author-page.jpg"
                            class="avatar" width="36" height="36"
                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                            style="width: 36px; height: 36px;">
                        <img *ngIf="currentUser.success.user.avatar" alt="author" [src]="userData[0].avatar"
                            class="avatar" width="36" height="36"
                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                            style="width: 36px; height: 36px;">
                        <!-- <span class="icon-status online"> </span> -->
                    </a>
                    <div class="more-dropdown more-with-triangle">
                        <div class="mCustomScrollbar" data-mcs-theme="dark">
                            <div class="ui-block-title ui-block-title-small">
                                <h6 class="title">Your Account</h6>
                            </div>

                            <ul class="account-settings">
                                <li>
                                    <a routerLink="/profile">
                                        <img *ngIf="!currentUser.success.user.avatar" alt="author"
                                            src="assets/img/author-page.jpg" class="avatar" width="20" height="20"
                                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                            style="width: 20px; height: 20px;">
                                        <img *ngIf="currentUser.success.user.avatar" alt="author"
                                            [src]="userData[0].avatar" class="avatar" width="20" height="20"
                                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                            style="width: 20px; height: 20px;">

                                        <span style="margin: 0px 10px;">My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/profile-setting">

                                        <svg class="olymp-menu-icon">
                                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-menu-icon">
                                            </use>
                                        </svg>

                                        <span>Profile Settings</span>
                                    </a>
                                </li>

                                <li>
                                    <a (click)="logout()">
                                        <svg class="olymp-logout-icon">
                                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-logout-icon">
                                            </use>
                                        </svg>

                                        <span>Log Out</span>

                                    </a>
                                </li>
                            </ul>
                            <div class="ui-block-title ui-block-title-small">
                                <h6 class="title">About Football Families</h6>
                            </div>

                            <ul>
                                <li>
                                    <a routerLink="/terms-conditions">
                                        <span>Terms and Conditions</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/faqs">
                                        <span>FAQs</span>
                                    </a>
                                </li>

                                <li>
                                    <a routerLink="/contact-us">
                                        <span>Contact</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <a routerLink="/profile" class="author-name fn">
                    <div class="author-title">
                        {{ currentUser.success.user.fullName | titlecase }} <svg class="olymp-dropdown-arrow-icon">
                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                            </use>
                        </svg>
                    </div>
                    <!-- <span class="author-subtitle">SPACE COWBOY</span> -->
                </a>
            </div>

        </div>
    </div>

</header>

<header class="header" id="site-header" *ngIf="lang !='ar'">

    <div class="page-title">
        <a routerLink="/home">
            <img src="assets/img/logo.webp" width="150" alt="logo" style="margin-top: -33px;">
        </a>

    </div>


    <div class="header-content-wrapper">
        <a (click)="backClicked()">
            <img src="assets/svg-icons/back-icon.png" alt="Home" style="width: 40px; margin-top: 16px;">
        </a>
        <a (click)="forwardClicked()">
            <img src="assets/svg-icons/forward-icon.png" alt="Home" style="width: 40px; margin-top: 16px;">
        </a>
        <form [formGroup]="searchForm" (ngSubmit)="search()"
            class="search-bar w-search notification-list friend-requests">
            <div class="form-group with-button">
                <input class="form-control js-user-search" placeholder="{{'header.search' | translate}}" type="text"
                    formControlName="query" [ngClass]="{ 'is-invalid': submitted && f.query.errors }">
                <button routerLink="/search" [queryParams]="{'query': f.query.value}">
                    <svg class="olymp-magnifying-glass-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use>
                    </svg>
                </button>
            </div>
        </form>

        <div class="control-block">
            <div class="control-icon more has-items">
                <a routerLink="/home">
                    <img src="assets/svg-icons/home-icon.png" alt="Home" style="width: 30px;">
                </a>
            </div>

            <div class="control-icon more has-items">
                <img src="assets/img/translation.png" alt="Language" style="width: 30px;">

                <div class="more-dropdown more-with-triangle triangle-top-center">

                    <div class="mCustomScrollbar" data-mcs-theme="dark">
                        <ul class="notification-list friend-requests">
                            <li (click)="selectedLanguage('en','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/en.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">English</a>
                                </div>
                            </li>

                            <li (click)="selectedLanguage('de','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/de.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">German</a>
                                </div>
                            </li>
                            <li (click)="selectedLanguage('ar','rtl')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/sa.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">Arabic</a>
                                </div>
                            </li>
                            <li (click)="selectedLanguage('fr','ltr')">
                                <div class="author-thumb">
                                    <img src="assets/svg-icons/1x1/fr.svg" alt="language" width="100%">
                                </div>
                                <div class="notification-event">
                                    <a href="javascript:void(0);" class="h6 notification-friend">French</a>
                                </div>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>


            <div class="control-icon more has-items">
                <img src="assets/svg-icons/notification.png" alt="Home" style="width: 30px;">

            </div>

            <div class="author-page author vcard inline-items more">
                <div class="author-thumb">
                    <a routerLink="/profile">
                        <img *ngIf="!currentUser.success.user.avatar" alt="author" src="assets/img/author-page.jpg"
                            class="avatar" width="36" height="36"
                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                            style="width: 36px; height: 36px;">
                        <img *ngIf="currentUser.success.user.avatar" alt="author" [src]="userData[0].avatar"
                            class="avatar" width="36" height="36"
                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                            style="width: 36px; height: 36px;">
                        <!-- <span class="icon-status online"> </span> -->
                    </a>
                    <div class="more-dropdown more-with-triangle">
                        <div class="mCustomScrollbar" data-mcs-theme="dark">
                            <div class="ui-block-title ui-block-title-small">
                                <h6 class="title">Your Account</h6>
                            </div>

                            <ul class="account-settings">
                                <li>
                                    <a routerLink="/profile">
                                        <img *ngIf="!currentUser.success.user.avatar" alt="author"
                                            src="assets/img/author-page.jpg" class="avatar" width="20" height="20"
                                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                            style="width: 20px; height: 20px;">
                                        <img *ngIf="currentUser.success.user.avatar" alt="author"
                                            [src]="userData[0].avatar" class="avatar" width="20" height="20"
                                            onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                            style="width: 20px; height: 20px;">

                                        <span style="margin: 0px 10px;">My Profile</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/profile-setting">

                                        <svg class="olymp-menu-icon">
                                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-menu-icon">
                                            </use>
                                        </svg>

                                        <span>Profile Settings</span>
                                    </a>
                                </li>

                                <li>
                                    <a (click)="logout()">
                                        <svg class="olymp-logout-icon">
                                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-logout-icon">
                                            </use>
                                        </svg>

                                        <span>Log Out</span>

                                    </a>
                                </li>
                            </ul>
                            <div class="ui-block-title ui-block-title-small">
                                <h6 class="title">About Football Families</h6>
                            </div>

                            <ul>
                                <li>
                                    <a routerLink="/terms-conditions">
                                        <span>Terms and Conditions</span>
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/faqs">
                                        <span>FAQs</span>
                                    </a>
                                </li>

                                <li>
                                    <a routerLink="/contact-us">
                                        <span>Contact</span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <a routerLink="/profile" class="author-name fn">
                    <div class="author-title">
                        {{ currentUser.success.user.fullName | titlecase }} <svg class="olymp-dropdown-arrow-icon">
                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                            </use>
                        </svg>
                    </div>
                    <!-- <span class="author-subtitle">SPACE COWBOY</span> -->
                </a>
            </div>

        </div>
    </div>

</header>

<!-- ... end Header-BP -->


<!-- Responsive Header-BP -->

<header class="header header-responsive" id="site-header-responsive" [dir]="direction">

    <div class="header-content-wrapper">
        <ul class="nav nav-tabs mobile-app-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link" routerLink="/home">
                    <div class="control-icon has-items">
                        <img src="assets/svg-icons/home-icon.png" alt="Home" style="width: 30px;">
                    </div>
                </a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#notification" role="tab">
                    <div class="control-icon has-items">
                        <img src="assets/img/translation.png" alt="Language" style="width: 30px;">
                    </div>
                    <div class="more-dropdown more-with-triangle triangle-top-center">

                        <div class="mCustomScrollbar" data-mcs-theme="dark">
                            <ul class="notification-list friend-requests">
                                <li (click)="selectedLanguage('en','ltr')">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/en.svg" alt="language" width="100%">
                                    </div>
                                    <div class="notification-event">
                                        <a href="javascript:void(0);" class="h6 notification-friend">English</a>
                                    </div>
                                </li>

                                <li (click)="selectedLanguage('de','ltr')">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/de.svg" alt="language" width="100%">
                                    </div>
                                    <div class="notification-event">
                                        <a href="javascript:void(0);" class="h6 notification-friend">German</a>
                                    </div>
                                </li>
                                <li (click)="selectedLanguage('ar','rtl')">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/sa.svg" alt="language" width="100%">
                                    </div>
                                    <div class="notification-event">
                                        <a href="javascript:void(0);" class="h6 notification-friend">Arabic</a>
                                    </div>
                                </li>
                                <li (click)="selectedLanguage('fr','ltr')">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/fr.svg" alt="language" width="100%">
                                    </div>
                                    <div class="notification-event">
                                        <a href="javascript:void(0);" class="h6 notification-friend">French</a>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </a>
            </li>


            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#notification" role="tab">
                    <div class="control-icon has-items">
                        <img src="assets/svg-icons/notification.png" alt="Home" style="width: 30px;">
                        <!-- <div class="label-avatar bg-primary">0</div> -->
                    </div>
                </a>
            </li>

            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#search" role="tab">
                    <svg class="olymp-magnifying-glass-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use>
                    </svg>
                    <svg class="olymp-close-icon">
                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                    </svg>
                </a>
            </li>
        </ul>
    </div>

    <!-- Tab panes -->
    <div class="tab-content tab-content-responsive">

        <div class="tab-pane " id="request" role="tabpanel">

            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <div class="ui-block-title ui-block-title-small">
                    <h6 class="title">FRIEND REQUESTS</h6>
                    <a href="#">Find Friends</a>
                    <a href="#">Settings</a>
                </div>
                <ul class="notification-list friend-requests">
                    <li>
                        <div class="author-thumb">
                            <img src="assets/img/avatar55-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Tamara Romanoff</a>
                            <span class="chat-message-item">Mutual Friend: Sarah Hetfield</span>
                        </div>
                        <span class="notification-icon">
                            <a href="#" class="accept-request">
                                <span class="icon-add without-text">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                            <a href="#" class="accept-request request-del">
                                <span class="icon-minus">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                        </span>

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="author-thumb">
                            <img src="assets/img/avatar56-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Tony Stevens</a>
                            <span class="chat-message-item">4 Friends in Common</span>
                        </div>
                        <span class="notification-icon">
                            <a href="#" class="accept-request">
                                <span class="icon-add without-text">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                            <a href="#" class="accept-request request-del">
                                <span class="icon-minus">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                        </span>

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>
                    <li class="accepted">
                        <div class="author-thumb">
                            <img src="assets/img/avatar57-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            You and <a href="#" class="h6 notification-friend">Mary Jane Stark</a> just became friends.
                            Write on <a href="#" class="notification-link">her wall</a>.
                        </div>
                        <span class="notification-icon">
                            <svg class="olymp-happy-face-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon"></use>
                            </svg>
                        </span>

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                            <svg class="olymp-little-delete">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="author-thumb">
                            <img src="assets/img/avatar58-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Stagg Clothing</a>
                            <span class="chat-message-item">9 Friends in Common</span>
                        </div>
                        <span class="notification-icon">
                            <a href="#" class="accept-request">
                                <span class="icon-add without-text">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                            <a href="#" class="accept-request request-del">
                                <span class="icon-minus">
                                    <svg class="olymp-happy-face-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                        </use>
                                    </svg>
                                </span>
                            </a>

                        </span>

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>
                </ul>
                <a href="#" class="view-all bg-blue">Check all your Events</a>
            </div>

        </div>

        <div class="tab-pane " id="chat" role="tabpanel">

            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <div class="ui-block-title ui-block-title-small">
                    <h6 class="title">Chat / Messages</h6>
                    <a href="#">Mark all as read</a>
                    <a href="#">Settings</a>
                </div>

                <ul class="notification-list chat-message">
                    <li class="message-unread">
                        <div class="author-thumb">
                            <img src="assets/img/avatar59-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Diana Jameson</a>
                            <span class="chat-message-item">Hi James! It’s Diana, I just wanted to let you know that we
                                have to reschedule...</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">4 hours ago</time></span>
                        </div>
                        <span class="notification-icon">
                            <svg class="olymp-chat---messages-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon"></use>
                            </svg>
                        </span>
                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img src="assets/img/avatar60-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Jake Parker</a>
                            <span class="chat-message-item">Great, I’ll see you tomorrow!.</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">4 hours ago</time></span>
                        </div>
                        <span class="notification-icon">
                            <svg class="olymp-chat---messages-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon"></use>
                            </svg>
                        </span>

                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>
                    <li>
                        <div class="author-thumb">
                            <img src="assets/img/avatar61-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">Elaine Dreyfuss</a>
                            <span class="chat-message-item">We’ll have to check that at the office and see if the client
                                is on board with...</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 9:56pm</time></span>
                        </div>
                        <span class="notification-icon">
                            <svg class="olymp-chat---messages-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon"></use>
                            </svg>
                        </span>
                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>

                    <li class="chat-group">
                        <div class="author-thumb">
                            <img src="assets/img/avatar11-sm.jpg" alt="author">
                            <img src="assets/img/avatar12-sm.jpg" alt="author">
                            <img src="assets/img/avatar13-sm.jpg" alt="author">
                            <img src="assets/img/avatar10-sm.jpg" alt="author">
                        </div>
                        <div class="notification-event">
                            <a href="#" class="h6 notification-friend">You, Faye, Ed &amp; Jet +3</a>
                            <span class="last-message-author">Ed:</span>
                            <span class="chat-message-item">Yeah! Seems fine by me!</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">March 16th at 10:23am</time></span>
                        </div>
                        <span class="notification-icon">
                            <svg class="olymp-chat---messages-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon"></use>
                            </svg>
                        </span>
                        <div class="more">
                            <svg class="olymp-three-dots-icon">
                                <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                            </svg>
                        </div>
                    </li>
                </ul>

                <a href="#" class="view-all bg-purple">View All Messages</a>
            </div>

        </div>

        <div class="tab-pane " id="notification" role="tabpanel">

            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <ul class="notification-list friend-requests">
                    <li (click)="selectedLanguage('en','ltr')">
                        <div class="author-thumb">
                            <img src="assets/svg-icons/1x1/en.svg" alt="language" width="100%">
                        </div>
                        <div class="notification-event">
                            <a href="javascript:void(0);" class="h6 notification-friend">English</a>
                        </div>
                    </li>

                    <li (click)="selectedLanguage('de','ltr')">
                        <div class="author-thumb">
                            <img src="assets/svg-icons/1x1/de.svg" alt="language" width="100%">
                        </div>
                        <div class="notification-event">
                            <a href="javascript:void(0);" class="h6 notification-friend">German</a>
                        </div>
                    </li>
                    <li (click)="selectedLanguage('ar','rtl')">
                        <div class="author-thumb">
                            <img src="assets/svg-icons/1x1/sa.svg" alt="language" width="100%">
                        </div>
                        <div class="notification-event">
                            <a href="javascript:void(0);" class="h6 notification-friend">Arabic</a>
                        </div>
                    </li>
                    <li (click)="selectedLanguage('fr','ltr')">
                        <div class="author-thumb">
                            <img src="assets/svg-icons/1x1/fr.svg" alt="language" width="100%">
                        </div>
                        <div class="notification-event">
                            <a href="javascript:void(0);" class="h6 notification-friend">French</a>
                        </div>
                    </li>

                </ul>
            </div>

        </div>

        <div class="tab-pane " id="search" role="tabpanel">


            <form class="search-bar w-search notification-list friend-requests">
                <div class="form-group with-button">
                    <input class="form-control js-user-search" placeholder="Search here people or pages..." type="text">
                </div>
            </form>


        </div>

    </div>

</header>

<!-- ... end Responsive Header-BP -->
<div class="header-spacer"></div>