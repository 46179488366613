import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-league-clubs',
  templateUrl: './league-clubs.component.html',
  styleUrls: ['./league-clubs.component.css']
})
export class LeagueClubsComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  clubs = [];
  searchText: string;
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getClubsByLeague();
  }
  // countryDetails() {
  //   const data = {
  //     countryId: this.id,
  //   };
  //   this.iffhsService.getCountryDetails(data).pipe(first<any>())
  //     .subscribe(countryData => this.countryData = countryData[0]);
  // }
  getClubsByLeague() {
    this.isLoaderVisible = true;
    const data = {
      leagueId: this.id,
    };
    this.iffhsService.getClubsByLeague(data).subscribe((res: any) => {
      this.clubs = res.clubs;
      this.isLoaderVisible = false;
    });
  }

}
