import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { FormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';
declare var $: any;

@Component({
  selector: 'app-left-side',
  templateUrl: './left-side.component.html',
  styleUrls: ['./left-side.component.css']
})
export class LeftSideComponent implements OnInit, AfterViewInit {

  sidebar: any;
  currentUser: any;
  userData: any = {};
  notification: any;
  isIffhsLoggedIn: boolean;
  language='';
  
  constructor(
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.isIffhsLoggedIn = JSON.parse(localStorage.getItem('isIffhsLoggedIn'));
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res;
    });
    // this.getProfile();
    // this.getNotification();
  }
  // getNotification() {
  //   const data = {
  //     userId: this.currentUser.success.user.id,
  //   };
  //   this.userService.getNotification(data).pipe(first()).subscribe(notification => this.notification = notification);
  // }
  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(userData => this.userData = userData[0]);
  // }
  backClicked() {
    this.location.back();
  }
  forwardClicked() {
    this.location.forward();
  }
  // abc(event){
  //   console.log(event);
  //   var mobileWidthApp = $('body').outerWidth();
  //   if (mobileWidthApp <= 560) {
  //     $(event.target).closest('body').find('.popup-chat-responsive').removeClass('open-chat');
  //   }

  //   $(event.target).toggleClass('active');
  //   $(event.target).closest(this.sidebar).toggleClass('open');
  //   return false;
    
  //   }
  toggleSideBar(event) {
    var mobileWidthApp = $('body').outerWidth();
    if (mobileWidthApp <= 560) {
      $(event.target).closest('body').find('.popup-chat-responsive').removeClass('open-chat');
    }

    $(event.target).toggleClass('active');
    $(event.target).closest(this.sidebar).toggleClass('open');
    return false;
  }

  ngAfterViewInit(): void {
    this.sidebar = $('.fixed-sidebar');
    // this.clock();
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);

  }

  // clock() {
  //   var canvas: any = document.getElementById("canvas");
  //   let ctx = canvas.getContext("2d");
  //   let radius = canvas.height / 2;
  //   ctx.translate(radius, radius);
  //   radius = radius * 0.90
  //   setInterval(drawClock, 1000);
  //   function drawClock() {
  //     drawFace(ctx, radius);
  //     drawNumbers(ctx, radius);
  //     drawTime(ctx, radius);
  //   }
  //   function drawFace(ctx, radius) {
  //     let grad;
  //     ctx.beginPath();
  //     ctx.arc(0, 0, radius, 0, 2 * Math.PI);
  //     ctx.fillStyle = 'white';
  //     ctx.fill();
  //     grad = ctx.createRadialGradient(0, 0, radius * 0.95, 0, 0, radius * 1.05);
  //     grad.addColorStop(0, '#333');
  //     grad.addColorStop(0.5, 'white');
  //     grad.addColorStop(1, '#333');
  //     ctx.strokeStyle = grad;
  //     ctx.lineWidth = radius * 0.1;
  //     ctx.stroke();
  //     ctx.beginPath();
  //     ctx.arc(0, 0, radius * 0.1, 0, 2 * Math.PI);
  //     ctx.fillStyle = '#333';
  //     ctx.fill();
  //   }
  //   function drawNumbers(ctx, radius) {
  //     let ang;
  //     let num;
  //     ctx.font = radius * 0.15 + "px arial";
  //     ctx.textBaseline = "middle";
  //     ctx.textAlign = "center";
  //     for (num = 1; num < 13; num++) {
  //       ang = num * Math.PI / 6;
  //       ctx.rotate(ang);
  //       ctx.translate(0, -radius * 0.85);
  //       ctx.rotate(-ang);
  //       ctx.fillText(num.toString(), 0, 0);
  //       ctx.rotate(ang);
  //       ctx.translate(0, radius * 0.85);
  //       ctx.rotate(-ang);
  //     }
  //   }
  //   function drawTime(ctx, radius) {
  //     let now = new Date();
  //     let hour = now.getHours();
  //     let minute = now.getMinutes();
  //     let second = now.getSeconds();
  //     //hour
  //     hour = hour % 12;
  //     hour = (hour * Math.PI / 6) +
  //       (minute * Math.PI / (6 * 60)) +
  //       (second * Math.PI / (360 * 60));
  //     drawHand(ctx, hour, radius * 0.5, radius * 0.07);
  //     //minute
  //     minute = (minute * Math.PI / 30) + (second * Math.PI / (30 * 60));
  //     drawHand(ctx, minute, radius * 0.8, radius * 0.07);
  //     // second
  //     second = (second * Math.PI / 30);
  //     drawHand(ctx, second, radius * 0.9, radius * 0.02);
  //   }
  //   function drawHand(ctx, pos, length, width) {
  //     ctx.beginPath();
  //     ctx.lineWidth = width;
  //     ctx.lineCap = 'round';
  //     ctx.moveTo(0, 0);
  //     ctx.rotate(pos);
  //     ctx.lineTo(0, -length);
  //     ctx.stroke();
  //     ctx.rotate(-pos);
  //   }
  // }


}
