<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>

<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title text-center"> IFFHS Awards </div>
                    <template *ngIf="isIffhsLoggedIn">
                        <div
                            *ngIf="currentUser.success.user.id == 1 || currentUser.success.user.id == 5 || currentUser.success.user.id == 18 || currentUser.success.user.user_type_id == '1'">
                            <a routerLink="/add-award-banner">
                                <img src="assets/img/dashboard.webp" class="img-responsive img-fluid center"
                                    alt="Dashboard" style="width: 3rem;"></a>
                        </div>
                    </template>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div *ngIf="banners.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>

    </div>
</div>

<div *ngFor="let banner of banners" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div (click)="toggle(banner.id)" class="ui-block responsive-flex">
                <img src="https://api.football-family.com/img/banners/{{banner.id}}.png" alt="banner">
            </div>
        </div>
    </div>
    <section *ngIf="show && show === banner.id" class="blog-post-wrap">
        <div class="container">
            <div class="row">
                <div *ngFor="let award of awards  | filter:searchText"
                    class="col col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12">
                    <div class="ui-block">
                        <article class="hentry blog-post">
                            <div class="post-thumb">
                                <a routerLink="/award-details" [queryParams]="{'id': award.id}"><img
                                        src="https://api.football-family.com/img/awards/{{award.image}}" width="400"
                                        height="400" alt="{{award.name}}"> </a>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>