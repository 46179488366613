<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <div class="h6 title">
                    <h1 class="text-center" style="font-weight: 500;"> {{'teams.title' | translate}}</h1>
                </div>
                <ul *ngIf="afterSelect" class="cat-list-bg-style align-center sorting-menu">
                    <div (click)="goTo(7)" class="author-thumb">
                        <a> <img src="assets/img/icons/coach-icon.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 7}">
                                <a href="javascript:void(0);" class="">{{'teams.coaches' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div (click)="goTo(2)" class="author-thumb">
                        <a> <img src="assets/img/icons/players.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 2}">
                                <a href="javascript:void(0);" class="">{{'teams.players' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div (click)="goTo(3)" class="author-thumb">
                        <a> <img src="assets/img/icons/referees.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 3}">
                                <a href="javascript:void(0);" class="">{{'teams.referees' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div (click)="goTo(4)" class="author-thumb">
                        <a> <img src="assets/img/icons/national-teams.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 4}">
                                <a href="javascript:void(0);" class="">{{'teams.national_teams' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div (click)="goTo(5)" class="author-thumb">
                        <a> <img src="assets/img/clubs.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 5}">
                                <a href="javascript:void(0);" class="">{{'teams.clubs' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div (click)="goTo(6)" class="author-thumb">
                        <a> <img src="assets/img/icons/legends.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 6}">
                                <a href="javascript:void(0);" class="">{{'teams.legends' | translate}}</a>
                            </li>
                        </a>
                    </div>


                </ul>

                <ul class="cat-list-bg-style align-center sorting-menu">
                    <div *ngIf="selectedTop === 7" (click)="goTo(7)" class="author-thumb">
                        <a> <img src="assets/img/icons/coach-icon.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 7}">
                                <a href="javascript:void(0);" class="">{{'teams.coaches' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div *ngIf="selectedTop === 2" (click)="goTo(2)" class="author-thumb">
                        <a> <img src="assets/img/icons/players.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 2}">
                                <a href="javascript:void(0);" class="">{{'teams.players' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div *ngIf="selectedTop === 3" (click)="goTo(3)" class="author-thumb">
                        <a> <img src="assets/img/icons/referees.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 3}">
                                <a href="javascript:void(0);" class="">{{'teams.referees' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div *ngIf="selectedTop === 4" (click)="goTo(4)" class="author-thumb">
                        <a> <img src="assets/img/icons/national-teams.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 4}">
                                <a href="javascript:void(0);" class="">{{'teams.national_teams' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div *ngIf="selectedTop === 5" (click)="goTo(5)" class="author-thumb">
                        <a> <img src="assets/img/clubs.webp" style="
                            margin: 0px auto;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedTop === 5}">
                                <a href="javascript:void(0);" class="">{{'teams.clubs' | translate}}</a>
                            </li>
                        </a>
                    </div>

                </ul>
                <ul *ngIf="visible && !legends" class="cat-list-bg-style align-center sorting-menu">
                    <div (click)="getContinent(1)" class="author-thumb">

                        <a> <img src="assets/img/caf.webp" style="padding: 15px;" width="120" height="120"
                                alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 1}">
                                <a href="javascript:void(0);" class="">{{'teams.caf' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(3)"> <img src="assets/img/afc.webp" width="120" height="120"
                                style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 3}"><a
                                    href="javascript:void(0);">AFC</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(4)"> <img src="assets/img/uefa.webp" width="120" height="120"
                                style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 4}"><a
                                    href="javascript:void(0);" class="">{{'teams.uefa' | translate}}</a></li>
                        </a>
                    </div>

                    <div class="author-thumb">
                        <a (click)="getContinent(5)"> <img src="assets/img/ofc.webp" width="120" height="120"
                                style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 5}"><a
                                    href="javascript:void(0);" class="">{{'teams.ofc' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(7)"> <img src="assets/img/conmebol.webp" width="120"
                                height="120" style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 7}"><a
                                    href="javascript:void(0);" class="">{{'teams.conmebol' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(6)"> <img src="assets/img/concacaf.webp" width="120"
                                height="120" style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 6}"><a
                                    href="javascript:void(0);" class="">{{'teams.concacaf' | translate}}</a></li>
                        </a>
                    </div>

                </ul>
                <div *ngIf="legends" class="blog-post-wrap">
                    <div class="container">
                        <h2 class="text-center legendText"> {{'teams.legend_player_phase1' | translate}}</h2>
                        <div class="row justify-content-md-center">

                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="ui-block" data-mh="friend-groups-item">
                                    <div class="friend-item friend-groups">
                                        <div class="friend-item-content">
                                            <a routerLink="/legends" [queryParams]="{'phase': 1, 'gender': 'male'}">
                                                <img src="assets/img/men-legends.png"
                                                    class="img-responsive img-fluid" height="120" width="120"
                                                    alt="photo">
                                            </a>

                                        </div>
                                    </div>
                                </div>
                                <div class="text-center author-content club">
                                    <a routerLink="/legends" [queryParams]="{'phase': 1, 'gender': 'male'}"
                                        class="h2 author-name football-club">
                                        {{'teams.men' | translate}}</a>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="ui-block" data-mh="friend-groups-item">
                                    <div class="friend-item friend-groups">
                                        <div class="friend-item-content">
                                            <a routerLink="/legends" [queryParams]="{'phase': 1, 'gender': 'female'}"
                                                routerLink="/legends">
                                                <img src="assets/img/women-legends.png"
                                                    class="img-responsive img-fluid" height="120" width="120"
                                                    alt="photo">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center author-content club">
                                    <a routerLink="/legends" [queryParams]="{'phase': 1, 'gender': 'female'}"
                                        class=" h2 author-name football-club ">
                                        {{'teams.women' | translate}}</a>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="container ">
                        <h2 class="text-center legendText"> {{'teams.legend_player_phase2' | translate}}</h2>
                        <div class="row justify-content-md-center ">

                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
                                <div class="ui-block " data-mh="friend-groups-item ">
                                    <div class="friend-item friend-groups ">
                                        <div class="friend-item-content ">
                                            <a routerLink="/legends" [queryParams]="{'phase': 2, 'gender': 'male'}">
                                                <img src="assets/img/men-legends.png"
                                                    class="img-responsive img-fluid" height="120" width="120"
                                                    alt="photo ">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class=" text-center author-content club ">
                                    <a routerLink="/legends" [queryParams]="{'phase': 2, 'gender': 'male'}"
                                        class="h2 author-name football-club ">
                                        {{'teams.men' | translate}}</a>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
                                <div class="ui-block " data-mh="friend-groups-item ">
                                    <div class="friend-item friend-groups ">
                                        <div class="friend-item-content ">
                                            <a routerLink="/legends" [queryParams]="{'phase':2,'gender':'female'}">
                                                <img src="assets/img/women-legends.png"
                                                    class="img-responsive img-fluid" height="120" width="120"
                                                    alt="photo ">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class=" text-center author-content club">
                                    <a routerLink="/legends" [queryParams]="{'phase':2,'gender':'female'}"
                                        class="h2 author-name football-club ">
                                        {{'teams.women' | translate}}</a>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>



<div *ngIf="visible " class="container ">
    <div class="row ">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
            <div class="ui-block responsive-flex ">
                <div class="ui-block-title ">
                    <!-- <div class="h6 title "> Football families </div> -->
                    <div class="h6 title "> </div>
                    <form class="w-search ">
                        <div class="form-group with-button ">
                            <input class="form-control " type="text " name="search " [(ngModel)]="searchText "
                                autocomplete="off " [ngModelOptions]="{standalone: true} " placeholder="{{'teams.search' | translate}} ">
                            <button>
                                <svg class="olymp-magnifying-glass-icon ">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon ">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="visible " class="container ">
    <div class="row ">
        <div *ngFor="let country of countries | filter:searchText "
            class="col col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 ">
            <div class="ui-block ">
                <div class="birthday-item inline-items ">
                    <div class="author-thumb ">
                        <a routerLink="/team-{{url}}" [queryParams]="{ 'id': country.id}"> <img
                                src="assets/svg-icons/1x1/{{country.code | lowercase}}.svg " alt="flag "
                                style="width: 55px; height: 55px; "> </a>
                    </div>
                    <div class="birthday-author-name ">
                        <a routerLink="/team-{{url}}" [queryParams]="{ 'id': country.id}"
                            class="h6 author-name">{{ country.name | titlecase}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>