<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/covers/{{countryData.continent_id}}.webp" alt="cover" class="img-responsive img-fluid" onerror="this.onerror=null;this.src='assets/img/club-cover.webp';" style="width: 100%;height: 100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive img-fluid" style="width: 100%; height: auto;" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: 100%;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name | titlecase}}</p>
                                        <div class="h3 author-name"> {{category}} {{ teamClass | titlecase}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png" class="img-responsive float-left img-fluid" alt="flag" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: auto;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/national-team" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active">Timeline</a>
                                    </li>
                                    <li>
                                        <a routerLink="/national-team-photo" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active">Media</a>
                                    </li>

                                    <li>
                                        <a routerLink="/national-team-followers" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active">Followers</a>
                                    </li>
                                    <li *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'iffhs-executive-member' ">
                                        <a routerLink="/national-team-dashboard" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active">
                                            <img src="assets/img/dashboard.webp" alt="cover" width="40" height="40">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>