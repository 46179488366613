<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-friend-profile-header></app-friend-profile-header>
<div class="container">
    <div class="row">
        <div *ngIf="following" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> Totals Following ( {{following.length}} ) </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <!-- <a  href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
							<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
						</svg></a> -->
                </div>
            </div>
        </div>
        <div *ngIf="following.length === 0" class="ui-block-title">
            <h6 class="title" style="text-align: center;">No Followers Found!
            </h6>

        </div>

    </div>
</div>


<!-- Friends -->

<div class="container">
    <div class="row">
        <div *ngFor="let follower of following | filter:searchText"
            class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="friend-item">
                    <div class="friend-header-thumb">
                        <img alt="friend" src="assets/img/small-cover.webp" width="318" height="122">
                        <!-- <img *ngIf="follower.banner" alt="friend" [src]="follower.banner" width="318" height="122"> -->
                    </div>

                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': follower.id}">
                                    <img *ngIf="!follower.avatar" alt="author" src="assets/img/user.jpg" width="100"
                                        height="100"
                                        onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;">

                                    <img *ngIf="follower.avatar" alt="author" [src]="follower.avatar" width="100"
                                        height="100"
                                        onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': follower.id}"
                                    class="h5 author-name">{{ follower.fullName | titlecase}}</a>
                                <div *ngIf="follower.countryName" class="country">{{ follower.countryName | titlecase}}
                                </div>
                                <div *ngIf="!follower.countryName" class="country">{{ 'Country Name'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>