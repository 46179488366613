<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Hobbies and Interests</h1>
                    <p style="font-size: 1.5rem;"> Welcome to your Hobbies and Interests page! You will be able to list all your favorite hobbies, speak about your interests, and share it with your special friends!
                    </p>

                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->



<!-- Your Account Personal Information -->

<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Hobbies and Interests</h6>
                </div>
                <div class="ui-block-content">


                    <!-- Form Hobbies and Interests -->

                    <form [formGroup]="hobbiesForm" (ngSubmit)="setHobbies()">
                        <div class="row">

                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <strong class="title"> Hobbies:</strong>
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="A hobby is an activity, interest, or pastime that is undertaken for pleasure or relaxation, done during one's own time." formControlName="hobby1"></textarea>
                                </div>
                                <strong class="title"> Favorite Games:</strong>
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="Swimming, Surfing, Scuba Diving, Anime, Photography, Tattoos, Street Art." formControlName="hobby2"></textarea>
                                </div>

                                <!-- <button class="btn btn-secondary btn-lg full-width">Cancel</button> -->
                            </div>

                            <div class="col col-lg-6 col-md-6 col-sm-12 col-12">
                                <strong class="title"> Favorite TV Shows:</strong>
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="The Crime of the Century, Egiptian Mythology 101, The Scarred Wizard, Lord of the Wings, Amongst Gods, The Oracle, A Tale of Air and Water." formControlName="hobby3"></textarea>
                                </div>
                                <strong class="title"> Other Interests:</strong>
                                <div class="form-group">
                                    <textarea class="form-control" placeholder="Martin T. Georgeston, Jhonathan R. Token, Ivana Rowle, Alexandria Platt, Marcus Roth. " formControlName="hobby4"></textarea>
                                </div>
                            </div>

                            <div *ngIf="errorMessage != ''" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div *ngIf="errorMessage != ''" class="alert alert-danger" role="alert">
                                    {{errorMessage}}
                                </div>
                            </div>


                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button [disabled]="loading" class="btn btn-primary btn-lg full-width">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Save 
                                </button>
                            </div>

                        </div>
                    </form>

                    <!-- ... end Form Hobbies and Interests -->

                </div>
            </div>
        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">
                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
                        <h6 class="title">Your PROFILE</h6>
                    </div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Profile Settings
                                        <svg class="olymp-dropdown-arrow-icon">
                                            <use
                                                xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                            </use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">Personal Information</a>
                                    </li>
                                    <!-- <li>
										<a href="javascript:void(0);">Account Settings</a>
									</li> -->
                                    <li>
                                        <a routerLink="/change-password">Change Password</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">Hobbies and Interests</a>
                                    </li>
                                    <!-- <li>
										<a href="javascript:void(0);">Education and Employement</a>
									</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>


                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">Notifications</a>
                        <!-- <a routerLink="/notifications" class="items-round-little bg-primary">8</a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" class="h6 title">Chat / Messages</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">Friend Requests</a>
                        <!-- <a routerLink="/friend-requets" class="items-round-little bg-blue">4</a> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>