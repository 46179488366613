import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-f-friends',
  templateUrl: './f-friends.component.html',
  styleUrls: ['./f-friends.component.css']
})
export class FFriendsComponent implements OnInit {
  currentUser: any;
  userData: any = {};
  friends = [];
  selectFriend: any = {};
  id: any;
  friendData: any = {};
  searchText: string;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(
      params => this.id = params.id
    );
    // console.log(this.id);
    this.friendDetails();
    this.getMyFriends();
  }
  friendDetails() {
    const data = {
      id: this.id,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );

  }
  getMyFriends() {
    const data = {
      userId: this.id,
    };
    this.userService.getFriends(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
  }


  // openFriendDeleteModal(modal: any, friend: any) {
  //   this.selectFriend = friend;
  //   modal.show();
  // }

  // deleteFriend(modal: any) {
  //   const data = {
  //     userId: this.currentUser.success.user.id,
  //     friendId: this.selectFriend.id,
  //     status: 'declined',
  //   };
  //   this.userService.confrimFriend(data).subscribe(
  //     deleted => {
  //       modal.hide();
  //       alert('deleted');
  //       this.getMyFriends();
  //     },
  //     error => {
  //       console.log(error);
  //       alert('failed');
  //     }
  //   );
  // }

  openchatModal(modal: any, friend: any) {
    this.selectFriend = friend;
    modal.show();
  }

  closeChatModal(modal: any) {
    modal.hide();
  }

}
