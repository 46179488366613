import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-f-following',
  templateUrl: './f-following.component.html',
  styleUrls: ['./f-following.component.css']
})
export class FFollowingComponent implements OnInit {

  currentUser: any;
  following = [];
  searchText: string;
  selectFollowing: any;
  id: number;
  role: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.role = params['role'];
    });
    this.getFollowing();
  }

  getFollowing() {
    const data = {
      user_id: this.id,
    };
    this.userService.getFollowing(data).pipe(first<any[]>()).subscribe(following => this.following = following);
  }
}
