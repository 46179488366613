import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-national-team-dashboard',
  templateUrl: './national-team-dashboard.component.html',
  styleUrls: ['./national-team-dashboard.component.css']
})
export class NationalTeamDashboardComponent implements OnInit {

  newsForm: FormGroup;
  id: number;
  teamClass: string;
  category: string;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage = '';
  fileName: string;
  imageError = '';
  onChangeValue = '';

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.teamClass = params['name'];
      this.category = params['category'];
    });
    this.countryDetails();
    this.newsForm = this.formBuilder.group({
      countryId: [this.id],
      teamClass: [this.teamClass],
      newsType: ['', Validators.required],
      newsTitle: ['', Validators.required],
      newsContent: ['', Validators.required],
      newsAttachment: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
    this.countryId = this.countryData.id;
    this.countryName = this.countryData.name;
    this.isLoaderVisible = false;
  }
  onChange(e) {
    this.onChangeValue = e.target.value || 0;
    console.log(this.onChangeValue);
  }
  onFileSelect(event) {
    const newsAttachment = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.fileName = event.target.files[0].name;
    this.newsForm.get('newsAttachment').setValue(newsAttachment);

  }


  get f() { return this.newsForm.controls; }
  postNews() {
    this.submitted = true;

    if (this.newsForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('countryId', this.newsForm.get('countryId').value);
    fd.append('newsType', this.newsForm.get('newsType').value);
    fd.append('newsTitle', this.newsForm.get('newsTitle').value);
    fd.append('newsContent', this.newsForm.get('newsContent').value);
    fd.append('teamClass', this.newsForm.get('teamClass').value);
    fd.append('teamClass', this.category);
    fd.append('newsAttachment', this.newsForm.get('newsAttachment').value);
    fd.append('createdBy', this.newsForm.get('createdBy').value);

    this.iffhsService.addNews(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success(this.onChangeValue + ' Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.newsForm = this.formBuilder.group({
      countryId: [this.id],
      newsType: [''],
      newsTitle: [''],
      newsContent: [''],
      newsAttachment: [''],
      createdBy: [this.currentUser.success.user.id],
    });

  }


}
