<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Notification Page</h1>
                    <p style="font-size: 1.5rem;"> Welcome to your Notification page! You will be able to monitor all your previous and newest notifications.</p>

                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->



<!-- Your Account Personal Information -->

<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Notifications</h6>
                    <!-- <a routerLink="/notifications" class="more"><svg class="olymp-three-dots-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg></a> -->
                </div>
                <div *ngIf="notifications.length === 0" class="ui-block-title">
                    <h6 class="title" style="text-align: center;"> No Notification found.! </h6>

                </div>


                <!-- Notification List -->

                <!-- <ul class="notification-list">
					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
						</div>
						<div class="notification-event">
							<a routerLink="/notifications" class="h6 notification-friend">Mathilda Brinker</a> commented on your new <a routerLink="/notifications" class="notification-link">profile status</a>.
							<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">4 hours ago</time></span>
						</div>
						<span class="notification-icon">
												<svg class="olymp-comments-post-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon"></use></svg>
											</span>
				
						<div class="more">
							<svg class="olymp-three-dots-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg>
							<svg class="olymp-little-delete"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use></svg>
						</div>
					</li>
				
					<li class="un-read">
						<div class="author-thumb">
							<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
						</div>
						<div class="notification-event">
							You and <a routerLink="/notifications" class="h6 notification-friend">Nicholas Grissom</a> just became friends. Write on <a routerLink="/notifications" class="notification-link">his wall</a>.
							<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">9 hours ago</time></span>
						</div>
						<span class="notification-icon">
												<svg class="olymp-happy-face-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon"></use></svg>
											</span>
				
						<div class="more">
							<svg class="olymp-three-dots-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg>
							<svg class="olymp-little-delete"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use></svg>
						</div>
					</li>
				</ul> -->

                <!-- ... end Notification List -->

            </div>


            <!-- Pagination -->

            <!-- <nav aria-label="Page navigation">
				<ul class="pagination justify-content-center">
					<li class="page-item disabled">
						<a class="page-link" routerLink="/notifications" tabindex="-1">Previous</a>
					</li>
					<li class="page-item"><a class="page-link" routerLink="/notifications">1<div class="ripple-container"><div class="ripple ripple-on ripple-out" style="left: -10.3833px; top: -16.8333px; background-color: rgb(255, 255, 255); transform: scale(16.7857);"></div></div></a></li>
					<li class="page-item"><a class="page-link" routerLink="/notifications">2</a></li>
					<li class="page-item"><a class="page-link" routerLink="/notifications">3</a></li>
					<li class="page-item"><a class="page-link" routerLink="/notifications">12</a></li>
					<li class="page-item">
						<a class="page-link" routerLink="/notifications">Next</a>
					</li>
				</ul>
			</nav> -->

            <!-- ... end Pagination -->

        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">

                <!-- Your Profile  -->

                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
						<h6 class="title">Your PROFILE</h6>
					</div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										Profile Settings
										<svg class="olymp-dropdown-arrow-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon"></use></svg>
									</a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">Personal Information</a>
                                    </li>

                                    <li>
                                        <a routerLink="/change-password">Change Password</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">Hobbies and Interests</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">Notifications</a>
                        <!-- <a routerLink="/notifications" class="items-round-little bg-primary">8</a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" class="h6 title">Chat / Messages</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">Friend Requests</a>
                        <!-- <a routerLink="/friend-requests" class="items-round-little bg-blue">4</a> -->
                    </div>
                    <!-- <div class="ui-block-title ui-block-title-small">
						<h6 class="title">FAVOURITE PAGE</h6>
					</div>
					<div class="ui-block-title">
						<a routerLink="/notifications" class="h6 title">Create Fav Page</a>
					</div>
					<div class="ui-block-title">
						<a routerLink="/notifications" class="h6 title">Fav Page Settings</a>
					</div> -->
                </div>

                <!-- ... end Your Profile  -->

            </div>
        </div>
    </div>
</div>

<!-- ... end Your Account Personal Information -->