import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-conf-photos',
  templateUrl: './conf-photos.component.html',
  styleUrls: ['./conf-photos.component.css']
})
export class ConfPhotosComponent implements OnInit {

  id: any;
  // countryData: any = {};
  confData: any = {};
  currentUser: any;
  searchText: any;
  status: boolean;
  photos = [];
  videos = [];
  selectFriend: any = {};
  isLoaderVisible = false;
  selectedPhoto: any = {};
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getPhotos();
    this.getVideos();
  }

  getPhotos() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getMedia(data).subscribe((res: any) => {
      this.photos = res.photos;
      this.isLoaderVisible = false;
    });
  }
  getVideos() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getMedia(data).subscribe((res: any) => {
      this.videos = res.videos;
      this.isLoaderVisible = false;
    });
  }

  openModal(modal: any, photo: any) {
    this.selectedPhoto = photo;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }

}
