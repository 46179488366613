import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-conf-dashboard-photos',
  templateUrl: './conf-dashboard-photos.component.html',
  styleUrls: ['./conf-dashboard-photos.component.css']
})
export class ConfDashboardPhotosComponent implements OnInit {

  mediaForm: FormGroup;
  id: number;
  countryData: any = {};
  confederationData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  imageError: string = '';
  visible = true;
  onChangeValue = '';
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) { }

  ngOnInit(): void {

    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getConfederation();
    this.mediaForm = this.formBuilder.group({
      confederationId: [this.id],
      mediaType: ['', Validators.required],
      mediaTitle: ['', Validators.required],
      mediaContent: ['', Validators.required],
      mediaAttachment: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],

    });
  }
  onChange(e) {
    this.onChangeValue = e.target.value || 0;
    console.log(this.onChangeValue);
    if (this.onChangeValue === 'Video') {
      this.visible = false;
    } else if (this.onChangeValue === 'Photo') {
      this.visible = true;
    }
  }
  getConfederation() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getConfederation(data).subscribe((res: any) => {
      this.confederationData = res.continent[0];
      this.isLoaderVisible = false;
    });
  }
  onImageSelect(event) {
    const mediaAttachment = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.mediaForm.get('mediaAttachment').setValue(mediaAttachment);

  }
  onVideoSelect(event) {
    const mediaAttachment = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/video\/*/) === null) {
      this.toastrService.error('Only videos are supported', 'Message!');
      return;
    }
    this.mediaForm.get('mediaAttachment').setValue(mediaAttachment);

  }
  get f() { return this.mediaForm.controls; }
  postMedia() {
    this.submitted = true;
    if (this.mediaForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('confederationId', this.mediaForm.get('confederationId').value);
    fd.append('mediaType', this.mediaForm.get('mediaType').value);
    fd.append('mediaTitle', this.mediaForm.get('mediaTitle').value);
    fd.append('mediaContent', this.mediaForm.get('mediaContent').value);
    fd.append('createdBy', this.mediaForm.get('createdBy').value);
    fd.append('mediaAttachment', this.mediaForm.get('mediaAttachment').value);

    this.iffhsService.addMedia(fd).pipe(first()).subscribe(
      data => {
        this.toastrService.success(this.onChangeValue + ' Added', 'Message!');
        this.isLoaderVisible = false;
        this.submitted = true;
      },
      error => {
        this.toastrService.error('Something went wrong please Try Again!', 'Message!');
        this.isLoaderVisible = false;
      }
    );
    this.mediaForm = this.formBuilder.group({
      confederationId: [this.id],
      mediaType: [null],
      mediaTitle: [null],
      mediaContent: [null],
      mediaAttachment: [null],
      createdBy: [this.currentUser.success.user.id],

    });

  }

}
