import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-club-dashboard-player',
  templateUrl: './club-dashboard-player.component.html',
  styleUrls: ['./club-dashboard-player.component.css']
})
export class ClubDashboardPlayerComponent implements OnInit {

  playerForm: FormGroup;
  id: any;
  countryData: any = {};
  clubData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';
  imageError: string = '';
  maxDate: Date;
  minDate: Date;
  positions = [
    {
      id: '1',
      name: 'Goal Keeper',
    },
    {
      id: '2',
      name: 'Defender',
    },
    {
      id: '3',
      name: 'Center back',
    },
    {
      id: '4',
      name: 'Right back',
    },
    {
      id: '5',
      name: 'Left back',
    },
    {
      id: '6',
      name: 'Center Defensive midfielder',
    },
    {
      id: '7',
      name: 'Center midfielder',
    },
    {
      id: '8',
      name: 'Central attacking midfielder',
    },
    {
      id: '9',
      name: 'Right midfielder',
    },
    {
      id: '10',
      name: 'Left midfielder',
    },
    {
      id: '11',
      name: 'Right wing',
    },
    {
      id: '12',
      name: 'Left wing',
    },
    {
      id: '13',
      name: 'Center forward',
    },
    {
      id: '14',
      name: 'Left forward',
    },
    {
      id: '15',
      name: 'Right forward',
    },

  ];
  teamClasses = [
    {
      id: '1',
      name: 'First Team',
    },
    {
      id: '2',
      name: 'Olympic Team',
    },
    {
      id: '3',
      name: 'Youth Team',
    },
    {
      id: '4',
      name: 'Under 20',
    },
    {
      id: '5',
      name: 'Under 19',
    },
    {
      id: '6',
      name: 'Under 16',
    },
    {
      id: '7',
      name: 'Special Needs Team',
    },
    {
      id: '8',
      name: 'Beach Soccer',
    },
    {
      id: '9',
      name: 'Futsal Soccer',
    },
    {
      id: '10',
      name: 'World Cup',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    public datePipe: DatePipe,
    private toastrService: ToastrService,

  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 52850);
    this.maxDate.setDate(this.maxDate.getDate() - 2920);
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getClubProfile();
    this.playerForm = this.formBuilder.group({
      clubId: [this.id],
      fullName: ['', Validators.required],
      lastName: ['', Validators.required],
      playerPosition: ['', Validators.required],
      dob: ['', Validators.required],
      teamClass: ['', Validators.required],
      avatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  getClubProfile() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getClubProfile(data).subscribe((res: any) => {
      this.clubData = res.club;
      this.countryData = res.country;
      this.isLoaderVisible = false;
    });
  }
  onFileSelect(event) {
    const playerAvatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.playerForm.get('playerAvatar').setValue(playerAvatar);

  }
  get f() { return this.playerForm.controls; }
  addPlayer() {
    this.submitted = true;
    if (this.playerForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const dob = this.datePipe.transform(this.f.dob.value, 'yyyy-MM-dd');
    const fd = new FormData();
    fd.append('clubId', this.playerForm.get('clubId').value);
    fd.append('fullName', this.playerForm.get('fullName').value);
    fd.append('lastName', this.playerForm.get('lastName').value);
    fd.append('playerPosition', this.playerForm.get('playerPosition').value);
    fd.append('dob', dob);
    fd.append('teamClass', this.playerForm.get('teamClass').value);
    fd.append('avatar', this.playerForm.get('avatar').value);
    fd.append('createdBy', this.playerForm.get('createdBy').value);
    this.iffhsService.addPlayer(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Player Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.playerForm = this.formBuilder.group({
      clubId: [this.id],
      firstName: [null],
      lastName: [null],
      playerPosition: [null],
      playerAvatar: [null],
      createdBy: [this.currentUser.success.user.id],
    });

  }

}
