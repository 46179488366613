import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-legend-profile',
  templateUrl: './legend-profile.component.html',
  styleUrls: ['./legend-profile.component.css']
})
export class LegendProfileComponent implements OnInit {
  legend: any = {};
  isLoaderVisible = false;
  id: number;
  safeSrc: SafeResourceUrl;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer

  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getLegendProfile();
    const url = 'https://iffhs.de/images/legend-videos/' + this.id + '/legend.mp4';
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    console.log(url);
    console.log(this.safeSrc);
  }
  getLegendProfile() {
    this.isLoaderVisible = true;
    const data = {
      legendId: this.id,
    };
    this.iffhsService.getLegendProfile(data).subscribe((res: any) => {
      this.legend = res.legends;
      this.isLoaderVisible = false;
    });

  }
}
