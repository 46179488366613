import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { AlertService } from 'src/app/data-services/alert.service';
import { first, map } from 'rxjs/operators';
import { MustMatch } from '../../_Helpers/must-match.validator';
import countries from '../../../assets/json/countries.json';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  submitted = false;
  loading = false;
  loginLoading = false;
  floading = false;
  returnUrl: string;
  visible = false;
  isSubmitted = true;
  errorFlag = false;
  message: any = {};
  token: any;
  errorMessage = '';
  maxDate: Date;
  minDate: Date;
  countries: any = [];
  phoneNumberFlag = false;
  dateOfBirthFlag = false;
  country: string = '';
  show = true;
  keyword = 'name';
  countryList: { name: string, id: string }[] = countries;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService

  ) {
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 32850);
    this.maxDate.setDate(this.maxDate.getDate() - 2920);
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      fullName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      lastName: ['', Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      countryName: ['', Validators.required],
      mobileNumber: ['', Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(9)])],
      IsoCode: ['', Validators.required],
      IsdCode: ['', Validators.required],
      email: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50)])],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });



  }

  selectEvent(item) {
    this.country = item.name;
    this.registerForm.get('countryName').setValue(item.name);

  }

  // onChangeSearch(val: string) {
  //   console.log(val);
  // }

  // onFocused(e) {
  //   console.log(e);
  // }

  get f() {
    return this.registerForm.controls;
  }
  signUp() {
    this.submitted = true;
    this.message = '';
    this.visible = false;
    this.isSubmitted = false;
    this.errorFlag = false;
    this.loading = true;
    if (this.registerForm.invalid) {
      this.loading = false;
      return;
    }
    this.isSubmitted = true;
    this.userService.register(this.registerForm.value).pipe(first<any>()).subscribe(
      succuss => {
        if (succuss !== 'The email has already been taken.' && succuss !== 'The password must be at least 6 characters.') {
          this.errorFlag = true;
          this.message = 'Successfully Registered. Please verify your email!';
          this.loading = false;
          this.show = false;
        } else {
          this.errorFlag = true;
          this.message = succuss;
          this.loading = false;
        }
      },
      error => {
        console.log(error);
        this.message = 'Unable to register Please try again!';
        this.visible = false;
        this.loading = false;
        this.errorFlag = true;
      }
    );
    this.errorFlag = false;
    this.registerForm = this.formBuilder.group({
      fullName: [''],
      lastName: [''],
      countryName: [''],
      mobileNumber: [''],
      IsoCode: [''],
      IsdCode: [''],
      email: [''],
      dob: [''],
      gender: [''],
      password: [''],
      confirmPassword: ['']
    });

  }


  getCountries() {
    this.userService.getCountries().pipe().subscribe((res: any) => {
      this.countries = res;
      localStorage.setItem('countries', JSON.stringify(this.countries));
    });
  }
  getNumber(event) {
    // console.log(event);
  }
  telInputObject(obj) { 
    obj.setCountry('sa');
  }

  onCountryChange(event) {
    this.registerForm.controls['IsdCode'].setValue(event.dialCode);
    this.registerForm.controls['IsoCode'].setValue(event.iso2);
 
  }



}
