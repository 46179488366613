import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AlertService } from 'src/app/data-services/alert.service';
import { UserService } from 'src/app/data-services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { PostService } from 'src/app/data-services/post.service';
import { ToastrService } from 'ngx-toastr';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  language = '';
  direction;
  defaultLanguage;

  postForm: FormGroup;
  commentForm: FormGroup;
  updatePostForm: FormGroup;
  submitted: boolean;
  errorFlag: boolean;
  loading: boolean;
  cloading: boolean;
  id: number;
  userId: number;
  profile: any = {};
  currentUser: any;
  posts: any;
  likes: number;
  comments: any;
  userData: any = [];
  public show: number;
  public buttonName: any = 'Show';
  selectPost: any = {};
  selectComment: any = {};
  visible: boolean;
  selectPostId: number;
  friends = [];
  selectedFile: File = null;
  imageName = '';
  videoName = '';
  today = new Date();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private postService: PostService,
    private alertService: AlertService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
    private messageService: MessageService
    ) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.userData = JSON.parse(localStorage.getItem('userData'));
  }


  ngOnInit() {

    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
    
    this.loadAllPost();

    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: [''],
      image: [''],
      video: [''],
      type: [''],
    });

    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required],
      post_id: '',
    });

    this.initForm(null);


  }
  trackByFn(index, item) {
    return index; // or item.id
  }

  initForm(data: null) {
    this.updatePostForm = this.formBuilder.group({
      content: ['', Validators.compose([Validators.minLength(1), Validators.maxLength(500)])],
      type: [''],
      image: [''],
      video: [''],
    });
  }

  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(
  //     userData => this.userData = userData[0]
  //   );

  // }
  /////////////////////////////////// image try code

  onImageSelect(event) {
    const image = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only Images are Supported', 'Message!');
      return;
    }
    this.imageName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('image').setValue(image);

  }
  onVideoSelect(event) {
    const video = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/video\/*/) === null) {
      this.toastrService.error('Only Videos are Supported', 'Message!');
      return;
    }
    this.videoName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('video').setValue(video);

  }

  /////////////////////////////////////// Posts Code
  get f() { return this.postForm.controls; }
  setPost() {
    this.submitted = true;
    this.errorFlag = false;
    this.loading = true;
    // if (this.postForm.invalid) {
    //   this.loading = false;
    //   return;
    // }
    const fd = new FormData();
    fd.append('user_id', this.postForm.get('user_id').value);
    fd.append('content', this.postForm.get('content').value);
    fd.append('type', this.postForm.get('type').value);
    fd.append('image', this.postForm.get('image').value);
    fd.append('video', this.postForm.get('video').value);

    this.postService.post(fd).pipe(first()).subscribe(
      data => {
        this.toastrService.success('Your Post is Added', 'Message!');
        this.visible = false;
        this.loading = false;
        this.errorFlag = false;
        this.submitted = true;
        this.loadAllPost();
      },
      error => {
        console.log(error);
        this.toastrService.error('Something went wrong Try again', 'Message!');
        this.visible = false;
        this.loading = false;
        this.errorFlag = false;

      }
    );
    // this.postForm.reset();
    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: [''],
      image: [''],
      video: [''],
      type: [''],
    });
    this.imageName = '';

  }

  loadAllPost() {
    const data = {
      user_id: this.currentUser.success.user.id,
    };
    this.postService.getAll(data).pipe(first()).subscribe(
      posts => {
        this.posts = posts;
        for (const post of this.posts) {
          // post.type = post.type.split('/')[0];
        }
      }

    );
  }

  deletePost(modal: any) {
    const data = {
      postId: this.selectPost.id,
    };
    this.postService.deletePost(data).subscribe(
      posts => {
        modal.hide();
        this.loadAllPost();
        this.toastrService.success('Post Deleted', 'Message!');
      },
      error => {
        console.log(error);
      }
    );
  }
  openDeleteModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  openEditModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  updatePost(modal: any) {
    const data = {
      id: this.selectPost.id,
      content: this.selectPost.content,
      image: this.selectPost.image,
    };
    this.postService.updatePost(data).subscribe(
      updatedPost => {
        this.toastrService.success('Post Updated', 'Message!');
        modal.hide();
      },
      error => {
        console.log(error);
      }
    );
  }

  closeEditModal(modal: any) {
    modal.hide();
    this.loadAllPost();
  }

  whoLikedMyPost(id: number) {
    const data = {
      post_id: id,
    };
    this.postService.whoLikedMyPost(data).pipe(first()).subscribe(likes => this.likes = likes[0]);

  }

  ///////////////////////////////////////// Comments Codes

  doComment(pId: number) {
    this.cloading = true;

    if (this.commentForm.valid === true) {
      this.commentForm.value.post_id = pId;
      this.commentForm.value.commenter_id = this.currentUser.success.user.id;
      this.postService.comment(this.commentForm.value).pipe(first()).subscribe(
        succuss => {
          this.toastrService.success('Comment Added', 'Message!');
          this.cloading = false;
          this.loadAllPost();
          this.showComments(pId);
        },
        error => {
          console.log(error);
          this.cloading = false;
        }
      );
    } else {
      this.toastrService.error('Invalid Form', 'Message!');
      this.cloading = false;
    }
    this.commentForm.reset();

  }

  openCommentDeleteModal(modal: any, comment: any, postId: number) {
    this.selectComment = comment;
    this.selectPostId = postId;
    modal.show();
  }

  deleteComment(modal: any, postId: number) {
    const data = {
      commentId: this.selectComment.id,
      post_id: this.selectPostId,
    };
    this.postService.deleteComment(data).subscribe(
      comments => {
        modal.hide();
        this.toastrService.success('Your Comment Deleted', 'Message!');
        this.showComments(postId);
      },
      error => {
        console.log(error);
      }
    );
  }

  toggle(Id: number) {
    if (this.show !== Id) {
      this.show = Id;
    } else {
      this.show = 0;
    }

    this.showComments(Id);

    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }

  }

  showComments(Id: number) {
    const data = {
      postId: Id,
    };
    this.postService.getAllComments(data).pipe(first()).subscribe(comments => this.comments = comments);

  }

  ///////////////////////////////////////// Likes Codes

  likeUnlike(id: number) {
    const data = {
      post_id: id,
      user_id: this.currentUser.success.user.id,

    };
    this.postService.likeUnlike(data).pipe(first()).subscribe(
      succuss => {
        this.loading = false;
        this.errorFlag = false;
        this.loadAllPost();
      },
      error => {
        console.log(error);
        this.loading = false;
        this.errorFlag = false;
      }
    );
  }

  /////////////////////////////////////// Friends

  getMyFriends() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getFriends(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
  }








}
