import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-referee',
  templateUrl: './dashboard-referee.component.html',
  styleUrls: ['./dashboard-referee.component.css']
})
export class DashboardRefereeComponent implements OnInit {

  refereeForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  countryId: number;
  countryName: string;
  errorMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,


  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.refereeForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      refereeAvatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.countryId = this.countryData.id;
    this.countryName = this.countryData.name;
    this.isLoaderVisible = false;
  }
  onFileSelect(event) {
    const refereeAvatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.refereeForm.get('refereeAvatar').setValue(refereeAvatar);

  }
  get f() { return this.refereeForm.controls; }
  addReferee() {
    this.submitted = true;
    if (this.refereeForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('countryId', this.refereeForm.get('countryId').value);
    fd.append('firstName', this.refereeForm.get('firstName').value);
    fd.append('lastName', this.refereeForm.get('lastName').value);
    fd.append('createdBy', this.refereeForm.get('createdBy').value);
    fd.append('refereeAvatar', this.refereeForm.get('refereeAvatar').value);


    this.iffhsService.addReferee(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Referee Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.resetForm();

  }
  resetForm() {
    this.refereeForm = this.formBuilder.group({
      countryId: [this.id],
      firstName: [''],
      lastName: [''],
      refereeAvatar: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
