<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="assets/img/loader.webp">
</div>




<div class="container">
    <div class="row">

        <!-- Main Content -->

        <div class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <div class="h6 title">Showing Results for: “<span class="c-primary">{{searchData.query}}</span>”
                    </div>
                </div>
                <div *ngIf="data === 'no data found'" class="ui-block-title">
                    <h6 class="title" style="text-align: center;"> No Data Found ! </h6>

                </div>
            </div>

            <div id="search-items-grid">
                <div *ngFor="let result of data" class="ui-block">
                    <article class="hentry post searches-item">
                        <div class="post__author author vcard inline-items">
                            <a routerLink="/profile-details" [queryParams]="{'id': result.id}"> <img *ngIf="!result.avatar" alt="author" src="../../../assets/img/author-main1.webp" width="40" height="40"> </a>
                            <a routerLink="/profile-details" [queryParams]="{'id': result.id}"> <img *ngIf="result.avatar" alt="author" [src]="result.avatar" onerror="this.onerror=null; this.src='../../../assets/img/author-main1.webp';" width="40" height="40">
                            </a>

                            <div class="author-date">
                                <a class="h6 post__author-name fn" routerLink="/profile-details" [queryParams]="{'id': result.id}">{{result.fullName}}</a>
                                <div class="country">{{result.countryName}}</div>
                            </div>                         

                            

                        </div>

                        <p class="user-description">
                            <span class="title">About Me:</span> Hi!, I’m {{result.fullName}}. {{result.about}}

                        </p>

                       </article>
                    <!-- ... end Search Result -->
                </div>

            </div>

        </div>

        <!-- ... end Main Content -->


        <!-- Left Sidebar -->

        <div class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">
            <div class="ui-block">           

                <div class="widget w-action">

                    <img src="../../../assets/img/logo.webp" alt="Football Families">
                    <div class="content">
                        <h4 class="title">Football Families</h4>
                        <span>THE BEST SOCIAL NETWORK !</span>
                     
                    </div>
                </div>

            
            </div>




        </div>

        <!-- ... end Left Sidebar -->


        <!-- Right Sidebar -->

        <div class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">

            <div class="ui-block">           

                <div class="widget w-action">

                    <img src="../../../assets/img/logo.webp" alt="Football Families">
                    <div class="content">
                        <h4 class="title">Football Families</h4>
                        <span>THE BEST SOCIAL NETWORK !</span>
                     
                    </div>
                </div>

            
            </div>

           
        </div>

       

    </div>
</div>