<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-club-header></app-club-header>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Payment</h6>
                </div>
                <div class="container">


                    <div class="row">
                        <aside class="col-sm-6">

                            <article class="card">
                                <div class="card-body p-5">

                                    <form role="form">
                                        <div class="form-group">
                                            <label for="username">Email</label>
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <!-- <span class="input-group-text"><i class="fa fa-user"></i></span> -->
                                                </div>
                                                <input type="text" class="form-control" name="username" placeholder=""
                                                    required="">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">First Name</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">Last Name</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="cardNumber">Address</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="cardNumber"
                                                    placeholder="">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">City</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">Postal Code</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">Country</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <label for="username">Contact Number</label>
                                                    <div class="input-group">

                                                        <input type="text" class="form-control" name="username"
                                                            placeholder="" required="">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- card-body.// -->
                            </article>
                            <!-- card.// -->


                        </aside>
                       
                        <!-- <aside class="col-sm-6">


                            <article class="card">
                                <div class="card-body p-5">

                                    <ul class="nav bg-light nav-pills rounded nav-fill mb-3" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-toggle="pill" href="#nav-tab-card">
                                                <i class="fa fa-credit-card"></i> Credit Card</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" data-toggle="pill" href="#nav-tab-bank">
                                                <i class="fa fa-university"></i> Bank Transfer</a>
                                        </li>
                                    </ul>

                                    <div class="tab-content">
                                        <div class="tab-pane fade show active" id="nav-tab-card">
                                            <form role="form">
                                                <div class="form-group">
                                                    <label for="username">Full name (on the card)</label>
                                                    <input type="text" class="form-control" name="username"
                                                        placeholder="" required="">
                                                </div>


                                                <div class="form-group">
                                                    <label for="cardNumber">Card number</label>
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" name="cardNumber"
                                                            placeholder="">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text text-muted">
                                                                <i class="fab fa-cc-visa"></i>   <i
                                                                    class="fab fa-cc-amex"></i>  
                                                                <i class="fab fa-cc-mastercard"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="row">
                                                    <div class="col-sm-8">
                                                        <div class="form-group">
                                                            <label><span class="hidden-xs">Expiration</span> </label>
                                                            <div class="input-group">
                                                                <input type="number" class="form-control"
                                                                    placeholder="MM" name="">
                                                                <input type="number" class="form-control"
                                                                    placeholder="YY" name="">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div class="form-group">
                                                            <label data-toggle="tooltip" title=""
                                                                data-original-title="3 digits code on back side of the card">CVV
                                                                <i class="fa fa-question-circle"></i></label>
                                                            <input type="number" class="form-control" required="">
                                                        </div>

                                                    </div>
                                                </div>

                                                <button class="subscribe btn btn-primary btn-block" type="button">
                                                    Pay Now </button>

                                            </form>
                                            <div>
                                                <dl class="param">
                                                    <dt>BANK: </dt>
                                                    <dd> THE WORLD BANK</dd>
                                                </dl>
                                                <dl class="param">
                                                    <dt>Accaunt number: </dt>
                                                    <dd> 12345678912345</dd>
                                                </dl>
                                                <dl class="param">
                                                    <dt>Amount: </dt>
                                                    <dd> 500.00</dd>
                                                </dl>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="nav-tab-bank">
                                            <p>Bank accaunt details</p>
                                            <dl class="param">
                                                <dt>BANK: </dt>
                                                <dd> THE WORLD BANK</dd>
                                            </dl>
                                            <dl class="param">
                                                <dt>Accaunt number: </dt>
                                                <dd> 12345678912345</dd>
                                            </dl>
                                            <dl class="param">
                                                <dt>IBAN: </dt>
                                                <dd> 123456789</dd>
                                            </dl>
                                            <p><strong>Note:</strong> Lorem ipsum dolor sit amet, consectetur
                                                adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                                magna aliqua. </p>
                                        </div>

                                    </div>


                                </div>

                            </article>



                        </aside> -->

                    </div>


                </div>
            </div>
        </div>
    </div>
</div>