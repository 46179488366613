import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';
declare var $: any;
@Component({
  selector: 'app-right-side',
  templateUrl: './right-side.component.html',
  styleUrls: ['./right-side.component.css']
})
export class RightSideComponent implements OnInit, AfterViewInit {
  sendMessageForm: FormGroup;
  searchText: string;
  currentUser: any;
  senderMessage: any = {};
  messages: any = {};
  userData: any = {};
  friends = [];
  selectFriend: any = {};
  sidebar: any;
  senderMessages: any;
  receiverMessages: any;
  isIffhsLoggedIn: boolean;
  language='';


  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
  ) {

  }
  toggleSideBar(event) {
    var mobileWidthApp = $('body').outerWidth();
    if (mobileWidthApp <= 560) {
      $(event.target).closest('body').find('.popup-chat-responsive').removeClass('open-chat');
    }

    $(event.target).toggleClass('active');
    $(event.target).closest(this.sidebar).toggleClass('open');
    return false;
  }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.isIffhsLoggedIn = JSON.parse(localStorage.getItem('isIffhsLoggedIn'));
    // this.getProfile();
    // this.getMyFriends();

    this.sendMessageForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res;
    });

  }
  ngAfterViewInit(): void {
    this.sidebar = $('.fixed-sidebar');

    // $('.js-sidebar-open').on('click', function () {

    //   var mobileWidthApp = $('body').outerWidth();
    //   if (mobileWidthApp <= 560) {
    //     $(this).closest('body').find('.popup-chat-responsive').removeClass('open-chat');
    //   }

    //   $(this).toggleClass('active');
    //   $(this).closest(this.sidebar).toggleClass('open');
    //   return false;
    // });
  }

  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(
  //     userData => this.userData = userData[0]
  //   );

  // }

  sendMessage(id: number) {
    console.log(id);
    const data = {
      senderId: this.currentUser.success.user.id,
      message: this.sendMessageForm.value.message,
      receiverId: id,
    };
    this.userService.sendMessage(data).pipe(first<any>()).subscribe(
      senderMessage => {
        this.senderMessage = senderMessage;
        this.receivedMessage(id);
      }
    );
    this.sendMessageForm.reset();
  }
  receivedMessage(rId: number) {

    const data = {
      senderId: rId,
      receiverId: this.currentUser.success.user.id,
    };
    this.userService.receivedMessage(data).pipe(first<any>()).subscribe((res: any) => {
      console.log(res);
      // console.log(res.subContinents[0].continent_id);
      this.messages = res;
      this.senderMessages = res.receiver;
      this.receiverMessages = res.sender;
      // this.selectedId = id;
      // console.log(this.selectedId);
    });
    // console.log(this.ReceiverMessages);
  }

  getMyFriends() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getFriends(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
  }

  openChatModal(modal: any, friend: any) {
    this.selectFriend = friend.user;
    console.log(this.selectFriend);
    this.receivedMessage(this.selectFriend.id);
    modal.show();
  }

  closeChatModal(modal: any) {
    modal.hide();
  }

}
