import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-conf-videos',
  templateUrl: './conf-videos.component.html',
  styleUrls: ['./conf-videos.component.css']
})
export class ConfVideosComponent implements OnInit {

  id: number;
  countryData: any = {};
  confData: any = {};
  currentUser: any;
  searchText: any;
  status: boolean;
  videos  = [];
  selectFriend: any = {};
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getVideos();
  }
  getVideos() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getMedia(data).subscribe((res: any) => {
      this.videos = res.videos;
      this.isLoaderVisible = false;
    });
  }


}
