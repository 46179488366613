<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title">Coaches </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div *ngIf="coaches.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message">No Data Found ! </h6>

        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let coach of coaches  | filter:searchText"
            class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}">
                                    <img src="{{coach.coachAvatar}}" alt="Avatar" style="width: 120px; height: 120px;"
                                        onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                        style="width: 120px; height: 120px;">
                                </a>
                            </div>

                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}"
                                    class="h5 author-name"> {{coach.coachName | titlecase}} </a>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>