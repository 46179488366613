<app-national-team-header></app-national-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Player</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="playerForm" (ngSubmit)="addPlayer()">
                        <div class="row">

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="firstName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="lastName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label">Select Position *</label>
                                    <select class="form-control" formControlName="playerPosition"
                                        [ngClass]="{ 'is-invalid': submitted && f.playerPosition.errors }">
                                        <option *ngFor="let position of positions" value="{{position.name}}" selected>
                                            {{position.name}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.playerPosition.errors" class="invalid-feedback">
                                        <div *ngIf="f.playerPosition.errors.required">Position is required *</div>
                                    </div>
                                    <!-- <label class="control-label">Content *</label>
                                    <textarea class="form-control" name="newsContent" formControlName="newsContent" placeholder="Details of news *" [ngClass]="{ 'is-invalid': submitted && f.newsDetails.errors }"></textarea>
                                    <div *ngIf="submitted && f.newsContent.errors" class="invalid-feedback">
                                        <div *ngIf="f.newsContent.errors.required">Content is required *</div>
                                    </div> -->
                                </div>

                            </div>

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">

                                    <div class="form-group">
                                        <label class="control-label">Date Of birth <span style="color: red;"> *
                                            </span></label>


                                        <input class="form-control" formControlName="playerDob" placeholder="02/03/1990"
                                            bsDatepicker [maxDate]="maxDate"
                                            [ngClass]="{ 'is-invalid': submitted && f.playerDob.errors }">

                                        <div *ngIf="submitted && f.playerDob.errors" class="invalid-feedback">
                                            <div *ngIf="f.playerDob.errors.required">Date is required *</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Avatar <span style="color: red;"> *
                                        </span></label>
                                    <input type="file" formControlName="playerAvatar" accept="image/*"
                                        class="form-control" (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.playerAvatar.errors }" />
                                    <div *ngIf=" submitted && f.playerAvatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.playerAvatar.errors.required ">Avatar is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none ">
            <div class="ui-block ">
                <div class="your-profile ">
                    <!-- <div class="ui-block-title ui-block-title-small ">
                        <h6 class="title ">Your DASHBOARD</h6>
                    </div> -->

                    <div id="accordion " role="tablist " aria-multiselectable="true ">
                        <div class="card ">
                            <!-- <div class="card-header " role="tab " id="headingOne ">
                                <h6 class="mb-0 ">
                                    <a data-toggle="collapse " data-parent="#accordion " href="#collapseOne " aria-expanded="true " aria-controls="collapseOne ">
                                        Dashboard Settings
                                        <svg class="olymp-dropdown-arrow-icon ">
                                            <use xlink:href="#olymp-dropdown-arrow-icon "></use>
                                        </svg>
                                    </a>
                                </h6>
                            </div> -->

                            <div id="collapseOne " class="collapse show " role="tabpanel "
                                aria-labelledby="headingOne ">
                                <ul class="your-profile-menu ">
                                    <li class="active ">
                                        <a routerLink="/national-team-dashboard"
                                            [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}"
                                            routerLinkActive="active ">News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/national-team-dashboard-media"
                                            [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}"
                                            routerLinkActive="active ">Photos/Videos</a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/dashboard-matches" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active ">Add Matches</a>
                                    </li> -->
                                    <!-- <li>
                                        <a routerLink="/dashboard-leagues" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active ">Add Leagues</a>
                                    </li> -->
                                    <!-- <li>
                                        <a routerLink="/national-team-dashboard-media" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active ">Add Media</a>
                                    </li> -->
                                    <li>
                                        <a routerLink="/national-team-dashboard-player"
                                            [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}"
                                            routerLinkActive="active ">Add Player</a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/dashboard-referee" [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}" routerLinkActive="active ">Add Referee</a>
                                    </li> -->
                                    <li>
                                        <a routerLink="/national-team-dashboard-coach"
                                            [queryParams]="{'id': countryData.id, 'name':teamClass, 'category':category}"
                                            routerLinkActive="active ">Add Coach</a>
                                    </li>


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>