import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
  private subject = new Subject<any>();
  private legendSubject = new Subject<any>();
  selectedLanguage = new BehaviorSubject<any>('');

  /************* User Data ********************************** */
  sendMessage(data: any) {
    this.subject.next(data);
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  onMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  /************* Legends********************************** */
  sendLegendData(data: any) {
    this.legendSubject.next(data);
  }
  getLegendData(): Observable<any> {
    return this.legendSubject.asObservable();
  }
  onLegendData(): Observable<any> {
    return this.legendSubject.asObservable();
  }

}
