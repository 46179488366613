<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-presidents></app-presidents>

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <ul class="cat-list-bg-style align-center sorting-menu">
                    <li class="cat-list__item active" data-filter="*"><a routerLink="/executive-committee" class=""><strong> {{'executive-committee.executive_committee' | translate}} </strong> </a></li>
                    <li class="cat-list__item" data-filter=".politics">
                        <a routerLink="/honorable-committee" class="">
                            <strong> {{'executive-committee.honorable_committee' | translate}} </strong></a>
                    </li>
                    <li class="cat-list__item" data-filter=".natural">
                        <a routerLink="/iffhs-members" class="">
                            <strong> {{'executive-committee.iffhs_member' | translate}} </strong></a>
                    </li>
                    <li class="cat-list__item" data-filter=".worlds">
                        <a routerLink="/president-consultant" class="">
                            <strong> {{'executive-committee.president_consultant' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/president-assistants" class="">
                            <strong> {{'executive-committee.president_assistant' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/gala-award" class="">
                            <strong> {{'executive-committee.iffhs_awards' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/ranking" class="">
                            <strong> {{'executive-committee.ranking' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/history" class="">
                            <strong> {{'executive-committee.history' | translate}} </strong> </a>
                    </li>
                </ul>

                <div class="row sorting-container" id="clients-grid-1" data-layout="masonry">

                    <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sorting-item ecommerce natural">
                        <div class="ui-block">

                            <article class="hentry post has-post-thumbnail" style="padding-top: 70px;">

                                <div class="post__author author vcard inline-items">


                                </div>


                                <div class="post-thumb">

                                    <!-- <img src="../../../assets/img/ex-co.webp" usemap="#image-map">

                                    <map name="image-map">
                                        <area target="_self" alt="saleh" title="saleh" href="#" coords="578,890,317"
                                            shape="circle">
                                        <area target="_self" alt="jose" title="jose" href="#" coords="253,1382,137"
                                            shape="circle">
                                        <area target="_self" alt="hecter" title="hecter" href="" coords="796,1525,171"
                                            shape="circle">
                                        <area target="_self" alt="robret" title="robret" href="" coords="1223,1348,176"
                                            shape="circle">
                                        <area target="_self" alt="jorgon" title="jorgon" href="" coords="1664,1541,161"
                                            shape="circle">
                                        <area target="_self" alt="igor" title="igor" href="" coords="2221,1384,142"
                                            shape="circle">
                                        <area target="_self" alt="khaled" title="khaled" href="" coords="2182,1780,139"
                                            shape="circle">
                                        <area target="_self" alt="imed" title="imed" href="" coords="1925,2085,124"
                                            shape="circle">
                                        <area target="_self" alt="clovis" title="clovis" href="" coords="1581,2240,136"
                                            shape="circle">
                                        <area target="_self" alt="fani" title="fani" href="" coords="1234,2292,136"
                                            shape="circle">
                                        <area target="_self" alt="marcelo" title="marcelo" href="" coords="883,2231,132"
                                            shape="circle">
                                        <area target="_self" alt="jasim" title="jasim" href="" coords="1230,1814,158"
                                            shape="circle">
                                        <area target="_self" alt="ignotis" title="ignotis" href="" coords="534,2086,135"
                                            shape="circle">
                                        <area target="_self" alt="ashish" title="ashish" href="" coords="306,1783,147"
                                            shape="circle">
                                    </map> -->
                                    <a href="javascript:void(0);" (click)="onClick(images[13])" id="saleh" style="position: absolute;margin-top: 189px; margin-left: 114px;">
                                        <span style="opacity:0;">saleh<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[0])" id="julio" style="position: absolute;margin-top: 330px; margin-left: 160px;">
                                        <span style="opacity: 0">Julio<br><BR><BR></span>
                                    </a>

                                    <a href="javascript:void(0);" id="clovis" (click)="onClick(images[1])" style="position: absolute;margin-top:500px; margin-left:335px;">
                                        <span style="opacity: 0">Clovis<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" id="jorgen" (click)="onClick(images[2])" style="position: absolute;margin-top:335px; margin-left:340px;">
                                        <span style="opacity: 0">Jorgen<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[3])" style="position: absolute;margin-top:385px; margin-left:470px;">
                                        <span style="opacity: 0">khaled<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" id="robert" (click)="onClick(images[4])" style="position: absolute;margin-top:290px; margin-left:255px;">
                                        <span style="opacity: 0">Robert<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[5])" id="igor" style="position: absolute;margin-top:300px; margin-left:485px;">
                                        <span style="opacity: 0">Igor<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[6])" style="position: absolute;margin-top:300px; margin-left:42px;">
                                        <span style="opacity: 0">Jose<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[7])" style="position: absolute;margin-top:400px; margin-left:255px;">
                                        <span style="opacity: 0">Jasem<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[8])" style="position: absolute;margin-top:385px; margin-left:45px;">
                                        <span style="opacity: 0">Ashish<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[9])" id="ignatius" style="position: absolute;margin-top:460px; margin-left:95px;">
                                        <span style="opacity: 0">Ignatius<br><BR><BR> </span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[10])" id="marcelo" style="position: absolute;margin-top:490px; margin-left:170px;">
                                        <span style="opacity: 0">Marcelo<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[11])" id="fani" style="position: absolute;margin-top:500px; margin-left:260px;">
                                        <span style="opacity: 0">Fani<br><BR><BR></span>
                                    </a>
                                    <a href="javascript:void(0);" (click)="onClick(images[12])" id="kilani" style="position: absolute;margin-top:460px; margin-left:415px;">
                                        <span style="opacity: 0">Kilani<br><BR><BR></span>
                                    </a>

                                    <img src="assets/img/ex-co.webp" alt="photo" width="523" height="720">
                                </div>

                            </article>
                        </div>
                    </div>

                    <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sorting-item worlds family politics">
                        <div class="ui-block">
                            <article class="hentry post has-post-thumbnail">
                                <div class="post__author author vcard inline-items">

                                </div>
                                <!-- [id] ="post.id" -->
                                <div class="post-thumb">
                                    <a routerLink="/profile-details" [queryParams]="{'id': imageSrc.id , 'role':'User'}"> <img src="{{imageSrc.src}}"> </a>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">{{'executive-committee.name' | translate}}</th>
                                                <th scope="col"> <a routerLink="/profile-details" [queryParams]="{'id': imageSrc.id , 'role':'User'}" class="h5 author-name" style="color: #3f4257;">{{imageSrc.name}}
                                                    </a></th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="col">{{'executive-committee.position' | translate}}</td>
                                                <td>{{imageSrc.position}}</td>
                                            </tr>
                                            <tr>
                                                <td scope="col">{{'executive-committee.nationality' | translate}}</td>
                                                <td> <img *ngIf="language != 'ar'" src="{{imageSrc.flag}}" style="width: 30px; height: 20px; float: left;margin: 0px 12px;"> {{imageSrc.nationality}}
                                                    <img *ngIf="language == 'ar'" src="{{imageSrc.flag}}" style="width: 30px; height: 20px; float: right;margin: 0px 12px;"> {{imageSrc.nationality}} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </article>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- <app-iffhs-news></app-iffhs-news> -->
    </div>
</div>