<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-profile-header></app-profile-header>
<div class="container">
    <div class="row">
        <div *ngIf="followers" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> Totals Following ( {{followers.length}} ) </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <!-- <a  href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
							<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
						</svg></a> -->
                </div>
            </div>
        </div>
        <div *ngIf="followers.length === 0" class="ui-block-title">
            <h6 class="title" style="text-align: center;">No Followers Found!
            </h6>

        </div>

    </div>
</div>


<!-- Friends -->

<div class="container">
    <div class="row">
        <div *ngFor="let follower of followers | filter:searchText"
            class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block">

                <!-- Friend Item -->

                <div class="friend-item">
                    <div class="friend-header-thumb">
                        <img alt="friend" src="../../../assets/img/small-cover.webp" width="318" height="122">
                        <!-- <img *ngIf="follower.banner" alt="friend" [src]="follower.banner" width="318" height="122"> -->
                    </div>

                    <div class="friend-item-content">

                        <div class="more">
                            <a (click)="openFollowerDeleteModal(followerDeleteModal, follower)"> <i class="fa fa-trash"
                                    aria-hidden="true"></i> </a>
                            <!-- <svg class="olymp-three-dots-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
								</use>
							</svg> -->
                            <!-- <ul class="more-dropdown">
								<li>
									<a (click)="openFollowerDeleteModal(followerDeleteModal, follower)">Delete </a>
								</li>
								 <li>
									<a  href="javascript:void(0);">Block Profile</a>
								</li>
								<li>
									<a  href="javascript:void(0);">Turn Off Notifications</a>
								</li> 
							</ul> -->
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': follower.id}">
                                    <img *ngIf="!follower.avatar" alt="author" src="../../../assets/img/user.jpg"
                                        width="100" height="100"
                                        onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;">

                                    <img *ngIf="follower.avatar" alt="author" [src]="follower.avatar" width="100"
                                        height="100"
                                        onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': follower.id}"
                                    class="h5 author-name">{{ follower.user.fullName | titlecase}}</a>
                                <div *ngIf="follower.countryName" class="country">
                                    {{ follower.user.countryName | titlecase}}</div>
                                <div *ngIf="!follower.countryName" class="country">{{ 'Country Name'}}</div>
                            </div>
                        </div>

                        <!-- <div class="swiper-container" data-slide="fade">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="friend-count" data-swiper-parallax="-500">
                                        <a href="javascript:void(0);" class="friend-count-item">
                                            <div class="h6">{{followers.length}}</div>
                                            <div class="title">Friends</div>
                                        </a>
                                        <a href="javascript:void(0);" class="friend-count-item">
                                            <div class="h6">{{followers.length}}</div>
                                            <div class="title">Followers</div>
                                        </a>
                                        <a href="javascript:void(0);" class="friend-count-item">
                                            <div class="h6">16</div>
                                            <div class="title">Following</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>


    </div>
</div>

<!-- ... end Friends -->


<!-- Delete Modal Friend -->
<div class="modal fade" bsModal #followerDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Follower</h5>
                <button type="button" class="close" (click)="followerDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you Sure you want to delete Follower</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="followerDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteFollower(followerDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!--  Delete Modal Friend  -->