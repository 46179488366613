import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-edit-ranking',
  templateUrl: './edit-ranking.component.html',
  styleUrls: ['./edit-ranking.component.css']
})
export class EditRankingComponent implements OnInit {

  editRankingForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  rank: any = {};
  positions = [];
  years = [];
  awards = [];
  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private messageService: MessageService

  ) {
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getRankById();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }
  getRankById() {
    this.isLoaderVisible = true;
    const data = {
      rankId: this.id,
    };
    this.iffhsService.getRankById(data).subscribe((res: any) => {
      this.rank = res;
      this.isLoaderVisible = false;
    },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }
    );
  }
  getAllAwards() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllAwards().subscribe((res: any) => {
      this.awards = res.awards;
      this.isLoaderVisible = false;
    });
  }
  initForm() {
    this.editRankingForm = this.formBuilder.group({
      category: [this.rank.category, Validators.required],
      award: [this.rank.award, Validators.required],
      rankId: [this.id],
      firstName: [this.rank.firstName, Validators.required],
      lastName: [this.rank.lastName, Validators.required],
      yearOfRanking: [this.rank.yearOfRanking, Validators.required],
      rank: [this.rank.rank, Validators.required],
      avatar: [''],
      createdBy: [this.currentUser.success.user.id],

    });
    this.positions = Array(10).fill(0).map((x, i) => ({ position: (i + 1), }));
    this.years = Array(20).fill(0).map((x, i) => ({ name: (i + 2019) }));
    this.getAllAwards();
  }

  onFileSelect(event) {
    const avatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.editRankingForm.get('avatar').setValue(avatar);

  }
  goToDashboard() {
    this.router.navigate(['/add-ranking']);
  }

  get f() { return this.editRankingForm.controls; }
  editRanking() {
    this.submitted = true;
    if (this.editRankingForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('rankId', this.editRankingForm.get('rankId').value);
    fd.append('category', this.editRankingForm.get('category').value);
    fd.append('award', this.editRankingForm.get('award').value);
    fd.append('firstName', this.editRankingForm.get('firstName').value);
    fd.append('lastName', this.editRankingForm.get('lastName').value);
    fd.append('yearOfRanking', this.editRankingForm.get('yearOfRanking').value);
    fd.append('rank', this.editRankingForm.get('rank').value);
    fd.append('avatar', this.editRankingForm.get('avatar').value);
    fd.append('createdBy', this.editRankingForm.get('createdBy').value);

    this.iffhsService.editRank(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Rank Updated!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.getRankById();
    this.editRankingForm = this.formBuilder.group({
      category: [this.rank.category, Validators.required],
      award: [this.rank.award, Validators.required],
      rankId: [this.id],
      firstName: [this.rank.firstName, Validators.required],
      lastName: [this.rank.lastName, Validators.required],
      yearOfRanking: [this.rank.yearOfRanking, Validators.required],
      rank: [this.rank.rank, Validators.required],
      avatar: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
