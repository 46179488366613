import { Component, OnInit } from '@angular/core';
import { Directionality } from '@angular/cdk/bidi';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-executive-committee',
  templateUrl: './executive-committee.component.html',
  styleUrls: ['./executive-committee.component.css']
})
export class ExecutiveCommitteeComponent implements OnInit {
  language = '';
  direction;
  defaultLanguage;

  imageSrc = {
    src: 'assets/img/committee/saleh-salem.webp',
    id: '111',
    name: 'Shaikh Saleh Salem',
    position: 'President',
    nationality: 'Saudi Arabia',
    flag: 'assets/svg-icons/1x1/sa.svg'
  };
  images = [
    {
      src: 'assets/img/committee/Hector.webp',
      id: '114',
      name: 'Hector Macias',
      position: 'Executive Committee',
      nationality: 'Argentina',
      flag: 'assets/svg-icons/1x1/ar.svg'
    },
    {
      src: 'assets/img/committee/Clovis.webp',
      id: '116',
      name: 'Clovis Martins Da Silva',
      position: 'Executive Committee',
      nationality: 'Brazil',
      flag: 'assets/svg-icons/1x1/br.svg'
    },
    {
      src: 'assets/img/committee/Jorgen.webp',
      id: '117',
      name: 'Jorgen Nielsen',
      position: 'Executive Committee',
      nationality: 'Denmark',
      flag: 'assets/svg-icons/1x1/dk.svg'
    },
    {
      src: 'assets/img/committee/Khaled.webp',
      id: '118',
      name: 'Khaled Abul OYON',
      position: 'Executive Committee',
      nationality: 'Egypt',
      flag: 'assets/svg-icons/1x1/eg.svg'
    },
    {
      src: 'assets/img/committee/Robert.webp',
      id: '119',
      name: 'Robert Ley',
      position: 'Executive Committee',
      nationality: 'France',
      flag: 'assets/svg-icons/1x1/fr.svg'
    },
    {
      src: 'assets/img/committee/Igor.webp',
      id: '121',
      name: 'Igor Goldes',
      position: 'Executive Committee',
      nationality: 'Russian Federation',
      flag: 'assets/svg-icons/1x1/ru.svg'
    },
    {
      src: 'assets/img/committee/Jose.webp',
      id: '122',
      name: 'Jose Del Olmo',
      position: 'Executive Committee',
      nationality: ' Spain',
      flag: 'assets/svg-icons/1x1/es.svg'
    },
    {
      src: 'assets/img/committee/Jasem.webp',
      id: '123',
      name: 'Jasem Al-Sayed',
      position: 'Executive Committee',
      nationality: 'United Arab Emirates ',
      flag: 'assets/svg-icons/1x1/ae.svg'
    },
    {
      src: 'assets/img/committee/Ashish.webp',
      id: '13417',
      name: 'Ashish Pendses',
      position: 'Executive Committee',
      nationality: 'India',
      flag: 'assets/svg-icons/1x1/in.svg'
    },
    {
      src: 'assets/img/committee/Ignatius.webp',
      id: '221',
      name: 'Ignatius Fon Echekiye',
      position: 'Executive Committee',
      nationality: 'Cameroon ',
      flag: 'assets/svg-icons/1x1/cm.svg'
    },
    {
      src: 'assets/img/committee/Marcelo.webp',
      id: '13419',
      name: 'Marcelo Assaf',
      position: 'Executive Committee',
      nationality: 'Mexico',
      flag: 'assets/svg-icons/1x1/mx.svg'
    },
    {
      src: 'assets/img/committee/Fani.webp',
      id: '13420',
      name: 'Fani Stipkovic',
      position: 'Executive Committee',
      nationality: 'Croatia',
      flag: 'assets/svg-icons/1x1/hr.svg'
    },
    {
      src: 'assets/img/committee/Imed.webp',
      id: '13422',
      name: 'Imed Kilani',
      position: 'Executive Committee',
      nationality: 'Tunisia',
      flag: 'assets/svg-icons/1x1/tn.svg'
    },
    {
      src: 'assets/img/committee/saleh-salem.webp',
      id: '111',
      name: 'Shaikh Saleh Salem',
      position: 'President',
      nationality: 'Saudi Arabia',
      flag: 'assets/svg-icons/1x1/sa.svg'
    },
  ];

  constructor(
    private messageService: MessageService) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

  onClick(imageNameObject) {
    this.imageSrc = imageNameObject;
  }

}
