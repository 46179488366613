<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-conf-header></app-conf-header>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title">
                        Followers
                    </div>
                    <form class="w-search">
                        <div (click)="doFollow()" class="h6 label-title text-right">{{totalFollowers}} {{placeName}}
                            <a href="javascript:void(0);">
                                <img src="assets/img/unfollow.webp" alt="follow" style="width: 44px;
                                margin: -10px 10px; "></a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex1200">
                <div class="ui-block-title">

                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control">
                                <option class="list option selected focus"> Select Continent</option>
                                <option class="list option selected focus" *ngFor="let continent of continents"
                                    [value]="continent.id" (click)="getCountries(continent.id, continent.name)">
                                    {{continent.name}}
                                </option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isCountryDisabled">
                                <option class="list option selected focus" *ngFor="let country of countries"
                                    [value]="country.id" (click)="getState(country.id,country.name )">
                                    {{country.name}}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isStateDisabled">
                                <option class="list option selected focus" *ngFor="let state of states"
                                    [value]="state.id" (click)="getCities(state.id, state.name)">{{state.name}}</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">

                        <fieldset class="form-group">
                            <select class=" form-control" [disabled]="isCityDisabled">
                                <option class="list option selected focus" *ngFor="let city of cities" [value]="city.id"
                                    (click)="getCity(city.id,city.name )">{{city.name}}</option>
                            </select>
                        </fieldset>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>


<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="ui-block">


                    <article class="hentry blog-post">

                        <div class="post-thumb">
                            <iframe *ngIf="url === ''" width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=asia&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY" allowfullscreen=""></iframe>

                            <iframe *ngIf="url !== ''" width="100%" height="450" frameborder="0" style="border:0" [src]='sanitizer.bypassSecurityTrustResourceUrl(url)' allowfullscreen="">
                            </iframe>
                        </div>

                    </article>
                </div>
            </div>
        </div>
    </div>
</section>