<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Edit IFFHS Award </h6>
                    <button (click)="goToDashboard()" class="btn btn-primary float-right" style="font-size: 1.5rem;">
                        <i class="fa fa-eye" aria-hidden="true"></i>
                        View Awards</button>
                </div>
                <div class="ui-block-content">
                    <form *ngIf="awardEditForm" class="content" [formGroup]="awardEditForm" (ngSubmit)="editAward()">
                        <div class="row">

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div [formGroup]="awardEditForm" class="form-group">
                                    <label class="control-label">Award Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="awardName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.awardName.errors }" />
                                    <div *ngIf="submitted && f.awardName.errors" class="invalid-feedback">
                                        <div *ngIf="f.awardName.errors.required">Name is required *</div>
                                    </div>
                                </div>
                                <img src="https://iffhs.de/images/awards/{{award.image}}" height="120" alt="photo"
                                    width="120"><br>

                                <div [formGroup]="awardEditForm" class="form-group">
                                    <label class="control-label">Photo ( Select only Image ) </label>
                                    <input type="file" formControlName="awardPhoto" accept="image/*"
                                        class="form-control" (change)="onImageSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.awardPhoto.errors }" />

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">

                                    Update
                                </button>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>