<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Award Winner </h6>
                    <button (click)="goToDashboard()" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>

                        View</button>
                </div>
                <div class="ui-block-content">
                    <form *ngIf="editAwardWinnerForm" [formGroup]="editAwardWinnerForm" (ngSubmit)="editWinner()"
                        class="content">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Award Banner <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="bannerId"
                                        (change)="onChangeBanner($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerId.errors }">
                                        <option *ngFor="let banner of banners" value="{{banner.id}}" selected>
                                            {{banner.name | slice:0:30}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.bannerId.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerId.errors.required">Banner is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Award <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="award" (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.award.errors }">
                                        <option *ngFor="let award of awards" value="{{award.id}}" selected>
                                            {{award.name | slice:0:30}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.award.errors" class="invalid-feedback">
                                        <div *ngIf="f.award.errors.required">Award is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="firstName" placeholder="Jhon"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="lastName" placeholder="Snow"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Country <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="winnerCountry"
                                        placeholder="Saudi Arabia"
                                        [ngClass]="{ 'is-invalid': submitted && f.winnerCountry.errors }">
                                        <option *ngFor="let country of countryList" [value]="country.name" selected>
                                            {{country.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.winnerCountry.errors" class="invalid-feedback">
                                        <div *ngIf="f.winnerCountry.errors.required">Country is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label">Select Year of Award <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="yearOfAward" placeholder="2018"
                                        [ngClass]="{ 'is-invalid': submitted && f.yearOfAward.errors }">
                                        <option *ngFor="let year of years" [value]="year.name" selected>
                                            {{year.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.yearOfAward.errors" class="invalid-feedback">
                                        <div *ngIf="f.yearOfAward.errors.required">Year of Award is required *</div>
                                    </div>

                                </div>

                            </div>

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">

                                    <div class="form-group">
                                        <label class="control-label">Date Of birth <span style="color: red;"> *
                                            </span></label>

                                        <input class="form-control" formControlName="dob" placeholder="02/03/1990"
                                            bsDatepicker [maxDate]="maxDate"
                                            [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">

                                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                            <div *ngIf="f.dob.errors.required">Date is required *</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <!-- <img src="https://api.football-family.com/img/awards/{{winner.award_id}}/{{winner.image}}" class="center" height="120" alt="Winner Photo" width="120"><br> -->
                                <div class="form-group">
                                    <label class="control-label">Winner Avatar <span style="color: red;"> *
                                        </span></label>
                                    <input type="file" formControlName="winnerAvatar" accept="image/*"
                                        class="form-control" (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.winnerAvatar.errors }" />
                                    <div *ngIf=" submitted && f.winnerAvatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.winnerAvatar.errors.required ">Winner Avatar is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width"> Update
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>