import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent implements OnInit {
  id: any;
  userData: any = {};
  currentUser: any;
  friends = [];

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      }); 
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    // this.getProfile();
    this.getMyFriends();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }
  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(userData => this.userData = userData[0]);
  // }
  getMyFriends() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getFriends(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
    // console.log(this.friends);
  }

}
