<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-conf-header></app-conf-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Photos </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
           
        </div>
        <div *ngIf="photos.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>

        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">          
            <div class="tab-content">
                <div class="tab-pane active" id="album-page" role="tabpanel">
                    <div class="photo-album-wrapper">
                        <div *ngFor="let photo of photos |filter:searchText" class="photo-album-item-wrap col-4-width">
                            <div (click)="openModal(photoModal,photo)" class="photo-album-item" data-mh="album-item">
                                <div class="photo-item">
                                    <img class="conf-img" src="{{photo.mediaAttachment}}" alt="photo">
                                </div>
                                <div class="content">
                                    <a href="javascript:void(0)" class="date h5">{{photo.mediaTitle | titlecase}}</a>
                                    <span class="sub-title date">{{photo.created_at | date: 'longDate'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Videos </div>
                </div>
            </div>            
        </div>
        <div *ngIf="videos.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div *ngFor="let video of videos |filter:searchText" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div style="background-color: transparent;" class="ui-block video-item">
                <video id="my_video_1" class="video-js vjs-default-skin conf-video" controls muted data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                    <source [src]="video.mediaAttachment" type='video/mp4' />
                    <source [src]="video.mediaAttachment" type="video/webm">
                    Sorry, your browser doesn't support embedded videos.
                </video>
                <div class="ui-block-content video-content">
                    <p class="h6 title">{{video.mediaTitle | titlecase}}</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" bsModal #photoModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="private-event" aria-hidden="true">
    <div class="modal-dialog window-popup event-private-public private-event" role="document">
        <div class="modal-content">
            <a (click)="closeModal(photoModal)" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-body">
                <article class="hentry post has-post-thumbnail thumb-full-width private-event">

                    <div class="private-event-head inline-items">
                        <!-- <img src="../../../assets/img/author-page.jpg" alt="author"> -->
                        <div class="author-date">
                            <a class="h3 event-title" href="javascript:void(0);">{{selectedPhoto.mediaTitle | titlecase}}</a>
                            <div class="event__date">
                                <time class="published date" datetime="2017-03-24T18:18">
                                    {{selectedPhoto.created_at | date:'mediumDate'}}
                                </time>
                            </div>
                        </div>
                    </div>
                    <div class="post-thumb">
                        <img src="{{selectedPhoto.mediaAttachment}}" alt="photo" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';">
                    </div>
                    <div class="row">
                        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="post__author author vcard inline-items">
                                <h3 class="head content-custom "> Description:</h3>
                            </div>
                            <p class="content-custom ">
                                {{selectedPhoto.mediaContent }}
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>