<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<!-- Main Header Birthday -->

<div class="main-header">
	<div class="content-bg-wrap bg-birthday"></div>
	<div class="container">
		<div class="row">
			<div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
				<div class="main-header-content">
					<h1>Never Miss a Birthday!</h1>
					<p style="font-size: 1.5rem;">Welcome to your friends' birthday page! 
						You will find all your friends' birthdays so you will never miss one again. 
						To remind you, the dates are saved automatically to your calendar.
						 Also, we added a "Create Event" button to organize a party or event on a specific date.
						</p>
				</div>
			</div>
		</div>
	</div>

	<img class="img-bottom" src="../../../assets/img/event-banner.png" alt="friends">
</div>

<!-- ... end Main Header Birthday -->



<!-- Main Content Birthday -->

<div class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">January</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar7-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Marina Valentine </a>
						<div class="birthday-date">January 16th, 1989</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar20-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Michael Maximoff</a>
						<div class="birthday-date">January 24th, 1972</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">February</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar21-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Rachel Howlett</a>
						<div class="birthday-date">February 4th, 1992</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar4-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Chris Greyson</a>
						<div class="birthday-date">February 7th, 1988</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar22-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Dave Marinara</a>
						<div class="birthday-date">February 12th, 1980</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->
				

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar23-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessica Stevens</a>
						<div class="birthday-date">February 18th, 1986</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar1-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mathilda Brinker</a>
						<div class="birthday-date">February 23rd, 1988</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar2-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Nicholas Grissom</a>
						<div class="birthday-date">February 27th, 1990</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">March</h6>
				</div>
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar10-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Elaine Dreyfuss</a>
						<div class="birthday-date">March 1st, 1984</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar67-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Carol Summers</a>
						<div class="birthday-date">March 1st, 1991</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar24-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Luke Scarred</a>
						<div class="birthday-date">March 6th, 1988</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar26-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Marina Polson</a>
						<div class="birthday-date">March 13th, 1984</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar27-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Sarah Knight</a>
						<div class="birthday-date">March 16th, 1994</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar28-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Claire Roberts</a>
						<div class="birthday-date">March 22nd, 1985</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar29-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jet Pepelu IV</a>
						<div class="birthday-date">March 27th, 1989</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">April</h6>
				</div>
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar6-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">James Summers</a>
						<div class="birthday-date">April 14th, 1985</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar30-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Marie Claire Stevens</a>
						<div class="birthday-date">April 21st, 1994</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar31-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Jessy Owens</a>
						<div class="birthday-date">April 28th, 1988</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">May</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">June</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">July</h6>
				</div>
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar32-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Lindsay Jeffson</a>
						<div class="birthday-date">July 5th, 1990</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar33-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mary Jane Stark</a>
						<div class="birthday-date">July 8th, 1987</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">August</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">September</h6>
				</div>
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar34-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Mark Taylor</a>
						<div class="birthday-date">September 14th, 1987</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar3-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Sarah Hetfield</a>
						<div class="birthday-date">September 17th, 1990</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar35-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Tony Stevens</a>
						<div class="birthday-date">September 20th, 1984</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar36-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Ann Marie Gibson</a>
						<div class="birthday-date">September 22nd, 1988</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">October</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">November</h6>
				</div>
			</div>
		</div>
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block">
				<div class="ui-block-title">
					<h6 class="title">December</h6>
				</div>
			</div>
		</div>

		<div class="col col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
			<div class="ui-block">

				
				<!-- Birthday Item -->
				
				<div class="birthday-item inline-items">
					<div class="author-thumb">
						<img src="../../../assets/img/avatar8-sm.jpg" alt="author">
					</div>
					<div class="birthday-author-name">
						<a href="javascript:void(0);" class="h6 author-name">Diana Jameson</a>
						<div class="birthday-date">December 17th, 1989</div>
					</div>
					<a href="20-CalendarAndEvents-MonthlyCalendar.html" class="btn btn-sm bg-blue">Create Event</a>
				</div>
				
				<!-- ... end Birthday Item -->

			</div>
		</div>

	</div>
</div>

<!-- ... end Main Content Birthday -->




<!-- Window-popup-CHAT for responsive min-width: 768px -->

<div class="ui-block popup-chat popup-chat-responsive" tabindex="-1" role="dialog" aria-labelledby="popup-chat-responsive" aria-hidden="true">

	<div class="modal-content">
		<div class="modal-header">
			<span class="icon-status online"></span>
			<h6 class="title" >Chat</h6>
			<div class="more">
				<svg class="olymp-three-dots-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg>
				<svg class="olymp-little-delete js-chat-open"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use></svg>
			</div>
		</div>
		<div class="modal-body">
			<div class="mCustomScrollbar">
				<ul class="notification-list chat-message chat-message-field">
					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m gonna be handling the gifts and Jake’s gonna get the drinks</span>
							<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
						</div>
					</li>

					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/author-page.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Don’t worry Mathilda!</span>
							<span class="chat-message-item">I already bought everything</span>
							<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
						</div>
					</li>

					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m gonna be handling the gifts and Jake’s gonna get the drinks</span>
							<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
						</div>
					</li>
				</ul>
			</div>

			<form class="need-validation">

		<div class="form-group">
			<textarea class="form-control" placeholder="Press enter to post..."></textarea>
			<div class="add-options-message">
				<a href="javascript:void(0);" class="options-message">
					<svg class="olymp-computer-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use></svg>
				</a>
				<div class="options-message smile-block">

					<svg class="olymp-happy-sticker-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon"></use></svg>

					<ul class="more-dropdown more-with-triangle triangle-bottom-right">
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat1.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat2.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat3.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat4.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat5.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat6.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat7.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat8.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat9.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat10.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat11.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat12.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat13.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat14.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat15.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat16.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat17.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat18.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat19.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat20.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat21.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat22.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat23.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat24.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat25.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat26.png" alt="icon">
							</a>
						</li>
						<li>
							<a href="javascript:void(0);">
								<img src="../../../assets/img/icon-chat27.png" alt="icon">
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>

	</form>
		</div>
	</div>

</div>

<!-- ... end Window-popup-CHAT for responsive min-width: 768px -->



