<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-conf-header></app-conf-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container  mb60">
    <div class="row">
        <div class="col col-xl-8 m-auto col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <article class="hentry blog-post single-post single-post-v1">
                    <h1 class="post-title">History of {{ confederationData.football_name }} </h1>
                    <div class="post-content-wrap">
                        <div class="post-content">
                            <p> Coming Soon</p>
                            
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>