<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div *ngIf="domElement==='view'" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">IFHHS Award Winners</h6>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <button (click)="onclick('add')" class="btn btn-primary float-right" style="font-size: 1.5rem;">
                        <!-- <i class="fa fa-plus" aria-hidden="true"></i> -->
                        Add</button>
                </div>
                <div class="ui-block-content">


                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col" class="id-width">ID</th>
                                <th scope="col">Full Name</th>
                                <th scope="col">Photos</th>
                                <th scope="col">Year</th>
                                <th scope="col" class="id-width">Edit</th>
                                <th scope="col" class="id-width">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let winner of pageOfItems | filter:searchText">
                                <td scope="row">{{winner.id}}</td>
                                <td>{{winner.name }}</td>
                                <td>
                                    <img src="https://api.football-family.com/img/awards/{{winner.award_id}}/{{winner.image}}"
                                        width="50px" class="avatar" alt="photo">
                                </td>
                                <td>{{winner.year}}</td>
                                <td routerLink="/edit-award-winner" [queryParams]="{'id': winner.id}"><i
                                        class="fas fa-edit fa-2x"></i></td>
                                <td (click)="openDeleteModal(winnerDeleteModal,winner)"><i
                                        class="fas fa-trash fa-2x"></i></td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="winners.length === 0" class="ui-block-title">
                        <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
                    </div>
                    <nav aria-label="Page navigation">
                        <ul class="pagination justify-content-center">
                            <div>
                                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>

</div>
<div *ngIf="domElement==='add'" class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Award Winner </h6>
                    <button (click)="onclick('view')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>

                        View</button>
                </div>
                <div class="ui-block-content">
                    <form [formGroup]="awardWinnerForm" (ngSubmit)="addAwardWinner()" class="content">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Award Banner <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="bannerId"
                                        (change)="onChangeBanner($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerId.errors }">
                                        <option *ngFor="let banner of banners" value="{{banner.id}}" selected>
                                            {{banner.name | slice:0:30}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.bannerId.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerId.errors.required">Banner is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Award <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="award" (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.award.errors }">
                                        <option *ngFor="let award of awards" value="{{award.id}}" selected>
                                            {{award.name | slice:0:30}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.award.errors" class="invalid-feedback">
                                        <div *ngIf="f.award.errors.required">Award is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">First Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="firstName" placeholder="Jhon"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                        <div *ngIf="f.firstName.errors.required">First name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Last Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="lastName" placeholder="Snow"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                        <div *ngIf="f.lastName.errors.required">Last Name is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Country <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="winnerCountry"
                                        placeholder="Saudi Arabia"
                                        [ngClass]="{ 'is-invalid': submitted && f.winnerCountry.errors }">
                                        <option *ngFor="let country of countryList" [value]="country.name">
                                            {{country.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.winnerCountry.errors" class="invalid-feedback">
                                        <div *ngIf="f.winnerCountry.errors.required">Country is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

                                <div class="form-group">
                                    <label class="control-label">Select Year of Award <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="yearOfAward" placeholder="2018"
                                        [ngClass]="{ 'is-invalid': submitted && f.yearOfAward.errors }">
                                        <option *ngFor="let year of years" value="{{year.name}}" selected>
                                            {{year.name}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.yearOfAward.errors" class="invalid-feedback">
                                        <div *ngIf="f.yearOfAward.errors.required">Year of Award is required *</div>
                                    </div>

                                </div>

                            </div>

                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">

                                    <div class="form-group">
                                        <label class="control-label">Date Of birth <span style="color: red;"> *
                                            </span></label>

                                        <input class="form-control" formControlName="dob" placeholder="02/03/1990"
                                            bsDatepicker [maxDate]="maxDate"
                                            [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">

                                        <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                            <div *ngIf="f.dob.errors.required">Date is required *</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Winner Avatar <span style="color: red;"> *
                                        </span></label>
                                    <input type="file" formControlName="winnerAvatar" accept="image/*"
                                        class="form-control" (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.winnerAvatar.errors }" />
                                    <div *ngIf=" submitted && f.winnerAvatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.winnerAvatar.errors.required ">Winner Avatar is required *</div>
                                    </div>

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" bsModal #winnerDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Award Winner </h5>
                <button type="button" class="close" (click)="winnerDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you sure you want to delete {{selectedWinner.name }}.?</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="winnerDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteWinner(winnerDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->