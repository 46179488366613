import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.css']
})
export class FriendsComponent implements OnInit {
  currentUser: any;
  userData: any = {};
  friends = [];
  selectFriend: any = {};
  searchText: string;
  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // this.getProfile();
    this.getMyFriends();

  }

  getProfile() {
    const data = {
      id: this.currentUser.success.user.id,
    };
    this.userService.getProfile(data).pipe(first()).subscribe(
      userData => this.userData = userData[0]
    );

  }

  getMyFriends() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getFriends(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
  }


  openFriendDeleteModal(modal: any, friend: any) {
    this.selectFriend = friend;
    modal.show();
  }

  deleteFriend(modal: any) {
    const data = {
      userId: this.currentUser.success.user.id,
      friendId: this.selectFriend.user.id,
      status: 'declined',
    };
    this.userService.confirmFriend(data).subscribe(
      deleted => {
        modal.hide();
        this.toastrService.success('Friend Deleted', 'Message!');
        this.getMyFriends();
      },
      error => {
        console.log(error);
        this.toastrService.error('Something went wrong please try again later!', 'Message!');
      }
    );
  }

  openchatModal(modal: any, friend: any) {
    this.selectFriend = friend;
    modal.show();
  }

  closeChatModal(modal: any) {
    modal.hide();
  }



}
