<!-- ... end Preloader style="background-color: lightblue;" -->
<div class="content-bg-wrap landing-page "></div>


<!-- Header Standard Landing  -->

<div class="header--standard header--standard-landing" id="header--standard">
    <div class="container">
        <div class="header--standard-wrap">

            <a href="javascript:void(0);" class="logo">
                <div class="img-wrap mt-5">
                    <img class="img-fluid" src="../../../assets/../../../assets/img/logo.webp" alt="Football Families">

                </div>
                <div class="title-block">
                    <h6 class="logo-title" style="font-size: 1.6rem;"> Football Families </h6>
                </div>
            </a>

            <!-- <a routerLink="/login" class="open-responsive-menu js-open-responsive-menu">
                <svg class="olymp-menu-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-menu-icon"></use>
                </svg>
            </a> -->

            <!-- <div class="nav nav-pills nav1 header-menu">
                <div class="mCustomScrollbar">
                    <ul>
                        <li class="nav-item">
                            <a routerLink="/login" class="nav-link">Home</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" data-hover="dropdown" data-toggle="dropdown" routerLink="/login"
                                role="button" aria-haspopup="false" aria-expanded="false" tabindex='1'>Profile</a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                <a class="dropdown-item" routerLink="/login">Newsfeed</a>
                                <a class="dropdown-item" routerLink="/login">Post Versions</a>
                            </div>
                        </li>
                        <li class="nav-item dropdown dropdown-has-megamenu">
                            <a routerLink="/login" class="nav-link dropdown-toggle" data-hover="dropdown" data-toggle="dropdown"
                                role="button" aria-haspopup="false" aria-expanded="false" tabindex='1'>Forums</a>
                            <div class="dropdown-menu megamenu">
                                <div class="row">
                                    <div class="col col-sm-3">
                                        <h6 class="column-tittle">Main Links</h6>
                                        <a class="dropdown-item" routerLink="/login">Profile Page<span
                                                class="tag-label bg-blue-light">new</span></a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                    </div>
                                    <div class="col col-sm-3">
                                        <h6 class="column-tittle">BuddyPress</h6>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page<span
                                                class="tag-label bg-primary">HOT!</span></a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                    </div>
                                    <div class="col col-sm-3">
                                        <h6 class="column-tittle">Corporate</h6>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                    </div>
                                    <div class="col col-sm-3">
                                        <h6 class="column-tittle">Forums</h6>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                        <a class="dropdown-item" routerLink="/login">Profile Page</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/login" class="nav-link">Terms & Conditions</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/login" class="nav-link">Events</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/login" class="nav-link">Privacy Policy</a>
                        </li>
                        <li class="close-responsive-menu js-close-responsive-menu">
                            <svg class="olymp-close-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                            </svg>
                        </li>
                        <li class="nav-item js-expanded-menu">
                            <a routerLink="/login" class="nav-link">
                                <svg class="olymp-menu-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-menu-icon"></use>
                                </svg>
                                <svg class="olymp-close-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="shoping-cart more">
                            <a routerLink="/login" class="nav-link">
                                <svg class="olymp-shopping-bag-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-shopping-bag-icon"></use>
                                </svg>
                                <span class="count-product">2</span>
                            </a>
                            <div class="more-dropdown shop-popup-cart">
                                <ul>
                                    <li class="cart-product-item">
                                        <div class="product-thumb">
                                            <img src="../../../assets/img/product1.png" alt="product">
                                        </div>
                                        <div class="product-content">
                                            <h6 class="title">White Enamel Mug</h6>
                                            <ul class="rait-stars">
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>

                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="far fa-star star-icon" aria-hidden="true"></i>
                                                </li>
                                            </ul>
                                            <div class="counter">x2</div>
                                        </div>
                                        <div class="product-price">$20</div>
                                        <div class="more">
                                            <svg class="olymp-little-delete">
                                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete">
                                                </use>
                                            </svg>
                                        </div>
                                    </li>
                                    <li class="cart-product-item">
                                        <div class="product-thumb">
                                            <img src="../../../assets/img/product2.png" alt="product">
                                        </div>
                                        <div class="product-content">
                                            <h6 class="title">Olympus Orange Shirt</h6>
                                            <ul class="rait-stars">
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>

                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="fa fa-star star-icon c-primary" aria-hidden="true"></i>
                                                </li>
                                                <li>
                                                    <i class="far fa-star star-icon" aria-hidden="true"></i>
                                                </li>
                                            </ul>
                                            <div class="counter">x1</div>
                                        </div>
                                        <div class="product-price">$40</div>
                                        <div class="more">
                                            <svg class="olymp-little-delete">
                                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete">
                                                </use>
                                            </svg>
                                        </div>
                                    </li>
                                </ul>

                                <div class="cart-subtotal">Cart Subtotal:<span>$80</span></div>

                                <div class="cart-btn-wrap">
                                    <a routerLink="/login" class="btn btn-primary btn-sm">Go to Your Cart</a>
                                    <a routerLink="/login" class="btn btn-purple btn-sm">Go to Checkout</a>
                                </div>
                            </div>
                        </li>

                        <li class="menu-search-item">
                            <a routerLink="/login" class="nav-link" data-toggle="modal" data-target="#main-popup-search">
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon"></use>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> -->
        </div>
    </div>
</div>

<!-- ... end Header Standard Landing  -->
<div class="header-spacer--standard"></div>

<div class="container">
    <div class="row display-flex">
        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="landing-content">
                <h2>Welcome to Football Families World</h2>
                <p style="font-size: 1.125rem;font-weight: 500;">
                    Come along with us to the biggest Football Platform in the World!
                </p>
                <a data-toggle="tab" routerLink="/register" role="tab" class="btn btn-md btn-border c-white register">Register
                    Now!</a>
            </div>
        </div>


        <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">

            <!-- <div *ngIf="errorFlag" class="alert alert-danger" role="alert">
                {{message}} <br> Please Login ! click <a routerLink="/login"> here </a>
            </div> -->

            <!-- Login-Registration Form  -->

            <div class="registration-login-form">

                <!-- Nav tabs -->
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item ">
                        <a class="nav-link" data-toggle="tab" routerLink="/login" role="tab">
                            <img src="../../../../assets/svg-icons/login.webp" alt="Register" style="width: 22px;">
                        </a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link active" data-toggle="tab" routerLink="/register" role="tab">
                            <img src="../../../../assets/svg-icons/register.webp" alt="Login" style="width: 22px;">
                        </a>
                    </li>

                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <div class="tab-pane active " id="home" role="tabpanel" data-mh="log-tab">
                        <div class="title h6">Register to Football Families</div>
                        <form [formGroup]="registerForm" (ngSubmit)="signUp()" class="content">
                            <!--  -->
                            <div *ngIf="show" class="row">
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                    <img src="../../../assets/img/note.gif" style="margin: 0px auto;" alt="Football Families">

                                    <!-- <p style="color: red; margin: 0 auto; font-size: 1rem;"> <strong>Note:</strong> you the annual winner of FF cash award name and details according to the passport</p> -->

                                </div>
                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">First & Middle
                                            Name <span style="color: red;"> * </span> </label>
                                        <input placeholder="" name="First Name" formControlName="fullName" pattern="[a-zA-Z ]*" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }">
                                        <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
                                            <div *ngIf="f.fullName.errors.required">First & middle Name is required
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Last Name <span
                                                style="color: red;"> * </span></label>
                                        <input placeholder="" name="First Name" formControlName="lastName" pattern="[a-zA-Z ]*" type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                        <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Gender <span style="color: red;"> * </span></label>
                                        <select formControlName="gender" name="gender" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gender.errors }">
                                            <option value="male" selected>Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                        <div *ngIf="submitted && f.gender.errors" class="invalid-feedback">
                                            <div *ngIf="f.gender.errors.required">Gender is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group label-floating">

                                        <div class="form-group label-floating">
                                            <label class="control-label custom-lable">Date Of birth <span style="color: red;"> *
                                                </span></label>

                                            <input placeholder="02/03/1990" bsDatepicker [minDate]="minDate" [maxDate]="maxDate" formControlName="dob" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dob.errors }">

                                            <div *ngIf="submitted && f.dob.errors" class="invalid-feedback">
                                                <div *ngIf="f.dob.errors.required">Date of birth is required</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group ">
                                        <!-- <label class="control-label" style="padding-right: 62px;padding-left: 100px;line-height: 2.0;">Mobile Number</label> -->
                                        <input style="padding-right: 70px;padding-left: 100px;line-height: 2.0; width: 100%; " placeholder="55 555 5555" ng2TelInput (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)" formControlName="mobileNumber"
                                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.mobileNumber.errors }">
                                        <div *ngIf="submitted && f.mobileNumber.errors" class="invalid-feedback">
                                            <div *ngIf="f.mobileNumber.errors.required"> Mobile Number is required</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">

                                    <div class="form-group">
                                        <label class="control-label custom-lable">Nationality <span
                                                style="color: red;"> * </span></label>

                                        <ng-autocomplete style="font-weight: 700;" formControlName="countryName" placeHolder="Country Name" [data]="countryList" [searchKeyword]="keyword" (selected)='selectEvent($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [ngClass]="{ 'is-invalid': submitted && f.countryName.errors }">
                                        </ng-autocomplete>

                                        <ng-template #itemTemplate let-country>
                                            <a [innerHTML]="country.name"></a>
                                        </ng-template>

                                        <ng-template #notFoundTemplate let-notFound>
                                            <div [innerHTML]="notFound"></div>
                                        </ng-template>
                                    </div>
                                    <small class="form-text text-muted error" *ngIf="!registerForm.controls['countryName'].valid && !isSubmitted">
                                        country is required
                                    </small>
                                </div>
                                <!-- <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group ">
                                        <label class="control-label" style="padding-right: 62px;padding-left: 100px;line-height: 2.0;">Mobile Number</label> 
                                        <input class="form-control" style="padding-right: 70px;padding-left: 100px;line-height: 2.0; width: 100%;" ng2TelInput (ng2TelOutput)="getNumber($event)" (intlTelInputObject)="telInputObject($event)" (countryChange)="onCountryChange($event)" formControlName="mobileNumber"
                                            placeholder="55 555 5555">
                                        <small class="form-text text-muted error" *ngIf="!registerForm.controls['mobileNumber'].valid && !isSubmitted">
                                            Mobile# is required: hints (9 numbers)
                                        </small>
                                    </div>
                                </div> -->
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable"> Email <span
                                                style="color: red;"> * </span></label>
                                        <input placeholder="Enter your Email" name="email" formControlName="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$" type="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Your Password
                                            <span style="color: red;"> * </span></label>
                                        <input class="form-control" name="password" placeholder="Enter Password" formControlName="password" type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                            <div *ngIf="f.password.errors.required">Password is required
                                            </div>
                                        </div>
                                        <small class="form-text text-muted error" *ngIf="!registerForm.controls['password'].valid && !isSubmitted">
                                            Hints:(min 8 chars) required
                                        </small>
                                    </div>
                                </div>
                                <div class="col col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">

                                    <div class="form-group label-floating">
                                        <label class="control-label custom-lable">Confirm Password
                                            <span style="color: red;"> * </span></label>
                                        <input class="form-control" name="password" placeholder="Confirm password" formControlName="confirmPassword" type="password" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }">
                                        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                            <div *ngIf="f.confirmPassword.errors.required">confirmPassword is required Hints:(min 8 chars) </div>
                                            <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                                        </div>
                                    </div>
                                </div>





                            </div>
                            <div class="row">
                                <div class="col col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                    <div class="remember">
                                        <!-- <div class="checkbox">
                                            <label class="customStyle">
                                                <input name="optionsCheckboxes" type="checkbox">
                                                I accept the <a routerLink="/terms-conditions">Terms and Conditions</a>
                                                of the football family
                                            </label>

                                        </div> -->
                                        <p class="customStyle">You have an account? <a data-toggle="tab" routerLink="/login" role="tab">Login
                                                Now!</a></p>
                                    </div>

                                    <!-- (click)="signUp()" -->
                                    <button [disabled]="loading" class="btn btn-primary full-width">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Register
                                    </button>
                                    <div *ngIf="errorFlag" class="alert alert-danger" role="alert">
                                        {{message}} <br> For Login ! click <a routerLink="/login"> here </a>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <!-- ... end Login-Registration Form  -->
        </div>
    </div>
</div>