<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="../../../assets/img/team-cover.webp" alt="nature">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="../../../assets/../../../assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag" class="img-responsive" style="width: 120px; height: 120px;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name}}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png" class="img-responsive float-left" alt="flag" style="width: 100%; height: auto;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLinkActive="active">{{'team-details.teams' | translate}}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" 
                            placeholder="{{'team-details.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
          
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div *ngFor="let team of clubs | filter:searchText" class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/team-profile" [queryParams]="{'id': countryData.id}"> <img src="../../../assets/../../../assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" style="width: 120px; height: 120px;" alt="photo"> </a>

                            </div>

                            <div class="author-content">
                                <a routerLink="/team-profile" [queryParams]="{'id': countryData.id}" class="h5 author-name"> {{team.name}} </a>
                                <div class="country">{{countryData.name}}</div>
                                <div class="friend-since" data-swiper-parallax="-100">
                                    <!-- <span>President Since:</span> -->
                                    <!-- <div class="h6" style="color: #fb9b30;">{{team.year}}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>