<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/voting/voting-dashboard-banner.webp"
                            class="img-responsive img-fluid" alt="cover" style="width: 100%;height: 100%;">
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a href="javascript:void(0);" routerLinkActive="active"> Voting Questions</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Edit IFFHS Voting Questions</h6>
                    <button (click)="goToDashboard()" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>
                        View Questions</button>
                </div>
                <div class="ui-block-content">
                    <form *ngIf="editQuestionForm" class="content" [formGroup]="editQuestionForm"
                        (ngSubmit)="editQuestion()">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Question Category <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionCategory"
                                        (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.questionCategory.errors }">
                                        <option value="Annual" selected>Annual</option>
                                        <option value="Events">Events</option>
                                    </select>
                                    <div *ngIf="submitted && f.questionCategory.errors" class="invalid-feedback">
                                        <div *ngIf="f.questionCategory.errors.required">Question Category is required *
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Question Modal <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionModal"
                                        (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.questionModal.errors }">
                                        <option *ngFor="let model of models" [ngValue]="model.model" selected>
                                            {{model.model}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.questionModal.errors" class="invalid-feedback">
                                        <div *ngIf="f.questionModal.errors.required">Question Modal is required *
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label"> Question Structure <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionStructure"
                                        (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.questionStructure.errors }">
                                        <option value="International" selected [ngValue]="question.structure">
                                            International</option>
                                        <option value="Local">Local</option>

                                    </select>
                                    <div *ngIf="submitted && f.questionStructure.errors" class="invalid-feedback">
                                        <div *ngIf="f.questionStructure.errors.required">Question Structure is required
                                            *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label"> Question <span style="color: red;"> *
                                        </span></label>
                                    <textarea type="text" formControlName="question" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.question.errors }"></textarea>
                                    <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                                        <div *ngIf="f.question.errors.required">Question is required *</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width"> Update
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="question.length === 0 " class="ui-block-title">
        <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
    </div>
</div>