import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: './assets/translate/screens/commons/header/', suffix: '.json' },
    { prefix: './assets/translate/screens/commons/presidents/', suffix: '.json' },
    { prefix: './assets/translate/screens/commons/profile-header/', suffix: '.json' },
    { prefix: './assets/translate/screens/home/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/executive-committee/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/honorable-committee/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/iffhs-members/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/president-consultant/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/president-assistants/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/ranking/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/associations/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/associations/fifa/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/associations/non-fifa/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-club/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-coaches/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-details/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-legends/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-players/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-referees/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/teams/team-team/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/media/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/media/media-details/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/fans/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/ranking/ranking-dashboard/add-ranking/', suffix: '.json' },
    { prefix: './assets/translate/screens/iffhs/ranking/ranking-dashboard/edit-ranking/', suffix: '.json' },
    { prefix: './assets/translate/screens/profile/', suffix: '.json' },
    { prefix: './assets/translate/screens/profile/about/', suffix: '.json' },
    { prefix: './assets/translate/screens/profile/change-password/', suffix: '.json' },
    { prefix: './assets/translate/screens/profile/change-message/', suffix: '.json' },
  ]);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    HttpClientModule,
    TranslateModule
  ]
})
export class LangTranslateModule { }
