<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<!-- Main Header Badges -->

<div class="main-header">
	<div class="content-bg-wrap bg-badges"></div>
	<div class="container">
		<div class="row">
			<div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
				<div class="main-header-content">
					<h1>Collect your Badges!</h1>
					<p style="font-size: 1.5rem;">Welcome to your badge collection! Here you’ll find all the badges you can unlock to show on your profile.
                        Learn how to achieve the goal to acquire them and collect them all. Some have leveled tiers and others don’t.
                         You can challenge your friends to see who gets more and let the fun begin!</p>

				</div>
			</div>
		</div>
	</div>

	<img class="img-bottom" src="../../../assets/img/group-bottom.png" alt="friends">
</div>

<!-- ... end Main Header Badges -->



<!-- Main Content Badges -->

<div class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge1.png" alt="author">
						<div class="label-avatar bg-primary">2</div>
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Olympian User</a>
						<div class="birthday-date">Congratulations! You have been in the Olympus community for 2 years.
						</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 76%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge2.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Favourite Creator</a>
						<div class="birthday-date">You created a favourite page.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge3.png" alt="author">
						<div class="label-avatar bg-blue">4</div>
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Friendly User</a>
						<div class="birthday-date">You have more than 80 friends. Next Tier: 150 Friends. </div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 52%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge4.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Professional Photographer</a>
						<div class="birthday-date">You have uploaded more than 500 images to your profile.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge5.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Expert Filmaker</a>
						<div class="birthday-date">You have uploaded more than 80 videos to your profile.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 70%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge6.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Mightier Than The Sword</a>
						<div class="birthday-date">You have written more than 50 blog post entries.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 23%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge7.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Universe Explorer</a>
						<div class="birthday-date">You have visited more than 1000 different user profiles.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge8.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Nothing to Hide</a>
						<div class="birthday-date">You have completed all your profile fields.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge9.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Messaging Master</a>
						<div class="birthday-date">You have messaged with at least 20 different people.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 69%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge10.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Musical Sharer</a>
						<div class="birthday-date">You have linked your Spotify account to share your playlist.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 33%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge11.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Superliked Hero</a>
						<div class="birthday-date">You have collected more than 100 likes in one post.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge12.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Strongly Caffeinated </a>
						<div class="birthday-date">You have written more than 1000 posts.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 65%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge13.png" alt="author">
						<div class="label-avatar bg-breez">2</div>
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Events Promoter</a>
						<div class="birthday-date">You have created more than 25 public or private events. Next Tier:
							50.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge14.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Friendship Cultivator</a>
						<div class="birthday-date">You have tagged friends on 200 different posts.</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 80%"></span>
						</div>
					</div>

				</div>

			</div>

			<div class="ui-block">


				<div class="birthday-item inline-items badges">
					<div class="author-thumb">
						<img src="../../../assets/img/badge15.png" alt="author">
					</div>
					<div class="birthday-author-name">
						<a routerLink="/members"class="h6 author-name">Phantom Profile</a>
						<div class="birthday-date">You haven’t posted anything on your profile for more than 1 month.
						</div>
					</div>

					<div class="skills-item">
						<div class="skills-item-meter">
							<span class="skills-item-meter-active" style="width: 100%"></span>
						</div>
					</div>

				</div>

			</div>

		</div>
	</div>
</div>

<!-- ... end Main Content Badges -->




<!-- Window-popup-CHAT for responsive min-width: 768px -->

<div class="ui-block popup-chat popup-chat-responsive" tabindex="-1" role="dialog"
	aria-labelledby="popup-chat-responsive" aria-hidden="true">

	<div class="modal-content">
		<div class="modal-header">
			<span class="icon-status online"></span>
			<h6 class="title">Chat</h6>
			<div class="more">
				<svg class="olymp-three-dots-icon">
					<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
				</svg>
				<svg class="olymp-little-delete js-chat-open">
					<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use>
				</svg>
			</div>
		</div>
		<div class="modal-body">
			<div class="mCustomScrollbar">
				<ul class="notification-list chat-message chat-message-field">
					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
								gonna be handling the gifts and Jake’s gonna get the drinks</span>
							<span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
						</div>
					</li>

					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/author-page.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Don’t worry Mathilda!</span>
							<span class="chat-message-item">I already bought everything</span>
							<span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
						</div>
					</li>

					<li>
						<div class="author-thumb">
							<img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
						</div>
						<div class="notification-event">
							<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
								gonna be handling the gifts and Jake’s gonna get the drinks</span>
							<span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
						</div>
					</li>
				</ul>
			</div>

			<form class="need-validation">

				<div class="form-group">
					<textarea class="form-control" placeholder="Press enter to post..."></textarea>
					<div class="add-options-message">
						<a routerLink="/members"class="options-message">
							<svg class="olymp-computer-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
							</svg>
						</a>
						<div class="options-message smile-block">

							<svg class="olymp-happy-sticker-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon">
								</use>
							</svg>

							<ul class="more-dropdown more-with-triangle triangle-bottom-right">
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat1.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat2.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat3.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat4.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat5.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat6.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat7.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat8.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat9.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat10.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat11.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat12.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat13.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat14.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat15.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat16.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat17.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat18.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat19.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat20.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat21.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat22.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat23.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat24.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat25.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat26.png" alt="icon">
									</a>
								</li>
								<li>
									<a  href="javascript:void(0);">
										<img src="../../../assets/img/icon-chat27.png" alt="icon">
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</form>
		</div>
	</div>

</div>

<!-- ... end Window-popup-CHAT for responsive min-width: 768px -->
