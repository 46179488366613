<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-club-header></app-club-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<!-- ********************************************************************* Timeline ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Timeline:
                    </div>
                    <div class="h6 label-title text-right">
                        <a routerLink="/apply" [queryParams]="{'id': clubData.id}">
                            Who Want to be Member?
                            <img src="assets/img/unfollow.webp" class="img-responsive img-fluid" alt="follow" style="width: 44px;
                            margin: -10px 0px; ">
                        </a>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

            </div>

        </div>
        <div *ngIf="news.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>
<div *ngIf="news.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="label-title">News </h6>
                </div>
                <ul class="widget w-friend-pages-added notification-list friend-requests">
                    <li *ngFor="let _new of news | slice:0:4" (click)="onClick(_new)" class="inline-items card">
                        <div (click)="onClick(_new)" class="notification-event">
                            <a class="h6 news-list">{{_new.newsTitle | titlecase}}</a>
                        </div>
                        <span class="notification-icon" data-toggle="tooltip" data-placement="top"
                            data-original-title="read more">
                            <a class="read" href="javascript:void(0);" (click)="openModal(newsModal,_new)">
                                <span class="read"> Read more</span>
                            </a>
                        </span>
                    </li>
                    <li *ngIf="news.length > 4" class="inline-items card">
                        <button (click)="moveToStructure()" class="btn btn-primary read btn-block">All News</button>
                    </li>

                </ul>

            </div>
        </div>
        <div class="col col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
            <div id="carouselExampleIndicators" class="carousel" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let _new of news" class="carousel-item active">
                        <img *ngIf="!slider.newsAttachment" (click)="openModal(newsModal,_new)" class="d-block w-100"
                            src="{{_new.newsAttachment}}" alt="News"
                            onerror="this.onerror=null;this.src='assets/img/clubs.webp';"
                            style="width: 800px; height: 400px;">
                        <img *ngIf="slider.newsAttachment" (click)="openModal(newsModal,slider)" class="d-block w-100"
                            src="{{slider.newsAttachment}}" alt="News"
                            onerror="this.onerror=null;this.src='assets/img/clubs.webp';"
                            style="width: 800px; height: 400px;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Timeline ************************************************* -->
<!-- ********************************************************************* Blogs ************************************************* -->
<div class="container mt-2">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Blogs </div>
                </div>
            </div>

            <div *ngIf="blogs.length === 0" class="ui-block-title">
                <h6 class="display-message"> No Data found! </h6>
            </div>
        </div>

    </div>
</div>
<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let blog of blogs | filter:searchText" class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="ui-block">
                    <article (click)="openModal(newsModal,blog)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{blog.newsAttachment}}" width="370" height="260" alt="Photo"
                                onerror="this.onerror=null;this.src='assets/img/clubs.webp';"
                                style="width: 370px; height: 260px;">
                        </div>
                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{blog.newsTitle | titlecase}} </a>

                            <div class="author-date">
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{blog.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ********************************************************************* /Blogs ************************************************* -->
<!-- ********************************************************************* News ************************************************* -->
<div #newsSection id="newsSection" *ngIf="news.length > 4" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> News </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section *ngIf="news.length > 4" class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let item of pageOfItems |filter:searchText"
                class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div style="background-color: transparent;" class="ui-block">
                    <article (click)="openModal(newsModal,item)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{item.newsAttachment}}" width="370" height="260" alt="Photo"
                                onerror="this.onerror=null;this.src='assets/img/clubs.webp';"
                                style="width: 100%; height: 260px;">
                        </div>
                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{item.newsTitle}} </a>
                            <div class="author-date">
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{item.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <div>
                    <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                </div>
            </ul>
        </nav>
    </div>
</section>
<!-- ********************************************************************* /News ************************************************* -->
<!-- ********************************************************************* Club Teams ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Club Teams </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let team of teams | filter:searchText" class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div (click)="openTeamModal(template , team)" class="author-thumb">
                                <img src="{{team.src}}" style="width: 120px; height: 120px;" alt="photo">
                            </div>
                            <div class="author-content">
                                <p (click)="openTeamModal(template , team)" class="h5 author-name"> {{team.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Club Teams ************************************************* -->
<!-- ********************************************************************* Matches ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Matches </div>
                </div>
            </div>
        </div>
        <div *ngIf="matches.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Match found! </h6>
        </div>
    </div>
</div>
<div *ngIf="matches.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Host Team</th>
                        <th scope="col">Host Score</th>
                        <th scope="col">Visiting Team</th>
                        <th scope="col">Visiting Score</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let match of matches">
                        <th scope="row">1</th>
                        <td>{{match.name}}</td>
                        <td>{{match.hostScore}}</td>
                        <td>{{match.country.name}}</td>
                        <td>{{match.visitingScore}}</td>
                        <td>{{match.created_at | date: 'mediumDate'}}</td>
                        <td>{{match.status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Matches ************************************************* -->
<!-- ********************************************************************* Players ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Players </div>
                    <!-- <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>

                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}
                                </option>

                            </select>
                        </fieldset>
                    </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="players.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Players found! </h6>

        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let player of players | filter:searchText; let i =index"
            class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div style="background-color: transparent;" class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}">
                                    <img src="{{player.avatar}}" alt="Avatar"
                                        onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';"
                                        style="width: 120px; height: 120px;">
                                </a>
                            </div>

                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}"
                                    class="h5 author-name"> {{player.fullName | titlecase}}</a>

                                <div class="friend-since" data-swiper-parallax="-100">

                                    <span class="friend-since"><strong class="friend-since">
                                            {{player.class | titlecase}} </strong></span><br>
                                    <span class="friend-since"><strong class="friend-since">
                                            {{player.playerPosition | titlecase}} </strong></span> <br>
                                    <!-- <span class="friend-since"> <strong class="friend-since">
                                            {{age[i]}} Years </strong></span> -->
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Players ************************************************* -->
<!-- ********************************************************************* Coaches ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Coaches </div>
                    <!-- <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div> -->
                </div>
            </div>
        </div>
        <div *ngIf="coaches.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Coaches found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let coach of coaches  | filter:searchText"
            class="col col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}">
                                    <img src="{{coach.playerAvatar}}" alt="Avatar" style="width: 120px; height: 120px;">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}"
                                    class="h5 author-name"> {{coach.playerName | titlecase}} </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Coaches ************************************************* -->
<!-- ********************************************************************* Suggested Team ************************************************* -->
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Suggested Team </div>
                </div>
            </div>
        </div>
        <div *ngIf="suggestedTeam.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Team found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let player of suggestedTeam  | filter:searchText"
            class="col col-xl-2 col-lg-2 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id}">
                                    <img src="{{player.playerAvatar}}" alt="Avatar" style="width: 120px; height: 120px;"
                                        onerror="this.onerror=null; this.src='assets/img/author-main1.webp';"
                                        class="img-responsive img-fluid">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id}"
                                    class="h5 author-name"> {{player.playerName | titlecase}} </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ********************************************************************* /Suggested Team ************************************************* -->
<ng-template #template>
    <div class="modal-header">
        <h5 class="modal-title pull-left "> {{ selectTeam.name }} of {{clubData.name}}</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <!-- routerLink="/national-team"
                                        [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category':
                                        'Men'}" -->
                                        <a class="h5 author-name" (click)="modalRef.hide()"> Men
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <a class="h5 author-name" (click)="modalRef.hide()"> Women
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="modal fade" bsModal #newsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="private-event"
    aria-hidden="true">
    <div class="modal-dialog window-popup event-private-public private-event" role="document">
        <div class="modal-content">
            <a (click)="closeModal(newsModal)" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-body">
                <article class="hentry post has-post-thumbnail thumb-full-width private-event">

                    <div class="private-event-head inline-items">
                        <div class="author-date">
                            <a class="h3 event-title"
                                href="javascript:void(0);">{{selectNews.newsTitle | titlecase}}</a>
                            <div class="event__date">
                                <time class="published" datetime="2017-03-24T18:18">
                                    {{selectNews.created_at | date:'mediumDate'}}
                                </time>
                            </div>
                        </div>
                    </div>
                    <div class="post-thumb">
                        <img src="{{selectNews.newsAttachment}}" alt="photo"
                            onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';">
                    </div>
                    <div class="row">
                        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="post__author author vcard inline-items">
                                <h3 class="head"> Description:</h3>
                            </div>
                            <p>
                                {{selectNews.newsContent | titlecase}}
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>