import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { PostService } from 'src/app/data-services/post.service';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {
  id: number;
  role: string;
  friendData: any = {};
  friendPosts: any = [];
  currentUser: any;
  today = new Date();
  // Posts
  selectPostId: number;
  // Comments
  commentForm: FormGroup;
  selectComment: any = {};
  comments: any;
  cloading: boolean;
  public show: number;
  public buttonName: any = 'Show';
  isLoaderVisible = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private postService: PostService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.role = params['role'];
    });
    this.friendDetails();
    this.getFrindPosts();

    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required],
      post_id: '',
    });

  }

  friendDetails() {
    this.isLoaderVisible = true;
    const data = {
      id: this.id,
      role: this.role,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );
    this.isLoaderVisible = false;

  }

  getFrindPosts() {
    this.isLoaderVisible = true;
    const data = {
      user_id: this.id,
    };
    this.postService.getAll(data).pipe(first()).subscribe(
      posts => {
        this.friendPosts = posts;
        this.isLoaderVisible = false;

        for (const post of this.friendPosts) {
          // post.type = post.type.split('/')[0];
        }
      }

    );
  }
  ///////////////////////////////////////// Comments Codes

  doComment(pId: number) {
    this.cloading = true;
    if (this.commentForm.invalid) {
      this.cloading = false;
      return;
    }

    this.commentForm.value.post_id = pId;
    this.commentForm.value.commenter_id = this.currentUser.success.user.id;
    this.postService.comment(this.commentForm.value).pipe(first()).subscribe(
      succuss => {
        this.cloading = false;
        this.getFrindPosts();
        this.showComments(pId);
      },
      error => {
        console.log(error);
        this.cloading = false;
      }
    );

    this.commentForm.reset();

  }

  openCommentDeleteModal(modal: any, comment: any, postId: number) {
    this.selectComment = comment;
    this.selectPostId = postId;
    modal.show();
  }

  deleteComment(modal: any, postId: number) {
    const data = {
      commentId: this.selectComment.id,
      post_id: this.selectPostId,
    };
    this.postService.deleteComment(data).subscribe(
      comments => {
        modal.hide();
        this.showComments(postId);
      },
      error => {
        console.log(error);
      }
    );
  }

  toggle(Id: number) {
    if (this.show !== Id) {
      this.show = Id;
    } else {
      this.show = 0;
    }

    this.showComments(Id);

    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }

  }

  showComments(Id: number) {
    const data = {
      postId: Id,
    };
    this.postService.getAllComments(data).pipe(first()).subscribe(comments => this.comments = comments);

  }
  ///////////////////////////////////////// Likes/ Unlike Codes

  likeUnlike(id: number) {
    const data = {
      post_id: id,
      user_id: this.currentUser.success.user.id,

    };
    this.postService.likeUnlike(data).pipe(first()).subscribe(
      succuss => {
        console.log('succusfully  ');
        this.getFrindPosts();
      },
      error => {
        console.log(' Like ');
      }
    );
  }


}
