<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 label-title">Players </div> -->
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let league of leagues" value="{{league.name}}">{{league.name}}
                                </option>

                            </select>
                        </fieldset>
                    </div>

                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}
                                </option>

                            </select>
                        </fieldset>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
        <div *ngIf="players.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let player of players | filter:searchText; let i =index"
            class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}">
                                    <img src="{{player.avatar}}" alt="Avatar" style="width: 120px; height: 120px;"
                                        onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';"
                                        style="width: 120px; height: 120px;">
                                </a>
                            </div>

                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}"
                                    class="h5 author-name"> {{player.fullName | titlecase}}</a>

                                <div class="friend-since" data-swiper-parallax="-100">

                                    <span class="friend-since"><strong class="friend-since">
                                            {{player.class | titlecase}} </strong></span><br>
                                    <span class="friend-since"><strong class="friend-since">
                                            {{player.playerPosition | titlecase}} </strong></span> <br>
                                    <span class="friend-since"> <strong class="friend-since">
                                            {{age[i]}} Years </strong></span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <div>
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </ul>
    </nav> -->
</div>