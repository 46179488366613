import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-honorable-committee',
  templateUrl: './honorable-committee.component.html',
  styleUrls: ['./honorable-committee.component.css']
})
export class HonorableCommitteeComponent implements OnInit {
  language = '';
  direction;
  defaultLanguage;

  imageSrc = {
    src: '../../../assets/img/committee/edward.webp',
    id: '115',
    name: 'Edward Simmons',
    position: 'Executive Committee',
    nationality: ' Australia',
    flag: '../../../assets/svg-icons/1x1/au.svg'
  };
  images = [
    {
      src: '../../../assets/img/committee/edward.webp',
      id: '115',
      name: 'Edward Simmons',
      position: 'Executive Committee',
      nationality: ' Australia',
      flag: '../../../assets/svg-icons/1x1/au.svg'
    },
    {
      src: '../../../assets/img/committee/carlos.webp',
      id: '120',
      name: 'Carlos F. Ramirez',
      position: 'Executive Committee',
      nationality: ' Mexico',
      flag: '../../../assets/svg-icons/1x1/mx.svg'
    },
  ];
  constructor(
    private messageService: MessageService) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    }); }


  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }

  onClick(imageNameObject) {
    this.imageSrc = imageNameObject;
  }

}
