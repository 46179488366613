<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div *ngIf="visible" class="container">
    <div class="row">
        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="ui-block">
                <div class="friend-item">
                    <div (click)="onClick('fifa')" class="card">
                        <img src="../../../assets/img/fifa-2.webp" class="img-responsive card-img" style="width: 100%; height: auto;"> </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="ui-block">
                <div class="friend-item">
                    <div (click)="onClick('non-fifa')" class="card">
                        <img src="../../../assets/img/non-fifa-4.webp" class="img-responsive card-img" style="width: 100%; height: auto;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!visible" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title">
                        {{ title | titlecase }} Countries </div>

                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="!visible" class="container">
    <div class="row">
        <div *ngFor="let country of countries | filter:searchText" class="col col-xl-4 col-lg-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="birthday-item inline-items">
                    <div class="author-thumb">
                        <a routerLink="/award-fans-details" [queryParams]="{'id': country.id, 'name':country.name}">
                            <img src="../../../assets/svg-icons/1x1/{{country.code | lowercase}}.svg" alt="flag" style="width: 55px; height: 55px;"> </a>
                    </div>
                    <div class="birthday-author-name" style="margin-left: 0.5rem;">
                        <a routerLink="/award-fans-details" [queryParams]="{'id': country.id, 'name':country.name}" class="h6 author-name">{{ country.name | titlecase}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>