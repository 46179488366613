<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="../../../../../assets/img/awards/award-banner.jpg" class="img-responsive img-fluid" alt="cover" style="width: 100%;height: 100%;">

                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/add-award-banner" routerLinkActive="active"> Category</a>
                                    </li>
                                    <li>
                                        <a routerLink="/add-award" routerLinkActive="active"> Award</a>
                                    </li>
                                    <li>
                                        <a routerLink="/add-award-winner" routerLinkActive="active">Winner</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>