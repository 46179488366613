import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'app-national-team',
  templateUrl: './national-team.component.html',
  styleUrls: ['./national-team.component.css']
})
export class NationalTeamComponent implements OnInit {
  @ViewChild('newsSection') public newsSection: ElementRef;
  id: number;
  teamClass: string;
  category: string;
  countryData: any = {};
  clubData: any = {};
  matches = [];
  players = [];
  country: any = {};
  coaches = [];
  searchText: any;
  currentUser: any;
  status: boolean;
  isLoaderVisible = false;
  selectNews: any = {};
  news = [];
  slider: any = {};
  blogs = [];
  items = [];
  pageOfItems: Array<any>;
  age = [];
  categories = [
    {
      id: '1',
      name: 'All Category'
    },
    {
      id: '2',
      name: 'By Clubs'
    },
    {
      id: '3',
      name: 'By Position'
    },
    {
      id: '4',
      name: 'By Ranking'
    },
    {
      id: '5',
      name: 'By Age'
    },
    {
      id: '6',
      name: 'By Nationality'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.teamClass = params['name'];
      this.category = params['category'];
    });
    this.getNationalTeam();
    this.getNews();
    this.getMatches();
    this.getPlayers();
    this.getCoaches();
  }
  getNationalTeam() {
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getNationalTeam(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    console.log(this.countryData);
  }
  getNews() {
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getNews(data).subscribe((res: any) => {
      this.news = res.news;
      this.blogs = res.blogs;
      this.items = this.news;
      this.isLoaderVisible = false;
    });
  }



  onClick(_new) {
    this.slider = _new;
  }
  openModal(modal: any, _new: any) {
    this.selectNews = _new;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }
  getMatches() {
    this.isLoaderVisible = true;
    const data = {
      hostTeam: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getMatches(data).subscribe((res: any) => {
      this.matches = res.matches;
      this.country = res.country;
      this.isLoaderVisible = false;
    });
  }
  getPlayers() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getNationalTeam(data).subscribe((res: any) => {
      this.players = res.players;
      this.isLoaderVisible = false;
      for (let i = 0; i < this.players.length; i++) {
        const timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }


    });
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getPlayers(data).subscribe((res: any) => {
      this.players = res.players;
      this.items = this.players;
      this.isLoaderVisible = false;
      for (let i = 0; i < this.players.length; i++) {
        var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
        this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      }
    });
  }
  getCoaches() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getCoaches(data).subscribe((res: any) => {
      this.coaches = res.coaches;
      this.isLoaderVisible = false;
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    this.pageOfItems = pageOfItems;
  }
  moveToStructure(): void {
    this.newsSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }


}
