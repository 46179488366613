import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  url = 'https://api.football-family.com/api/v1/';

  constructor(private http: HttpClient) { }

  getAllGroups(data: any) {
    return this.http.post(this.url + 'showGroups', data);
  }

  createGroup(data: any) {
    return this.http.post(this.url + 'createGroup', data);
  }



}
