import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.css']
})
export class VotingComponent implements OnInit {
  votingForm: FormGroup;
  annualQuestions = [];
  eventsQuestions = [];
  question = [];
  questions: any;
  players = [];
  answers = [];
  keyword = 'fullName';
  isSubmitted: boolean;
  currentUser: any;
  annualVisible = true;
  isLoaderVisible = false;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,

  ) { }

  ngOnInit() {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getVotingQuestions();
    this.votingForm = this.formBuilder.group({
      answer: ['', Validators.required],
    });
  }

  selectEvent(item, index, question) {
    this.answers[index] = item.fullName;
    this.question[index] = question.id;
  }

  onChangeSearch(val: string) {
    // console.log(val);
  }

  onFocused(e) {
    // console.log(e);
  }


  getVotingQuestions() {
    this.isLoaderVisible = true;
    this.iffhsService.getVotingQuestions(null).subscribe((res: any) => {
      this.annualQuestions = res.annual;
      this.eventsQuestions = res.events;
      this.players = res.players;
      this.isLoaderVisible = false;
    });
  }
  castVote() {
    this.isLoaderVisible = true;
    const data = {
      userId: this.currentUser.success.user.id,
      answers: this.answers,

    };
    this.iffhsService.castVoting(data).subscribe((res: any) => {
      this.isLoaderVisible = false;
    });

  }
  onClick(question: string) {
    if (question === 'annualQuestion') {
      this.annualVisible = false;
      this.questions = this.annualQuestions;
    } else if (question === 'eventQuestion') {
      this.annualVisible = false;
      this.questions = this.eventsQuestions;
    }
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getVotingQuestions(data).subscribe((res: any) => {
      this.questions = res.questions;
      this.isLoaderVisible = false;
    });
  }
}
