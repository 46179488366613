<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div *ngIf="domElement==='view'" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">IFHHS Awards</h6>

                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <button (click)="onclick('add')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-plus" aria-hidden="true"></i>
                        Add</button>
                </div>

                <div class="ui-block-content">


                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="id-width">ID</th>
                                <th>Name</th>
                                <th>Photos</th>
                                <th class="id-width">Edit</th>
                                <th class="id-width">Delete</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr *ngFor="let award of pageOfItems | filter:searchText">
                                <td>{{award.id}}</td>
                                <td>{{award.name | slice:0:110}}</td>
                                <td>
                                    <img src="https://api.football-family.com/img/awards/{{award.image}}" width="50px"
                                        class="avatar" alt="photo">
                                </td>

                                <td routerLink="/edit-award" [queryParams]="{'id': award.id}"><i
                                        class="fas fa-edit fa-2x"></i></td>
                                <td (click)="openDeleteModal(awardDeleteModal,award)"><i class="fas fa-trash fa-2x"></i>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div *ngIf="awards.length === 0" class="ui-block-title">
                    <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
                </div>
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center">
                        <div>
                            <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                        </div>
                    </ul>
                </nav>
            </div>
        </div>

    </div>

</div>



<div *ngIf="domElement==='add' || domElement==='edit'" class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add IFFHS Award </h6>
                    <button (click)="onclick('view')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>

                        View</button>
                </div>
                <div class="ui-block-content">
                    <form [formGroup]="addAwardForm" (ngSubmit)="addAward()" class="content">
                        <div class="row">
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Award Banner <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="bannerId" (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerId.errors }">
                                        <option *ngFor="let banner of banners" value="{{banner.id}}" selected>
                                            {{banner.name | slice:0:90}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.bannerId.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerId.errors.required">Banner is required *</div>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <label class="control-label"> Award Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="awardName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.awardName.errors }" />
                                    <div *ngIf="submitted && f.awardName.errors" class="invalid-feedback">
                                        <div *ngIf="f.awardName.errors.required">Name is required *</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Photo ( Select only Image ) <span style="color: red;">
                                            *
                                        </span></label>
                                    <input type="file" formControlName="awardPhoto" accept="image/*"
                                        class="form-control" (change)="onImageSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.awardPhoto.errors }" />
                                    <div *ngIf=" submitted && f.awardPhoto.errors " class="invalid-feedback ">
                                        <div *ngIf="f.awardPhoto.errors.required ">Award Photo is required *</div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Delete Modal -->

<div class="modal fade" bsModal #awardDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Award </h5>
                <button type="button" class="close" (click)="awardDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you sure you want to delete {{selectedAward.name }}.?</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="awardDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteAward(awardDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->