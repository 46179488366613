import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../../data-services/authentication.service';
import { UserService } from '../../../data-services/user.service';
import { first } from 'rxjs/operators';
import { AlertService } from '../../../data-services/alert.service';
import { UserRootObject } from '../../../_models/user';
import { MustMatch } from '../../../_Helpers/must-match.validator';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  changeForm: FormGroup;
  message: string;
  visible: boolean;
  submitted: boolean;
  errorFlag: boolean;
  loading: boolean;
  updateForm: any;
  currentUser: any;
  confirmPassword: any;
  errorMessage = '';
  isSubmitted = true;

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      }); 
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.changeForm = this.formBuilder.group({
      id: this.currentUser.success.user.id,
      currentPass: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(50)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8), Validators.maxLength(50)])],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;

  }


  get f() { return this.changeForm.controls; }
  changePassword() {
    this.submitted = true;
    this.errorFlag = false;
    this.loading = true;
    const data = {
      id: this.currentUser.success.user.id,
      currentPass: this.f.currentPass.value
    };
    this.isSubmitted = true;

    this.userService.validatePassword(data).pipe(first()).subscribe((Data: UserRootObject) => {

      if (Data) {
        const changeData = {
          id: this.currentUser.success.user.id,
          newPassword: this.f.password.value
        };
        if (this.f.password.value === this.f.confirmPassword.value) {
          this.userService.changePassword(changeData).pipe(first()).subscribe(() => {
            this.errorMessage = 'Password updated successfully';
            this.loading = false;
          },
            (error) => {
              console.log('error');
              this.loading = false;
            }
          );
        } else {
          this.errorMessage = 'Your new password do not matching';
          this.loading = false;
        }

      } else {
        this.errorMessage = 'Please Enter your Valid Current password';
        this.loading = false;
      }
    }
    );

  }

}



