import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-national-team-photo',
  templateUrl: './national-team-photo.component.html',
  styleUrls: ['./national-team-photo.component.css']
})
export class NationalTeamPhotoComponent implements OnInit {

  id: number;
  teamClass: string;
  category: string;
  countryData: any = {};
  currentUser: any;
  searchText: any;
  status: boolean;
  photos = [];
  videos = [];
  selectedPhoto: any = {};
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.teamClass = params['name'];
      this.category = params['category'];
    });
    this.getCountryDetails();
    this.getPhotos();
  }
  getCountryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    console.log(this.countryData);
  }
  getPhotos() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      teamClass: this.teamClass,
      category: this.category,
    };
    this.iffhsService.getMedia(data).subscribe((res: any) => {
      this.photos = res.photos;
      this.videos = res.videos;
      this.isLoaderVisible = false;
    });
  }
  // getVideos() {
  //   const data = {
  //     countryId: this.id,
  //     teamClass: this.teamClass,
  //   };
  //   this.iffhsService.getMedia(data).subscribe((res: any) => {
  //     this.videos = res.videos;
  //     this.isLoaderVisible = false;
  //     console.log(this.videos);
  //   });
  // }
  openModal(modal: any, photo: any) {
    this.selectedPhoto = photo;
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }

}
