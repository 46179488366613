<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div *ngIf="id == 63 || id == 64 || id == 65">
    <app-award-fans></app-award-fans>
</div>

<div *ngIf="visible" class="container mt-2">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div style="border: none;" class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <img src="https://api.football-family.com/img/banners/{{bannerId}}.png" alt="banner">
                </div>
                <div style="border: none;" class="ui-block-title">
                    <div class="h6 label-title text-center"> {{title }}</div>
                </div>
                <div *ngIf="awards.length > 0" class="ui-block-title">
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>

                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let year of years" value="{{year.name}}">{{year.name}}
                                </option>
                            </select>
                        </fieldset>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>

                </div>

            </div>
            <div *ngIf="awards.length === 0" class="ui-block-title">
                <h6 *ngIf="!isLoaderVisible" class="display-message"> Coming Soon End of Season 2020 </h6>
            </div>
        </div>


    </div>
</div>
<section *ngIf="visible" class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let award of awards | filter:searchText" class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">

                <div class="post-thumb">
                    <img src="https://api.football-family.com/img/awards/winners/{{award.award_id}}/{{award.year}}.jpg" onerror="this.onerror=null;this.src='assets/img/author-main1.webp';" alt="Photo" style="width: 100%; height: auto;">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <td scope="col">Name:</td>
                                <td scope="col"> {{award.name}} </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="col">Country:</td>
                                <td>{{award.country_id}}
                                    <!-- <span> <img src="../../../assets/svg-icons/1x1/br.svg" style="width: 30px; height: 20px; float: left;margin: 0px 12px;"></span> -->
                                </td>
                            </tr>
                            <tr>
                                <td scope="col">Date of Birth:</td>
                                <td> {{award.dob}} </td>
                            </tr>
                            <tr>
                                <td scope="col">Year of Award:</td>
                                <td> {{award.year}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- </article> -->
                <!-- </div> -->
            </div>
        </div>
    </div>
</section>