<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-profile-header></app-profile-header>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <div class="h6 title"> Videos</div>

                    <!-- <div class="align-right">
                        <a class="btn btn-primary btn-md-2" data-toggle="modal"
                            data-target="#update-header-photo">Upload Video +</a>
                    </div> -->
                </div>
            </div>
            <div *ngIf="videos.length === 0" class="ui-block-title">
                <h6 class="display-message">No Video Found!
                </h6>

            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">

        <div *ngFor="let video of videos" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">

            <div class="ui-block video-item">
                <video id="my_video_1" class="video-js vjs-default-skin" width="100%" height="auto" controls muted data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                    <source [src]="video.video" type='video/mp4' />
                    <source [src]="video.video" type="video/webm">
                    Sorry, your browser doesn't support embedded videos.
                </video>
                <div class="video-player">


                    <div class="overlay overlay-dark"></div>


                </div>

                <div class="ui-block-content video-content">
                    <a routerLink="/videos" class="h6 title">{{video.content}}</a>
                    <!-- <time class="published" datetime="2017-03-24T18:18">0:23</time> -->
                    <!-- <span class="sub-title"> Likes: {{video.likesCounter}}</span> -->
                </div>
            </div>
        </div>

    </div>
</div>