import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';


@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.css']
})
export class MediaDetailsComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  media: any = [];
  searchText: string;
  isLoaderVisible = false;

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      }); 
    }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getFootballMedia();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  getFootballMedia() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getFootballMedia(data).subscribe((res: any) => {
      this.media = res.footballMedia;
      this.isLoaderVisible = false;
    });
  }
}
