<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<app-presidents></app-presidents>


<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <ul class="cat-list-bg-style align-center sorting-menu">
                    <li class="cat-list__item" data-filter="*">
                        <a routerLink="/executive-committee" class=""> <strong>
							{{'president-consultant.executive_committee' | translate}} </strong> </a>
                    </li>
					<li class="cat-list__item " data-filter=".politics"><a routerLink="/honorable-committee" class="">
						<strong> {{'president-consultant.honorable_committee' | translate}} </strong></a></li>
                    <li class="cat-list__item" data-filter=".natural"><a routerLink="/iffhs-members" class=""><strong>
						{{'president-consultant.iffhs_member' | translate}} </strong></a></li>
                    <li class="cat-list__item active" data-filter=".worlds">
                        <a routerLink="/president-consultant" class="">
                            <strong> {{'president-consultant.president_consultant' | translate}} </strong></a>
                    </li>
					<li class="cat-list__item " data-filter=".animals"><a routerLink="/president-assistants" class="">
						<strong> {{'president-consultant.president_assistant' | translate}}</strong></a></li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/gala-award" class="">
                            <strong> {{'president-consultant.iffhs_awards' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/ranking" class="">
                            <strong> {{'president-consultant.ranking' | translate}} </strong> </a>
                    </li>
                    <li class="cat-list__item" data-filter=".animals">
                        <a routerLink="/history" class="">
                            <strong> {{'president-consultant.history' | translate}} </strong> </a>
                    </li>
                </ul>

                <div class="row sorting-container" id="clients-grid-1" data-layout="masonry">

                    <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sorting-item ecommerce natural">
                        <div class="ui-block">

                            <article class="hentry post has-post-thumbnail">

                                <div class="post__author author vcard inline-items">
                                    <!-- <img src="../../../assets/img/avatar5-sm.jpg" alt="author">

									<div class="author-date">
										<a class="h6 post__author-name fn" href="#">Green Goo Rock</a>
										<div class="post__date">
											<time class="published" datetime="2004-07-24T18:18">
												March 8 at 6:42pm
											</time>
										</div>
									</div>

									<div class="more"><svg class="olymp-three-dots-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
											</use>
										</svg>
										<ul class="more-dropdown">
											<li>
												<a href="#">Edit Post</a>
											</li>
											<li>
												<a href="#">Delete Post</a>
											</li>
											<li>
												<a href="#">Turn Off Notifications</a>
											</li>
											<li>
												<a href="#">Select as Featured</a>
											</li>
										</ul>
									</div> -->

                                </div>

                                <!-- <p>Hey guys! We are gona be playing this Saturday of <a href="#">The Marina Bar</a> for
									their new Mystic Deer Party.
									If you wanna hang out and have a really good time, come and join us. We’l be waiting
									for you!
								</p> -->

                                <div class="post-thumb">

                                    <a href="javascript:void(0);" (click)="onClick(images[0])" id="ibrahim" style="position: absolute;margin-top: 180px; margin-left: 110px;"><span style="opacity: 0;font-size: 30px;">Ibrahim<br><BR><BR></span></a>
                                    <a href="javascript:void(0);" (click)="onClick(images[1])" id="mohamed" style="position: absolute;margin-top:180px; margin-left:340px;"><span style="opacity: 0;font-size: 30px;">Mohamed<br><BR><BR></span></a>

                                    <img src="../../../assets/img/consultant.jpg" alt="photo">
                                </div>

                                <div class="post-additional-info inline-items">

                                    <!-- <a href="#" class="post-add-icon inline-items">
										<svg class="olymp-heart-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-heart-icon">
											</use>
										</svg>
										<span>49</span>
									</a> -->

                                    <!-- <ul class="friends-harmonic">
										<li>
											<a href="#">
												<img src="../../../assets/img/friend-harmonic9.jpg" alt="friend">
											</a>
										</li>
										<li>
											<a href="#">
												<img src="../../../assets/img/friend-harmonic10.jpg" alt="friend">
											</a>
										</li>
										<li>
											<a href="#">
												<img src="../../../assets/img/friend-harmonic7.jpg" alt="friend">
											</a>
										</li>
										<li>
											<a href="#">
												<img src="../../../assets/img/friend-harmonic8.jpg" alt="friend">
											</a>
										</li>
										<li>
											<a href="#">
												<img src="../../../assets/img/friend-harmonic11.jpg" alt="friend">
											</a>
										</li>
									</ul>

									<div class="names-people-likes">
										<a href="#">Jimmy</a>, <a href="#">Andrea</a> and
										<br>47 more liked this
									</div> -->


                                    <div class="comments-shared">
                                        <!-- <a href="#" class="post-add-icon inline-items">
											<svg class="olymp-speech-balloon-icon">
												<use
													xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-speech-balloon-icon">
												</use>
											</svg>
											<span>264</span>
										</a>

										<a href="#" class="post-add-icon inline-items">
											<svg class="olymp-share-icon">
												<use
													xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-share-icon">
												</use>
											</svg>
											<span>37</span>
										</a> -->
                                    </div>


                                </div>

                                <div class="control-block-button post-control-button">

                                    <!-- <a href="#" class="btn btn-control">
										<svg class="olymp-like-post-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-like-post-icon">
											</use>
										</svg>
									</a> -->

                                    <!-- <a href="#" class="btn btn-control">
										<svg class="olymp-comments-post-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon">
											</use>
										</svg>
									</a>

									<a href="#" class="btn btn-control">
										<svg class="olymp-share-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-share-icon">
											</use>
										</svg>
									</a> -->

                                </div>

                            </article>
                        </div>
                    </div>

                    <div class="col col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 sorting-item worlds family politics">

                        <div class="ui-block">

                            <article class="hentry post has-post-thumbnail">

                                <div class="post__author author vcard inline-items">
                                    <!-- <img src="../../../assets/img/avatar5-sm.jpg" alt="author">

									<div class="author-date">
										<a class="h6 post__author-name fn" href="#">Green Goo Rock</a>
										<div class="post__date">
											<time class="published" datetime="2004-07-24T18:18">
												March 8 at 6:42pm
											</time>
										</div>
									</div>

									<div class="more"><svg class="olymp-three-dots-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
											</use>
										</svg>
										<ul class="more-dropdown">
											<li>
												<a href="#">Edit Post</a>
											</li>
											<li>
												<a href="#">Delete Post</a>
											</li>
											<li>
												<a href="#">Turn Off Notifications</a>
											</li>
											<li>
												<a href="#">Select as Featured</a>
											</li>
										</ul>
									</div> -->

                                </div>

                                <!-- <p>Hey guys! We are gona be playing this Saturday of <a href="#">The Marina Bar</a> for
									their new Mystic Deer Party.
									If you wanna hang out and have a really good time, come and join us. We’l be waiting
									for you!
								</p> -->

                                <div class="post-thumb">
                                    <a routerLink="/profile-details" [queryParams]="{'id': imageSrc.id}"><img src="{{imageSrc.src}}"></a>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">{{'president-consultant.name' | translate}}</th>
                                                <th scope="col"> <a routerLink="/profile-details" [queryParams]="{'id': imageSrc.id}" class="h5 author-name" style="color: #3f4257;">{{imageSrc.name}} </a></th>
                                            </tr>
                                            <!-- <tr>
											<th scope="col">Position:</th>
											<th scope="col">{{imageSrc.position}}</th>
										  </tr>
										  <tr>
											<th scope="col">Nationality:</th>
											<th scope="col">{{imageSrc.nationality}}</th>
										  </tr> -->
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td scope="col">{{'president-consultant.position' | translate}}</td>
                                                <td>{{imageSrc.position}}</td>

                                            </tr>
                                            <tr>
                                                <td scope="col">{{'president-consultant.nationality' | translate}}</td>
												<td> <img src="{{imageSrc.flag}}" *ngIf="language != 'ar'" style="width: 30px; height: 20px; float: left; margin: 0px 12px;"> {{imageSrc.nationality}}
													<img src="{{imageSrc.flag}}" *ngIf="language == 'ar'" style="width: 30px; height: 20px; float: right; margin: 0px 12px;"> {{imageSrc.nationality}} </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>



                            </article>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!-- <app-iffhs-news></app-iffhs-news> -->
    </div>
</div>