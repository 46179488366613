<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-profile-header></app-profile-header>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> Total Friends ({{friends.length}})</div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div *ngIf="friends.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Friends Found!.
            </h6>

        </div>
    </div>
</div>


<!-- Friends -->

<div class="container">
    <div class="row">
        <div *ngFor="let friend of friends | filter:searchText" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="ui-block">

                <!-- Friend Item -->

                <div class="friend-item">
                    <div class="friend-header-thumb">
                        <img src="../../../assets/img/small-cover.webp" alt="friend">
                        <!-- <img *ngIf="!friend.banner" alt="friend" src="../../../assets/img/small-cover.webp" width="318" height="122">
						<img *ngIf="friend.banner" alt="friend" [src]="friend.banner" width="318" height="122"> -->
                    </div>

                    <div class="friend-item-content">

                        <div class="more">
                            <a (click)="openFriendDeleteModal(friendDeleteModal, friend)"> <i class="fa fa-trash"
                                    aria-hidden="true"></i> </a>

                            <!-- <svg class="olymp-three-dots-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
								</use>
							</svg> -->
                            <!-- <ul class="more-dropdown">
								<li>
									<a (click)="openFriendDeleteModal(friendDeleteModal, friend)">Delete </a>
								</li>
								 <li>
									<a href="javascript:void(0);">Block Profile</a>
								</li>
								<li>
									<a href="javascript:void(0);">Turn Off Notifications</a>
								</li> 
							</ul> -->
                        </div>
                        <div class="friend-avatar">
                            <div class="author-thumb">

                                <a routerLink="/profile-details" [queryParams]="{'id': friend.user.id}"><img
                                        *ngIf="!friend.avatar" alt="author" src="assets/img/user.jpg" width="100"
                                        height="100"
                                        onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;"> </a>
                                <a routerLink="/profile-details" [queryParams]="{'id': friend.user.id}"><img
                                        *ngIf="friend.avatar" alt="author" [src]="friend.avatar" width="100"
                                        height="100"
                                        onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                        style="width: 100px; height: 100px;"> </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': friend.user.id}"
                                    class="h5 author-name"> {{friend.user.fullName | titlecase}} </a>
                                <div *ngIf="friend.countryName" class="country">{{friend.countryName}}</div>
                                <div *ngIf="!friend.countryName" class="country">{{'Country Name'}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- ... end Friend Item -->
            </div>
        </div>


    </div>
</div>

<!-- ... end Friends -->



<!-- Delete Modal Friend -->
<div class="modal fade" bsModal #friendDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Friend</h5>
                <button type="button" class="close" (click)="friendDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you Sure you want to delete Friend</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="friendDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteFriend(friendDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!--  Delete Modal Friend  -->

<!-- Popup Chat -->
<div class="modal fade" bsModal #chatModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog window-popup restore-password-popup" role="document">

        <div class="ui-block popup-chat">
            <div class="ui-block-title">
                <div class="author-thumb">
                    <span class="icon-status online"></span>
                    <img *ngIf="!selectFriend.avatar" alt="author" class="avatar" src="../../../assets/img/user.jpg"
                        width="36" height="36">
                    <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar" width="36"
                        height="36">
                </div>
                <h6 class="title">{{ selectFriend.fullName | titlecase }}</h6>
                <button type="button" class="close" (click)="closeChatModal(chatModal)">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="mCustomScrollbar" data-mcs-theme="dark">
                <ul class="notification-list chat-message chat-message-field">
                    <li>
                        <div class="author-thumb">
                            <img *ngIf="!selectFriend.avatar" alt="author" class="avatar"
                                src="../../../assets/img/user.jpg" width="36" height="36">
                            <img *ngIf="selectFriend.avatar" alt="author" class="avatar" [src]="selectFriend.avatar"
                                width="36" height="36">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow!
                                I’m
                                gonna be
                                handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img *ngIf="!currentUser.success.user.avatar" alt="author" class="avatar"
                                src="../../../assets/img/user.jpg" width="36" height="36">
                            <img *ngIf="currentUser.success.user.avatar" alt="author" class="avatar"
                                [src]="currentUser.success.user.avatar" width="36" height="36">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Don’t worry Mathilda!</span>
                            <span class="chat-message-item">I already bought everything</span>
                            <span class="notification-date"><time class="entry-date updated"
                                    datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
                        </div>
                    </li>
                </ul>
            </div>

            <form>

                <div class="form-group label-floating is-empty">
                    <textarea class="form-control" placeholder="Press enter to reply..."></textarea>
                    <div class="add-options-message">
                        <a href="javascript:void(0);" class="options-message">
                            <svg class="olymp-computer-icon">
                                <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
                            </svg>
                        </a>
                        <!-- <div class="options-message smile-block">

							<svg class="olymp-happy-sticker-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon">
								</use>
							</svg>

							<ul class="more-dropdown more-with-triangle triangle-bottom-right">
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat1.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat2.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat3.png" alt="icon">
									</a>
								</li>
								<li>
									<a href="#">
										<img src="../../../assets/img/icon-chat4.png" alt="icon">
									</a>
								</li>


							</ul>
						</div> -->
                    </div>
                </div>

            </form>


        </div>

    </div>
</div>

<!-- ... end Popup Chat -->