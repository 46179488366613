import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-voting-question',
  templateUrl: './edit-voting-question.component.html',
  styleUrls: ['./edit-voting-question.component.css']
})
export class EditVotingQuestionComponent implements OnInit {

  editQuestionForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  question: any = {};
  models = [
    {
      id: 1,
      model: 'Referee'
    },
    {
      id: 2,
      model: 'Coach'
    },
    {
      id: 3,
      model: 'Goalkeeper'
    },

    {
      id: 4,
      model: 'Playmaker'
    },
    {
      id: 5,
      model: 'Defender'
    },
    {
      id: 6,
      model: 'Player'
    },
    {
      id: 7,
      model: 'personage'
    },
    {
      id: 8,
      model: 'club'
    },
    {
      id: 9,
      model: 'team'
    },
    {
      id: 10,
      model: 'tournament'
    },

  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) {
    this.route.queryParams.subscribe(params => this.id = params.id);

  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getQuestionById();
  }

  getQuestionById() {
    this.isLoaderVisible = true;
    const data = {
      questionId: this.id,
    };
    this.iffhsService.getQuestionById(data).subscribe((res: any) => {
      this.question = res;
      this.isLoaderVisible = false;
    },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }
    );
  }
  initForm() {
    this.editQuestionForm = this.formBuilder.group({
      questionCategory: [this.question.category, Validators.required],
      questionId: [this.id],
      questionModal: [this.question.model, Validators.required],
      questionStructure: [this.question.structure, Validators.required],
      question: [this.question.question, Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
  onChange(e) {
    const onChangeValue = e.target.value || 0;
    this.editQuestionForm.get('questionCategory').setValue(onChangeValue);
  }

  onImageSelect(event) {
    const bannerPhoto = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.editQuestionForm.get('bannerPhoto').setValue(bannerPhoto);

  }
  goToDashboard() {
    this.router.navigate(['/add-voting-questions']);
  }

  get f() { return this.editQuestionForm.controls; }
  editQuestion() {
    this.submitted = true;

    if (this.editQuestionForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('questionId', this.editQuestionForm.get('questionId').value);
    fd.append('questionCategory', this.editQuestionForm.get('questionCategory').value);
    fd.append('questionModal', this.editQuestionForm.get('questionModal').value);
    fd.append('questionStructure', this.editQuestionForm.get('questionStructure').value);
    fd.append('question', this.editQuestionForm.get('question').value);
    fd.append('createdBy', this.editQuestionForm.get('createdBy').value);

    this.iffhsService.editQuestion(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Question Updated!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.getQuestionById();
    this.editQuestionForm = this.formBuilder.group({
      questionId: [this.id, Validators.required],
      questionCategory: [this.question.category, Validators.required],
      questionModal: [this.question.model, Validators.required],
      questionStructure: [this.question.structure, Validators.required],
      question: [this.question.question, Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
  }
}
