import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-fans-details',
  templateUrl: './fans-details.component.html',
  styleUrls: ['./fans-details.component.css']
})
export class FansDetailsComponent implements OnInit {
  searchText: string;
  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  fans = [];
  isLoaderVisible = false;
  items = [];
  pageOfItems: Array<any>;
  pageSize = 12;
  maxPages = 12;
  initialPage = 1;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getFans();
  }
  getFans() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getFans(data).subscribe((res: any) => {
      this.fans = res;
      this.items = this.fans;
      this.isLoaderVisible = false;
    });
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);  
    this.isLoaderVisible = false;
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }


}
