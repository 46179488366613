<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Leagues </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
								<svg class="olymp-magnifying-glass-icon">
									<use
										xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
									</use>
								</svg>
							</button>
                        </div>
                    </form>
                </div>
            </div>
            
        </div>
        <div *ngIf="leagues.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row ">
        <div *ngFor="let league of leagues | filter:searchText" class="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div (click)="getClubsByLeague(league.id)" class="friend-avatar">
                            <div (click)="moveToStructure()" class="author-thumb">
                                <a href="javascript:void(0);"> <img src="{{league.leagueLogo}}" style="width: 120px; height: 120px;" alt="logo" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 120px; height: 120px;"> </a>
                            </div>
                            <div class="author-content">
                                <a href="javascript:void(0);" class="h5 author-name"> {{league.leagueName | titlecase}}</a>
                                <!-- <div class="country">{{league.nationality}}</div> -->
                                <div class="friend-since" data-swiper-parallax="-100">
                                    <!-- <span> President Since:</span> -->
                                    <!-- <div class="h6" style="color: #fb9b30;">{{league.year}}</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>
<div #clubSection id="clubSection" *ngIf="clubVisible" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Clubs ({{clubs.length}}) </div>
                    <!-- <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
								<svg class="olymp-magnifying-glass-icon">
									<use
										xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
									</use>
								</svg>
							</button>
                        </div>
                    </form> -->
                </div>
            </div>            
        </div>
        <div *ngIf="clubs.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div *ngFor="let club of clubs  | filter:searchText" class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div routerLink="/club-profile" [queryParams]="{'id': club.id}" class="friend-item-content">
                        <div class="friend-avatar">
                            <a routerLink="/club-profile" [queryParams]="{'id': club.id}">                                
                                <img src="https://api.football-family.com/img/clubs/{{countryData.name}}/{{club.user_id}}/avatar.png" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 120px; height: 120px;" />
                            </a>
                            <div class="author-content">
                                <a routerLink="/club-profile" [queryParams]="{'id': club.id}" class="h5 author-name"> {{club.name}}</a>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>