import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-ga-clubs',
  templateUrl: './ga-clubs.component.html',
  styleUrls: ['./ga-clubs.component.css']
})
export class GaClubsComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  clubs = [];
  searchText: string;
  url = 'https://api.football-family.com/img/clubs/';
  items = [];
  pageOfItems: Array<any>;
  isLoaderVisible = false;
  categories = [
    {
      id: '1',
      name: 'All Category'
    },
    {
      id: '2',
      name: 'By First Team'
    },
    {
      id: '3',
      name: 'By Olympic Team'

    },
    {
      id: '4',
      name: 'By Under17'
    },
    {
      id: '5',
      name: 'By Under19'
    },
    {
      id: '6',
      name: 'By Academic'
    },
  ];
  leagues = [
    {
      id: '1',
      name: 'First League'
    },
    {
      id: '2',
      name: '2nd League'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getClubs();
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  getClubs() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getClubs(data).subscribe((res: any) => {
      this.clubs = res;
      this.items = this.clubs;
      this.isLoaderVisible = false;
    });
  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getClubs(data).subscribe((res: any) => {
      this.clubs = res;
      this.items = this.clubs;
      this.isLoaderVisible = false;
      // this.items = this.players;
      // for (let i = 0; i < this.players.length; i++) {
      //   var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
      //   this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      // }
    });
  }

}
