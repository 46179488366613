<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/voting/voting-dashboard-banner.webp" class="img-responsive img-fluid" alt="cover" style="width: 100%;height: 100%;">
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a href="javascript:void(0);" routerLinkActive="active"> Voting Questions</a>
                                    </li>
                                    <!-- <li>
                                        <a routerLink="/add-award" routerLinkActive="active"> Award</a>
                                    </li>
                                    <li>
                                        <a routerLink="/add-award-winner" routerLinkActive="active">Winner</a>
                                    </li> -->

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="domElement==='view'" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">IFFHS Voting Questions</h6>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                    <button (click)="onClick('add')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-plus" aria-hidden="true"></i>
                        Add</button>
                </div>
                <div class="ui-block-content">


                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="id-width">ID</th>
                                <th style="width: 40%;">Question</th>
                                <th>Modal</th>
                                <th>Category</th>
                                <th>Structure</th>
                                <th class="id-width">Edit</th>
                                <th class="id-width">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let question of pageOfItems | filter:searchText">
                                <td>{{question.id}}</td>
                                <td>{{question.question | slice:0:70}}</td>
                                <td>{{question.model}}</td>
                                <td> {{question.category}} </td>
                                <td> {{question.structure}} </td>
                                <td routerLink="/edit-voting-question" [queryParams]="{'id': question.id}"><i class="fas fa-edit fa-2x"></i></td>
                                <td (click)="openDeleteModal(questionDeleteModal,question)"><i class="fas fa-trash fa-2x"></i></td>
                            </tr>                           
                        </tbody>
                    </table>
                    <div *ngIf="questions.length === 0 && eventQuestions.length === 0" class="ui-block-title">
                        <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <div>
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </ul>
    </nav>
</div>
<div *ngIf="domElement==='add'" class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Voting Question</h6>
                    <button (click)="onClick('view')" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>
                        View Question</button>
                </div>
                <div class="ui-block-content">

                    <form [formGroup]="votingQuestionForm" (ngSubmit)="addVotingQuestion()" class="content">
                        <div class="row">
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Question Category <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionStructure" (change)="onChange($event)" [ngClass]="{ 'is-invalid': submitted && f.questionStructure.errors }">
                                        <option value="Annual" selected>Annual Question</option>
                                        <option value="Event">Event Question</option>

                                    </select>
                                    <div *ngIf="submitted && f.questionStructure.errors" class="invalid-feedback">
                                        <div *ngIf="f.questionStructure.errors.required">Question Category is required *
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label">Select Question Modal <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionModal" (change)="onChange($event)" [ngClass]="{ 'is-invalid': submitted && f.questionModal.errors }">
                                        <option *ngFor="let model of models" value="{{model.model}}" selected>
                                            {{model.model}}</option>

                                    </select>
                                    <div *ngIf="submitted && f.questionModal.errors" class="invalid-feedback">
                                        <div *ngIf="f.questionModal.errors.required">Question Modal is required *
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label"> Question Structure <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="questionCategory" (change)="onChange($event)" [ngClass]="{ 'is-invalid': submitted && f.questionCategory.errors }">
                                        <option value="International" selected>International </option>
                                        <option value="Local">Local </option>

                                    </select>
                                    <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                                        <div *ngIf="f.question.errors.required">Question Structure is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label class="control-label"> Question <span style="color: red;"> *
                                        </span></label>
                                    <textarea type="text" formControlName="question" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.question.errors }"></textarea>
                                    <div *ngIf="submitted && f.question.errors" class="invalid-feedback">
                                        <div *ngIf="f.question.errors.required">Question is required *</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button [disabled]="loading" class="btn btn-primary btn-lg full-width">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                    Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" bsModal #questionDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Voting Question</h5>
                <button type="button" class="close" (click)="questionDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you sure you want to delete {{selectedQuestion.question }}.?</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="questionDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger" (click)="deleteQuestion(questionDeleteModal)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->