<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-friend-profile-header></app-friend-profile-header>

<div class="container">
    <div class="row">
        <div class="col col-xl-8 order-xl-2 col-lg-8 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Hobbies and Interests</h6>
                </div>
                <div class="ui-block-content">
                    <div class="row">
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">


                            <!-- W-Personal-Info -->

                            <ul class="widget w-personal-info item-block">
                                <li>
                                    <span class="title">Hobbies:</span>
                                    <span *ngIf="friendData.hobby1"
                                        class="text">{{ friendData.hobby1 | titlecase }}.</span>
                                    <span *ngIf="!friendData.hobby1" class="text">No information available !</span>
                                </li>
                                <li>
                                    <span class="title">Favourite TV Shows:</span>
                                    <span *ngIf="friendData.hobby2"
                                        class="text">{{ friendData.hobby2 | titlecase }}.</span>
                                    <span *ngIf="!friendData.hobby2" class="text">No information available !</span>
                                </li>
                            </ul>

                            <!-- ... end W-Personal-Info -->
                        </div>
                        <div class="col col-lg-6 col-md-6 col-sm-12 col-12">


                            <!-- W-Personal-Info -->

                            <ul class="widget w-personal-info item-block">
                                <li>
                                    <span class="title">Hobbies:</span>
                                    <span *ngIf="friendData.hobby3"
                                        class="text">{{ friendData.hobby3 | titlecase }}.</span>
                                    <span *ngIf="!friendData.hobby3" class="text">No information available !</span>
                                </li>
                                <li>
                                    <span class="title">Favourite TV Shows:</span>
                                    <span *ngIf="friendData.hobby4"
                                        class="text">{{ friendData.hobby4 | titlecase }}.</span>
                                    <span *ngIf="!friendData.hobby4" class="text">No information available !</span>
                                </li>
                            </ul>

                            <!-- ... end W-Personal-Info -->
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col col-xl-4 order-xl-1 col-lg-4 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Personal Info</h6>
                </div>
                <div class="ui-block-content">


                    <!-- W-Personal-Info -->

                    <ul class="widget w-personal-info">
                        <li>
                            <span class="title">About Me:</span>
                            <span class="text">Hi, I’m {{friendData.fullName  | titlecase}}<br>
                                {{friendData.about}}</span>
                        </li>
                        <li>
                            <span class="title">Birthday:</span>                           
                            <span *ngIf="friendData.dob" class="text">{{ friendData.dob | date  }}</span>
                            <span *ngIf="!friendData.dob" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Country:</span>                           
                            <span *ngIf="friendData.countryName" class="text">{{ friendData.countryName | titlecase}}</span>
                            <span *ngIf="!friendData.countryName" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Lives in:</span>
                            <span *ngIf="friendData.residentCountry" class="text">{{friendData.residentCountry}}</span>
                            <span *ngIf="!friendData.residentCountry" class="text">No info available !</span>
                        </li>
                        <!-- <li>
                            <span class="title">Occupation:</span>
                            <span class="text">UI/UX Designer</span>
                        </li> -->
                        <li>
                            <span class="title">Joined:</span>
                           
                            <span *ngIf="friendData.created_at" class="text">{{ friendData.created_at | date }}</span>
                            <span *ngIf="!friendData.created_at" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Gender:</span>
                          
                            <span *ngIf="friendData.gender" class="text">{{ friendData.gender  | titlecase}}</span>
                            <span *ngIf="!friendData.gender" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Marital status:</span>
                            <span *ngIf="friendData.martialStatus" class="text">{{friendData.martialStatus}}</span>
                            <span *ngIf="!friendData.martialStatus" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Email:</span>
                            <a href="javascript:void(0);" class="text">{{ friendData.email | titlecase }}</a>
                        </li>
                        <!-- <li>
                            <span class="title">Website:</span>
                            <a  href="javascript:void(0);" class="text">daydreamsagency.com</a>
                        </li> -->
                        <li>
                            <span class="title">Mobile:</span>
                           
                            <span *ngIf="friendData.mobileNumber" class="text">{{ friendData.mobileNumber }}</span>
                            <span *ngIf="!friendData.mobileNumber" class="text">No info available !</span>
                        </li>
                        <!-- <li>
                            <span class="title">Religious :</span>
                            <span class="text">Islam</span>
                        </li> -->
                        <!-- <li>
                            <span class="title">Political :</span>
                            <span class="text">Democrat</span>
                        </li> -->
                    </ul>

                    <!-- ... end W-Personal-Info -->
                    <!-- W-Socials -->

                    <div class="widget w-socials">
                        <h6 *ngIf="friendData.facebook || friendData.twitter "  class="title">Other Social Networks:</h6>
                        <a *ngIf="friendData.facebook" href="https://{{friendData.facebook}}" class="social-item bg-facebook">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i>
                            Facebook
                        </a>
                        <a *ngIf="friendData.twitter" href="https://{{friendData.twitter}}" class="social-item bg-twitter">
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                            Twitter
                        </a>
                        <!-- <a  routerLink="/profile" class="social-item bg-dribbble">
                            <i class="fab fa-dribbble" aria-hidden="true"></i>
                            Dribbble
                        </a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>