<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/covers/{{countryData.continent_id}}.webp" class="img-responsive  img-fluid"
                            alt="cover" style="width: 100%;height: 100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData.code | lowercase}}.svg" alt="flag"
                                            class="img-responsive  img-fluid" style="width: 100%; height: 100%;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData.name}}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData.code}}.png"
                                            class="img-responsive float-left img-fluid" alt="flag"
                                            onerror="this.onerror=null;this.src='assets/img/clubs.webp';"
                                            style="width: 100%; height: 100%;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-8 m-auto col-lg-8 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLinkActive="active">{{'team-team.national_teams' | translate}}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> {{'team-team.national_teams' | translate}} </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{'team-team.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        <div *ngIf="teams.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message">{{'team-team.no_data' | translate}} </h6>

        </div>
    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div *ngFor="let team of teams | filter:searchText" class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div (click)="openModal(template , team)" class="author-thumb">
                                <img src="{{team.src}}" style="width: 120px; height: 120px;" alt="photo">
                            </div>
                            <div class="author-content">
                                <p (click)="openModal(template , team)" class="h5 author-name"> {{team.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #template [dir]="direction">
    <div class="modal-header">
        <h5 class="modal-title pull-left "> {{ selectTeam.name }} </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container">
            <div class="row">
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Men'}"
                                            (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Men'}"
                                            class="h5 author-name" (click)="modalRef.hide()"> {{'team-team.men' | translate}}
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="ui-block" data-mh="friend-groups-item" style="border: none;margin: 0px;">
                        <div class="friend-item friend-groups">
                            <div class="friend-item-content">
                                <div class="friend-avatar">
                                    <div class="author-thumb">

                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Women'}"
                                            (click)="modalRef.hide()"> <img src="{{selectTeam.src}}"
                                                style="width: 120px; height: 120px;" alt="photo"> </a>
                                    </div>
                                    <div class="author-content">
                                        <a routerLink="/national-team"
                                            [queryParams]="{'id': countryData.id, 'name': selectTeam.name, 'category': 'Women'}"
                                            class="h5 author-name" (click)="modalRef.hide()"> Women
                                            {{selectTeam.name}}</a>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>