import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { MessageService } from 'src/app/data-services/message.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  token: string;
  currentUser: any;
  userData: any = [];
  language = '';
  direction = '';
  defaultLanguage;


  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private messageService: MessageService,
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.messageService.selectedLanguage.subscribe(res => {
      console.log(res);
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
    this.language = this.defaultLanguage.lang;
    console.log(this.direction);
    this.getProfile();

  }


  getProfile() {
    const data = {
      id: this.currentUser.success.user.id,
      role: this.currentUser.success.user.role,
    };
    this.userService.getProfile(data).subscribe((res: any) => {
      this.userData = res;
      this.messageService.sendMessage(this.userData);
      localStorage.setItem('userData', JSON.stringify(this.userData));

    });
  }

}
