<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>


<div class="main-header">
    <div class="content-bg-wrap bg-birthday"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>{{'home.welcome' | translate}}</h1>
                    <p style="font-size: 1.5rem;"> {{'home.message' | translate}}</p>
                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="assets/img/home-banner.png" alt="Legends">
</div>
<div *ngIf="language !='ar'" class="container-fluid">
    <div class="row">
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <a routerLink="/executive-committee"> <img src="assets/img/iffhs-member.webp"
                                style="height: auto;" alt="IFFHS Members"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <a routerLink="/associations"> <img src="assets/img/associations.webp" style="height: auto;"
                                alt="associations"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/teams"> <img src="assets/img/football-teams.webp" style="height: auto;"
                                alt="football teams"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/media"> <img src="assets/img/media.webp" style="height: auto;"
                                alt="football media"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/general-assembly"> <img src="assets/img/g-assembly.webp" style="height: auto;"
                                alt="general-assembly"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/fans"> <img src="assets/img/fans.webp" style="height: auto;" alt="Fans"> </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div *ngIf="language =='ar'" class="container-fluid">
    <div class="row">
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/fans"> <img src="assets/img/{{language}}/fans.webp" style="height: auto;"
                                alt="Fans"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/media"> <img src="assets/img/{{language}}/media.webp" style="height: auto;"
                                alt="football media"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/general-assembly"> <img src="assets/img/{{language}}/g-assembly.webp"
                                style="height: auto;" alt="general-assembly"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">
                        <a routerLink="/teams"> <img src="assets/img/{{language}}/football-teams.webp"
                                style="height: auto;" alt="football teams"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <a routerLink="/associations"> <img src="assets/img/{{language}}/associations.webp"
                                style="height: auto;" alt="associations"> </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <a routerLink="/executive-committee"> <img src="assets/img/{{language}}/iffhs-member.webp"
                                style="height: auto;" alt="IFFHS Members"> </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>