import { Component, OnInit } from '@angular/core';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/data-services/user.service';
@Component({
  selector: 'app-conf-followers',
  templateUrl: './conf-followers.component.html',
  styleUrls: ['./conf-followers.component.css'],
})
export class ConfFollowersComponent implements OnInit {
  id: number;
  countryData: any = {};
  currentUser: any;
  searchText: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  followers: [];
  countries: [];
  states: [];
  cities: [];
  mapLocation = '';
  placeName = 'World Wide';

  url = '';
  urlSafe: SafeResourceUrl;
  isCountryDisabled = true;
  isStateDisabled = true;
  isCitiesDisabled = true;
  isCityDisabled = true;
  totalFollowers: number = 0;
  confederationData: any = {};
  userId: number;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    public sanitizer: DomSanitizer,
    private toastrService: ToastrService,
    private userService: UserService,
  ) { }
  continents = [
    {
      id: '1',
      name: 'CAF',
      src: '../../../assets/img/caf.webp',
      continent: 'Africa',
    },
    {
      id: '3',
      name: 'AFC',
      src: '../../../assets/img/afc.webp',
      continent: 'Asia',
    },
    {
      id: '4',
      name: 'UEFA',
      src: '../../../assets/img/uefa.webp',
      continent: 'Europe',
    },
    {
      id: '5',
      name: 'OFC',
      src: '../../../assets/img/ofc.webp',
      continent: 'Oceania ',
    },
    {
      id: '6',
      name: 'CONCACAF',
      src: '../../../assets/img/concacaf.webp',
      continent: 'North America',
    },
    {
      id: '7',
      name: 'CONMEBOL',
      src: '../../../assets/img/conmebol.webp',
      continent: 'South America',
    },
  ];

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.confederationData = JSON.parse(localStorage.getItem('confederationData'));
    this.userId = this.confederationData.user.id;
    this.route.queryParams.subscribe((params) => (this.id = params.id));
    this.countryDetails();
  }
  countryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService
      .getCountryDetails(data)
      .pipe(first<any>())
      .subscribe((countryData) => (this.countryData = countryData[0]));
  }
  getCountries(id: number, name: string) {
    this.placeName = name;
    const data = {
      continentId: id,
    };
    this.iffhsService.getFederation(data).subscribe((res: any) => {
      if (res[0].continent_id === 1) {
        this.countries = res;
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=Africa&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;

      } else if (res[0].continent_id === 3) {
        this.countries = res;
        this.mapLocation = 'Asia';
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=Asia&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;
        ;
      } else if (res[0].continent_id === 4) {
        this.countries = res;
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=Europe&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;

      } else if (res[0].continent_id === 5) {
        this.countries = res;
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=Oceania&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;

      } else if (res[0].continent_id === 6) {
        this.countries = res;
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=North America&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;

      } else if (res[0].continent_id === 7) {
        this.countries = res;
        this.mapLocation = 'South America';
        this.url =
          'https://www.google.com/maps/embed/v1/place?q=South America&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
        this.isCountryDisabled = false;

      }
    });
  }
  getState(id: number, name: string) {
    this.placeName = name;
    const data = {
      continentId: id,
      countryName: name,
    };
    this.url =
      'https://www.google.com/maps/embed/v1/place?q=' +
      name +
      '&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
    this.isStateDisabled = false;
    this.iffhsService
      .getState(data)
      .pipe(first<any>())
      .subscribe((states) => (this.states = states));
  }
  getCities(id: number, name: string) {
    this.placeName = name;
    const data = {
      stateId: id,
    };
    this.url =
      'https://www.google.com/maps/embed/v1/place?q=' +
      name +
      '&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
    this.isCityDisabled = false;
    this.iffhsService
      .getCities(data)
      .pipe(first<any>())
      .subscribe((cities) => (this.cities = cities));
  }
  getCity(id: number, name: string) {
    this.placeName = name;
    // const data = {
    //   stateId: id,

    // };
    this.url =
      'https://www.google.com/maps/embed/v1/place?q=' +
      name +
      '&key=AIzaSyBfPkUvRhTwnXd-Ivd-lF_UVfMB43wjevY';
    this.isCityDisabled = false;
    // this.iffhsService.getCities(data).pipe(first<any>()).subscribe(cities => this.cities = cities);
  }
  doFollow() {
    const data = {
      user_id: this.currentUser.success.user.id,
      follower_id: this.userId,
    };

    this.userService.follow(data).pipe(first<boolean>()).subscribe(
      succuss => {
        if (succuss) {
          this.followed = succuss;
          this.toastrService.success('Followed', 'Message!');
        } else if (!succuss) {
          this.toastrService.success('Unfollow', 'Message!');

        }

      },
      error => {
        console.log(error);
      }
    );
  }
}
