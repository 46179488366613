import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-team-matches',
  templateUrl: './team-matches.component.html',
  styleUrls: ['./team-matches.component.css']
})
export class TeamMatchesComponent implements OnInit {

  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  status: boolean;
  matches = [];
  country: any = {};
  selectFriend: any = {};
  isLoaderVisible = false;
  categories = [
    {
      id: '1',
      name: 'By Federation'
    },
    {
      id: '2',
      name: 'By Clubs'
    },
    {
      id: '3',
      name: 'By Leagues'
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getMatches();
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }
  getMatches() {
    this.isLoaderVisible = true;
    const data = {
      hostTeam: this.id,
    };
    this.iffhsService.getMatches(data).subscribe((res: any) => {
      this.matches = res.matches;
      this.country = res.country;
      this.isLoaderVisible = false;
    });
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getMatches(data).subscribe((res: any) => {
      this.matches = res.matches;
      this.country = res.country;
      this.isLoaderVisible = false;
      // this.items = this.players;
      // for (let i = 0; i < this.players.length; i++) {
      //   var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
      //   this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      // }
    });
  }
}
