<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>

<div class="profile-settings-responsive">

    <a (click)="toggleSideBar($event)" class="js-profile-settings-open profile-settings-open">
        <i class="fa fa-angle-right" aria-hidden="true"></i>
        <i class="fa fa-angle-left" aria-hidden="true"></i>
    </a>
    <div class="mCustomScrollbar" data-mcs-theme="dark">
        <div class="ui-block">
            <div class="your-profile">


                <div id="accordion1" role="tablist" aria-multiselectable="true">
                    <div class="card">
                        <div class="card-header" role="tab" id="headingOne-1">
                            <h6 class="mb-0">
                                <a data-toggle="collapse" data-parent="#accordion" aria-expanded="true"
                                    aria-controls="collapseOne">
                                    Dashboard Settings
                                    <svg class="olymp-dropdown-arrow-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                        </use>
                                    </svg>
                                </a>
                            </h6>
                        </div>

                        <div id="collapseOne-1" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                            <ul class="your-profile-menu ">
                                <li class="active ">
                                    <a routerLink="/team-dashboard" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">News/Blogs</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-media" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Photos/Videos</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-matches" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Matches</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-leagues" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Leagues</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard--add-media" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Media</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-player" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Player</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-referee" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Referee</a>
                                </li>
                                <li>
                                    <a routerLink="/dashboard-coach" [queryParams]="{ 'id': countryData.id} "
                                        routerLinkActive="active ">Add Coach</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- ... end Profile Settings Responsive -->
<div class="container">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Add Club</h6>
                </div>
                <div class="ui-block-content">
                    <form class="content" [formGroup]="clubForm" (ngSubmit)="onSubmitClub()">
                        <div class="row">
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">

                                    <label class="control-label">Select League <span style="color: red;"> *
                                        </span></label>
                                    <select class="form-control" formControlName="leagueId" (change)="onChange($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.leagueId.errors }">
                                        <option *ngFor="let league of leagues" value="{{league.id}}" selected>
                                            {{league.leagueName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.leagueId.errors" class="invalid-feedback">
                                        <div *ngIf="f.leagueId.errors.required">League is required. Hints:First add
                                            League if the list is empty</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="control-label">Club Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="clubName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.clubName.errors }" />
                                    <div *ngIf="submitted && f.clubName.errors" class="invalid-feedback">
                                        <div *ngIf="f.clubName.errors.required">Club Name is required *</div>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <label class="control-label">Content <span style="color: red;"> *
                                        </span></label>
                                    <textarea class="form-control" name="newsContent" formControlName="newsContent" placeholder="Content..." [ngClass]="{ 'is-invalid': submitted && f.newsContent.errors }"></textarea>
                                    <div *ngIf="submitted && f.newsContent.errors" class="invalid-feedback">
                                        <div *ngIf="f.newsContent.errors.required">Content is required *</div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label class="control-label">Logo ( Only Image ) <span style="color: red;"> *
                                        </span></label>
                                    <!-- <Label style="margin: 0px 25px;"> {{fileName}}</Label> -->
                                    <input type="file" formControlName="clubAvatar" accept="image/*"
                                        class="form-control" (change)="onFileSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.clubAvatar.errors }" />
                                    <div *ngIf=" submitted && f.clubAvatar.errors " class="invalid-feedback ">
                                        <div *ngIf="f.clubAvatar.errors.required ">Logo is required *</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width">
                                    Save
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div
            class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none ">
            <div class="ui-block ">
                <div class="your-profile ">
                    <div id="accordion " role="tablist " aria-multiselectable="true ">
                        <div class="card ">
                            <div class="card-header " role="tab " id="headingOne ">
                                <h6 class="mb-0 ">
                                    <a data-toggle="collapse " data-parent="#accordion " href="#collapseOne "
                                        aria-expanded="true " aria-controls="collapseOne ">
                                        Dashboard Settings
                                        <svg class="olymp-dropdown-arrow-icon ">
                                            <use xlink:href="#olymp-dropdown-arrow-icon "></use>
                                        </svg>
                                    </a>
                                </h6>
                            </div>
                            <div id="collapseOne " class="collapse show " role="tabpanel "
                                aria-labelledby="headingOne ">
                                <ul class="your-profile-menu ">
                                    <li class="active ">
                                        <a routerLink="/team-dashboard" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">News/Blogs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-media" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Photos/Videos</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-matches" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Matches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-add-club" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Club</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-leagues" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Leagues</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-add-media" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Media</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-player" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Player</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-referee" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Referee</a>
                                    </li>
                                    <li>
                                        <a routerLink="/dashboard-coach" [queryParams]="{ 'id': countryData.id} "
                                            routerLinkActive="active ">Add Coach</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>