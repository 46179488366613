import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first, map } from 'rxjs/operators';
import { Location } from '@angular/common';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  searchForm: FormGroup;
  currentUser: any;
  userData: any = {};
  searchData: any = {};
  data: any = [];
  submitted = false;
  public show = false;
  public buttonName: any = 'Show';
  sidebar: any;
  isLoaderVisible = false;



  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private location: Location,
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.searchData = params;
    });
    this.isLoaderVisible = true;
    this.userService.search(this.searchData).pipe(first<any>()).subscribe(
      succuss => {
        this.data = succuss;
        this.isLoaderVisible = false;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
      }
    );

  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getProfile();
    this.searchForm = this.formBuilder.group({
      query: ['', Validators.required],
    });
  }
  get f() {
    return this.searchForm.controls;
  }
  search() {
    this.isLoaderVisible = true;
    this.activatedRoute.queryParams.subscribe((params) => {
      this.searchData = params;
    });
    this.userService.search(this.searchData).pipe(first<any>()).subscribe(
      succuss => {
        this.data = succuss;
        this.isLoaderVisible = false;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
      }
    );
  }


  getProfile() {
    const data = {
      id: this.currentUser.success.user.id,
    };
    this.userService.getProfile(data).pipe(first()).subscribe(userData => this.userData = userData[0]);
  }
  backClicked() {
    this.location.back();
  }
  forwardClicked() {
    this.location.forward();
  }


  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }
  }
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);

  }




}
