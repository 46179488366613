import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-conf-header',
  templateUrl: './conf-header.component.html',
  styleUrls: ['./conf-header.component.css']
})
export class ConfHeaderComponent implements OnInit {

  id: number;
  confederationData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  isLoaderVisible = false;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.confederationData = JSON.parse(localStorage.getItem('confederationData'));
    this.getConfederation();
  }
  getConfederation() {
    this.isLoaderVisible = true;
    const data = {
      confederationId: this.id,
    };
    this.iffhsService.getConfederation(data).subscribe((res: any) => {
      this.confederationData = res.continent[0];
  
  
      this.isLoaderVisible = false;
      localStorage.setItem('confederationData', JSON.stringify(this.confederationData));
    });
  }


}
