import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-team-leagues',
  templateUrl: './team-leagues.component.html',
  styleUrls: ['./team-leagues.component.css']
})
export class TeamLeaguesComponent implements OnInit {
  @ViewChild('clubSection') public clubSection: ElementRef;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  status: boolean;
  leagues = [];
  clubs = [];
  selectFriend: any = {};
  isLoaderVisible = false;
  clubVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.getLeagues();
  }
  countryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);

  }
  public moveToStructure(): void {
    this.clubSection.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
  }
  getClubsByLeague(id) {
    this.isLoaderVisible = true;
    const data = {
      leagueId: id,
    };
    this.iffhsService.getClubsByLeague(data).subscribe((res: any) => {
      this.clubs = res.clubs;
      this.clubVisible = true;
      this.isLoaderVisible = false;
    });
  }
  getLeagues() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getLeagues(data).subscribe((res: any) => {
      this.leagues = res.leagues;
      this.isLoaderVisible = false;
    });
  }
  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getLeagues(data).subscribe((res: any) => {
      this.leagues = res.leagues;
      this.isLoaderVisible = false;
      // this.items = this.players;
      // for (let i = 0; i < this.players.length; i++) {
      //   var timeDiff = Math.abs(Date.now() - new Date(this.players[i].dob).getTime());
      //   this.age[i] = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      // }
    });
  }
}
