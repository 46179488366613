<app-national-team-header></app-national-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Timeline:
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>

                </div>

            </div>
            
        </div>
        <div *ngIf="news.length === 0 && blogs.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data Found! </h6>
        </div>
    </div>
</div>
<div *ngIf="news.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="label-title">News </h6>
                </div>
                <ul class="widget w-friend-pages-added notification-list friend-requests">
                    <li *ngFor="let _new of news | slice:0:4" (click)="onClick(_new)" class="inline-items card">
                        <div (click)="onClick(_new)" class="notification-event">
                            <a class="h6 news-list">{{_new.newsTitle | titlecase}}</a>
                        </div>
                        <span class="notification-icon" data-toggle="tooltip" data-placement="top" data-original-title="read more">
                            <a class="read" href="javascript:void(0);" (click)="openModal(newsModal,_new)">
                                <span class="read"> Read more</span>
                        </a>
                        </span>
                    </li>
                    <li *ngIf="news.length > 4" class="inline-items card">
                        <button (click)="moveToStructure()" class="btn btn-primary read btn-block">All News</button>
                    </li>
                </ul>

            </div>
        </div>
        <div class="col col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12">
            <div id="carouselExampleIndicators" class="carousel" data-ride="carousel">
                <div class="carousel-inner">
                    <div *ngFor="let _new of news" class="carousel-item active">
                        <img *ngIf="!slider.newsAttachment" (click)="openModal(newsModal,_new)" class="d-block w-100" src="{{_new.newsAttachment}}" alt="News" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 800px; height: 400px;">
                        <img *ngIf="slider.newsAttachment" (click)="openModal(newsModal,slider)" class="d-block w-100" src="{{slider.newsAttachment}}" alt="News" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 800px; height: 400px;">

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Blogs </div>
                </div>
            </div>
            <div *ngIf="blogs.length === 0" class="ui-block-title">
                <h6 class="display-message"> No Blogs Found! </h6>
            </div>
        </div>

    </div>
</div>
<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let blog of blogs" class="col col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="ui-block">
                    <article (click)="openModal(newsModal,blog)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{blog.newsAttachment}}" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 100%; height: 260px;"> </div>

                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{blog.newsTitle}} </a>                        

                            <div class="author-date">
                           
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{blog.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
   </section>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Matches </div>
                </div>
            </div>
        </div>
        <div *ngIf="matches.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Matches found! </h6>

        </div>
    </div>
</div>
<div *ngIf="matches.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Host Team</th>
                        <th scope="col">Host Score</th>
                        <th scope="col">Visiting Team</th>
                        <th scope="col">Visiting Score</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let match of matches | filter:searchText; let i =index;">
                        <th scope="row">{{i+1}}</th>
                        <td>{{country.name}}</td>
                        <td>{{match.hostScore}}</td>
                        <td>{{match.country.name}}</td>
                        <td>{{match.visitingScore}}</td>
                        <td>{{match.created_at | date: 'mediumDate'}}</td>
                        <td>{{match.status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Players </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}
                                </option>
                            </select>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="players.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Players found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let player of players; let i =index" class="col col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div style="background-color: transparent;" class="ui-block" data-mh="friend-groups-item">
                <div class="friend-item friend-groups">
                    <div class="friend-item-content">
                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}">
                                    <img src="{{player.avatar}}" alt="Avatar" style="width: 120px; height: 120px;" onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': player.id,'role': 'Player'}" class="h5 author-name"> {{player.fullName | titlecase}} </a>

                                <div class="friend-since" data-swiper-parallax="-100">
                                    <span class="friend-since">Position: <strong class="friend-since">
                                            {{player.playerPosition}} </strong></span> <br>
                                    <span class="friend-since">Age: <strong class="friend-since">
                                            {{age[i]}} Years </strong></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Coaches </div>
                    <div  class="w-select">
                        <fieldset class="form-group">
                            <select class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="coaches.length === 0" class="ui-block-title">
            <h6 class="display-message"> No Coaches found! </h6>
        </div>
    </div>
</div>
<div class="container">
    <div class="row justify-content-md-center">
        <div *ngFor="let coach of coaches  | filter:searchText" class="col col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
            <div style="background-color: transparent;" class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}">
                                    <img src="{{coach.coachAvatar}}" alt="Avatar" style="width: 120px; height: 120px;" onerror="this.onerror=null;this.src='../../../assets/img/author-main1.webp';" style="width: 120px; height: 120px;">
                                </a>
                            </div>
                            <div class="author-content">
                                <a routerLink="/profile-details" [queryParams]="{'id': coach.id}" class="h5 author-name"> {{coach.coachName | titlecase}} </a>                             
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div #newsSection id="newsSection" *ngIf="news.length > 4" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> News </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section *ngIf="news.length > 4" class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let _new of pageOfItems |filter:searchText" class="col col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                <div style="background-color: transparent;" class="ui-block">
                    <article (click)="openModal(newsModal,_new)" class="hentry blog-post">
                        <div class="post-thumb">
                            <img src="{{_new.newsAttachment}}" width="370" height="260" alt="Photo" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';" style="width: 100%; height: 260px;">
                        </div>
                        <div class="post-content">
                            <a href="javascript:void(0);" class="h4 post-title">{{_new.newsTitle}} </a>
                            <div class="author-date">
                                <div class="post__date">
                                    <time class="published" datetime="2017-03-24T18:18">
                                        {{_new.created_at | date:'mediumDate'}}
                                    </time>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </div>

        </div>
        <nav aria-label="Page navigation">
            <ul class="pagination justify-content-center">
                <div>
                    <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
                </div>
            </ul>
        </nav>
    </div>
</section>

<div class="modal fade" bsModal #newsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="private-event" aria-hidden="true">
    <div class="modal-dialog window-popup event-private-public private-event" role="document">
        <div class="modal-content">
            <a (click)="closeModal(newsModal)" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-body">
                <article class="hentry post has-post-thumbnail thumb-full-width private-event">

                    <div class="private-event-head inline-items">
                        <div class="author-date">
                            <a class="h3 event-title" href="javascript:void(0);">{{selectNews.newsTitle | titlecase}}</a>
                            <div class="event__date">
                                <time class="published" datetime="2017-03-24T18:18">
                                    {{selectNews.created_at | date:'mediumDate'}}
                                </time>
                            </div>
                        </div>
                    </div>
                    <div class="post-thumb">
                        <img src="{{selectNews.newsAttachment}}" alt="photo" style="height: auto; width: 100%;" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';">
                    </div>
                    <div class="row">
                        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="post__author author vcard inline-items">
                                <h3 class="head"> Description:</h3>
                            </div>
                            <p>
                                {{selectNews.newsContent | titlecase}}
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div>

<!-- <div class="modal fade" bsModal #newsModal="bs-modal" tabindex="-1" role="dialog" aria-labelledby="private-event" aria-hidden="true">
    <div class="modal-dialog window-popup event-private-public private-event" role="document">
        <div class="modal-content">
            <a (click)="closeModal(newsModal)" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>
            <div class="modal-body">
                <article class="hentry post has-post-thumbnail thumb-full-width private-event">

                    <div class="private-event-head inline-items">
                        <div class="author-date">
                            <a class="h3 event-title" href="javascript:void(0);">{{selectNews.newsTitle | titlecase}}</a>
                            <div class="event__date">
                                <time class="published" datetime="2017-03-24T18:18">
                                    {{selectNews.created_at | date:'mediumDate'}}
                                </time>
                            </div>
                        </div>
                    </div>
                    <div class="post-thumb">
                        <img src="{{selectNews.newsAttachment}}" alt="photo" style="height: auto; width: 100%;" onerror="this.onerror=null;this.src='../../../assets/img/clubs.webp';">
                    </div>
                    <div class="row">
                        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="post__author author vcard inline-items">
                                <h3 class="head"> Description:</h3>
                            </div>
                            <p>
                                {{selectNews.newsContent | titlecase}}
                            </p>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </div>
</div> -->