<!-- Fixed Sidebar Left -->

<div class="fixed-sidebar" >
    <!-- *ngIf="language !='ar'" -->
    <div class="fixed-sidebar-left sidebar--small" id="sidebar-left">

        <!-- <button> click </button> -->
        <!-- <a href="02-ProfilePage.html" class="logo">
            <div class="img-wrap">
                <img src="img/logo.webp" alt="F-F">
            </div>
        </a> -->

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="left-menu">
                <li>
                    <a (click)="toggleSideBar($event)" class="js-sidebar-open">
                        <div class="olymp-menu-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="OPEN MENU">
                            <img src="../../../assets/svg-icons/menu-icon.png" alt="Menu">
                        </div>
                    </a>
                </li>
                <div *ngIf="isIffhsLoggedIn">
                    <li
                        *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'">
                        <a routerLink="/calling-members">
                            <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                                data-original-title="Video">
                                <img src="../../../assets/svg-icons/video-icon.png" alt="Video" style="width: 35px;">
                            </div>
                        </a>
                    </li>
                </div>
                <li>
                    <a routerLink="/friend-requests">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Requests">
                            <img src="../../../assets/svg-icons/requests-icon.png" alt="Requests">
                        </div>

                    </a>
                </li>
                <li>
                    <a routerLink="/newsfeed">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="NEWSFEED">
                            <img src="../../../assets/svg-icons/newsfeed-icon.png" alt="newsfeed">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/friends">
                        <div class="olymp-happy-faces-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="FRIENDS">
                            <img src="../../../assets/svg-icons/friends.webp" alt="friends">
                        </div>
                    </a>
                </li>

                <li>
                    <a routerLink="/weather">
                        <div class="olymp-weather-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="WEATHER APP">
                            <img src="../../../assets/svg-icons/weather-icon.png" alt="weather">
                        </div>
                    </a>
                </li>
                <!-- <li>
                    <a routerLink="/calender">
                        <div class="olymp-calendar-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="CALENDAR AND EVENTS">
                            <img src="../../../assets/svg-icons/calendar-icon.png" alt="calender">
                        </div>
                    </a>
                </li> -->
                <li>
                    <a routerLink="/statistics">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Account Stats">
                            <img src="../../../assets/svg-icons/statistic-icon.png" alt="statistic">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/fans">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="My Country">
                            <img src="../../../assets/svg-icons/country-icon.png" alt="My Country">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/voting">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Voting">
                            <img src="../../../assets/svg-icons/vote-icon.png" alt="Voting">
                        </div>
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="fixed-sidebar-left sidebar--large" id="sidebar-left-1">
        <!-- <a href="javascript:void(0)" class="logo">
            <div class="img-wrap">
                <img src="../../../../assets/img/logo.webp" alt="F-F">
            </div>
            <div class="title-block">
                <h6 class="logo-title">F-F</h6>
            </div>
        </a> -->

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="left-menu">
                <li>
                    <a (click)="toggleSideBar($event)" class="js-sidebar-open">
                        <div class="olymp-close-icon left-menu-icon">
                            <img src="../../../assets/svg-icons/close-icon.webp" alt="Close">
                        </div>
                        <span class="left-menu-title">Collapse Menu</span>
                    </a>
                </li>
                <div *ngIf="isIffhsLoggedIn">
                    <li
                        *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'">
                        <a routerLink="/calling-members">
                            <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                                data-original-title="Video">
                                <img src="../../../assets/svg-icons/video-icon.png" alt="Video" style="width: 26px;">
                            </div>
                            <span class="left-menu-title">Conference</span>
                        </a>
                    </li>
                </div>
                <li>
                    <a routerLink="/friend-requests">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Requests">
                            <img src="../../../assets/svg-icons/requests-icon.png" alt="Requests">
                        </div>
                        <span class="left-menu-title">Friends Requests</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/newsfeed">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="NEWSFEED">
                            <img src="../../../assets/svg-icons/newsfeed-icon.png" alt="newsfeed">
                        </div>
                        <span class="left-menu-title">Newsfeed</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/friends">
                        <div class="olymp-happy-faces-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="FRIEND GROUPS">
                            <img src="../../../assets/svg-icons/friends.webp" alt="friends">
                        </div>
                        <span class="left-menu-title">My Friends</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/weather">
                        <div class="olymp-weather-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="WEATHER APP">
                            <img src="../../../assets/svg-icons/weather-icon.png" alt="weather">
                        </div>
                        <span class="left-menu-title">Weather </span>
                    </a>
                </li>
                <!-- <li>
                    <a routerLink="/calender">
                        <div class="olymp-calendar-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="CALENDAR AND EVENTS">
                            <img src="../../../assets/svg-icons/calendar-icon.png" alt="calender">
                        </div>
                        <span class="left-menu-title">Calendar</span>
                    </a>
                </li> -->
                <li>
                    <a routerLink="/statistics">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Account Stats">
                            <img src="../../../assets/svg-icons/statistic-icon.png" alt="statistic">
                        </div>
                        <span class="left-menu-title">Account Stats</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/fans">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="My Country">
                            <img src="../../../assets/svg-icons/country-icon.png" alt="My Country">
                        </div>
                        <span class="left-menu-title">My Country</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/voting">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Voting">
                            <img src="../../../assets/svg-icons/vote-icon.png" alt="Voting">
                        </div>
                        <span class="left-menu-title">Voting</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <canvas id="canvas" width="100" height="100"></canvas>
                    </a>
                </li>
            </ul>
            <!-- <div class="profile-completion">

                <div class="skills-item">
                    <div class="skills-item-info">
                        <span class="skills-item-title">Profile Completion</span>
                        <span class="skills-item-count"><span class="count-animate" data-speed="1000"
                                data-refresh-interval="50" data-to="76" data-from="0"></span><span class="units">76%</span></span>
                    </div>
                    <div class="skills-item-meter">
                        <span class="skills-item-meter-active bg-primary" style="width: 76%"></span>
                    </div>
                </div>

                <span>Complete <a href="#">your profile</a> so people can know more about you!</span>

            </div> -->

        </div>
    </div>
</div>

<!-- <div class="fixed-sidebar ar-fixed" *ngIf="language =='ar'" >
    <div class="fixed-sidebar-left sidebar--small" id="sidebar-left">

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="left-menu">
                <li>
                    <a (click)="toggleSideBar($event)" class="js-sidebar-open">
                        <div class="olymp-menu-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="OPEN MENU">
                            <img src="../../../assets/svg-icons/menu-icon.png" alt="Menu">
                        </div>
                    </a>
                </li>
                <template *ngIf="isIffhsLoggedIn">
                    <li
                        *ngIf="currentUser.success.user.role === 'President' || currentUser.success.user.role === 'president'">
                        <a routerLink="/calling-members">
                            <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                                data-original-title="Video">
                                <img src="../../../assets/svg-icons/video-icon.png" alt="Video" style="width: 35px;">
                            </div>
                        </a>
                    </li>
                </template>
                <li>
                    <a routerLink="/friend-requests">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Requests">
                            <img src="../../../assets/svg-icons/requests-icon.png" alt="Requests">
                        </div>

                    </a>
                </li>
                <li>
                    <a routerLink="/newsfeed">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="NEWSFEED">
                            <img src="../../../assets/svg-icons/newsfeed-icon.png" alt="newsfeed">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/friends">
                        <div class="olymp-happy-faces-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="FRIENDS">
                            <img src="../../../assets/svg-icons/friends.webp" alt="friends">
                        </div>
                    </a>
                </li>

                <li>
                    <a routerLink="/weather">
                        <div class="olymp-weather-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="WEATHER APP">
                            <img src="../../../assets/svg-icons/weather-icon.png" alt="weather">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/calender">
                        <div class="olymp-calendar-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="CALENDAR AND EVENTS">
                            <img src="../../../assets/svg-icons/calendar-icon.png" alt="calender">
                        </div>
                    </a>
                </li>

                <li>
                    <a routerLink="/statistics">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Account Stats">
                            <img src="../../../assets/svg-icons/statistic-icon.png" alt="statistic">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/fans">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="My Country">
                            <img src="../../../assets/svg-icons/country-icon.png" alt="My Country">
                        </div>
                    </a>
                </li>
                <li>
                    <a routerLink="/voting">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Voting">
                            <img src="../../../assets/svg-icons/vote-icon.png" alt="Voting">
                        </div>
                    </a>
                </li>

            </ul>
        </div>
    </div>

    <div class="fixed-sidebar-left sidebar--large" id="sidebar-left-1">

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <ul class="left-menu">
                <li>
                    <a (click)="toggleSideBar($event)" class="js-sidebar-open">
                        <div class="olymp-close-icon left-menu-icon">
                            <img src="../../../assets/svg-icons/close-icon.webp" alt="Close">
                        </div>
                        <span class="left-menu-title">Collapse Menu</span>
                    </a>
                </li>
                <template *ngIf="isIffhsLoggedIn">
                    <li
                        *ngIf="currentUser.success.user.role === 'president' || currentUser.success.user.role === 'IFFHS Executive Member'">
                        <a routerLink="/calling-members">
                            <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                                data-original-title="Video">
                                <img src="../../../assets/svg-icons/video-icon.png" alt="Video" style="width: 26px;">
                            </div>
                            <span class="left-menu-title">Conference</span>
                        </a>
                    </li>
                </template>
                <li>
                    <a routerLink="/friend-requests">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Requests">
                            <img src="../../../assets/svg-icons/requests-icon.png" alt="Requests">
                        </div>
                        <span class="left-menu-title">Friends Requests</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/newsfeed">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="NEWSFEED">
                            <img src="../../../assets/svg-icons/newsfeed-icon.png" alt="newsfeed">
                        </div>
                        <span class="left-menu-title">Newsfeed</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/friends">
                        <div class="olymp-happy-faces-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="FRIEND GROUPS">
                            <img src="../../../assets/svg-icons/friends.webp" alt="friends">
                        </div>
                        <span class="left-menu-title">My Friends</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/weather">
                        <div class="olymp-weather-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="WEATHER APP">
                            <img src="../../../assets/svg-icons/weather-icon.png" alt="weather">
                        </div>
                        <span class="left-menu-title">Weather </span>
                    </a>
                </li>
                <li>
                    <a routerLink="/calender">
                        <div class="olymp-calendar-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="CALENDAR AND EVENTS">
                            <img src="../../../assets/svg-icons/calendar-icon.png" alt="calender">
                        </div>
                        <span class="left-menu-title">Calendar</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/statistics">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Account Stats">
                            <img src="../../../assets/svg-icons/statistic-icon.png" alt="statistic">
                        </div>
                        <span class="left-menu-title">Account Stats</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/fans">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="My Country">
                            <img src="../../../assets/svg-icons/country-icon.png" alt="My Country">
                        </div>
                        <span class="left-menu-title">My Country</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/voting">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Voting">
                            <img src="../../../assets/svg-icons/vote-icon.png" alt="Voting">
                        </div>
                        <span class="left-menu-title">Voting</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <canvas id="canvas" width="100" height="100"></canvas>
                    </a>
                </li>
            </ul>

        </div>
    </div>
</div> -->

<!-- ... end Fixed Sidebar Left -->


<!-- Fixed Sidebar Left -->

<div class="fixed-sidebar fixed-sidebar-responsive">

    <div class="fixed-sidebar-left sidebar--small" id="sidebar-left-responsive">
        <a (click)="toggleSideBar($event)" class="logo js-sidebar-open">
            <img src="assets/img/logo.webp" alt="F-F">
        </a>

    </div>

    <div class="fixed-sidebar-left sidebar--large" id="sidebar-left-1-responsive">
        <!-- <a href="#" class="logo">
            <div class="img-wrap">
                <img src="../../../../assets/img/logo.webp" alt="F-F">
            </div>
            <div class="title-block">
                <h6 class="logo-title">Football Families</h6>
            </div>
        </a> -->

        <div class="mCustomScrollbar" data-mcs-theme="dark">
            <div *ngIf="isIffhsLoggedIn">
                <div class="control-block">
                    <div class="author-page author vcard inline-items">
                        <div class="author-thumb">
                            <img *ngIf="!currentUser.success.user.avatar" src="assets/img/author-main1.webp"
                                class="avatar" alt="author" style="height: 50px;width: 50px;">
                            <img *ngIf="currentUser.success.user.avatar" [src]="currentUser.success.user.avatar"
                                alt="author" style="height: 50px;width: 50px;">
                            <span class="icon-status online"></span>
                        </div>
                        <a routerLink="/profile" class="author-name fn">
                            <div class="author-title">
                                {{ currentUser.success.user.fullName | titlecase }} <svg
                                    class="olymp-dropdown-arrow-icon">
                                    <use
                                        xlink:href="./../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
                                    </use>
                                </svg>
                            </div>
                            <span class="author-title">{{ currentUser.success.user.countryName }}</span><br>
                            <span class="author-title">Role: {{ currentUser.success.user.role }}</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="ui-block-title ui-block-title-small">
                <h6 class="title">MAIN SECTIONS</h6>
            </div>

            <ul class="left-menu">
                <li>
                    <a (click)="toggleSideBar($event)" class="js-sidebar-open">
                        <svg class="olymp-close-icon left-menu-icon">
                            <use xlink:href="./../../assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                        </svg>
                        <span class="left-menu-title">Collapse Menu</span>
                    </a>
                </li>
                <div *ngIf="isIffhsLoggedIn">
                    <li *ngIf="currentUser.success.user.role === 'President' || currentUser.success.user.role === 'president'">
                        <a routerLink="/calling-members">
                            <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                                data-original-title="Video">
                                <img src="../../../assets/svg-icons/video-icon.png" alt="Video" style="width: 26px;">
                            </div>
                            <span class="left-menu-title">Conference</span>
                        </a>
                    </li>
                </div>
                <li>
                    <a routerLink="/friend-requests">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Requests">
                            <img src="../../../assets/svg-icons/requests-icon.png" alt="Requests">
                        </div>
                        <span class="left-menu-title">Friends Requests</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/newsfeed">
                        <div class="olymp-newsfeed-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="NEWSFEED">
                            <img src="../../../assets/svg-icons/newsfeed-icon.png" alt="newsfeed">
                        </div>
                        <span class="left-menu-title">Newsfeed</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/friends">
                        <div class="olymp-happy-faces-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="FRIEND GROUPS">
                            <img src="../../../assets/svg-icons/friends.webp" alt="friends">
                        </div>
                        <span class="left-menu-title">My Friends</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/weather">
                        <div class="olymp-weather-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="WEATHER APP">
                            <img src="../../../assets/svg-icons/weather-icon.png" alt="weather">
                        </div>
                        <span class="left-menu-title">Weather </span>
                    </a>
                </li>
                <!-- <li>
                    <a routerLink="/calender">
                        <div class="olymp-calendar-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="CALENDAR AND EVENTS">
                            <img src="../../../assets/svg-icons/calendar-icon.png" alt="calender">
                        </div>
                        <span class="left-menu-title">Calendar</span>
                    </a>
                </li> -->

                <li>
                    <a routerLink="/statistics">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Account Stats">
                            <img src="../../../assets/svg-icons/statistic-icon.png" alt="statistic">
                        </div>
                        <span class="left-menu-title">Account Stats</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/fans">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="My Country">
                            <img src="../../../assets/svg-icons/country-icon.png" alt="My Country">
                        </div>
                        <span class="left-menu-title">My Country</span>
                    </a>
                </li>
                <li>
                    <a routerLink="/voting">
                        <div class="olymp-stats-icon left-menu-icon" data-toggle="tooltip" data-placement="right"
                            data-original-title="Voting">
                            <img src="../../../assets/svg-icons/vote-icon.png" alt="Voting">
                        </div>
                        <span class="left-menu-title">Voting</span>
                    </a>
                </li>

            </ul>

            <div class="ui-block-title ui-block-title-small">
                <h6 class="title">YOUR ACCOUNT</h6>
            </div>

            <ul class="account-settings">
                <li>
                    <a routerLink="/profile-setting">

                        <svg class="olymp-menu-icon">
                            <use xlink:href="./../../assets/svg-icons/sprites/icons.svg#olymp-menu-icon"></use>
                        </svg>

                        <span>Profile Settings</span>
                    </a>
                </li>
                <!-- <li>
                    <a href="#">
                        <svg class="olymp-star-icon left-menu-icon" data-toggle="tooltip" data-placement="right" data-original-title="FAV PAGE">
                            <use xlink:href="./../../assets/svg-icons/sprites/icons.svg#olymp-star-icon"></use>
                        </svg>

                        <span>Create Fav Page</span>
                    </a>
                </li> -->
                <li>
                    <a (click)="logout()">
                        <svg class="olymp-logout-icon">
                            <use xlink:href="./../../assets/svg-icons/sprites/icons.svg#olymp-logout-icon"></use>
                        </svg>

                        <span>Log Out</span>
                    </a>
                </li>
            </ul>

            <div class="ui-block-title ui-block-title-small">
                <h6 class="title">About Football Families</h6>
            </div>

            <ul class="about-olympus">
                <li>
                    <a href="javascript:void(0)">
                        <span>Terms and Conditions</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <span>FAQs</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <span>Careers</span>
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0)">
                        <span>Contact</span>
                    </a>
                </li>
            </ul>

        </div>
    </div>
</div>