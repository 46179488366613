import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-add-ranking',
  templateUrl: './add-ranking.component.html',
  styleUrls: ['./add-ranking.component.css']
})
export class AddRankingComponent implements OnInit {

  addRankingForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  awards = [];
  years = [];
  ranking = [];
  domElement: string = 'view';
  selectedRank: any = {};
  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    private messageService: MessageService

  ) {
    this.years = Array(20).fill(0).map((x, i) => ({ name: (i + 2019) }));
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getAllRanking();
    this.addRankingForm = this.formBuilder.group({
      category: ['', Validators.required],
      award: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      yearOfRanking: ['', Validators.required],
      rank: ['', Validators.required],
      avatar: ['', Validators.required],
      createdBy: [this.currentUser.success.user.id],
    });
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }


  onFileSelect(event) {
    const avatar = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.addRankingForm.get('avatar').setValue(avatar);

  }
  getAllRanking() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllRanking().subscribe((res: any) => {
      this.ranking = res.ranking;
      this.isLoaderVisible = false;
    });
  }
  onClick(add: string) {
    if (add === 'add') {
      this.domElement = add;
      this.getAllAwards();
    } else if (add === 'view') {
      this.domElement = add;
      this.getAllRanking();
    }
  }
  openDeleteModal(modal: any, rank: any) {
    this.selectedRank = rank;
    modal.show();
  }
  deleteRank(modal: any) {
    this.isLoaderVisible = true;
    const data = {
      rankId: this.selectedRank.id,
    };
    this.iffhsService.deleteRank(data).subscribe(
      res => {
        modal.hide();
        this.isLoaderVisible = false;
        this.getAllRanking();
        this.toastrService.success('Rank Deleted', 'Message!');
      },
      error => {
        console.log(error);
      }
    );
  }
  getAllAwards() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllAwards().subscribe((res: any) => {
      this.awards = res.awards;
      this.isLoaderVisible = false;
    });
  }

  // onChangeCategory(e) {
  //   const onChangeCategoryValue = e.target.value || 0;
  //   this.addRankingForm.get('category').setValue(onChangeCategoryValue);
  // }


  // onChangeAward(e) {
  //   const onChangeAwardValue = e.target.value || 0;
  //   this.addRankingForm.get('award').setValue(onChangeAwardValue);
  // }
  get f() { return this.addRankingForm.controls; }
  addRanking() {
    this.submitted = true;
    if (this.addRankingForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('category', this.addRankingForm.get('category').value);
    fd.append('award', this.addRankingForm.get('award').value);
    fd.append('firstName', this.addRankingForm.get('firstName').value);
    fd.append('lastName', this.addRankingForm.get('lastName').value);
    fd.append('yearOfRanking', this.addRankingForm.get('yearOfRanking').value);
    fd.append('rank', this.addRankingForm.get('rank').value);
    fd.append('avatar', this.addRankingForm.get('avatar').value);
    fd.append('createdBy', this.addRankingForm.get('createdBy').value);
    this.iffhsService.addRanking(fd).pipe(first()).subscribe(
      data => {
        this.getAllRanking();
        this.isLoaderVisible = false;
        this.toastrService.success('Raking Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');
      }
    );
    this.resetForm();
  }
  resetForm() {
    this.addRankingForm = this.formBuilder.group({
      category: [''],
      award: [''],
      firstName: [''],
      lastName: [''],
      yearOfRanking: [''],
      rank: [''],
      avatar: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
