import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-friend-requests',
  templateUrl: './friend-requests.component.html',
  styleUrls: ['./friend-requests.component.css']
})
export class FriendRequestsComponent implements OnInit {
  currentUser: any;
  userData: any = {};

  friends = [];

  messageFlag = false;
  message = '';

  constructor(
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getFriendsRequests();
  }

  getFriendsRequests() {
    this.messageFlag = false;
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getFriendsRequests(data).pipe(first<any[]>()).subscribe(friends => this.friends = friends);
  }


  acceptRequest(id: number) {
    const data = {
      userId: this.currentUser.success.user.id,
      friendId: id,
      status: 'confirmed',
    };
    this.userService.confirmFriend(data).pipe(first()).subscribe(friends => this.getFriendsRequests());
    this.getFriendsRequests();
  }


  declineRequest(id: number) {
    const data = {
      userId: this.currentUser.success.user.id,
      friendId: id,
      status: 'declined',
    };
    this.userService.confirmFriend(data).pipe(first()).subscribe(friends => this.getFriendsRequests());
    this.getFriendsRequests();
  }

}
