<app-award-dashboard></app-award-dashboard>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 order-xl-2 col-lg-12 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Edit IFFHS Award Banner </h6>
                    <button (click)="goToDashboard()" class="btn btn-primary float-right" style="font-size: 1.5rem;"> <i
                            class="fa fa-eye" aria-hidden="true"></i>

                        View</button>
                </div>
                <div class="ui-block-content">
                    <form *ngIf="awardBannerForm" class="content" [formGroup]="awardBannerForm"
                        (ngSubmit)="editAwardBanner()">
                        <div class="row">

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <!-- <div class="form-group">
                                    <label class="control-label">Enter Banner ID<span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="bannerId" disabled class="form-control" />
                                    <div *ngIf="submitted && f.bannerId.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerId.errors.required">ID is required *</div>
                                    </div>
                                </div> -->
                                <div class="form-group">
                                    <label class="control-label">Banner Name <span style="color: red;"> *
                                        </span></label>
                                    <input type="text" formControlName="bannerName" class="form-control"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerName.errors }" />
                                    <div *ngIf="submitted && f.bannerName.errors" class="invalid-feedback">
                                        <div *ngIf="f.bannerName.errors.required">Name is required *</div>
                                    </div>
                                </div>

                                <!-- <div class="form-group">
                                    <label class="control-label">Number of Awards <span style="color: red;"> *
                                        </span> </label>
                                    <input type="text" formControlName="numberOfAwards" value="{{banner.awards.length}}" placeholder="23" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.numberOfAwards.errors }" />

                                    <div *ngIf="submitted && f.numberOfAwards.errors" class="invalid-feedback">
                                        <div *ngIf="f.numberOfAwards.errors.required">Award Numbers is required *</div>
                                    </div>
                                </div> -->

                                <!-- <img src="https://api.football-family.com/img/awards/banners/{{banner.id}}.png" height="120" alt="photo" width="100%"><br> -->

                                <div class="form-group">
                                    <label class="control-label">Photo ( Select only Image ) </label>
                                    <input type="file" formControlName="bannerPhoto" accept="image/*"
                                        class="form-control" (change)="onImageSelect($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.bannerPhoto.errors }" />
                                    <!-- <div *ngIf=" submitted && f.bannerPhoto.errors " class="invalid-feedback ">
                                        <div *ngIf="f.bannerPhoto.errors.required ">Photo is required *</div>
                                    </div> -->

                                </div>
                            </div>

                            <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <button class="btn btn-primary btn-lg full-width"> Update
                                </button>
                            </div>


                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>