import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { PostService } from 'src/app/data-services/post.service';

@Component({
  selector: 'app-f-photos',
  templateUrl: './f-photos.component.html',
  styleUrls: ['./f-photos.component.css']
})
export class FPhotosComponent implements OnInit {

  id: any;
  friendData: any = {};
  currentUser: any;
  photos: any = [];
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private postService: PostService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(
      params => this.id = params.id
    );
    this.friendDetails();
    this.getUserPhotos();
  }
  friendDetails() {
    const data = {
      id: this.id,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );
  }
  getUserPhotos() {
    const data = {
      userId: this.id,
    };
    this.postService.getUserPhotos(data).pipe(first<any>()).subscribe(photos => this.photos = photos);
    console.log(this.photos);
  }

}
