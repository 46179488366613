import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
// import { writeFileSync, readFileSync } from 'fs';

declare var require: any;
@Component({
  selector: 'app-legends',
  templateUrl: './legends.component.html',
  styleUrls: ['./legends.component.css']
})
export class LegendsComponent implements OnInit {
  phase: number;
  gender: string;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  legends = [];
  searchText: string;
  selectLegend: any = {};
  items = [];
  pageOfItems: Array<any>;
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.phase = params['phase'];
      this.gender = params['gender'];
    });
    this.getLegends();

  }
  onChangePage(pageOfItems: Array<any>) {
    this.pageOfItems = pageOfItems;
  }
  getLegends() {
    this.isLoaderVisible = true;
    const data = {
      phase: this.phase,
      gender: this.gender,
    };
    this.iffhsService.getLegends(data).subscribe((res: any) => {
      this.legends = res.legends;
      this.items = this.legends;
      this.isLoaderVisible = false;
    });
  }
  getLegendProfile(modal: any, legend: any) {
    this.isLoaderVisible = true;
    const data = {
      legendId: legend.id,
    };
    this.iffhsService.getLegendProfile(data).subscribe((res: any) => {
      this.selectLegend = res.legends;
      this.isLoaderVisible = false;
    });
    modal.show();
  }
  closeModal(modal: any) {
    modal.hide();
  }

}
