import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PostService } from 'src/app/data-services/post.service';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-iffhs-news',
  templateUrl: './iffhs-news.component.html',
  styleUrls: ['./iffhs-news.component.css']
})
export class IffhsNewsComponent implements OnInit {
  currentUser: any;
  postForm: FormGroup;
  commentForm: FormGroup;
  updatePostForm: FormGroup;
  submitted: boolean;
  errorFlag: boolean;
  loading: boolean;
  visible: boolean;
  posts: any;
  likes: any = {};
  userData: any = {};
  suggestedFriends = [];
  suggestedFollowers = [];
  cloading: boolean;
  selectPost: any = {};
  selectComment: any = {};
  selectPostId: number;
  comments: any;
  public show: number;
  public buttonName: any = 'Show';
  fileName = '';
  today = new Date();
  imageName = '';
  videoName = '';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private postService: PostService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    // this.getProfile();
    this.loadIffhsPost();
    this.suggestsFriends();
    this.suggestsFollowers();

    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: ['', Validators.required],
      image: [''],
      video: [''],
      type: [''],


    });

    this.commentForm = this.formBuilder.group({
      content: ['', Validators.required],
      post_id: '',
    });
  }
  ///////////////////////////////////////////////// user
  // getProfile() {
  //   const data = {
  //     id: this.currentUser.success.user.id,
  //   };
  //   this.userService.getProfile(data).pipe(first()).subscribe(
  //     userData => this.userData = userData[0]
  //   );

  // }
  suggestsFriends() {
    const data = {
      userId: this.currentUser.success.user.id,
    };
    this.userService.getSuggestFriends(data).pipe(first<any[]>()).subscribe(suggestedFriends => this.suggestedFriends = suggestedFriends);
  }

  suggestsFollowers() {
    const data = {
      userId: this.currentUser.success.user.id,
    };

    this.userService.getSuggestFollowers(data).pipe(first<any[]>())
      .subscribe(suggestedFollowers => this.suggestedFollowers = suggestedFollowers);
  }

  /////////////////////////////////////// Posts Code
  loadIffhsPost() {
    // const data = {
    //   user_id: this.currentUser.success.user.id,
    // };
    this.postService.getIffhsPosts().pipe(first()).subscribe(posts => this.posts = posts);
    // console.log(this.posts);
  }
  onImageSelect(event) {
    const image = event.target.files[0];
    const type = event.target.files[0].type;
    console.log(image);
    console.log(type);
    if (type.match(/image\/*/) === null) {
      alert('Only Image  are supported');
      return;
    }
    this.imageName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('image').setValue(image);

  }
  onVideoSelect(event) {
    const video = event.target.files[0];
    const type = event.target.files[0].type;
    console.log(video);
    console.log(type);
    if (type.match(/video\/*/) === null) {
      alert('Only video  are supported');
      return;
    }
    this.videoName = event.target.files[0].name;
    this.postForm.get('type').setValue(type);
    this.postForm.get('video').setValue(video);

  }
  get f() { return this.postForm.controls; }
  setPost() {
    this.submitted = true;
    this.errorFlag = false;
    this.loading = true;
    if (this.postForm.invalid) {
      this.loading = false;
      return;
    }
    const fd = new FormData();
    fd.append('user_id', this.postForm.get('user_id').value);
    fd.append('content', this.postForm.get('content').value);
    fd.append('type', this.postForm.get('type').value);
    fd.append('image', this.postForm.get('image').value);
    fd.append('video', this.postForm.get('video').value);

    this.postService.post(fd).pipe(first()).subscribe(
      data => {
        console.log('successfully post');
        this.visible = false;
        this.loading = false;
        this.errorFlag = false;
        this.submitted = true;
        this.loadIffhsPost();
      },
      error => {
        console.log(error);
        console.log('Post failed');
        this.visible = false;
        this.loading = false;
        this.errorFlag = false;

      }
    );
    this.postForm = this.formBuilder.group({
      user_id: this.currentUser.success.user.id,
      content: [''],
      image: [''],
      type: [''],
    });
    // this.fileName = '';

  }
  deletePost(modal: any) {
    const data = {
      postId: this.selectPost.id,

    };
    this.postService.deletePost(data).subscribe(
      posts => {
        modal.hide();
        this.loadIffhsPost();
        console.log('deleted');
      },
      error => {
        console.log(error);
        console.log('failed');
      }
    );
  }
  openDeleteModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  openEditModal(modal: any, post: any) {
    this.selectPost = post;
    modal.show();
  }
  updatePost(modal: any) {
    const data = {
      id: this.selectPost.id,
      content: this.selectPost.content,
      attachment: this.selectPost.attachment,
    };


    this.postService.updatePost(data).subscribe(
      updatedPost => {
        console.log('updated');
        modal.hide();
      },
      error => {
        console.log(error);
        console.log('updated Failed');
      }
    );
  }

  closeEditModal(modal: any) {
    modal.hide();
    this.loadIffhsPost();
  }



  ///////////////////////////////////////// Likes Codes

  likeUnlike(id: number) {
    const data = {
      post_id: id,
      user_id: this.currentUser.success.user.id,

    };
    this.postService.likeUnlike(data).pipe(first()).subscribe(
      succuss => {
        // console.log(data);
        console.log('successfully  ');
        this.loading = false;
        this.errorFlag = false;
        this.loadIffhsPost();
        // this.woLikedMyPost(id);
      },
      error => {
        console.log(' Like ');
        this.loading = false;
        this.errorFlag = false;
      }
    );
  }

  ///////////////////////////////////////// Comments Codes

  doComment(pId: number) {
    this.cloading = true;

    if (this.commentForm.valid === true) {
      this.commentForm.value.post_id = pId;
      console.log(pId);
      this.commentForm.value.commenter_id = this.currentUser.success.user.id;
      this.postService.comment(this.commentForm.value).pipe(first()).subscribe(
        succuss => {
          console.log('comment');
          this.cloading = false;
          this.loadIffhsPost();
          this.showComments(pId);
        },
        error => {
          console.log('Comment failed');
          this.cloading = false;
        }
      );
    } else {
      console.log('Validation error');
      this.cloading = false;
    }
    this.commentForm.reset();

  }

  openCommentDeleteModal(modal: any, comment: any, postId: number) {
    this.selectComment = comment;
    this.selectPostId = postId;
    modal.show();
  }

  deleteComment(modal: any, postId: number) {
    const data = {
      commentId: this.selectComment.id,
      post_id: this.selectPostId,
    };
    this.postService.deleteComment(data).subscribe(
      comments => {
        modal.hide();
        console.log('deleted');
        this.loadIffhsPost();
        this.showComments(postId);
      },
      error => {
        console.log(error);
        console.log('failed');
      }
    );
  }

  toggle(Id: number) {
    if (this.show !== Id) {
      this.show = Id;
    } else {
      this.show = 0;
    }

    this.showComments(Id);

    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }

  }


  showComments(Id: number) {
    const data = {
      postId: Id,
    };
    this.postService.getAllComments(data).pipe(first()).subscribe(comments => this.comments = comments);
    console.log(this.comments);

  }


}
