import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-friend-profile-header',
  templateUrl: './friend-profile-header.component.html',
  styleUrls: ['./friend-profile-header.component.css']
})
export class FriendProfileHeaderComponent implements OnInit {
  id: number;
  role: number;
  friendData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
  ) {
    console.log(window.location);
    this.friendData = JSON.parse(localStorage.getItem('friendData'));
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.role = params['role'];
    });
    this.friendDetails();
  }


  friendDetails() {
    const data = {
      id: this.id,
      role: this.role,
    };
    this.userService.getProfile(data).subscribe((res: any) => {
      this.friendData = res;
      localStorage.setItem('friendData', JSON.stringify(this.friendData));

    });
  }

  follow(id: number) {
    const data = {
      user_id: this.currentUser.success.user.id,
      follower_id: id,
    };

    this.userService.follow(data).pipe(first<boolean>()).subscribe(
      succuss => {
        if (succuss) {
          this.followed = succuss;
          this.toastrService.success('Followed', 'Message!');
          this.friendDetails();
        } else if (!succuss) {
          this.toastrService.success('Unfollow', 'Message!');
          this.friendDetails();
        }

      },
      error => {
        console.log(' failed');
      }
    );
  }

  request(id: number) {
    const data = {
      user_id: this.currentUser.success.user.id,
      friendId: id,
    };

    this.userService.request(data).pipe(first()).subscribe(
      succuss => {
        if (succuss) {
          this.toastrService.success('Your request has been sent.', 'Message!');
          this.friendDetails();
        } else if (!succuss) {
          this.toastrService.success('Friend Removed', 'Message!');
          this.friendDetails();
        }
      },
      error => {
        console.log(' failed');
      }
    );
  }
  openChatModal(modal: any, friend: any) {
    this.selectFriend = friend;
    modal.show();
  }

  closeChatModal(modal: any) {
    modal.hide();
  }

}
