<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-friend-profile-header></app-friend-profile-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">

        <!-- Main Content -->

        <main class="col col-xl-6 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">

            <div id="newsfeed-items-grid">
                <div *ngIf="friendPosts.length === 0" class="ui-block-title">
                    <h6 class="display-message">No Post Found!
                    </h6>
                </div>
                <div *ngFor="let post of friendPosts" class="ui-block">
                    <article class="hentry post">
                        <div class="post__author author vcard inline-items">
                            <a routerLink="/profile-details" [queryParams]="{'id': friendData.id}"><img
                                    *ngIf="!friendData.avatar" src="assets/img/author-main1.webp" width="40"
                                    height="40" alt="author"></a>
                            <a routerLink="/profile-details" [queryParams]="{'id': friendData.id}"><img
                                    *ngIf="friendData.avatar" alt="author" [src]="friendData.avatar" class="avatar"
                                    width="40" height="40"></a>

                            <div class="author-date">
                                <a class="h6 post__author-name fn" routerLink="/profile-details"
                                    [queryParams]="{'id': friendData.id}">{{friendData.fullName}}</a>
                                <div class="post__date">
                                    <time class="published" datetime="2004-07-24T18:18">
                                        {{post.created_at | date}}
                                    </time>
                                </div>
                            </div>
                        </div>
                        <p>
                            {{post.content |titlecase}}
                        </p>
                        <div class="post-thumb">
                            <img *ngIf="post.image" [src]="post.image" alt="Image ">
                            <video *ngIf="post.video" id="my_video_1" class="video-js vjs-default-skin" width="100%"
                                height="auto" controls muted
                                data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                                <source [src]="post.video" type='video/mp4' />
                                <source [src]="post.video" type="video/webm">
                                Sorry, your browser doesn't support embedded videos.
                            </video>
                            <p *ngIf="post.document">Click Here! <a href="{{post.document}}">{{post.content}}</a>.</p>
                        </div>


                        <div class="post-additional-info inline-items">

                            <a (click)="likeUnlike(post.id)" class="post-add-icon inline-items">
                                <img src="assets/svg-icons/unLike.webp" height="34" width="34">
                                <span>{{post.likesCounter}}</span>
                            </a>

                            <div class="comments-shared">
                                <a (click)="toggle(post.id)" class="post-add-icon inline-items">
                                    <svg class="olymp-speech-balloon-icon">
                                        <use
                                            xlink:href="assets/svg-icons/sprites/icons.svg#olymp-speech-balloon-icon">
                                        </use>
                                    </svg>
                                    <span>{{post.commentsCounter}}</span>
                                </a>

                            </div>


                        </div>

                        <div class="control-block-button post-control-button">

                            <a (click)="likeUnlike(post.id)" class="btn btn-control">
                                <img src="assets/svg-icons/unLike.webp" height="34" width="34">
                            </a>

                            <a click)="toggle(post.id)" class="btn btn-control">
                                <svg class="olymp-comments-post-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-comments-post-icon">
                                    </use>
                                </svg>
                            </a>

                        </div>

                    </article>

                    <!-- Comments -->

                    <ul *ngIf="show && show === post.id" class="comments-list">
                        <li *ngFor="let comment of comments" class="comment-item">
                            <div class="post__author author vcard inline-items">
                                <img *ngIf="!comment.avatar" src="assets/img/author-main1.webp" width="30"
                                    height="30" alt="author" class="mCS_img_loaded">
                                <img *ngIf="comment.avatar" alt="author" [src]="comment.avatar" class="mCS_img_loaded"
                                    width="30" height="30">

                                <div class="author-date">
                                    <a class="h6 post__author-name fn"> {{ comment.fullName}}</a>
                                    <div class="post__date">
                                        <time class="published" datetime="2004-07-24T18:18">
                                            {{comment.created_at | date}}
                                        </time>
                                    </div>
                                </div>
                                <a (click)="openCommentDeleteModal(commentDeleteModal, comment , post.id)"
                                    class="more"><i *ngIf="currentUser.success.user.id === friendData.id"
                                        class="fa fa-trash" aria-hidden="true"></i></a>


                            </div>

                            <p>{{ comment.commentsContent | titlecase}}.</p>

                            <!-- <a href="javascript:void(0);" class="post-add-icon inline-items">
								<svg class="olymp-heart-icon">
									<use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-heart-icon">
									</use>
								</svg>
								<span>3</span>
							</a> -->
                            <!-- <a  href="javascript:void(0);" class="reply">Reply</a> -->
                        </li>

                        <!-- Comment Form  -->

                        <form [formGroup]="commentForm" (ngSubmit)="doComment(post.id)"
                            class="comment-form inline-items">

                            <div class="post__author author vcard inline-items">
                                <img *ngIf="!friendData.avatar" src="assets/img/author-main1.webp"
                                    alt="author">
                                <img *ngIf="friendData.avatar" alt="author" [src]="friendData.avatar" class="avatar"
                                    width="28" height="28">

                                <div class="form-group with-icon-right ">
                                    <textarea class="form-control" formControlName="content" placeholder=""></textarea>
                                    <div class="add-options-message">
                                        <a href="javascript:void(0);" class="options-message" data-toggle="modal"
                                            data-target="#update-header-photo">
                                            <svg class="olymp-camera-icon">
                                                <use
                                                    xlink:href="assets/svg-icons/sprites/icons.svg#olymp-camera-icon">
                                                </use>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <button [disabled]="cloading" class="btn btn-md-2 btn-primary">
                                <!-- <span *ngIf="cloading" class="spinner-border spinner-border-sm mr-1"></span> -->
                                Comment
                            </button>

                        </form>
                        <!-- ... end Comment Form  -->

                    </ul>

                    <!-- ... end Comments -->

                    <!-- <a  href="javascript:void(0);" class="more-comments">View more comments <span>+</span></a> -->



                </div>

            </div>

        </main>

        <!-- ... end Main Content -->


        <!-- Left Sidebar -->

        <div class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">

            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Profile Intro</h6>
                </div>
                <div class="ui-block-content">

                    <ul class="widget w-personal-info item-block">
                        <li>
                            <span class="title">About Me:</span>
                            <span class="text">Hi, I’m
                                {{friendData.fullName | titlecase}},{{friendData.about | titlecase}}</span>
                        </li>
                        <li>
                            <span class="title">Hobbies:</span>
                            <span class="text">{{friendData.hobby1}}</span>
                            <span *ngIf="!friendData.hobby1" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Favourite Games:</span>
                            <span class="text">{{friendData.hobby2}}</span>
                            <span *ngIf="!friendData.hobby2" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Favourite TV Shows:</span>
                            <span class="text">{{friendData.hobby3}}</span>
                            <span *ngIf="!friendData.hobby3" class="text">No info available !</span>
                        </li>
                        <li>
                            <span class="title">Other Interests:</span>
                            <span class="text">{{friendData.hobby4}}</span>
                            <span *ngIf="!friendData.hobby4" class="text">No info available !</span>
                        </li>
                    </ul>

                    <div class="widget w-socials">
                        <h6 *ngIf="friendData.facebook || friendData.twitter " class="title">Other Social Networks:</h6>
                        <a *ngIf="friendData.facebook" href="https://{{friendData.facebook}}"
                            class="social-item bg-facebook">
                            <i class="fab fa-facebook-f" aria-hidden="true"></i> Facebook
                        </a>
                        <a *ngIf="friendData.twitter" href="https://{{friendData.twitter}}"
                            class="social-item bg-twitter">
                            <i class="fab fa-twitter" aria-hidden="true"></i> Twitter
                        </a>
                    </div>
                   
                </div>
            </div>
            <div class="ui-block">             

                <div class="widget w-wethear">
                    <a href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
                            <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
                        </svg></a>

                    <div class="wethear-now inline-items">
                        <div class="temperature-sensor">64°</div>
                        <div class="max-min-temperature">
                            <span>58°</span>
                            <span>76°</span>
                        </div>

                        <svg class="olymp-weather-partly-sunny-icon">
                            <use
                                xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                            </use>
                        </svg>
                    </div>

                    <div class="wethear-now-description">
                        <div class="climate">Partly Sunny</div>
                        <span>Real Feel: <span>67°</span></span>
                        <span>Chance of Rain: <span>49%</span></span>
                    </div>

                    <ul class="weekly-forecast">

                        <li>
                            <div class="day">sun</div>
                            <svg class="olymp-weather-sunny-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-sunny-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">60°</div>
                        </li>

                        <li>
                            <div class="day">mon</div>
                            <svg class="olymp-weather-partly-sunny-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-partly-sunny-icon">
                                </use>
                            </svg>
                            <div class="temperature-sensor-day">58°</div>
                        </li>

                        <li>
                            <div class="day">tue</div>
                            <svg class="olymp-weather-cloudy-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-cloudy-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">67°</div>
                        </li>

                        <li>
                            <div class="day">wed</div>
                            <svg class="olymp-weather-rain-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-rain-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">70°</div>
                        </li>

                        <li>
                            <div class="day">thu</div>
                            <svg class="olymp-weather-storm-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-storm-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">58°</div>
                        </li>

                        <li>
                            <div class="day">fri</div>
                            <svg class="olymp-weather-snow-icon">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-snow-icon">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">68°</div>
                        </li>

                        <li>
                            <div class="day">sat</div>

                            <svg class="olymp-weather-wind-icon-header">
                                <use
                                    xlink:href="assets/svg-icons/sprites/icons-weather.svg#olymp-weather-wind-icon-header">
                                </use>
                            </svg>

                            <div class="temperature-sensor-day">65°</div>
                        </li>

                    </ul>

                    <div class="date-and-place">
                        <h5 class="date">{{today | date: 'fullDate'}}</h5>
                        <h5 class="date">{{today | date: 'mediumTime'}}</h5>
                        <!-- <div class="place">{{userData.countryName}}</div> -->
                    </div>

                </div>

                <!-- W-Weather -->
            </div>

            <div class="ui-block">

                <!-- W-Calendar -->

                <div class="w-calendar">
                    <div class="calendar">
                        <header>
                            <h6 class="month">May</h6>
                        </header>
                        <table>
                            <thead>
                                <tr>
                                    <td>Mon</td>
                                    <td>Tue</td>
                                    <td>Wed</td>
                                    <td>Thu</td>
                                    <td>Fri</td>
                                    <td>Sat</td>
                                    <td>San</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-month="12" data-day="1">1</td>
                                    <td data-month="12" data-day="2">
                                        2
                                    </td>
                                    <td data-month="12" data-day="3">3</td>
                                    <td data-month="12" data-day="4">4</td>
                                    <td data-month="12" data-day="5">5</td>
                                    <td data-month="12" data-day="6">6</td>
                                    <td data-month="12" data-day="7">7</td>
                                </tr>
                                <tr>
                                    <td data-month="12" data-day="8">8</td>
                                    <td data-month="12" data-day="9">9</td>
                                    <td data-month="12" data-day="10">10</td>
                                    <td data-month="12" data-day="11">11</td>
                                    <td data-month="12" data-day="12">12</td>
                                    <td data-month="12" data-day="13">13</td>
                                    <td data-month="12" data-day="14">14</td>
                                </tr>
                                <tr>
                                    <td data-month="12" data-day="15">15</td>
                                    <td data-month="12" data-day="16">16</td>
                                    <td data-month="12" data-day="17">17</td>
                                    <td data-month="12" data-day="18">18</td>
                                    <td data-month="12" data-day="19">19</td>
                                    <td data-month="12" data-day="20">20</td>
                                    <td data-month="12" data-day="21">21</td>
                                </tr>
                                <tr>
                                    <td data-month="12" data-day="22">22</td>
                                    <td data-month="12" data-day="23">23</td>
                                    <td data-month="12" data-day="24">24</td>
                                    <td data-month="12" data-day="25">25</td>
                                    <td data-month="12" data-day="26">26</td>
                                    <td data-month="12" data-day="27">27</td>
                                    <td data-month="12" data-day="28">28</td>
                                </tr>
                                <tr>
                                    <td data-month="12" data-day="29">29</td>
                                    <td data-month="12" data-day="30">30</td>
                                    <td data-month="12" data-day="31">31</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <!-- ... end W-Calendar -->
            </div>




            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Twitter Feed</h6>
                </div>

                <!-- W-Twitter -->

                <ul class="widget w-twitter">
                    <li class="twitter-item">
                        <div class="author-folder">
                            <img src="assets/img/twitter-avatar1.png" alt="avatar">
                            <div class="author">
                                <a href="#" class="author-name">Space Cowboy</a>
                                <a href="#" class="group">@james_spiegelOK</a>
                            </div>
                        </div>
                        <p>Tomorrow with the agency we will run 5 km for charity. Come and give us your support!
                            <a href="#" class="link-post">#Daydream5K</a></p>
                        <span class="post__date">
                            <time class="published" datetime="2017-03-24T18:18">
                                2 hours ago
                            </time>
                        </span>
                    </li>
                    <li class="twitter-item">
                        <div class="author-folder">
                            <img src="assets/img/twitter-avatar1.png" alt="avatar">
                            <div class="author">
                                <a href="#" class="author-name">Space Cowboy</a>
                                <a href="#" class="group">@james_spiegelOK</a>
                            </div>
                        </div>
                        <p>Check out the new website of “The Bebop Bar”! <a href="#" class="link-post">bytle/thbp53f</a>
                        </p>
                        <span class="post__date">
                            <time class="published" datetime="2017-03-24T18:18">
                                16 hours ago
                            </time>
                        </span>
                    </li>
                    <li class="twitter-item">
                        <div class="author-folder">
                            <img src="assets/img/twitter-avatar1.png" alt="avatar">
                            <div class="author">
                                <a href="#" class="author-name">Space Cowboy</a>
                                <a href="#" class="group">@james_spiegelOK</a>
                            </div>
                        </div>
                        <p>The Sunday is the annual agency camping trip and I still haven’t got a tent
                            <a href="#" class="link-post">#TheWild #Indoors</a></p>
                        <span class="post__date">
                            <time class="published" datetime="2017-03-24T18:18">
                                Yesterday
                            </time>
                        </span>
                    </li>
                </ul>


                <!-- .. end W-Twitter -->
            </div>



        </div>

        <!-- ... end Left Sidebar -->


        <!-- Right Sidebar -->

        <div class="col col-xl-3 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">
            <div class="ui-block">
                <div class="ui-block-content">
                    <a routerLink="/voting">
                        <img src="assets/img/voting.gif" alt="photo">
                    </a>
                    <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                        style="color: #3f4257;font-size: 25px;">Vote
                        Now!</a>
                </div>

            </div>
            <div class="ui-block">

                <div class="widget w-action">

                    <img src="assets/img/logo.webp" alt="Football Families">
                    <div class="content">
                        <h4 class="title">Football Families</h4>
                        <span>THE BEST SOCIAL NETWORK THEME IS HERE!</span>
                        <span>Join Now !</span>

                    </div>
                </div>
            </div>
            <!-- <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Followers</h6>
                </div>
                <div class="ui-block-content">

                    <ul class="widget w-last-photo js-zoom-gallery">
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                        <li>
                            <img src="assets/img/author-main1.webp" height="34" width="34" alt="author">
                        </li>
                    </ul>
                </div>
            </div> -->


            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">Voting Poll</h6>
                </div>
                <div class="ui-block-content">
                    <ul class="widget w-pool">
                        <li>
                            <p>If you had to choose, which Player do you prefer to be the next Darkman? </p>
                        </li>
                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Thomas Bale
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="62" data-from="0"></span>
                                        <span class="units">62%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 62%"></span>
                                </div>

                                <div class="counter-friends">12 friends voted for this</div>
                            </div>
                        </li>

                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Ben Robertson
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="27" data-from="0"></span>
                                        <span class="units">27%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 27%"></span>
                                </div>
                                <div class="counter-friends">7 friends voted for this</div>
                            </div>
                        </li>

                        <li>
                            <div class="skills-item">
                                <div class="skills-item-info">
                                    <span class="skills-item-title">
                                        <span class="radio">
                                            <label>
                                                <input type="radio" name="optionsRadios">
                                                Michael Streiton
                                            </label>
                                        </span>
                                    </span>
                                    <span class="skills-item-count">
                                        <span class="count-animate" data-speed="1000" data-refresh-interval="50"
                                            data-to="11" data-from="0"></span>
                                        <span class="units">11%</span>
                                    </span>
                                </div>
                                <div class="skills-item-meter">
                                    <span class="skills-item-meter-active bg-primary" style="width: 11%"></span>
                                </div>

                                <div class="counter-friends">2 people voted for this</div>
                            </div>
                        </li>
                    </ul>
                    <a routerLink="/voting" class="btn btn-md-2 btn-border-think custom-color c-grey full-width"
                        style="color: #3f4257;font-size: 25px;">Vote
                        Now!</a>
                </div>
            </div>

        </div>

        <!-- ... end Right Sidebar -->

    </div>
</div>

<!-- Delete Modal -->
<div class="modal fade" bsModal #commentDeleteModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete Comment</h5>
                <button type="button" class="close" (click)="commentDeleteModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label>Are you Sure you want to delete Comment</label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="commentDeleteModal.hide()">No</button>
                <button type="button" class="btn btn-danger"
                    (click)="deleteComment(commentDeleteModal,selectPostId)">Yes</button>
            </div>
        </div>
    </div>
</div>
<!-- Delete Modal -->