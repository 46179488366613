<div class="blog-post-wrap" [dir]="direction">
    <div class="container">
        <div class="row">
            <div *ngFor="let member of members" class="col col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="ui-block" data-mh="friend-groups-item">
                    <div class="friend-item friend-groups">
                        <div class="friend-item-content">
                            <div class="friend-avatar">
                                <div class="author-thumb">
                                    <a routerLink="/profile-details" [queryParams]="{'id': member.id, 'role':'User'}">
                                        <img src="{{member.src}}" alt="photo"> </a>
                                </div>
                                <div class="author-content">
                                    <a routerLink="/profile-details" [queryParams]="{'id': member.id}"
                                        class="h5 author-name">H.E {{member.name}}</a>
                                    <div class="country">{{member.nationality}}</div>
                                    <div class="friend-since" data-swiper-parallax="-100">
                                        <span
                                            style="font-weight: bold;">{{'presidents.president_since' | translate}}:</span>
                                        <div class="h6" style="color: #3f4257;">{{member.year}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>