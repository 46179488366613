<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container mt-2" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"*ngIf="language != 'ar'"> {{'ranking.ranking' | translate}}</div>
                    <div class="h6 label-title" style="float: right;" *ngIf="language == 'ar'"> {{'ranking.ranking' | translate}}</div>
                    <div *ngIf="currentUser.success.user.id == 1 || currentUser.success.user.id == 5 || currentUser.success.user.id == 18 || currentUser.success.user.user_type_id == '1'">
                        <a routerLink="/add-ranking">
                            <img src="../../../assets/img/dashboard.webp" class="img-responsive img-fluid center" alt="Dashboard" style="width: 44px;"></a>
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
            <!-- <div *ngIf="awards.length === 0" class="ui-block-title">
                <h6 class="display-message"> Coming Soon End of Season 2020 </h6>
            </div> -->
        </div>


    </div>
</div>
<div class="container" [dir]="direction">
    <div class="row">
        <div *ngFor="let year of years" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">

                    <div class="h6 label-title text-center"> {{'ranking.coming_soon' | translate}} {{year.id}} </div>

                </div>

            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <!-- <th>Name</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let fan of fans| filter:searchText; let i=index">
                        <td>{{i+1}}</td>
                        <td>
                            <a href="javascript:void(0)"><img src="../../../../../assets/img/user.jpg" width="50px" class="avatar" alt="Avatar"> {{fan.name}} {{year.id}}
                            </a>
                        </td>
                        <td> <i class="fas fa-info-circle fa-lg"></i></td>

                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>