<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>

<div class="main-header">
    <div class="content-bg-wrap bg-birthday"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Meet the legends of Football history</h1>
                    <p>Welcome to the legends page! Here you’ll find all your favorite legend players. Players from all
                        over the world are listed below, whether your preferred legend was a woman or man. Check the
                        list out!</p>

                </div>
            </div>
        </div>
    </div>

    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="Legends">
</div>


<div class="container">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 col-sm-12 col-xs-12">
            <article class="post">
                <a routerLink="/profile-details" [queryParams]="{ 'id': legend.id}" class="post-thumb">
                    <div>
                        <img src="https://api.football-family.com/img/legends/avatars/{{legend.id}}/avatar.png"
                            style="min-height:17vh; max-height: 26vh; width: 35vh;">
                    </div>
                </a>
                <div class="post-info text-center" style="text-align: justify;
                text-justify: inter-word;">
                    <h1 class="post-title f-cs text-center" style="font-size: 1.9rem;">
                        <a routerLink="/profile-details" [queryParams]="{ 'id': legend.id}">
                            {{legend.fname}} {{legend.lname}}
                        </a>
                    </h1> <br>
                    <h1 class="post-title text-cente f-cs" style="font-size: 1.875rem;">
                        <a routerLink="/profile-details" [queryParams]="{ 'id': legend.id}">
                            {{'View Profile'}}
                        </a>
                    </h1>
                    <ul class="post-meta f-cs">
                        <div>
                            <li>
                                <h3 class="f-cs">
                                    <img src="../../../assets/svg-icons/1x1/{{legend.country.code | lowercase}}.svg"
                                        style="width: 30px; height: 20px;"> {{legend.country.name}}
                                </h3>
                            </li>
                        </div>
                    </ul>
                    <ul class="post-meta">
                        <div>
                            <li class="text">
                                <h3 class="text-center f-cs">Date of Birth: {{legend.dob}}</h3>
                            </li>
                        </div>
                    </ul>
                    <hr />
                    <h2 class="text-center f-cs">About Legend:</h2>
                    <hr />
                    <p class="post-desc" style="color: #000; text-align: justify;">
                        {{legend.description}}
                    </p>
                </div>
            </article>
        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <!-- <div class="col col-xl-12"></div> -->
        <div class="col col-xl-12">

            <div id="legend-slider" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->
                <ol class="carousel-indicators">

                    <!-- <li data-target="#legend-slider" data-slide-to="{{ $loop->index }}" class="{{ $loop->first ? 'active' : '' }}">
                        <img src="{{asset('images/legend-images/'.$legend->id.'/'.$image)}}">
                    </li> -->

                </ol>

                <div class="carousel-inner" role="listbox">

                    <!-- <div class="item {{ $loop->first ? ' active' : '' }}">
                        <img src="{{asset('images/legend-images/'.$legend->id.'/'.$image)}}" alt="" style="height: 1000px; width: 100%;">
                    </div> -->

                </div>

                <a class="left carousel-control" href="#legend-slider" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#legend-slider" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>


            <!-- <video style="margin-top: 10px;" id="my_video_1" class="video-js vjs-default-skin"
                    width="100%" height="auto" controls muted
                    data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                    <source src="https://iffhs.de/images/legend-videos/2/legend.mp4" type='video/mp4' />

                    Sorry, your browser doesn't support embedded videos.
                </video> -->

            <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" [src]="safeSrc" allowfullscreen></iframe>
                <!-- <iframe [class.thumbnail]="thumbnail" class="embed-responsive-item" [src]="safeSrc" width="100%" height="315" frameborder="0"
                    webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe> -->

            </div>




        </div>
    </div>
</div>