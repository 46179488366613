<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<!-- Main Header Account -->

<div class="main-header">
    <div class="content-bg-wrap bg-account"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Chatting Page</h1>
                    <p style="font-size: 1.5rem;"> Welcome to your Chatting page! You will be able to chat with your favorite friends all day all week!</p>

                </div>
            </div>
        </div>
    </div>
    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="friends">
</div>

<!-- ... end Main Header Account -->


<!-- Your Account Personal Information -->

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-9 order-xl-2 col-lg-9 order-lg-2 col-md-12 order-md-1 col-sm-12 col-12">
            <div class="ui-block">
                <div class="ui-block-title">
                    <h6 class="title">{{ currentUser.success.user.fullName}}’s Chat / Messages </h6>
                    <!-- <a  href="javascript:void(0);" class="more">
						<svg class="olymp-three-dots-icon">
							<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
						</svg>
					</a> -->
                </div>

                <div class="row">
                    <div *ngIf="friends.length === 0" class="ui-block-title">
                        <h6 class="title" style="text-align: center;">No chat found!</h6>

                    </div>
                    <div class="col col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12 padding-r-0">

                        <!-- Notification List Chat Messages -->

                        <ul class="notification-list chat-message">
                            <li *ngFor="let friend of friends">
                                <div class="author-thumb">
                                    <img *ngIf="!friend.avatar" alt="author" class="avatar" src="../../../assets/img/user.jpg" width="42" height="40">
                                    <img *ngIf="friend.avatar" alt="author" class="avatar" [src]="friend.avatar" width="42" height="42">
                                    <span class="icon-status online"></span>
                                </div>
                                <div class="notification-event">
                                    <a routerLink="/profile-details" [queryParams]="{'id': friend.id}" class="h6 notification-friend">{{friend.fullName}}</a>
                                    <span class="chat-message-item">Hi James! It’s Diana, I just wanted to let you know
										that we have to reschedule...</span>
                                    <span class="notification-date"><time class="entry-date updated"
											datetime="2004-07-24T18:18">4 hours ago</time></span>
                                </div>
                                <span class="notification-icon">
									<svg class="olymp-chat---messages-icon">
										<use
											xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon">
										</use>
									</svg>
								</span>

                                <div class="more">
                                    <svg class="olymp-three-dots-icon">
										<use
											xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
										</use>
									</svg>
                                </div>
                            </li>


                            <!-- <li class="chat-group">
								<div class="author-thumb">
									<img src="../../../assets/img/avatar11-sm.jpg" alt="author">
									<img src="../../../assets/img/avatar12-sm.jpg" alt="author">
									<img src="../../../assets/img/avatar13-sm.jpg" alt="author">
									<img src="../../../assets/img/avatar10-sm.jpg" alt="author">
								</div>
								<div class="notification-event">
									<a  href="javascript:void(0);" class="h6 notification-friend">You, Faye, Ed & Jet +3</a>
									<span class="last-message-author">Ed:</span>
									<span class="chat-message-item">Yeah! Seems fine by me!</span>
									<span class="notification-date"><time class="entry-date updated"
											datetime="2004-07-24T18:18">March 16th at 10:23am</time></span>
								</div>
								<span class="notification-icon">
									<svg class="olymp-chat---messages-icon">
										<use
											xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon">
										</use>
									</svg>
								</span>
								<div class="more">
									<svg class="olymp-three-dots-icon">
										<use
											xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
										</use>
									</svg>
								</div>
							</li> -->
                        </ul>

                        <!-- ... end Notification List Chat Messages -->


                        <!-- Popup Chat -->

                        <!-- <div class="ui-block popup-chat">
							<div class="ui-block-title">
								<span class="icon-status online"></span>
								<h6 class="title">Mathilda Brinker</h6>
								<div class="more">
									<svg class="olymp-three-dots-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use></svg>
									<svg class="olymp-little-delete"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use></svg>
								</div>
							</div>
							<div class="mCustomScrollbar" data-mcs-theme="dark">
								<ul class="notification-list chat-message chat-message-field">
									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/avatar14-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m gonna be handling the gifts and Jake’s gonna get the drinks</span>
											<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
										</div>
									</li>
						
									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/author-page.jpg" alt="author">
										</div>
										<div class="notification-event">
											<span class="chat-message-item">Don’t worry Mathilda!</span>
											<span class="chat-message-item">I already bought everything</span>
											<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
										</div>
									</li>
						
									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/avatar14-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m gonna be handling the gifts and Jake’s gonna get the drinks</span>
											<span class="notification-date"><time class="entry-date updated" datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
										</div>
									</li>
								</ul>
							</div>
						
							<form>
						
								<div class="form-group label-floating is-empty">
									<textarea class="form-control" placeholder="Press enter to post..."></textarea>
									<div class="add-options-message">
										<a  href="javascript:void(0);" class="options-message">
											<svg class="olymp-computer-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use></svg>
										</a>
										<div class="options-message smile-block">
						
											<svg class="olymp-happy-sticker-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon"></use></svg>
						
											<ul class="more-dropdown more-with-triangle triangle-bottom-right">
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat1.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat2.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat3.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat4.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat5.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat6.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat7.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat8.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat9.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat10.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat11.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat12.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat13.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat14.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat15.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat16.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat17.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat18.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat19.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat20.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat21.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat22.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat23.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat24.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat25.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat26.png" alt="icon">
													</a>
												</li>
												<li>
													<a  href="javascript:void(0);">
														<img src="../../../assets/img/icon-chat27.png" alt="icon">
													</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
						
							</form>
						
						
						</div> -->

                        <!-- ... end Popup Chat -->


                    </div>

                    <div class="col col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12 padding-l-0">


                        <!-- Chat Field -->

                        <!-- <div class="chat-field">
							<div class="ui-block-title">
								<h6 class="title">Elaine Dreyfuss</h6>
								<a  href="javascript:void(0);" class="more"><svg class="olymp-three-dots-icon">
										<use
											xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
										</use>
									</svg></a>
							</div>
							<div class="mCustomScrollbar" data-mcs-theme="dark">
								<ul class="notification-list chat-message chat-message-field">
									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/avatar10-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="javascript:void(0);" class="h6 notification-friend">Elaine
													Dreyfuss</a>
												<span class="notification-date"><time class="entry-date updated"
														datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
											</div>
											<span class="chat-message-item">Hi James, How are your doing? Please
												remember that next week we are going to Gotham Bar to celebrate Marina’s
												Birthday.</span>
										</div>
									</li>

									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/author-page.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="javascript:void(0);" class="h6 notification-friend">James
													Spiegel</a>
												<span class="notification-date"><time class="entry-date updated"
														datetime="2004-07-24T18:18">Yesterday at 8:30pm</time></span>
											</div>
											<span class="chat-message-item">Hi Elaine! I have a question, do you think
												that tomorrow we could talk to
												the client to iron out some details before the presentation? I already
												finished the first screen but
												I need to ask him something before moving on. Anyway, here’s a preview!
											</span>
											<div class="added-photos">
												<img src="../../../assets/img/photo-message1.jpg" alt="photo">
												<img src="../../../assets/img/photo-message2.jpg" alt="photo">
												<span class="photos-name">icons.jpeg; bunny.jpeg</span>
											</div>
										</div>
									</li>

									<li>
										<div class="author-thumb">
											<img src="../../../assets/img/avatar10-sm.jpg" alt="author">
										</div>
										<div class="notification-event">
											<div class="event-info-wrap">
												<a href="javascript:void(0);" class="h6 notification-friend">Elaine
													Dreyfuss</a>
												<span class="notification-date"><time class="entry-date updated"
														datetime="2004-07-24T18:18">Yesterday at 9:56pm</time></span>
											</div>
											<span class="chat-message-item">We’ll have to check that at the office and
												see if the client is on board with it. I think That looks really
												good!</span>
										</div>
									</li>
								</ul>
							</div>

							<form>

								<div class="form-group">
									<textarea class="form-control" placeholder="Write your reply here..."></textarea>
								</div>

								<div class="add-options-message">
									<a  href="javascript:void(0);" class="options-message">
										<svg class="olymp-computer-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use></svg>
									</a>
									<a  href="javascript:void(0);" class="options-message">
										<svg class="olymp-computer-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use></svg>
									</a>
									<div class="options-message smile-block">
										<svg class="olymp-happy-sticker-icon"><use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon"></use></svg>

										<ul class="more-dropdown more-with-triangle triangle-bottom-right">
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat1.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat2.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat3.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat4.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat5.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat6.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat7.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat8.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat9.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat10.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat11.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat12.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat13.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat14.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat15.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat16.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat17.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat18.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat19.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat20.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat21.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat22.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat23.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat24.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat25.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat26.png" alt="icon">
												</a>
											</li>
											<li>
												<a href="javascript:void(0);">
													<img src="../../../assets/img/icon-chat27.png" alt="icon">
												</a>
											</li>
										</ul>
									</div>

									<button class="btn btn-primary btn-sm"> Reply</button>
								</div>

							</form>

						</div> -->

                        <!-- ... end Chat Field -->

                    </div>
                </div>

            </div>


            <!-- Pagination -->

            <!-- <nav aria-label="Page navigation">
				<ul class="pagination justify-content-center">
					<li class="page-item disabled">
						<a class="page-link" href="javascript:void(0);" tabindex="-1">Previous</a>
					</li>
					<li class="page-item"><a class="page-link" href="javascript:void(0);">1<div
								class="ripple-container">
								<div class="ripple ripple-on ripple-out"
									style="left: -10.3833px; top: -16.8333px; background-color: rgb(255, 255, 255); transform: scale(16.7857);">
								</div>
							</div></a></li>
					<li class="page-item"><a class="page-link" href="javascript:void(0);">2</a></li>
					<li class="page-item"><a class="page-link" href="javascript:void(0);">3</a></li>
					<li class="page-item"><a class="page-link" href="javascript:void(0);">...</a></li>
					<li class="page-item"><a class="page-link" href="javascript:void(0);">12</a></li>
					<li class="page-item">
						<a class="page-link" href="javascript:void(0);">Next</a>
					</li>
				</ul>
			</nav> -->

            <!-- ... end Pagination -->

        </div>

        <div class="col col-xl-3 order-xl-1 col-lg-3 order-lg-1 col-md-12 order-md-2 col-sm-12 col-12 responsive-display-none">
            <div class="ui-block">

                <!-- Your Profile  -->

                <div class="your-profile">
                    <!-- <div class="ui-block-title ui-block-title-small">
                        <h6 class="title">Your PROFILE</h6>
                    </div> -->

                    <div id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="card">
                            <div class="card-header" role="tab" id="headingOne">
                                <h6 class="mb-0">
                                    <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
										Profile Settings
										<svg class="olymp-dropdown-arrow-icon">
											<use
												xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-dropdown-arrow-icon">
											</use>
										</svg>
									</a>
                                </h6>
                            </div>

                            <div id="collapseOne" class="collapse show" role="tabpanel" aria-labelledby="headingOne">
                                <ul class="your-profile-menu">
                                    <li>
                                        <a routerLink="/profile-setting">Personal Information</a>
                                    </li>
                                    <!-- <li>
										<a  href="javascript:void(0);">Account Settings</a>
									</li> -->
                                    <li>
                                        <a routerLink="/change-password">Change Password</a>
                                    </li>
                                    <li>
                                        <a routerLink="/hobbies-and-intrests">Hobbies and Interests</a>
                                    </li>
                                    <!-- <li>
										<a  href="javascript:void(0);">Education and Employement</a>
									</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="ui-block-title">
                        <a routerLink="/notifications" class="h6 title">Notifications</a>
                        <!-- <a routerLink="/notifications" class="items-round-little bg-primary">8</a> -->
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/chat-message" class="h6 title">Chat / Messages</a>
                    </div>
                    <div class="ui-block-title">
                        <a routerLink="/friend-requests" class="h6 title">Friend Requests</a>
                        <!-- <a routerLink="/friend-requests" class="items-round-little bg-blue">4</a> -->
                    </div>
                    <!-- <div class="ui-block-title ui-block-title-small">
						<h6 class="title">FAVOURITE PAGE</h6>
					</div>
					<div class="ui-block-title">
						<a  href="javascript:void(0);" class="h6 title">Create Fav Page</a>
					</div>
					<div class="ui-block-title">
						<a  href="javascript:void(0);" class="h6 title">Fav Page Settings</a>
					</div> -->
                </div>

                <!-- ... end Your Profile  -->

            </div>
        </div>
    </div>
</div>

<!-- ... end Your Account Personal Information -->




<!-- Window-popup-CHAT for responsive min-width: 768px -->

<div class="ui-block popup-chat popup-chat-responsive" tabindex="-1" role="dialog" aria-labelledby="popup-chat-responsive" aria-hidden="true" [dir]="direction">

    <div class="modal-content">
        <div class="modal-header">
            <span class="icon-status online"></span>
            <h6 class="title">Chat</h6>
            <div class="more">
                <svg class="olymp-three-dots-icon">
					<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon"></use>
				</svg>
                <svg class="olymp-little-delete js-chat-open">
					<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-little-delete"></use>
				</svg>
            </div>
        </div>
        <div class="modal-body">
            <div class="mCustomScrollbar">
                <ul class="notification-list chat-message chat-message-field">
                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
								gonna be handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/author-page.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Don’t worry Mathilda!</span>
                            <span class="chat-message-item">I already bought everything</span>
                            <span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:29pm</time></span>
                        </div>
                    </li>

                    <li>
                        <div class="author-thumb">
                            <img src="../../../assets/img/avatar14-sm.jpg" alt="author" class="mCS_img_loaded">
                        </div>
                        <div class="notification-event">
                            <span class="chat-message-item">Hi James! Please remember to buy the food for tomorrow! I’m
								gonna be handling the gifts and Jake’s gonna get the drinks</span>
                            <span class="notification-date"><time class="entry-date updated"
									datetime="2004-07-24T18:18">Yesterday at 8:10pm</time></span>
                        </div>
                    </li>
                </ul>
            </div>

            <form class="need-validation">

                <div class="form-group">
                    <textarea class="form-control" placeholder="Press enter to post..."></textarea>
                    <div class="add-options-message">
                        <a href="javascript:void(0);" class="options-message">
                            <svg class="olymp-computer-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-computer-icon"></use>
							</svg>
                        </a>
                        <div class="options-message smile-block">

                            <svg class="olymp-happy-sticker-icon">
								<use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-happy-sticker-icon">
								</use>
							</svg>

                            <!-- <ul class="more-dropdown more-with-triangle triangle-bottom-right">
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat1.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat2.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat3.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat4.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat5.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat6.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat7.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat8.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat9.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat10.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat11.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat12.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat13.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat14.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat15.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat16.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat17.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat18.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat19.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat20.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat21.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat22.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat23.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat24.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat25.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat26.png" alt="icon">
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">
                                        <img src="../../../assets/img/icon-chat27.png" alt="icon">
                                    </a>
                                </li>
                            </ul> -->
                        </div>
                    </div>
                </div>

            </form>
        </div>
    </div>

</div>

<!-- ... end Window-popup-CHAT for responsive min-width: 768px -->