<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header top-header-favorit">
                    <div class="top-header-thumb">
                        <img src="assets/img/covers/{{countryData[0].continent_id}}.webp" alt="cover" onerror="this.onerror=null;this.src='assets/img/federations-cover.jpg';" style="width: 100%;height: 100%;">
                        <div class="row">
                            <div class="col col-xl-9 col-lg-9 col-md-7 col-sm-8 col-7">
                                <div class="top-header-author">
                                    <div class="author-thumb">
                                        <img src="assets/svg-icons/1x1/{{countryData[0].code | lowercase}}.svg" alt="flag" class="img-responsive img-fluid" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: 100%;">
                                    </div>
                                    <div class="author-content">
                                        <p class="h3 author-name">{{countryData[0].name | titlecase}}</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col col-xl-3 col-lg-2 col-md-3 col-sm-3 col-3">
                                <div class="top-header-author">
                                    <div class="author-thumb ph" style="float: left!important;">
                                        <img src="https://www.api.football-family.com/federations/{{countryData[0].code}}.png" class="img-responsive float-left img-fluid" alt="flag" onerror="this.onerror=null;this.src='assets/img/clubs.webp';" style="width: 100%; height: 100%;">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-xl-12 m-auto col-lg-12 col-md-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/team-profile" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Timeline</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-photos" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Media</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-national-teams" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">National Teams</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-leagues" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Leagues</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-matches" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Matches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-player" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Players</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-clubs" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Clubs</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-coach" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Coaches</a>
                                    </li>
                                    <li>
                                        <a routerLink="/team-followers" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">Followers</a>
                                    </li>
                                    <li *ngIf="currentUser.success.user.user_type_id == '8' && currentUser.success.user.fullName == countryData[0].name || currentUser.success.user.user_type_id == '1'">
                                        <a routerLink="/team-dashboard" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">
                                            <img src="../../../assets/../../../assets/img/dashboard.webp" alt="cover" width="40" height="40">
                                        </a>
                                    </li>
                                    <li *ngIf="currentUser.success.user.id == '119' && countryData[0].name == 'France' || countryData[0].name == 'Switzerland' || countryData[0].name == 'Germany'">
                                        <a routerLink="/team-dashboard" [queryParams]="{'id': countryData[0].id}" routerLinkActive="active">
                                            <img src="../../../assets/../../../assets/img/dashboard.webp" alt="cover" width="40" height="40">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>