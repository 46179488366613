import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { IffhsService } from 'src/app/data-services/iffhs.service';

@Component({
  selector: 'app-club-header',
  templateUrl: './club-header.component.html',
  styleUrls: ['./club-header.component.css']
})
export class ClubHeaderComponent implements OnInit {
  id: number;
  clubData: any = {};
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  isLoaderVisible = false;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.clubData = JSON.parse(localStorage.getItem('clubData'));
    this.getClubProfile();
  }
  getClubProfile() {
    this.isLoaderVisible = true;
    const data = {
      clubId: this.id,
    };
    this.iffhsService.getClubProfile(data).subscribe((res: any) => {
      this.clubData = res.club;
      this.countryData = res.country;
      localStorage.setItem('clubData', JSON.stringify(this.clubData));
      this.isLoaderVisible = false;
    });
  }




}
