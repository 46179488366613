

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="top-header">
                    <div class="top-header-thumb">
                        <img src="assets/img/cover.webp" alt="nature">
                    </div>
                    <div class="profile-section">
                        <div class="row">
                            <div class="col col-lg-5 col-md-5 col-sm-12 col-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/profile"
                                            routerLinkActive="active">{{'profile_header.timeline' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/about" routerLinkActive="active">{{'profile_header.about' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/friends" routerLinkActive="active">{{'profile_header.friends' | translate}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col col-lg-5 ml-auto col-md-5 col-sm-12 col-12">
                                <ul class="profile-menu">
                                    <li>
                                        <a routerLink="/photos" routerLinkActive="active">{{'profile_header.photos' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/videos" routerLinkActive="active">{{'profile_header.videos' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/followers" routerLinkActive="active">{{'profile_header.followers' | translate}}</a>
                                    </li>
                                    <li>
                                        <a routerLink="/following" routerLinkActive="active">{{'profile_header.following' | translate}}</a>
                                    </li>
                                    <!-- <li>
										<div class="more">
											<svg class="olymp-three-dots-icon">
												<use
													xlink:href="assets/svg-icons/sprites/icons.svg#olymp-three-dots-icon">
												</use>
											</svg>
											<ul class="more-dropdown more-with-triangle">
												<li>
													<a routerLink="/profile">Report Profile</a>
												</li>
												<li>
													<a routerLink="/profile">Block Profile</a>
												</li>
											</ul>
										</div>
									</li> -->
                                </ul>
                            </div>
                        </div>

                        <div class="control-block-button">
                            <!-- <a (click)="request(currentUser.success.user.id)" class="btn btn-control bg-blue follow">
                                <svg class="olymp-happy-face-icon">
                                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-happy-face-icon">
                                    </use>
                                </svg>
                            </a> -->
                            <!-- 
                            <a (click)="follow(currentUser.success.user.id)" class="btn btn-control bg-purple follow">
                                <svg class="olymp-chat---messages-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon">
                                    </use>
                                </svg> 
                                <span>Follow</span>
                            </a> -->
                            <!-- <a routerLink="/profile" class="btn btn-control bg-purple follow">
                                <svg class="olymp-chat---messages-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-chat---messages-icon">
                                    </use>
                                </svg>
                            </a> -->
                            <form [formGroup]="editProfileForm" (ngSubmit)="setPhotos()">
                                <div class="btn btn-control bg-primary more follow">
                                    <svg class="olymp-settings-icon">
                                        <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-settings-icon">
                                        </use>
                                    </svg>

                                    <ul class="more-dropdown more-with-triangle triangle-bottom-right">

                                        <li>

                                            <a href="#" data-toggle="modal" data-target="#update-header-photo">Update
                                                Photo</a>
                                        </li>
                                        <!-- <li>
                                            <input style="display: none;" type="file" name="avatar" formcontrolName="avatar" #avatar (change)="onFileSelectAvatar($event)">
                                            <a href="javascript:void(0);" (click)="avatar.click()">Update
                                                Profile Photo</a>
                                        </li> -->

                                        <li>
                                            <a routerLink="/profile-setting">Profile Settings</a>
                                        </li>
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="top-header-author">
                        <a routerLink="/profile" *ngIf="currentUser.success.user.role === 'president'"
                            [ngStyle]="{'border-color':'#d99d41'}" class="author-thumb">
                            <img *ngIf="!currentUser.success.user.avatar" src="assets/img/author-main1.webp"
                                alt="author" style="width: 100%;"
                                onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                style="width: 100%; height: 120px;">
                            <img *ngIf="currentUser.success.user.avatar" [src]="userData[0].avatar" alt="author"
                                style="height: 120px;width: 120px;"
                                onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                style="width: 120px; height: 120px;">

                        </a>
                        <a routerLink="/profile" *ngIf="currentUser.success.user.role !== 'president'"
                            class="author-thumb">
                            <img *ngIf="!currentUser.success.user.avatar" src="assets/img/author-main1.webp"
                                alt="author" style="width: 100%;"
                                onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                style="width: 100%; height: 120px;">
                            <img *ngIf="currentUser.success.user.avatar" [src]="userData[0].avatar" alt="author"
                                style="height: 120px;width: 120px;"
                                onerror="this.onerror=null;this.src='assets/img/author-main1.webp';"
                                style="width: 120px; height: 120px;">

                        </a>
                        <div class="author-content">
                            <a routerLink="/profile" class="h4 author-name">{{ userData[0].fullName | titlecase }}</a>
                            <div class="country" style="font-weight: 600;"> {{ userData[0].role | titlecase}}
                            </div>
                            <!-- <div class="country"> {{ userData[0].countryName }}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="update-header-photo" tabindex="-1" role="dialog" aria-labelledby="update-header-photo"
    aria-hidden="true">
    <div class="modal-dialog  update-header-photo" role="document">
        <div class="modal-content">
            <a routerLink="/profile" class="close icon-close" data-dismiss="modal" aria-label="Close">
                <svg class="olymp-close-icon">
                    <use xlink:href="assets/svg-icons/sprites/icons.svg#olymp-close-icon"></use>
                </svg>
            </a>

            <div class="modal-header">
                <h6 class="title">Update Photo</h6>
            </div>

            <div class="modal-body">
                <webcam [height]="300" [width]="300" class="center" [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)" *ngIf="showWebcam" [allowCameraSwitch]="allowCameraSwitch"
                    [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions"
                    (cameraSwitched)="cameraWasSwitched($event)" (initError)="handleInitError($event)">
                </webcam>
                <br />
                <div class="actionBtn text-center" (click)="triggerSnapshot();" title="Take Picture">📸
                </div>
                <div class="actionBtn text-center" (click)="showNextWebcam(true);" title="Switch Camera">🔁
                </div>

                <a class="upload-photo-item">
                    <div class="snapshot" *ngIf="webcamImage">
                        <img [src]="webcamImage.imageAsDataUrl" height="140" width="140" />
                    </div>
                    <img [src]="imgURL" height="140" width="140" *ngIf="imgURL"><br>
                    <a href="javascript:void(0);" (click)="avatar.click()" class="options-message ">
                        <img src="../assets/img/icons/gallery.png">
                    </a>
                    <form [formGroup]="editProfileForm">
                        <input style="display: none;" type="file" name="avatar" formcontrolName="avatar" #avatar
                            (change)="preview(avatar.files)">

                        <button (click)="setPhotos()" [disabled]="loading" class="btn btn-md-2 btn-primary m-3">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Set Profile
                        </button>
                    </form>
                </a>


            </div>
        </div>
    </div>
</div>