import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { timer } from 'rxjs';
import { AuthenticationService } from '../../../data-services/authentication.service';
import { UserService } from '../../../data-services/user.service';
import { first, map } from 'rxjs/operators';
import { AlertService } from '../../../data-services/alert.service';
import { UserRootObject } from '../../../_models/user';
import countries from '../../../../assets/json/countries.json';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-profile-setting',
  templateUrl: './profile-setting.component.html',
  styleUrls: ['./profile-setting.component.css']
})
export class ProfileSettingComponent implements OnInit {
  currentUser: any;
  updateForm: FormGroup;
  message: string;
  visible: boolean;
  isSubmitted: boolean;
  errorFlag: boolean;
  loading: boolean;
  maxDate: Date;
  minDate: Date;
  dob: string;

  profile: any;
  userData: any = {};
  errorMessage = '';
  countries: any = [];
  phoneNumberFlag = false;
  dateOfBirthFlag = false;
  submitted = false;
  countryList: { name: string, id: string }[] = countries;



  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,
    public datePipe: DatePipe,

  ) {
    window.scrollTo(0, 0);
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 32850);
    this.maxDate.setDate(this.maxDate.getDate() - 4380);
    // this.userData = JSON.parse(localStorage.getItem('userData'));
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getProfile();
  }

  getProfile() {
    const data = {
      id: this.currentUser.success.user.id,
    };
    this.userService.getProfile(data).pipe(first()).subscribe(
      (userData) => {
        this.userData = userData[0];
        this.dob = this.datePipe.transform(this.userData.dob, 'yyyy-MM-dd');
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }
    );
  }
  initForm() {
    this.updateForm = this.formBuilder.group({
      userId: this.currentUser.success.user.id,
      fullName: [this.userData.fullName, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      lastName: [this.userData.lastName, Validators.compose([Validators.required, Validators.minLength(3), Validators.maxLength(50)])],
      countryName: [this.userData.countryName, Validators.compose([Validators.minLength(3), Validators.maxLength(50)])],
      mobileNumber: [this.userData.mobileNumber,
      Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])],
      IsoCode: ['', Validators.required],
      IsdCode: ['', Validators.required],
      dob: [this.dob, Validators.required],
      email: [this.userData.email, Validators.required],
      avatar: [''],
      banner: [''],
      gender: [this.userData.gender],
      residentCountry: [this.userData.residentCountry],
      province: [this.userData.province],
      city: [this.userData.city, Validators.compose([Validators.minLength(3), Validators.maxLength(50)])],
      about: [this.userData.about],
      martialStatus: [this.userData.martialStatus],
      facebook: [this.userData.facebook, Validators.compose([Validators.minLength(3), Validators.maxLength(100)])],
      twitter: [this.userData.twitter, Validators.compose([Validators.minLength(3), Validators.maxLength(100)])],
    });

  }


  updateProfile() {
    this.message = '';
    this.visible = false;
    this.submitted = true;
    this.isSubmitted = false;
    this.errorFlag = false;
    this.loading = true;
    this.isSubmitted = true;
    // if (this.updateForm.invalid) {
    //   this.loading = false;
    //   return;
    // }
    this.userService.updateProfile(this.updateForm.value).subscribe((res: any) => {
      this.userData = res;
      this.toastrService.success('Your Profile has been updated', 'Message!');
      this.getProfile();
      // localStorage.setItem('userData', JSON.stringify(this.userData));
      this.loading = false;
    },
      (error) => {
        this.toastrService.error('Something went wrong please try later', 'Message!');
        this.visible = false;
        this.loading = false;
        this.errorFlag = false;
      }
    );
  }

  // onFileSelected(event: any) {
  //   this.converToBase64(event.target.files[0]);
  // }
  // converToBase64(inputValue: File) {
  //   if (inputValue) {
  //     const file: File = inputValue;

  //     const myReader: FileReader = new FileReader();
  //     // console.log(myReader);
  //     myReader.onloadend = () => {

  //       if (myReader.result) {

  //         this.updateForm.value.avatar = myReader.result;
  //         // this.profile.avatar = myReader.result;
  //         // this.imageUrl = myReader.result;
  //       }
  //     };
  //     myReader.readAsDataURL(file);
  //   }
  // }

  reLoad() {
    location.reload();
  }
  getNumber(event) {
  }
  telInputObject(obj) {
    obj.setCountry(this.currentUser.success.user.IsoCode);
  }
  onCountryChange(event) {
    this.updateForm.controls['IsdCode'].setValue(event.dialCode);
    this.updateForm.controls['IsoCode'].setValue(event.iso2);
  }
  hasError(event) {
    if (event === true) {
      this.phoneNumberFlag = true;
      this.dateOfBirthFlag = true;
    }
  }



}
