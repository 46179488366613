<app-left-side></app-left-side>
<app-header></app-header>
<app-right-side></app-right-side>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img  src="../../../assets/img/loader.webp">
</div>
<div class="main-header">
    <div class="content-bg-wrap bg-birthday"></div>
    <div class="container">
        <div class="row">
            <div class="col col-lg-8 m-auto col-md-8 col-sm-12 col-12">
                <div class="main-header-content">
                    <h1>Meet the legends of Football history</h1>
                    <p style="font-size: 1.5rem;">Welcome to the legends page! Here you’ll find all your favorite legend players. Players from all over the world are listed below, whether your preferred legend was a woman or man. Check the list out!</p>

                </div>
            </div>
        </div>
    </div>

    <img class="img-bottom" src="../../../assets/img/legends-banner.png" alt="Legends">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title">
                        Legends:
                    </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>            
        </div>
        <div *ngIf="legends.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message">No Data Found ! </h6>
        </div>
    </div>

</div>

<div class="container">
    <div class="row">
        <div *ngFor="let legend of legends  | filter:searchText" class="col col-xl-3 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block" data-mh="friend-groups-item">

                <div class="friend-item friend-groups">

                    <div class="friend-item-content">

                        <div class="friend-avatar">
                            <div class="author-thumb">
                                <a routerLink="/legend-profile" [queryParams]="{ 'id': legend.id}">
                                    <img src="https://api.football-family.com/img/legends/avatars/{{legend.id}}/avatar.png" alt="Avatar" style="width: 120px; height: 120px;">
                                </a>

                            </div>

                            <div class="author-content">
                                <a routerLink="/legend-profile" [queryParams]="{ 'id': legend.id}" class="h5 author-name" style="font-size: 1.4rem; color: #000;">
                                    {{legend.fname | titlecase}}
                                    {{legend.lname | titlecase}}</a><br>
                                <span style="font-size: 1.3rem; color: #000;"> <strong>
                                        <img class="flag"
                                            src="../../../assets/svg-icons/1x1/{{legend.country.code | lowercase}}.svg">
                                        {{legend.country.name}}
                                    </strong></span><br>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center">
            <div>
                <jw-pagination [items]="items" (changePage)="onChangePage($event)"></jw-pagination>
            </div>
        </ul>
    </nav> -->
</div>