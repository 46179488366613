import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-calling-members',
  templateUrl: './calling-members.component.html',
  styleUrls: ['./calling-members.component.css']
})
export class CallingMembersComponent implements OnInit, OnDestroy {
  searchText = '';
  roomNameLabel: string = '';
  private jitsi: any;
  visible = true;
  currentUser: any;
  isLoaderVisible = false;


  constructor(
    private authenticationService: AuthenticationService
  ) { }
  rooms = [
    {
      id: '1',
      // src: '../../../assets/img/logo.png',
      name: 'IFFHS ROOM',
    },
    {
      id: '2',
      // src: '../../../assets/img/logo.png',
      name: 'Executive   ROOM',
    },
    {
      id: '3',
      // src: '../../../assets/img/logo.png',
      name: '  ROOM',
    },
    {
      id: '3',
      // src: '../../../assets/img/logo.png',
      name: '  ROOM',
    },
  ];
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }
  ngOnDestroy() {
    window.location.reload();
  }
  createRoom(roomName: HTMLInputElement) {
    this.isLoaderVisible = true;
    this.roomNameLabel = roomName.value;
    const domain = 'h-meetings.com';
    console.log(roomName.value);
    const options = {
      roomName: roomName.value,
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  iffhsMeeting() {
    this.isLoaderVisible = true;
    const domain = 'h-meetings.com';
    this.roomNameLabel = 'Iffhs Members Meeting';
    const options = {
      roomName: 'Iffhs Members Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  executiveMeeting() {
    this.isLoaderVisible = true;
    const domain = 'h-meetings.com';
    this.roomNameLabel = 'Iffhs Exco Committee Meeting';
    const options = {
      roomName: 'Iffhs Exco Committee Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  fansMeeting() {
    this.isLoaderVisible = true;
    this.roomNameLabel = 'IFFHS Fans Meeting';
    const domain = 'h-meetings.com';

    const options = {
      roomName: 'IFFHS Fans Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  legendsMeeting() {
    this.isLoaderVisible = true;
    this.roomNameLabel = 'Legends Meeting';
    const domain = 'h-meetings.com';
    const options = {
      roomName: 'Legends Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  superStarMeeting() {
    this.isLoaderVisible = true;
    this.roomNameLabel = 'Super Star Meeting';
    const domain = 'h-meetings.com';
    const options = {
      roomName: 'Super Star Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }
  GeneralMeeting() {
    this.isLoaderVisible = true;
    this.roomNameLabel = 'General Meeting';
    const domain = 'h-meetings.com';
    const options = {
      roomName: 'General Meeting',
      width: '100%',
      height: 600,
    };
    this.jitsi = new (window as any).JitsiMeetExternalAPI(domain, options);
    this.visible = false;
    this.isLoaderVisible = false;
  }


}
