<app-left-side></app-left-side>
<app-right-side></app-right-side>
<app-header></app-header>
<app-conf-header></app-conf-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div style="background-color: #fff;" class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 label-title"> Videos </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>

            </div>
        </div>
        <div *ngIf="videos.length === 0" class="ui-block-title">
            <h6 ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>

        </div>
    </div>
</div>

<div class="container">
    <div class="row">
        <div *ngFor="let video of videos |filter:searchText" class="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">

            <div class="ui-block video-item">
                <video id="my_video_1" class="video-js vjs-default-skin conf-video" controls muted data-setup='{ "aspectRatio":"640:267", "playbackRates": [1, 1.5, 2] }'>
                    <source [src]="video.mediaAttachment" type='video/mp4' />
                    <source [src]="video.mediaAttachment" type="video/webm">
                    Sorry, your browser doesn't support embedded videos.
                </video>
                <div class="ui-block-content video-content">
                    <p class="h6 title">{{video.mediaTitle | titlecase}}</p>

                </div>
            </div>
        </div>


    </div>
</div>