import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/data-services/authentication.service';

@Component({
  selector: 'app-gala-award',
  templateUrl: './gala-award.component.html',
  styleUrls: ['./gala-award.component.css']
})
export class GalaAwardComponent implements OnInit {
  searchText: string;
  awards = [];
  banners = [];
  currentUser: any;
  public show: number;
  public buttonName: any = 'Show';

  isLoaderVisible = false;
  isIffhsLoggedIn: boolean;
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.isIffhsLoggedIn = JSON.parse(localStorage.getItem('isIffhsLoggedIn'));
    console.log(this.isIffhsLoggedIn);
    this.getAllBanners();
  }

  toggle(Id: number) {
    this.isLoaderVisible = true;
    if (this.show !== Id) {
      this.show = Id;
    } else {
      this.show = 0;
    }
    const data = {
      categoryId: Id,
    };
    this.iffhsService.getAwards(data).subscribe((res: any) => {
      this.awards = res.awards;
      this.isLoaderVisible = false;
    });

    if (this.show) {
      this.buttonName = 'Show';
    } else {
      this.buttonName = 'Hide';
    }

  }
  getAllBanners() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllBanners().subscribe((res: any) => {
      this.banners = res.banners;
      this.awards = res.banners[0].awards;
      console.log(this.awards);
      this.isLoaderVisible = false;
    });
  }




}
