<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-team-header></app-team-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="assets/img/loader.webp">
</div>
<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 label-title"> Matches </div> -->
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option value="FIFA">FIFA</option>
                                <option value="NON FIFA">NON FIFA</option>
                            </select>
                        </fieldset>
                    </div>
                    <div class="w-select">
                        <fieldset class="form-group">
                            <select (change)="onChangeFilter($event)" class="form-control">
                                <option *ngFor="let category of categories" value="{{category.name}}">{{category.name}}
                                </option>
                            </select>
                        </fieldset>
                    </div>
                   
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
           
        </div>
        <div *ngIf="matches.length === 0" class="ui-block-title">
            <h6 *ngIf="!isLoaderVisible" class="display-message"> No Data found! </h6>

        </div>
    </div>
</div>
<div *ngIf="matches.length !== 0" class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Host Team</th>
                        <th scope="col">Host Score</th>
                        <th scope="col">Visiting Team</th>
                        <th scope="col">Visiting Score</th>
                        <th scope="col">Date</th>
                        <th scope="col">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let match of matches | filter:searchText; let i =index;">
                        <th scope="row">{{i+1}}</th>
                        <td>{{country.name}}</td>
                        <td>{{match.hostScore}}</td>
                        <td>{{match.country.name}}</td>
                        <td>{{match.visitingScore}}</td>
                        <td>{{match.created_at | date: 'mediumDate'}}</td>
                        <td>{{match.status}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>