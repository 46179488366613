import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-friend-about',
  templateUrl: './friend-about.component.html',
  styleUrls: ['./friend-about.component.css']
})
export class FriendAboutComponent implements OnInit {
  id: number;
  role: string;
  friendData: any = {};
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.role = params['role'];
    });
    this.friendDetails();
  }
  friendDetails() {
    const data = {
      id: this.id,
      role: this.role,
    };
    this.userService.getProfile(data).pipe(first())
      .subscribe(
        friendData => this.friendData = friendData[0]
      );

  }

}
