<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>

<div class="container">
	<div class="row">
		<div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
			<div class="ui-block responsive-flex">
				<div class="ui-block-title">
					<div class="h6 title"> Conference Call </div>
					<form class="w-search">
						<div class="form-group with-button">
							<input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
							<button>
								<svg class="olymp-magnifying-glass-icon">
									<use
										xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
									</use>
								</svg>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>


<section class="blog-post-wrap">
    <div class="container">
        <div class="row">
            <div *ngFor="let member of members | filter:searchText" class="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                <div class="ui-block">

                    <!-- Post -->

                    <article class="hentry blog-post has-post-thumbnail format-video">

                        <div class="post-thumb">
                            <a href="javascript:void(0);"> <img src="{{member.src}}" style="height: 271px;"   alt="profile photo"> </a>
                            <a href="https://youtube.com/watch?v=excVFQ2TWig" class="post-type-icon play-video">
                                <svg class="olymp-play-icon">
                                    <use xlink:href="../../../assets/svg-icons/sprites/icons.svg#olymp-play-icon"></use>
                                </svg>
                            </a>
                        </div>

                        <div class="post-content">
                            <a routerLink="/profile-details" [queryParams]="{'id': member.id}" class="h4 post-title">{{member.name | titlecase}}  <img src="{{member.flag}}" style="width: 35px; height: 25px; float: right;"></a>     
                        </div>

                    </article>

                    <!-- ... end Post -->
                </div>
            </div>
           
        </div>
    </div>
</section>