<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<app-profile-header></app-profile-header>

<div class="container">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> Photo Gallery</div>
                </div>
            </div>
            <!-- <div *ngIf="photos.length === 0" class="ui-block-title">
                <h6 class="title" style="text-align: center;"> No Photos Found!.
                </h6>

            </div> -->
        </div>
    </div>
</div>
<div class="container">
    <div *ngIf="photos && photos.length" class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <!-- Tab panes -->
            <div class="tab-content">

                <div class="tab-pane active" id="album-page" role="tabpanel">

                    <div class="photo-album-wrapper">

                        <div *ngFor="let item of items; index as i" class="photo-album-item-wrap col-4-width">

                            <div class="photo-album-item" data-mh="album-item">
                                <div class="photo-item">
                                    <img [lightbox]="i" [src]="item.data.thumb" alt="photo" style="width: 100%; height: 250px;">
                                </div>
                                <!-- <div class="content">
                                    <a href="javascript:void(0);" class="title h5">{{'photo.content' | titlecase}}</a>

                                </div> -->

                            </div>

                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</div>