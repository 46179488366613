import { Component, OnInit } from '@angular/core';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { MessageService } from 'src/app/data-services/message.service';
// import countries from '../../../../assets/json/countries.json';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  language = '';
  direction;
  defaultLanguage;
  countries: [];
  searchText: string;
  selectedId: number;
  activePro = true;
  isLoaderVisible = false;
  // countryList: { name: string, id: string }[] = countries;
  constructor(
    private iffhsService: IffhsService,
    private messageService: MessageService
  ) {
    this.messageService.selectedLanguage.subscribe(res => {
      this.language = res.lang;
      this.direction = res.dir;
    });
  }

  ngOnInit(): void {
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
    this.getAllCountries();
  }

  getAllCountries() {
    this.isLoaderVisible = true;
    this.iffhsService.getAllCountries().subscribe((res: any) => {
      this.countries = res;
      this.isLoaderVisible = false;
    });
  }
  getContinent(id: number) {
    this.isLoaderVisible = true;
    this.activePro = false;
    const data = {
      continentId: id,
    };
    this.iffhsService.getGeneralContinent(data).subscribe((res: any) => {
      if (res[0].continent_id === 1) {
        this.countries = res;
        this.selectedId = 1;
        this.isLoaderVisible = false;
      } else if (res[0].continent_id === 3) {
        this.countries = res;
        this.selectedId = 3;
        this.isLoaderVisible = false;


      } else if (res[0].continent_id === 4) {
        this.countries = res;
        this.selectedId = 4;
        this.isLoaderVisible = false;


      } else if (res[0].continent_id === 5) {
        this.countries = res;
        this.selectedId = 5;
        this.isLoaderVisible = false;

      } else if (res[0].continent_id === 6) {
        this.countries = res;
        this.selectedId = 6;
        this.isLoaderVisible = false;

      } else if (res[0].continent_id === 7) {
        this.countries = res;
        this.selectedId = 7;
        this.isLoaderVisible = false;
      }

    });

  }

}
