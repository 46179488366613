import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/data-services/user.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-following',
  templateUrl: './following.component.html',
  styleUrls: ['./following.component.css']
})
export class FollowingComponent implements OnInit {

  currentUser: any;
  following = [];
  searchText: string;
  selectFollowing: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.getFollowing();
  }

  getFollowing() {
    const data = {
      user_id: this.currentUser.success.user.id,
    };
    this.userService.getFollowing(data).pipe(first<any[]>()).subscribe(following => this.following = following);
  }

  openFollowerDeleteModal(modal: any, following: any) {
    this.selectFollowing = following;
    modal.show();
  }

  deleteFollower(modal: any) {
    const data = {
      user_id: this.currentUser.success.user.id,
      followingId: this.selectFollowing.id,
    };
    this.userService.follow(data).pipe(first()).subscribe(
      succuss => {
        if (succuss) {
          modal.hide();
          this.toastrService.success('Deleted', 'Message!');
        }
      },
      error => {
        this.toastrService.success('Something Went Wrong Try Again.', 'Message!');
      }
    );

  }

}
