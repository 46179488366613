import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Post } from '../_models/post';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  url = 'https://api.football-family.com/api/v1/';

  constructor(private http: HttpClient) { }


  getAll(data: any) {
    return this.http.post(this.url + 'showPosts', data);
  }
  getUserPhotos(data: any) {
    return this.http.post(this.url + 'showUserPhotos', data);
  }
  getUserVideos(data: any) {
    return this.http.post(this.url + 'showUserVideos', data);
  }
  getNewsFeed(data: any) {
    return this.http.post(this.url + 'feed', data);
  }
  getFrindVideos(data: any) {
    return this.http.post(this.url + 'friendVideos', data);
  }
  getFrindPhotos(data: any) {
    return this.http.post(this.url + 'friendPhotos', data);
  }
  getMyPhotos(data: any) {
    return this.http.post(this.url + 'getMyPhotos', data);
  }
  getIffhsPosts() {
    return this.http.post(this.url + 'showIffhsPosts', null);
  }

  post(post: any) {
    return this.http.post(this.url + 'restPost', post);
  }
  postPhotoAlbum(post: any) {
    return this.http.post(this.url + 'photoAlbum', post);
  }

  updatePost(post: any) {
    return this.http.post(this.url + 'editPost', post);
  }

  comment(comment: any) {
    return this.http.post(this.url + 'comment', comment);
  }

  getAllComments(data: any) {
    return this.http.post(this.url + 'showComments', data);
  }

  likeUnlike(data: any) {
    return this.http.post(this.url + 'likeUnlike', data);
  }

  whoLikedMyPost(data: any) {
    return this.http.post(this.url + 'whoLikedMyPost', data);
  }

  unlike(data: any) {
    return this.http.post(this.url + 'unlikePost', data);
  }

  deletePost(data: any) {
    return this.http.post(this.url + 'deletePost', data);
  }

  deleteComment(data: any) {
    return this.http.post(this.url + 'deleteComment', data);
  }
}
