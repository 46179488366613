import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-team-header',
  templateUrl: './team-header.component.html',
  styleUrls: ['./team-header.component.css']
})
export class TeamHeaderComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryData = JSON.parse(localStorage.getItem('countryData'));
    this.countryDetails();
  }
  countryDetails() {
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).subscribe((res: any) => {
      this.countryData = res;
      localStorage.setItem('countryData', JSON.stringify(this.countryData));

    });
  }
}
