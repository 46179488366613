import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
// import {Directionality} from '@angular/cdk/bidi';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],

})
export class AppComponent implements OnInit {
  
  title = 'f-f';
  location: Location;

  // constructor(dir: Directionality) {
  //   console.log(dir.value);

  //   dir.change.subscribe((changes) => {
  //     console.log(changes)
  //   });
  // }

  ngOnInit() {
    if (environment.production) {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }

}
