import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { first } from 'rxjs/operators';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-team-legends',
  templateUrl: './team-legends.component.html',
  styleUrls: ['./team-legends.component.css']
})
export class TeamLegendsComponent implements OnInit {

  id: number;
  countryData: any = {};
  currentUser: any;
  status: boolean;
  followed: boolean;
  selectFriend: any = {};
  clubs: [];
  searchText: string;
  isLoaderVisible = false;

  language = '';
  direction;
  defaultLanguage;

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService) {
      this.messageService.selectedLanguage.subscribe(res => {
        this.language = res.lang;
        this.direction = res.dir;
      }); 
    }
  members = [
    {
      name: 'Legend 1',
      position: 'Goal Keeper',
    },
    {
      name: 'Legend 2',
      position: 'Goal Keeper',
    },
    {
      name: 'Legend 3',
      position: 'Striker',
    },
    {
      name: 'Legend 4',
      position: 'Striker',
    },
    {
      name: 'Legend 5',
      position: 'Goal Keeper',
    },
    {
      name: 'Legend 6',
      position: 'Goal Keeper',
    },
    {
      name: 'Legend 7',
      position: 'Goal Keeper',
    },
    {
      name: 'Legend 8',
      position: 'Striker',
    },
    {
      name: 'Legend 9',
      position: 'Striker',
    },
    {
      name: 'Legend 10',
      position: 'Striker',
    },
  ];
  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.countryDetails();
    this.defaultLanguage = JSON.parse(localStorage.getItem('language'));
    this.direction = this.defaultLanguage.dir;
  }
  countryDetails() {
    this.isLoaderVisible = true;
    const data = {
      countryId: this.id,
    };
    this.iffhsService.getCountryDetails(data).pipe(first<any>())
      .subscribe(countryData => this.countryData = countryData[0]);
    this.isLoaderVisible = false;
  }


}
