import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { UserService } from 'src/app/data-services/user.service';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-edit-award-banner',
  templateUrl: './edit-award-banner.component.html',
  styleUrls: ['./edit-award-banner.component.css']
})
export class EditAwardBannerComponent implements OnInit {

  awardBannerForm: FormGroup;
  id: number;
  countryData: any = {};
  searchText: any;
  currentUser: any;
  isLoaderVisible = false;
  submitted = false;
  isSubmitted = true;
  errorMessage: string = '';
  visible = true;
  onChangeValue = '';
  banner: any = {};
  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private toastrService: ToastrService,

  ) {
    this.route.queryParams.subscribe(params => this.id = params.id);
    this.getAwardBannerById();
  }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  getAwardBannerById() {
    this.isLoaderVisible = true;
    const data = {
      bannerId: this.id,
    };
    this.iffhsService.getAwardBannerById(data).subscribe((res: any) => {
      this.banner = res;
      this.isLoaderVisible = false;
    },
      (error) => {
        console.log(error);
      },
      () => {
        this.initForm();
      }
    );
  }
  initForm() {
    this.awardBannerForm = this.formBuilder.group({
      bannerId: [this.id],
      bannerName: [this.banner.name, Validators.required],
      numberOfAwards: [''],
      bannerPhoto: [''],
      createdBy: [this.currentUser.success.user.id],

    });
  }

  onImageSelect(event) {
    const bannerPhoto = event.target.files[0];
    const type = event.target.files[0].type;
    if (type.match(/image\/*/) === null) {
      this.toastrService.error('Only images are supported', 'Message!');
      return;
    }
    this.awardBannerForm.get('bannerPhoto').setValue(bannerPhoto);

  }
  goToDashboard() {
    this.router.navigate(['/add-award-banner']);

  }

  get f() { return this.awardBannerForm.controls; }
  editAwardBanner() {
    this.submitted = true;
    if (this.awardBannerForm.invalid) {
      return;
    }
    this.isLoaderVisible = true;
    const fd = new FormData();
    fd.append('bannerId', this.awardBannerForm.get('bannerId').value);
    fd.append('bannerName', this.awardBannerForm.get('bannerName').value);
    fd.append('numberOfAwards', this.awardBannerForm.get('numberOfAwards').value);
    fd.append('createdBy', this.awardBannerForm.get('createdBy').value);
    fd.append('bannerPhoto', this.awardBannerForm.get('bannerPhoto').value);

    this.iffhsService.editAwardBanner(fd).pipe(first()).subscribe(
      data => {
        this.isLoaderVisible = false;
        this.toastrService.success('Award Banner Added!', 'Message!');
        this.submitted = true;
      },
      error => {
        console.log(error);
        this.isLoaderVisible = false;
        this.toastrService.error('Something went wrong please Try Later!', 'Message!');

      }
    );
    this.resetForm();
  }
  resetForm() {
    this.getAwardBannerById();
    this.awardBannerForm = this.formBuilder.group({
      bannerId: [this.id],
      bannerName: [this.banner.name, Validators.required],
      numberOfAwards: [''],
      bannerPhoto: [''],
      createdBy: [this.currentUser.success.user.id],
    });
  }

}
