<app-left-side></app-left-side>

<app-right-side></app-right-side>

<app-header></app-header>
<div *ngIf="isLoaderVisible" class="preloader js-preloader">
    <img src="../../../assets/img/loader.webp">
</div>


<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="clients-grid">

                <div class="h6 title">
                    <h1 class="text-center" style="font-weight: 500;">{{ 'fifa.title' | translate | uppercase}}</h1>
                </div>

                <ul *ngIf="afterSelect" class="cat-list-bg-style align-center sorting-menu">
                    <div (click)="getContinent(1)" class="author-thumb">

                        <a> <img src="../../../assets/img/caf.webp" style="padding: 15px;" width="120" height="120" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 1}">
                                <a href="javascript:void(0);" class="">{{ 'fifa.caf' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(3)"> <img src="../../../assets/img/afc.webp" width="120" height="120" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item" [ngClass]="{'active': selectedId === 3}"><a href="javascript:void(0);">{{ 'fifa.afc' | translate}}</a>
                            </li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(4)"> <img src="../../../assets/img/uefa.webp" width="120" height="120" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 4}"><a href="javascript:void(0);" class="">{{ 'fifa.uefa' | translate}}</a></li>
                        </a>
                    </div>

                    <div class="author-thumb">
                        <a (click)="getContinent(6)"> <img src="../../../assets/img/ofc.webp" width="120" height="120" style="padding: 15px;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 6}"><a href="javascript:void(0);" class="">{{ 'fifa.ofc' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(7)"> <img src="../../../assets/img/conmebol.webp" width="120" height="120" style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 7}"><a href="javascript:void(0);" class="">{{ 'fifa.conmebol' | translate}}</a></li>
                        </a>
                    </div>
                    <div class="author-thumb">
                        <a (click)="getContinent(5)"> <img src="../../../assets/img/concacaf.webp" width="120" height="120" style="padding: 0px 15px; margin: 15px auto;" alt="football teams">
                            <li class="cat-list__item " [ngClass]="{'active': selectedId === 5}"><a href="javascript:void(0);" class="">{{ 'fifa.concacaf' | translate}}</a></li>
                        </a>
                    </div>

                </ul>

            </div>

        </div>
    </div>
</div>

<!-- ... end Top Header-Profile -->
<div *ngIf="selectedId === 1" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 1}">
                    <h5> {{ 'fifa.caf' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 1}"> <img src="../../../assets/img/caf.webp" width="160" height="160" alt="CAF"> </a>
            </div>

            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <div class="h6 title"> <a href="https://www.cafonline.com/"> {{ 'fifa.caf_website' | translate}}</a> </div>
                    <!-- <div class="h6 title">Address: <strong> <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk006lsfPi2EfSK7dj-4SrZuXC_UvZw:1592477782839&q=6th+of+October+City&stick=H4sIAAAAAAAAAOPgE-LUz9U3MDY1y0lWAjOTco1Ms7S0spOt9POL0hPzMqsSSzLz81A4VhmpiSmFpYlFJalFxYtYhc1KMhTy0xT8k0vyk1KLFJwzSyp3sDICAGheoixdAAAA&sa=X&ved=2ahUKEwiV37uEmovqAhXfSRUIHZQPBYEQmxMoATAkegQIExAD">
                                6th of October City, Egypt </a> </strong> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : <strong> {{ 'fifa.caf_num' | translate}}</strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedId === 3" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 3}">
                    <h5> {{ 'fifa.afc' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 3}"> <img src="../../../assets/img/afc.webp" width="160" height="160" alt="AFC"> </a>
            </div>

            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 title">Name: <strong> AFC</strong> </div> -->
                    <div class="h6 title"> <a href="https://www.the-afc.com/"> {{ 'fifa.afc_website' | translate}} </a> </div>
                    <!-- <div class="h6 title">Address: <strong> <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk02N2WXWWsoWWJdAh-hLyMOWN9CXbw:1592477848317&q=Salman+bin+Ibrahim+Al+Khalifa&stick=H4sIAAAAAAAAAOPgE-LUz9U3MDY1yzJS4gIxTQuN40uStNQyyq30k_NzclKTSzLz8_Tzi9IT8zKrEkGcYquCotTizJTUvJJFrLLBiTm5iXkKSZl5Cp5JRYkZmbkKjjkK3hmJOZlpiTtYGQGmYOM9ZAAAAA&sa=X&ved=2ahUKEwjEqNijmovqAhWztXEKHeGzCY4QmxMoATAlegQIEhAD">
                                Kuala Lumpur, Malaysia</a> </strong> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : <strong> {{ 'fifa.afc_num' | translate}}</strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedId === 4" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 4}">
                    <h5> {{ 'fifa.uefa' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 4}"> <img src="../../../assets/img/uefa.webp" width="160" height="160" alt="UEFA"> </a>
            </div>
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 title">Name: <strong> UEFA</strong> </div> -->
                    <div class="h6 title"> <a href="https://www.uefa.com/"> {{ 'fifa.uefa_website' | translate}} </a> </div>
                    <!-- <div class="h6 title">Address: <strong> <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk01zngKty7m5whKrNvvZXHjtImGArQ:1592477930394&q=Aleksander+%C4%8Ceferin&stick=H4sIAAAAAAAAAONgVuLVT9c3NEw2LMg1tjCvWsQq7JiTml2cmJeSWqRwpCc1LbUoMw8AyisaHycAAAA&sa=X&ved=2ahUKEwjg2OnKmovqAhWlTxUIHenkANgQmxMoATAxegQIDBAD">
                                Aleksander Čeferin</a> </strong> </div> -->
                    <!-- <div class="h6 title">President: <a
                            href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk01zngKty7m5whKrNvvZXHjtImGArQ:1592477930394&q=Nyon&stick=H4sIAAAAAAAAAOPgE-LQz9U3MK-0zFbiBLGMCs0Ls7S0spOt9POL0hPzMqsSSzLz81A4VhmpiSmFpYlFJalFxYtYWfwq8_N2sDICAB5jfnlNAAAA&sa=X&ved=2ahUKEwjg2OnKmovqAhWlTxUIHenkANgQmxMoATAyegQIChAD">
                            Nyon, Switzerland </a> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : {{ 'fifa.uefa_num' | translate}} <strong> </strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedId === 6" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 6}">
                    <h5> {{ 'fifa.ofc' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 6}"> <img src="../../../assets/img/ofc.webp" width="160" height="160" alt="OFC"> </a>
            </div>
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">
                    <!-- <div class="h6 title">Name: <strong> OFC</strong> </div> -->
                    <div class="h6 title"> <a href="https://www.oceaniafootball.com/"> {{ 'fifa.ofc_website' | translate}}
                        </a> </div>
                    <!-- <div class="h6 title"> Address: <strong> Auckland, New Zealand <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk01vYP2P-S-PMetR4qC6xdyAxg4sow:1592477982250&q=Lambert+Maltock&stick=H4sIAAAAAAAAAOPgE-LUz9U3MCzIMzdR4tVP1zc0TC4uNDKxqErSUssot9JPzs_JSU0uyczP088vSk_My6xKBHGKrQqKUoszU1LzShax8vsk5ialFpUo-CbmlOQnZ-9gZQQAh6PPiVkAAAA&sa=X&ved=2ahUKEwiE98bjmovqAhWio3EKHWbfABQQmxMoATAYegQIExAD">
                            </a> </strong> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : {{ 'fifa.ofc_num' | translate}} <strong> </strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>



                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedId === 5" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 5}">
                    <h5> {{ 'fifa.concacaf' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 5}"> <img src="../../../assets/img/concacaf.webp" width="160" height="160" alt="CONCACAF"> </a>
            </div>
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">

                    <div class="h6 title"> <a href="https://www.concacaf.com/"> {{ 'fifa.concacaf_website' | translate}} </a> </div>
                    <!-- <div class="h6 title">Address: <strong> <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk00fYRAfevHEBICM2ZRopdVQDwMAaw:1592477191655&q=Miami&stick=H4sIAAAAAAAAAOPgE-LUz9U3MCwyrspT4gAx04zKDLSMMsqt9JPzc3JSk0sy8_P084vSE_MyqxJBnGKrjNTElMLSxKKS1KJihZz8ZLDwIlZW38zE3MwdrIwA4VskaFYAAAA&sa=X&ved=2ahUKEwiJ7cjql4vqAhVNTxUIHVeSBK4QmxMoATAsegQIChAD&biw=1920&bih=966">
                                Miami, Florida, United States </a> </strong> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : {{ 'fifa.concacaf_num' | translate}} <strong> </strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="selectedId === 7" class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="h3 text-center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 7}">
                    <h5> {{ 'fifa.conmebol' | translate}}</h5>
                </a>
            </div>
            <div class="center">
                <a routerLink="/conf-timeline" [queryParams]="{'id': 7}"> <img src="../../../assets/img/conmebol.webp" width="160" height="160" alt="CONMEBOL"> </a>
            </div>
            <div class="ui-block responsive-flex">
                <div class="ui-block-title">

                    <div class="h6 title"><a href="https://www.conmebol.com/"> {{ 'fifa.conmebol_website' | translate}} </a> </div>
                    <!-- <div class="h6 title">Address: <strong> <a
                                href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk02E6kGWhWaPhqLPwWcRdJgUps-_pQ:1592477461575&q=Luque+Central+Department,+Paraguay&stick=H4sIAAAAAAAAAOPgE-LUz9U3MMo2LMxRAjOTzTKyzbW0spOt9POL0hPzMqsSSzLz81A4VhmpiSmFpYlFJalFxYtYlXxKC0tTFZxT80qKEnMUXFILgDK5QJ6OQkBiUWJ6aWLlDlZGAGR3Py1sAAAA&sa=X&ved=2ahUKEwjPuqPrmIvqAhWkaRUIHU_jD_kQmxMoATAmegQIChAD">
                                Luque, Paraguay </a> </strong> </div> -->
                    <!-- <div class="h6 title">President: <a href="https://www.google.com/search?safe=strict&client=firefox-b-d&sxsrf=ALeKk02E6kGWhWaPhqLPwWcRdJgUps-_pQ:1592477461575&q=Alejandro+Dominguez&stick=H4sIAAAAAAAAAOPgE-LUz9U3MMo2LMxR4tVP1zc0TDYuN7A0NjHXUssot9JPzs_JSU0uyczP088vSk_My6xKBHGKrQqKUoszU1LzShaxCjvmpGYl5qUU5Su45Odm5qWXplbtYGUEAI7uR-hdAAAA&sa=X&ved=2ahUKEwjPuqPrmIvqAhWkaRUIHU_jD_kQmxMoATAlegQICxAD">
                            Alejandro Dominguez </a> </div> -->
                    <div class="h6 title">{{ 'fifa.contact' | translate}} : <strong> {{ 'fifa.conmebol_num' | translate}} </strong> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="{{ 'header.search' | translate}}">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="container" [dir]="direction">
    <div class="row">
        <div class="col col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="ui-block responsive-flex">
                <!-- <div class="ui-block-title">
                     <div class="h6 title"> </div> 
                    <div class="h6 title"> </div>
                    <form class="w-search">
                        <div class="form-group with-button">
                            <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" [ngModelOptions]="{standalone: true}" placeholder="Search">
                            <button>
                                <svg class="olymp-magnifying-glass-icon">
                                    <use
                                        xlink:href="assets/../../../../../assets/svg-icons/sprites/icons.svg#olymp-magnifying-glass-icon">
                                    </use>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div> -->
            </div>
        </div>
    </div>
</div>



<div class="container">
    <div class="row">
        <div *ngFor="let country of countries | filter:searchText" class="col col-xl-4 col-lg-4 col-lg-6 col-md-12 col-sm-12 col-12">
            <div class="ui-block">
                <div class="birthday-item inline-items">
                    <div class="author-thumb">
                        <a routerLink="/team-profile" [queryParams]="{'id': country.id}"> <img src="../../../assets/svg-icons/1x1/{{country.code | lowercase}}.svg" alt="flag" style="width: 55px; height: 55px;"> </a>
                    </div>
                    <div class="birthday-author-name" style="margin-left: 0.5rem;">
                        <a routerLink="/team-profile" [queryParams]="{'id': country.id}" class="h6 author-name">{{ country.name | titlecase}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>