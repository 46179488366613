import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IffhsService } from 'src/app/data-services/iffhs.service';
import { AuthenticationService } from 'src/app/data-services/authentication.service';
import { MessageService } from 'src/app/data-services/message.service';

@Component({
  selector: 'app-award-details',
  templateUrl: './award-details.component.html',
  styleUrls: ['./award-details.component.css'],
})
export class AwardDetailsComponent implements OnInit {
  awards = [];
  years = [];
  searchText: string;
  id: number;
  currentUser: any;
  countries = [];
  title: string;
  bannerId: number;
  isLoaderVisible = false;
  visible = true;
  flag = [];

  constructor(
    private route: ActivatedRoute,
    private iffhsService: IffhsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.authenticationService.currentUser.subscribe(
      (x) => (this.currentUser = x)
    );
    this.route.queryParams.subscribe((params) => (this.id = params.id));
    if (this.id === 63) {
      this.visible = false;
    }
    this.getAwardDetails();
    this.years = Array(35)
      .fill(0)
      .map((x, i) => ({ name: i + 1987 }));
  }
  sendMessage(): void {
    this.messageService.sendMessage('test');
  }

  getAwardDetails() {
    this.isLoaderVisible = true;
    const data = {
      awardId: this.id,
    };
    this.iffhsService.getAwardDetails(data).subscribe((res: any) => {
      this.awards = res.awards;
      this.title = res.title.name;
      this.bannerId = res.title.type_id;
      this.countries = res.countries;
      this.isLoaderVisible = false;
      // console.log(this.bannerId);
      // for (let i = 0; i < this.countries.length; i++) {
      //   for (let j = 0; j < this.awards.length; j++) {
      //     if (this.countries[i].name === this.awards[j].country_id) {
      //       // console.log(this.countries[i].code);
      //       this.flag[i] = this.countries[i].code;
      //       console.log(this.flag);

      //     }
      //   }

      // }
    });
  }

  onChangeFilter(e) {
    this.isLoaderVisible = true;
    const data = {
      awardId: this.id,
      filterBy: e.target.value || 0,
    };
    this.iffhsService.getAwardDetails(data).subscribe((res: any) => {
      this.awards = res.awards;
      this.title = res.title.name;
      this.countries = res.countries;
      this.isLoaderVisible = false;
    });
  }
}
